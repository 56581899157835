import type { IUOptions } from '@types'

class BankAccountEnums {
  public bankAccountCodeData = [
    {
      "value": "000",
      "label": "000 - Banco Bankpar S.A."
    },
    {
      "value": "001",
      "label": "001 - Banco do Brasil S.A."
    },
    {
      "value": "003",
      "label": "003 - Banco da Amazônia S.A."
    },
    {
      "value": "004",
      "label": "004 - Banco do Nordeste do Brasil S.A."
    },
    {
      "value": "012",
      "label": "012 - Banco Standard de Investimentos S.A."
    },
    {
      "value": "014",
      "label": "014 - Natixis Brasil S.A. Banco Múltiplo"
    },
    {
      "value": "018",
      "label": "018 - Banco Tricury S.A."
    },
    {
      "value": "019",
      "label": "019 - Banco Azteca do Brasil S.A."
    },
    {
      "value": "021",
      "label": "021 - BANESTES S.A. Banco do Estado do Espírito Santo"
    },
    {
      "value": "024",
      "label": "024 - Banco de Pernambuco S.A. - BANDEPE"
    },
    {
      "value": "025",
      "label": "025 - Banco Alfa S.A."
    },
    {
      "value": "029",
      "label": "029 - Banco Banerj S.A."
    },
    {
      "value": "031",
      "label": "031 - Banco Beg S.A."
    },
    {
      "value": "033",
      "label": "033 - Banco Santander (Brasil) S.A."
    },
    {
      "value": "036",
      "label": "036 - Banco Bradesco BBI S.A."
    },
    {
      "value": "037",
      "label": "037 - Banco do Estado do Pará S.A."
    },
    {
      "value": "039",
      "label": "039 - Banco do Estado do Piauí S.A. - BEP"
    },
    {
      "value": "040",
      "label": "040 - Banco Cargill S.A."
    },
    {
      "value": "041",
      "label": "041 - Banco do Estado do Rio Grande do Sul S.A."
    },
    {
      "value": "044",
      "label": "044 - Banco BVA S.A"
    },
    {
      "value": "045",
      "label": "045 - Banco Opportunity S.A."
    },
    {
      "value": "047",
      "label": "047 - Banco do Estado de Sergipe S.A"
    },
    {
      "value": "062",
      "label": "062 - Hipercard Banco Múltiplo S.A"
    },
    {
      "value": "063",
      "label": "063 - Banco Ibi S.A. Banco Múltiplo"
    },
    {
      "value": "064",
      "label": "064 - Goldman Sachs do Brasil Banco Múltiplo S.A."
    },
    {
      "value": "065",
      "label": "065 - Banco Bracce S.A."
    },
    {
      "value": "066",
      "label": "066 - Banco Morgan Stanley S.A."
    },
    {
      "value": "069",
      "label": "069 - BPN Brasil Banco Múltiplo S.A."
    },
    {
      "value": "070",
      "label": "070 - BRB - Banco de Brasília S.A."
    },
    {
      "value": "072",
      "label": "072 - Banco Rural Mais S.A."
    },
    {
      "value": "073",
      "label": "073 - BB Banco Popular do Brasil S.A."
    },
    {
      "value": "074",
      "label": "074 - Banco J. Safra S.A."
    },
    {
      "value": "075",
      "label": "075 - Banco ABN AMRO S.A."
    },
    {
      "value": "076",
      "label": "076 - Banco KDB S.A."
    },
    {
      "value": "077",
      "label": "077 - Banco Intermedium S.A."
    },
    {
      "value": "078",
      "label": "078 - BES Investimento do Brasil S.A. -Banco de Investimento"
    },
    {
      "value": "081",
      "label": "081 - Concórdia Banco S.A."
    },
    {
      "value": "082",
      "label": "082 - Banco Topázio S.A."
    },
    {
      "value": "083",
      "label": "083 - Banco da China Brasil S.A."
    },
    {
      "value": "084",
      "label": "084 - Uniprime Norte do Paraná"
    },
    {
      "value": "085",
      "label": "085 - Cooperativa Central de Crédito Urbano-CECRED"
    },
    {
      "value": "086",
      "label": "086 - OBOE Crédito Financiamento e Investimento S.A."
    },
    {
      "value": "087",
      "label": "087 - Cooperativa Unicred Central Santa Catarina"
    },
    {
      "value": "088",
      "label": "088 - Banco Randon S.A."
    },
    {
      "value": "089",
      "label": "089 - Cooperativa de Crédito Rural da Região de Mogiana"
    },
    {
      "value": "090",
      "label": "090 - Cooperativa Central de Economia e Crédito Mutuo das Unicreds"
    },
    {
      "value": "091",
      "label": "091 - Unicred Central do Rio Grande do Sul"
    },
    {
      "value": "092",
      "label": "092 - Brickell S.A. Crédito, financiamento e Investimento"
    },
    {
      "value": "093",
      "label": "093 - Pólocred SCM"
    },
    {
      "value": "094",
      "label": "094 - Banco Petra S.A."
    },
    {
      "value": "095",
      "label": "095 - Banco Confidence de Câmbio S.A."
    },
    {
      "value": "096",
      "label": "096 - Banco BM&FBOVESPA de Serviços de Liquidação e Custódia S.A"
    },
    {
      "value": "097",
      "label": "097 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda."
    },
    {
      "value": "098",
      "label": "098 - CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL"
    },
    {
      "value": "099",
      "label": "099 - Cooperativa Central de Economia e Credito Mutuo das Unicreds"
    },
    {
      "value": "10003",
      "label": "10003 - SmartMEI BPP"
    },
    {
      "value": "10004",
      "label": "10004 - HUB CARD S.A"
    },
    {
      "value": "10005",
      "label": "10005 - CDT"
    },
    {
      "value": "10006",
      "label": "10006 - Dock"
    },
    {
      "value": "104",
      "label": "104 - Caixa Econômica Federal"
    },
    {
      "value": "107",
      "label": "107 - Banco BBM S.A."
    },
    {
      "value": "114",
      "label": "114 - Central das Coop. de Economia e Crédito Mutuo do Est. do ES"
    },
    {
      "value": "119",
      "label": "119 - Banco Western Union do Brasil S.A."
    },
    {
      "value": "122",
      "label": "122 - Banco BERJ S.A."
    },
    {
      "value": "125",
      "label": "125 - Brasil Plural S.A. - Banco Múltiplo"
    },
    {
      "value": "130",
      "label": "130 - Caruana SCFI"
    },
    {
      "value": "136",
      "label": "136 - CONFEDERACAO NACIONAL DAS COOPERATIVAS CENTRAIS UNICREDS"
    },
    {
      "value": "168",
      "label": "168 - HSBC Finance (Brasil) S.A. - Banco Múltiplo"
    },
    {
      "value": "197",
      "label": "197 - Stone Pagamentos S.A."
    },
    {
      "value": "204",
      "label": "204 - Banco Bradesco Cartões S.A."
    },
    {
      "value": "208",
      "label": "208 - Banco BTG Pactual S.A."
    },
    {
      "value": "212",
      "label": "212 - Banco Original S.A."
    },
    {
      "value": "213",
      "label": "213 - Banco Arbi S.A."
    },
    {
      "value": "214",
      "label": "214 - Banco Dibens S.A."
    },
    {
      "value": "215",
      "label": "215 - Banco Comercial e de Investimento Sudameris S.A."
    },
    {
      "value": "217",
      "label": "217 - Banco John Deere S.A."
    },
    {
      "value": "222",
      "label": "222 - Banco Credit Agricole Brasil S.A."
    },
    {
      "value": "224",
      "label": "224 - Banco Fibra S.A."
    },
    {
      "value": "2246",
      "label": "2246 - Zoop Instituição de Pagamentos"
    },
    {
      "value": "225",
      "label": "225 - Banco Brascan S.A."
    },
    {
      "value": "229",
      "label": "229 - Banco Cruzeiro do Sul S.A."
    },
    {
      "value": "230",
      "label": "230 - Unicard Banco Múltiplo S.A."
    },
    {
      "value": "233",
      "label": "233 - Banco Cifra S.A."
    },
    {
      "value": "237",
      "label": "237 - Banco Bradesco S.A. Next"
    },
    {
      "value": "241",
      "label": "241 - Banco Clássico S.A."
    },
    {
      "value": "243",
      "label": "243 - Banco Máxima S.A."
    },
    {
      "value": "246",
      "label": "246 - Banco ABC Brasil S.A."
    },
    {
      "value": "248",
      "label": "248 - Banco Boavista Interatlântico S.A."
    },
    {
      "value": "249",
      "label": "249 - Banco Investcred Unibanco S.A."
    },
    {
      "value": "250",
      "label": "250 - BCV - Banco de Crédito e Varejo S.A."
    },
    {
      "value": "254",
      "label": "254 - Paraná Banco S.A."
    },
    {
      "value": "263",
      "label": "263 - Banco Cacique S.A."
    },
    {
      "value": "265",
      "label": "265 - Banco Fator S.A."
    },
    {
      "value": "266",
      "label": "266 - Banco Cédula S.A."
    },
    {
      "value": "273",
      "label": "273 - Sulcredi (Cooperativa de Credito Rural de Sao Miguel do Oest)"
    },
    {
      "value": "300",
      "label": "300 - Banco de La Nacion Argentina"
    },
    {
      "value": "301",
      "label": "301 - BPP Instituição de Pagamento"
    },
    {
      "value": "301",
      "label": "301 - BPP Instituição de Pagamento S.A."
    },
    {
      "value": "318",
      "label": "318 - Banco BMG S.A."
    },
    {
      "value": "320",
      "label": "320 - Banco Industrial e Comercial S.A."
    },
    {
      "value": "323",
      "label": "323 - MERCADOPAGO.COM REPRESENTACOES LTDA."
    },
    {
      "value": "332",
      "label": "332 - ACESSO SOLUCOES PAGAMENTO SA."
    },
    {
      "value": "335",
      "label": "335 - Banco Digio S.A."
    },
    {
      "value": "336",
      "label": "336 - Banco C6 S.A"
    },
    {
      "value": "341",
      "label": "341 - Itaú Unibanco S.A."
    },
    {
      "value": "356",
      "label": "356 - Banco Real S.A."
    },
    {
      "value": "363",
      "label": "363 - SOCOPA SC PAULISTA S.A."
    },
    {
      "value": "364",
      "label": "364 - GERENCIANET PAGTOS BRASIL LTDA"
    },
    {
      "value": "366",
      "label": "366 - Banco Société Générale Brasil S.A."
    },
    {
      "value": "370",
      "label": "370 - Banco Mizuho do Brasil S.A."
    },
    {
      "value": "376",
      "label": "376 - Banco J. P. Morgan S.A."
    },
    {
      "value": "383",
      "label": "383 - JUNO"
    },
    {
      "value": "389",
      "label": "389 - Banco Mercantil do Brasil S.A."
    },
    {
      "value": "394",
      "label": "394 - Banco Bradesco Financiamentos S.A."
    },
    {
      "value": "394",
      "label": "394 - Banco Finasa BMC S.A."
    },
    {
      "value": "396",
      "label": "396 - HUB PAGAMENTOS S.A."
    },
    {
      "value": "399",
      "label": "399 - HSBC Bank Brasil S.A. - Banco Múltiplo"
    },
    {
      "value": "403",
      "label": "403 - CORA SCD S.A."
    },
    {
      "value": "409",
      "label": "409 - UNIBANCO - União de Bancos Brasileiros S.A."
    },
    {
      "value": "412",
      "label": "412 - Banco Capital S.A."
    },
    {
      "value": "422",
      "label": "422 - Banco Safra S.A."
    },
    {
      "value": "453",
      "label": "453 - Banco Rural S.A."
    },
    {
      "value": "456",
      "label": "456 - Banco de Tokyo-Mitsubishi UFJ Brasil S.A."
    },
    {
      "value": "464",
      "label": "464 - Banco Sumitomo Mitsui Brasileiro S.A."
    },
    {
      "value": "473",
      "label": "473 - Banco Caixa Geral - Brasil S.A."
    },
    {
      "value": "477",
      "label": "477 - Citibank S.A."
    },
    {
      "value": "479",
      "label": "479 - Banco ItaúBank S.A"
    },
    {
      "value": "487",
      "label": "487 - Deutsche Bank S.A. - Banco Alemão"
    },
    {
      "value": "488",
      "label": "488 - JPMorgan Chase Bank"
    },
    {
      "value": "492",
      "label": "492 - ING Bank N.V."
    },
    {
      "value": "494",
      "label": "494 - Banco de La Republica Oriental del Uruguay"
    },
    {
      "value": "495",
      "label": "495 - Banco de La Provincia de Buenos Aires"
    },
    {
      "value": "505",
      "label": "505 - Banco Credit Suisse (Brasil) S.A."
    },
    {
      "value": "600",
      "label": "600 - Banco Luso Brasileiro S.A."
    },
    {
      "value": "604",
      "label": "604 - Banco Industrial do Brasil S.A."
    },
    {
      "value": "610",
      "label": "610 - Banco VR S.A."
    },
    {
      "value": "611",
      "label": "611 - Banco Paulista S.A."
    },
    {
      "value": "612",
      "label": "612 - Banco Guanabara S.A."
    },
    {
      "value": "613",
      "label": "613 - Banco Pecúnia S.A."
    },
    {
      "value": "626",
      "label": "626 - Banco Ficsa S.A."
    },
    {
      "value": "630",
      "label": "630 - Banco Intercap S.A."
    },
    {
      "value": "633",
      "label": "633 - Banco Rendimento S.A."
    },
    {
      "value": "634",
      "label": "634 - Banco Triângulo S.A."
    },
    {
      "value": "637",
      "label": "637 - Banco Sofisa S.A."
    },
    {
      "value": "638",
      "label": "638 - Banco Prosper S.A."
    },
    {
      "value": "641",
      "label": "641 - Banco Alvorada S.A."
    },
    {
      "value": "643",
      "label": "643 - Banco Pine S.A."
    },
    {
      "value": "652",
      "label": "652 - Itaú Unibanco Holding S.A."
    },
    {
      "value": "653",
      "label": "653 - Banco Indusval S.A."
    },
    {
      "value": "655",
      "label": "655 - Banco Votorantim S.A."
    },
    {
      "value": "707",
      "label": "707 - Banco Daycoval S.A."
    },
    {
      "value": "712",
      "label": "712 - Banco Ourinvest S.A."
    },
    {
      "value": "719",
      "label": "719 - Banif-Banco Internacional do Funchal (Brasil)S.A."
    },
    {
      "value": "721",
      "label": "721 - Banco Credibel S.A."
    },
    {
      "value": "724",
      "label": "724 - Banco Porto Seguro S.A."
    },
    {
      "value": "734",
      "label": "734 - Banco Gerdau S.A."
    },
    {
      "value": "735",
      "label": "735 - Banco Pottencial S.A."
    },
    {
      "value": "738",
      "label": "738 - Banco Morada S.A."
    },
    {
      "value": "739",
      "label": "739 - Banco Cetelem S.A."
    },
    {
      "value": "740",
      "label": "740 - Banco Barclays S.A."
    },
    {
      "value": "741",
      "label": "741 - Banco Ribeirão Preto S.A."
    },
    {
      "value": "743",
      "label": "743 - Banco Semear S.A."
    },
    {
      "value": "744",
      "label": "744 - BankBoston N.A."
    },
    {
      "value": "745",
      "label": "745 - Banco Citibank S.A."
    },
    {
      "value": "747",
      "label": "747 - Banco Rabobank International Brasil S.A."
    },
    {
      "value": "748",
      "label": "748 - Banco Cooperativo Sicredi S.A."
    },
    {
      "value": "749",
      "label": "749 - Banco Simples S.A."
    },
    {
      "value": "751",
      "label": "751 - Scotiabank Brasil S.A. Banco Múltiplo"
    },
    {
      "value": "752",
      "label": "752 - Banco BNP Paribas Brasil S.A."
    },
    {
      "value": "753",
      "label": "753 - NBC Bank Brasil S.A. - Banco Múltiplo"
    },
    {
      "value": "755",
      "label": "755 - Bank of America Merrill Lynch Banco Múltiplo S.A."
    },
    {
      "value": "756",
      "label": "756 - Banco Cooperativo do Brasil S.A. - BANCOOB"
    },
    {
      "value": "757",
      "label": "757 - Banco KEB do Brasil S.A."
    },
    {
      "value": "9991",
      "label": "9991 - Conductor FlexAssists"
    },
    {
      "value": "9992",
      "label": "9992 - Conductor Alpha Card"
    },
    {
      "value": "M03",
      "label": "M03 - Banco Fiat S.A."
    },
    {
      "value": "M03",
      "label": "M03 - Banco Itaucred Financiamentos S.A."
    },
    {
      "value": "M11",
      "label": "M11 - Banco IBM S.A."
    },
    {
      "value": "M12",
      "label": "M12 - Banco Maxinvest S.A."
    },
    {
      "value": "M14",
      "label": "M14 - Banco Volkswagen S.A."
    },
    {
      "value": "M15",
      "label": "M15 - Banco BRJ S.A."
    },
    {
      "value": "M16",
      "label": "M16 - Banco Rodobens S.A."
    },
    {
      "value": "M18",
      "label": "M18 - Banco Ford S.A."
    },
    {
      "value": "M19",
      "label": "M19 - Banco CNH Capital S.A."
    },
    {
      "value": "M20",
      "label": "M20 - Banco Toyota do Brasil S.A."
    },
    {
      "value": "M21",
      "label": "M21 - Banco Daimlerchrysler S.A."
    },
    {
      "value": "M22",
      "label": "M22 - Banco Honda S.A."
    },
    {
      "value": "M23",
      "label": "M23 - Banco Volvo (Brasil) S.A."
    },
    {
      "value": "M24",
      "label": "M24 - Banco PSA Finance Brasil S.A."
    },
    {
      "value": "079",
      "label": "079 - Banco Original do Agronegócio S.A."
    },
    {
      "value": "10001",
      "label": "10001 - Zoop BPP"
    },
    {
      "value": "121",
      "label": "121 - Banco AGIPLAN S.A."
    },
    {
      "value": "121",
      "label": "121 - BCO AGIBANK S.A."
    },
    {
      "value": "133",
      "label": "133 - Banco Cresol"
    },
    {
      "value": "172",
      "label": "172 - Albatross Corretora"
    },
    {
      "value": "184",
      "label": "184 - Banco Itaú BBA S.A."
    },
    {
      "value": "191",
      "label": "191 - Nova Futura CTVM LTDA"
    },
    {
      "value": "218",
      "label": "218 - Banco Bonsucesso S.A."
    },
    {
      "value": "260",
      "label": "260 - Nu Pagamentos"
    },
    {
      "value": "274",
      "label": "274 - Money Plus sociedade de crédito ao microempreendedor e a empresa de pequeno porte"
    },
    {
      "value": "280",
      "label": "280 - Avista S.A. CFI"
    },
    {
      "value": "290",
      "label": "290 - PagBank"
    },
    {
      "value": "623",
      "label": "623 - Banco Panamericano S.A."
    },
    // {
    //   "value": "",
    //   "label": "Banco Caterpillar S.A."
    // },
    // {
    //   "value": "",
    //   "label": "Banco CSF S.A."
    // },
    // {
    //   "value": "",
    //   "label": "Banco Fidis S.A."
    // },
    // {
    //   "value": "",
    //   "label": "Banco Itaucard S.A."
    // },
    // {
    //   "value": "",
    //   "label": "Banco Yamaha Motor S.A."
    // },
    // {
    //   "value": "",
    //   "label": "BNY Mellon Banco S.A."
    // },
    // {
    //   "value": "",
    //   "label": "Standard Chartered Bank (Brasil) SA–Bco Invest."
    // },
    // {
    //   "value": "",
    //   "label": "UBS Brasil Banco de Investimento S.A."
    // }
  ]

  public backAccountType = [
    {
      label: 'Conta Corrente',
      value: 'CONTA_CORRENTE'
    },
    {
      label: 'Conta Poupança',
      value: 'CONTA_POUPANCA'
    }
  ]

  public getBankAccountCode(param?: string): IUOptions {
    const filtered = this.bankAccountCodeData.find(item => item.value === param)

    return !filtered ? { label: 'N/I', value: null } : filtered
  }

  public getBankAccountType(param?: string): IUOptions {
    const filtered = this.bankAccountCodeData.find(item => item.value === param)

    return !filtered ? { label: 'N/I', value: null } : filtered
  }
}

export default new BankAccountEnums()
