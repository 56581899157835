import styled from 'styled-components';

export const AuthLayoutWrap = styled.div`
    .container-atom#account-access-content {
        .h1-atom {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 1.75rem;
        }
    }
`;
