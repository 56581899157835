import { generateURL } from './prefix';
import api from '../api';

import type { IAPropertyType } from '@types';

export const getAdministratorPropertiesTypesIndex = async () => {
    const url = generateURL(`/imoveis/tipos`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorPropertiesTypesShow = async (id: number | string) => {
    const url = generateURL(`/imoveis/tipos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorPropertiesTypes = async (
    payload: Partial<IAPropertyType>
) => {
    const url = generateURL(`/imoveis/tipos`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorPropertiesTypes = async (
    id: number | string,
    payload: Partial<IAPropertyType>
) => {
    const url = generateURL(`/imoveis/tipos/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorPropertiesTypes = async (id: number | string) => {
    const url = generateURL(`/imoveis/tipos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
