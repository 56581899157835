import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Table } from 'components/organisms';
import { Button, HelperBoolean, HelperOptions } from 'components/atoms';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getRealStateBannerIndex, removeRealStateBanner } from 'services';
import { sweetAlert } from 'utils';

import { BannerListWrap } from './styles';
import Layout from '../../Layout';

const Banner = () => {
    const navigate = useNavigate();
    const title = 'Banners';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function newRegister() {
        onHandleNavigate(
            getLinkWithSlug(ROUTES_REAL_STATE.BANNER.FORM.fullPath)
        );
    }

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.BANNER.base,
        () => getRealStateBannerIndex(),
        {refetchOnMount: true}
    );

    const { mutate: removeRegister, isLoading: isLoadingRemove } = useMutation(
        ({ id }: { id: number }) => removeRealStateBanner(Number(id)),
        {
            mutationKey: QUERIES_REAL_STATE.BANNER.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(QUERIES_REAL_STATE.BANNER.base);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function editRegister(id: string | number) {
        onHandleNavigate(
            getLinkWithSlug(`${ROUTES_REAL_STATE.BANNER.FORM.fullPath}/${id}`)
        );
    }

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_REAL_STATE.BANNER.fullPath),
                },
            ]}
            title={title}
            buttonTitle={
                <Button color="nonary" onClick={() => newRegister()}>
                    Novo Registro
                </Button>
            }
        >
            <BannerListWrap className="banner-template">
                <Table
                    isLoading={isLoadingList || isLoadingRemove}
                    columns={[
                        {
                            dataField: 'titulo',
                            text: 'Título',
                            sort: true,
                            headerStyle: { width: '100%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Publicado',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperBoolean value={fieldValue} />
                            ),
                            headerStyle: { width: '15rem' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    editFunction: () =>
                                        editRegister(fieldValue),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                            headerStyle: { minWidth: '10%' },
                        },
                    ]}
                    data={dataList}
                />
            </BannerListWrap>
        </Layout>
    );
};

export default Banner;
