import styled from 'styled-components'

export const ScheduledItemListWrap = styled.div`
  padding: 1.25rem;
  border: 0.188rem solid ${props => props.theme.colors.borderPrimary};
  margin-bottom: 0.625rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .column-left {
    display: flex;
    flex-direction: row;
    align-items: center;

    span.date {
      display: block;
      margin: 0 1.375rem 0 0.625rem;
      font-size: 0.813rem;
      font-weight: 700;
      color: ${props => props.theme.colors.tertiary};
      text-transform: uppercase;
    }

    span.hour {
      display: block;
      margin: 0 1.375rem 0 0.625rem;
      font-size: 0.875rem;
      font-weight: 300;
      color: ${props => props.theme.colors.tertiary};
      text-transform: uppercase;
    }
  }

  .column-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-left: 0.625rem;
    }
  }
`
