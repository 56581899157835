import { useState } from 'react'
import { parseISO } from 'date-fns'

import { useMutation, useQueryClient } from 'react-query'
import { VisitScheduleModal } from 'components/organisms'
import { ScheduledItemList } from 'components/molecules'

import { ButtonForm, Grid, Line, Text } from 'components/atoms'

import {
  createCustomerRentContractVisit,
  updateCustomerRentContractVisit,
  removeCustomerRentContractVisit,
  changeStepCustomerRentContract
} from 'services'

import { sweetAlert } from 'utils'
import { QUERIES_CUSTOMER } from '@constants'
import { onErrorMessage, onSuccessMessage } from 'helpers'
import { RentContractVisitInterface, RentContractVisitItemInterface } from './interface'

const RentContractVisit = ({
  idContract,
  visitas,
}: RentContractVisitInterface) => {
  const queryClient = useQueryClient();

  const title = "Agendar uma Visita!";

  const [showModalScheduled, setShowModalScheduled] = useState(false)

  const [itemVisit, setItemVisit] = useState<RentContractVisitItemInterface | undefined>(undefined);
  const [dateScheduled, setDateScheduled] = useState<Date | undefined>(undefined)
  const [hourScheduled, setHourScheduled] = useState<string | undefined>(undefined)

  const {
    mutate: mutateCreateRentContractVisit,
    isLoading: isLoadingCreate
  } = useMutation(
    () => createCustomerRentContractVisit({
      contratosAlugueisIds: idContract,
      data: dateScheduled,
      hora: hourScheduled
    }),
    {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT_VISIT.base, String(idContract)],
      onSuccess: () => {
        onSuccessMessage(title, 'Sucesso ao agendar horário!');

        queryClient.invalidateQueries(
          [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(idContract)]
        );
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  const {
    mutate: mutateUpdateRentContractVisit,
    isLoading: isLoadingUpdate
  } = useMutation(
    () => updateCustomerRentContractVisit(Number(itemVisit?.id), {
      ...itemVisit,
      data: dateScheduled,
      hora: hourScheduled
    }),
    {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT_VISIT.base, String(idContract)],
      onSuccess: () => {
        onSuccessMessage(title, 'Sucesso ao agendar horário!');

        queryClient.refetchQueries(
          [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(idContract)]
        );
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  const {
    mutate: mutateRemoveScheduled,
    isLoading: isLoadingCancel
  } = useMutation(
    (idVisit: number) => removeCustomerRentContractVisit(idVisit),
    {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT_VISIT.base, String(idContract)],
      onSuccess: () => {
        onSuccessMessage(title, 'Sucesso ao cancelar agendamento!');

        queryClient.invalidateQueries(
          [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(idContract)]
        );
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  const {
    mutate: mutateChangeStepRentContract,
    isLoading: isLoadingChangeStep
  } = useMutation(
    () => changeStepCustomerRentContract(Number(idContract), { etapa: 'ANALISE_CREDITO' }),
    {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(idContract)],
      onSuccess: () => {

        queryClient.refetchQueries(
          [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(idContract)]
        );
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  const onHandleClickScheduled = () => {
    if (!dateScheduled || !hourScheduled) {
      sweetAlert.fire({
        title: 'Agendamento!',
        text: 'O preenchimento de data e hora são obrigatórios!',
        icon: 'error'
      })

      return null
    }

    setShowModalScheduled(false)

    if (itemVisit === undefined) {
      mutateCreateRentContractVisit()

      mutateChangeStepRentContract()
    } else {
      mutateUpdateRentContractVisit()
    }

    return null;
  }

  function onSelectScheduledVisit(idVisit: number) {
    const itemScheduled = visitas.find(
      visitasItem => visitasItem.id === idVisit
    )
    setItemVisit(itemScheduled)
    setDateScheduled(parseISO(itemScheduled?.data || ''))
    setHourScheduled(itemScheduled?.hora)
    setShowModalScheduled(true)
  }

  return (
    <>
      <Text style={{ padding: '2.375rem 2.063rem' }}>
        {Array.isArray(visitas) && visitas.length > 0
          ? 'Sua visita foi agendada com sucesso, abaixo estão as informações referentes ao agendamento:'
          : 'Você pode agendar uma visita para conhecer o imóvel, ou se preferir clique em “Não quero agendar uma visita” para prosseguir com o processo:'}
      </Text>

      <Line type="secondary" />

      {Array.isArray(visitas) && visitas.length ? (
        <div className="list-scheculed">
          {visitas.map(visitasItem => (
            <ScheduledItemList
              key={visitasItem.id}
              date={visitasItem.data}
              hour={visitasItem.hora}
              isLoadingSchedule={isLoadingUpdate}
              isLoadingCancel={isLoadingCancel}
              onClickScheduled={() => onSelectScheduledVisit(visitasItem.id)}
              onClickCancel={() => mutateRemoveScheduled(visitasItem.id)}
            />
          ))}
        </div>
      ) : (
        <Grid
          numberColumns={2}
          gridGap="1.875rem"
          className="buttons-scheduled"
        >
          <ButtonForm
            onClick={() => setShowModalScheduled(true)}
            color="quaternary"
            size="medium"
            loading={isLoadingCreate || isLoadingChangeStep}
          >
            Quero Agendar uma Visita
          </ButtonForm>
          <ButtonForm
            onClick={() => mutateChangeStepRentContract()}
            color="secondary"
            size="medium"
            loading={isLoadingCreate || isLoadingChangeStep}
          >
            Não quero agendar uma visita
          </ButtonForm>
        </Grid>
      )}

      <VisitScheduleModal
        openModal={showModalScheduled}
        onCloseModal={() => setShowModalScheduled(false)}
        onClickButton={() => onHandleClickScheduled()}
        date={dateScheduled}
        onChangeDate={setDateScheduled}
        hour={hourScheduled}
        onChangeHour={setHourScheduled}
      />
    </>
  )
}

export default RentContractVisit
