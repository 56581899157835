import styled from 'styled-components'

export const ContractWrap = styled.div`
  .description {
    padding: 2.125rem;

    font-size: 1.063rem;
    font-weight: 300;
    color: ${props => props.theme.colors.textSecondary};

    border-bottom: 0.063rem solid ${props => props.theme.colors.borderSecondary};
  }

  .content {
    display: flex;
    flex-direction: column;

    margin-top: 5rem;
    padding: 0 6.25rem;

    h2 {
      font-size: 1.625rem;
      font-weight: 800;
      color: ${props => props.theme.colors.textSecondary};
      text-align: center;
    }

    span {
      display: block;
      margin-top: 1rem;
      font-size: 0.938rem;
      font-weight: 300;
      color: ${props => props.theme.colors.textSecondary};
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
`
