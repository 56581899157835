import { generateURL } from './prefix';
import api from '../api';

import type { IAPlan } from '@types';

export const getAdministratorPlanIndex = async () => {
    const url = generateURL(`/planos`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorPlanShow = async (id: number | string) => {
    const url = generateURL(`/planos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorPlan = async (
    payload: Partial<IAPlan>
) => {
    const url = generateURL(`/planos`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorPlan = async (
    id: number | string,
    payload: Partial<IAPlan>
) => {
    const url = generateURL(`/planos/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorPlan = async (id: number | string) => {
    const url = generateURL(`/planos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
