import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ROUTES_ACCOUNT, QUERIES_OWNER, ROUTES_OWNER } from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Modal } from 'components/molecules';
import { Table } from 'components/organisms';
import { Button, HelperDate, HelperOptions, Text } from 'components/atoms';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    acceptedOwnerRealStateOwnerInvite,
    getOwnerOwnerHasRealStateInviteIndex,
    removeOwnerRealStateOwnerInvite,
} from 'services';
import { sweetAlert } from 'utils';

import { ContactListWrap, ModalContent } from './styles';
import Layout from '../../Layout';

import type { IAOwnerHasRealStateInvite } from '@types';

const Contact = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [item, setItem] = useState<any>(null);

    const title = 'Convites das Imobiliárias';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_OWNER.REAL_STATE_INVITE.base,
        () => getOwnerOwnerHasRealStateInviteIndex(),
        { refetchOnMount: true }
    );


    const { mutate: acceptedRegister, isLoading: isLoadingAccepted } = useMutation(
        ({ id }: { id: number }) => acceptedOwnerRealStateOwnerInvite(Number(id)),
        {
            mutationKey: QUERIES_OWNER.REAL_STATE_INVITE.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Convite aceito com sucesso!'
                );

                setIsOpenModal(false);

                queryClient.refetchQueries(
                    QUERIES_OWNER.REAL_STATE_INVITE.base
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const { mutate: removeRegister, isLoading: isLoadingRemove } = useMutation(
        ({ id }: { id: number }) => removeOwnerRealStateOwnerInvite(Number(id)),
        {
            mutationKey: QUERIES_OWNER.REAL_STATE_INVITE.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Registro removido com sucesso!'
                );

                queryClient.refetchQueries(
                    QUERIES_OWNER.REAL_STATE_INVITE.base
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    function onViewRegister(idParam: number) {
        setItem(
            dataList?.find(
                (dataItem: IAOwnerHasRealStateInvite) => dataItem.id === idParam
            )
        );
        setIsOpenModal(true);
    }

    return (
        <Layout
            titlePage="Painel Proprietário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_OWNER.REAL_STATE_INVITE.fullPath),
                },
            ]}
            title={title}
        >
            <ContactListWrap className="owner-real-state-invite-template">
                <Table
                    isLoading={isLoadingList || isLoadingRemove}
                    columns={[
                        {
                            dataField: 'imobiliariasNomes',
                            text: 'Imobiliária',
                            sort: true,
                            headerStyle: { width: '50%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Status',
                            sort: true,
                            headerStyle: { width: '30%' },
                        },

                        {
                            dataField: 'createdAt',
                            text: 'Convidado em',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperDate value={fieldValue} distance />
                            ),
                            headerStyle: { minWidth: '15rem' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue, _index, row) =>
                                HelperOptions({
                                    edit: row.status === 'PENDENTE',
                                    editIcon: <FiEye />,
                                    editFunction: () =>
                                        onViewRegister(fieldValue),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                        },
                    ]}
                    data={dataList}
                />
            </ContactListWrap>

            <Modal
                size="lg"
                open={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                title={`Convite de ${item?.imobiliariasNomes}`}
                footer={
                    <>
                        <Button
                            color="octernary"
                            onClick={() => acceptedRegister({ id: item?.id })}
                            loading={isLoadingAccepted}
                        >
                            Aceitar
                        </Button>
                        <Button
                            color="septernary"
                            onClick={() => setIsOpenModal(false)}
                        >
                            Fechar
                        </Button>
                    </>
                }
            >
                <ModalContent>
                    <p>
                        <Text>Prezado(a),</Text>
                    </p>

                    <p>
                        <Text>
                            A imobiliária{' '}
                            <strong>{item?.imobiliariasNomes}</strong> gostaria
                            de solicitar sua autorização para ver seus imóveis
                            cadastrados no sistema e se interessada solicitar
                            alguns para seu portfólio.
                        </Text>
                    </p>

                    <p>
                        <Text>
                            Gostaríamos de ressaltar que, ao autorizar a
                            visualização dos imóveis, os mesmos devem ser
                            solicitados e aprovados para serem anúnciados
                            através da imobiliária.
                        </Text>
                    </p>

                    <p>
                        <Text>
                            Caso tenha interesse em autorizar a visualização dos
                            seus imóveis para a imobiliária clique no botão
                            abaixo.
                        </Text>
                    </p>
                </ModalContent>
            </Modal>
        </Layout>
    );
};

export default Contact;
