import { Helmet } from 'react-helmet';

import { AdministratorPlanList } from 'components/templates';

const AdministratorPlanListPage = () => (
    <>
        <Helmet>
            <title>Planos - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPlanList />
    </>
);

export default AdministratorPlanListPage;
