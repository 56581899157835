import styled from 'styled-components'

interface HelperMoneyWrapInterface {
  centered: boolean
}

export const HelperMoneyWrap = styled.span<HelperMoneyWrapInterface>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};

  svg {
    font-size: 1.75rem;
    color: ${props => props.theme.colors.danger};
  }

  &.active {
    svg {
      color: ${props => props.theme.colors.check};
    }
  }
`
