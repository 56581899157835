import {
  Container,
  H1,
  Line,
} from 'components/atoms'
import { Breadcrumbs } from 'components/molecules'

import { HeaderPagesWrap } from './styles'

import type { HeaderPagesInterface } from './interface'

const HeaderPages = ({
  label,
  breadcrumbs
}: HeaderPagesInterface) => (
  <HeaderPagesWrap className="header-pages-organism">
    <Container>
      <div className="alignament">
        <H1>
          <strong>{label}</strong>
        </H1>

        <Line type="secondary" />

        <Breadcrumbs data={breadcrumbs} />
      </div>
    </Container>
  </HeaderPagesWrap>
)

export default HeaderPages
