import styled from 'styled-components';

interface DataTableWrapInterface {
    noMargins: boolean;
    noShadow: boolean;
}

export const TableWrap = styled.div<DataTableWrapInterface>`
    background-color: ${(props) => props.theme.colors.senary};

    font-family: 'Work Sans', sans-serif !important;

    box-shadow: ${(props) =>
        props.noShadow ? 'none' : '0px -3px 23px 0px rgba(126, 157, 184, 0.3)'};

    padding: ${(props) => (props.noMargins ? 0 : '1.25rem')};
    font-family: 'Work Sans', sans-serif !important;

    table {
        box-shadow: ${(props) =>
            props.noShadow
                ? 'none'
                : '0px -3px 23px 0px rgba(126, 157, 184, 0.3)'};

        thead {
            background-color: ${(props) => props.theme.colors.tertiaryBold};
            border-top-left-radius: 0.188rem;
            border-top-right-radius: 0.188rem;

            th {
                padding: 1.5rem 2rem;

                &:focus {
                    outline: 0;
                }

                &.sort {
                    cursor: pointer;
                }

                .content-header {
                    font-size: 0.875rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 120%;
                    text-align: left;
                    color: ${(props) => props.theme.colors.textSenary};

                    display: flex;
                    align-items: center;

                    .carets {
                        display: flex;
                        flex-direction: column;
                        margin-left: 0.5rem;

                        svg {
                            font-size: 0.85rem;

                            &:last-child {
                                margin-top: -0.125rem;
                            }
                        }

                        .active {
                            fill: ${(props) => props.theme.colors.secondary};
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                max-height: 57px;
                height: 57px;

                background-color: ${(props) => props.theme.colors.senary};

                &:nth-child(2n) {
                    background-color: ${(props) =>
                        props.theme.colors.tableStriped};
                }

                td {
                    font-family: 'Work Sans';
                    font-weight: 300;
                    padding: 1.625rem 2rem;
                    font-size: 1.3rem;
                    color: ${(props) => props.theme.colors.tertiaryBold};
                    line-height: 100%;
                }
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        width: 100%;

        ul.pagination {
            li.page-item {
                margin-right: 0.313rem;
                display: inline-block;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3.75rem;
                    width: 3.75rem;
                    background-color: ${(props) => props.theme.colors.senary};
                    box-shadow: 0px -3px 23px 0px rgba(126, 157, 184, 0.2);

                    font-family: 'Work Sans';
                    font-weight: 700;
                    font-size: 0.938rem;
                    line-height: 140%;
                    text-decoration: none;
                    text-align: center;
                    color: ${(props) => props.theme.colors.tertiaryBold};

                    svg {
                        font-size: 0.85rem;
                    }
                }

                &.active {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.tertiaryBold};
                        color: ${(props) => props.theme.colors.senary};
                    }
                }

                &:hover {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.tertiaryBold};
                        color: ${(props) => props.theme.colors.secondary};
                    }
                }
            }
        }

        .box-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            select {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3.75rem;
                width: 3.75rem;
                box-shadow: 0px -3px 23px 0px rgba(126, 157, 184, 0.2);

                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 0.938rem;
                line-height: 140%;
                text-decoration: none;
                text-align: center;
                background-color: ${(props) => props.theme.colors.tertiaryBold};
                color: ${(props) => props.theme.colors.senary};
                margin-left: 1rem;
                padding-left: 0.3rem;

                &:focus {
                    outline: 0;
                }
            }

            .description {
                font-size: 1rem;
                font-weight: 400;
                color: ${(props) => props.theme.colors.textQuaternary};
            }
        }
    }
`;
