/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react';

import { Grid } from 'components/atoms';
import {
    InputImage,
    InputMask,
    InputText,
    InputTextArea,
    TextHtmlArea,
} from 'components/molecules';

import { InputImageWrap } from './styles';

import type {
    SettingGeneralInfoInterface,
} from './interface';

import type { IAPerson, IARealState } from '@types';

const SettingGeneralInfo = ({
    realStateData,
    onChangeRealStateData,
    personData,
    onChangePersonData,
    onRemoveImage,
    onUploadImage
}: SettingGeneralInfoInterface) => {

    const [applyMask, setApplyMask] = useState(true);

    const toggleMask = () => {
        setApplyMask(!applyMask);
    };

    const useFieldRealState = useCallback(
        (key: keyof IARealState) => {
            let value = null;

            if (realStateData !== undefined) {
                value = realStateData?.[key];
            }

            return {
                onChange: (newValue: string) =>
                    onChangeRealStateData({ ...realStateData, [String(key)]: newValue }),
                value: value ? String(value) : '',
                id: String(key),
                name: String(key),
            };
        },
        [realStateData]
    );

    const useFieldPerson = useCallback(
        (key: keyof IAPerson) => {
            let value = null;

            if (personData !== undefined) {
                value = personData?.[key];
            }

            return {
                onChange: (newValue: string) =>
                    onChangePersonData({ ...personData, [String(key)]: newValue }),
                value: value ? String(value) : '',
                id: String(key),
                name: String(key),
            };
        },
        [personData]
    );


    return (
        <div>
            <InputImageWrap>
                <InputImage
                    id="image_logo_header"
                    label="Logo Topo"
                    value={realStateData?.logoHeader?.url}
                    onChange={file => onUploadImage('logoHeader', file)}
                    onDelete={realStateData?.logoHeader?.id ? () => onRemoveImage('logoHeader') : undefined}
                />

                <InputImage
                    id="image_logo_footer"
                    label="Logo Rodapé"
                    value={realStateData?.logoFooter?.url}
                    onChange={file => onUploadImage('logoFooter', file)}
                    onDelete={realStateData?.logoHeader?.id ? () => onRemoveImage('logoFooter') : undefined}
                />
            </InputImageWrap>

            <InputText
                required
                label="E-mail"
                placeholder="Informe o e-mail"
                model="sinary"
                {...useFieldPerson('email')}
            />

            <Grid numberColumns={3}>
                <InputText
                    required
                    label="Nome"
                    placeholder="Informe o nome"
                    model="sinary"
                    {...useFieldPerson('nome')}
                />

                <InputText
                    required
                    label="Creci"
                    placeholder="Informe o creci"
                    model="sinary"
                    {...useFieldPerson('creci')}
                />

                <InputMask
                    model="sinary"
                    required
                    mask={(personData?.documento?.length || 0) === 11 ? '999.999.999-99 9' : '99.999.999/9999-99'}
                    label="Documento (CNPJ/CPF)"
                    placeholder="Informe o documento"
                    {...useFieldPerson('documento')}
                />
            </Grid>

            <Grid numberColumns={3}>
                <InputText
                    required
                    label="Slug"
                    placeholder="Informe o slug"
                    model="sinary"
                    {...useFieldRealState('slug')}

                />

                <InputMask
                    required
                    mask={
                        (personData?.telefone?.length || 0) > 10
                            ? '(99) 9 9999-9999'
                            : '(99) 9999-9999 9'
                    }
                    label="Telefone"
                    placeholder="Informe o telefone"
                    model="sinary"
                    {...useFieldPerson('telefone')}

                />

                <InputMask
                    required
                    mask={
                        (personData?.whatsapp?.length || 0) > 10
                            ? '(99) 9 9999-9999'
                            : '(99) 9999-9999 9'
                    }
                    label="Whatsapp"
                    placeholder="Informe o whatsapp"
                    model="sinary"
                    {...useFieldPerson('whatsapp')}
                />
            </Grid>

            <Grid numberColumns={3}>
                <InputText
                    label="URL do Instagram"
                    placeholder="Informe a url da instagram"
                    model="sinary"
                    {...useFieldPerson('instagram')}

                />

                <InputText
                    label="URL do Facebook"
                    placeholder="Informe a url do facebook"
                    model="sinary"
                    {...useFieldPerson('facebook')}

                />

                <InputText
                    label="URL do Youtube"
                    placeholder="Informe a url do youtube"
                    model="sinary"
                    {...useFieldPerson('youtube')}

                />
            </Grid>

            <InputTextArea
                label="Horário de Atendimento"
                placeholder="Informe os horários de atendimento"
                model="sinary"
                {...useFieldRealState('atendimento')}
            />

            <TextHtmlArea
                label="Sobre"
                placeholder="Informe o sobre"
                model="sinary"
                {...useFieldRealState('sobre')}
            />
        </div>
    )
};

export default SettingGeneralInfo;
