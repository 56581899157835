import styled, { css } from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

const noDrag = {
    primary: css`
        border: 0.125rem dashed ${(props) => props.theme.colors.borderSenary};
    `,
    secondary: css`
        border: 0.125rem dashed ${(props) => props.theme.colors.borderOcternary};
        background-color: ${(props) => props.theme.colors.backgroundPrimary};
    `,
};

const dragActive = {
    primary: css`
        border-color: ${(props) => props.theme.colors.secondaryExtraBold};
    `,
    secondary: css`
        border-color: ${(props) => props.theme.colors.secondaryBold};
        background-color: ${(props) => props.theme.colors.backgroundSuccess};
    `,
};

const dragReject = {
    primary: css`
        border-color: ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.danger};
    `,
    secondary: css`
        border: 0.125rem dashed ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.backgroundDanger};
    `,
};

export const UploadDropBoxWrap = styled.div``;

interface DropContainerInterface extends DropzoneRootProps {
    isDragActive: boolean;
    isDragReject: boolean;
    dragActive: boolean;
    dragReject: boolean;
    variation: 'primary' | 'secondary';
}

export const DropContainer = styled.div.attrs({
    className: 'dropzone',
})<DropContainerInterface>`
    cursor: pointer;
    transition: height 0.2s ease;

    ${(props) => noDrag[props.variation]};
    ${(props) => props.isDragActive && dragActive[props.variation]};
    ${(props) => props.isDragReject && dragReject[props.variation]};
`;

interface UploadMessageInterface {
    type?: string;
}

const colorTextOfStatus: Record<
    string,
    'textOcternary' | 'danger' | 'secondaryExtraBold'
> = {
    default: 'textOcternary',
    error: 'danger',
    success: 'secondaryExtraBold',
};

export const UploadMessage = styled.p<UploadMessageInterface>`
    display: flex;
    color: ${(props) =>
        props.theme.colors[colorTextOfStatus[props?.type || 'default']]};
    font-size: 0.875rem;
    justify-content: center;
    align-items: center;
    padding: 0.938rem 0;

    svg {
        margin-right: 0.45rem;
        height: 0.8rem;
        path {
            stroke: ${(props) => props.theme.colors.textOcternary};
        }
    }
`;
