import styled from 'styled-components';

export const ItemListWrap = styled.li`
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.87rem;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.textSenary};
        text-decoration: none;

        margin-bottom: 0.93rem;

        &:hover {
            color: ${(props) => props.theme.colors.secondary};
            text-decoration: underline;
        }

        svg {
            margin-left: -0.25rem;
            margin-right: 0.93rem;
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;
