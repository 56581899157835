import styled, { css } from 'styled-components';

import type { CardInfoInterface } from './interface';

interface CardInfoWrapInterface
    extends Pick<CardInfoInterface, 'variation' | 'model'> { }

const variations = {
    base: {
        primary: css`
            background-color: ${(props) => props.theme.colors.primary};

            svg {
                color: ${(props) => props.theme.colors.senary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.senary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.senary};
            }

            h4 {
                color: ${(props) => props.theme.colors.senary};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.secondary};

            svg {
                color: ${(props) => props.theme.colors.senary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.senary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.senary};
            }

            h4 {
                color: ${(props) => props.theme.colors.senary};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.danger};

            svg {
                color: ${(props) => props.theme.colors.senary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.senary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.senary};
            }

            h4 {
                color: ${(props) => props.theme.colors.senary};
            }
        `,
    },
    white: {
        primary: css`
            background-color: ${(props) => props.theme.colors.senary};

            svg {
                color: ${(props) => props.theme.colors.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.textPrimary};
            }

            h4 {
                color: ${(props) => props.theme.colors.textSecondary};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.senary};

            svg {
                color: ${(props) => props.theme.colors.secondary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.secondary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.textPrimary};
            }

            h4 {
                color: ${(props) => props.theme.colors.textSecondary};
            }
        `,

        danger: css`
            background-color: ${(props) => props.theme.colors.senary};

            svg {
                color: ${(props) => props.theme.colors.danger};
                g [fill] {
                    fill: ${(props) => props.theme.colors.danger};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.textPrimary};
            }

            h4 {
                color: ${(props) => props.theme.colors.textSecondary};
            }
        `,
    }
};

export const CardInfoWrap = styled.div<CardInfoWrapInterface>`
    position: relative;
    display: flex;

    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0;

    width: 100%;

    padding: 2rem 2.25rem;

    svg {
        height: 2.75rem;
        width: 2.75rem;
    }

    .number {
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 1.25rem;
        margin-bottom: 0.5rem;
    }

    .description {
        font-size: 1rem;
        font-weight: 400;
    }

    ${(props) =>
        variations[props.model || 'white'][props.variation || 'primary']}
`;
