import { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import { InputCapsule } from 'components/atoms';

import { checkEmpty } from 'utils';
import { InputTextWrap } from './styles';

import type { InputTextInterface } from './interface';

const InputText = ({
    id,
    label,
    required,
    name,
    placeholder,
    password = false,
    value,
    readOnly,
    onChange = () => null,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
    model = 'primary',
    ...rest
}: InputTextInterface) => {
    const [showPassword, setShowPassword] = useState<boolean>(password);

    return (
        <InputTextWrap className="input-text-molecule">
            <InputCapsule
                htmlFor={id}
                label={label}
                required={required}
                borderLeft={borderLeft}
                borderRight={borderRight}
                borderTop={borderTop}
                borderBottom={borderBottom}
                empty={checkEmpty(value)}
                model={model}
            >
                <input
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    type={showPassword ? 'password' : 'text'}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    readOnly={readOnly}
                    {...rest}
                />
                {password && (
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <AiOutlineEye />
                        ) : (
                            <AiOutlineEyeInvisible />
                        )}
                    </button>
                )}
            </InputCapsule>
        </InputTextWrap>
    );
};

export default InputText;
