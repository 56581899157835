import {FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {  useParams } from 'react-router-dom';

import * as Yup from 'yup';

import { QUERIES_AUTH, ROUTES_AUTH } from '@constants';

import { useAuth } from 'contexts/AuthContext';

import { ButtonForm, Container, H1, Shadow, TextForm } from 'components/atoms';

import { InputCode, InputText } from 'components/molecules';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { createAuthNewPassword } from 'services/auth';

import { InputCodeWrap, RecoveryCodeWrap } from './styles';
import Layout from '../Layout';

const RecoveryCode = () => {
    const { onHandleNavigateWithNext } = useAuth();
    const { email } = useParams();
    const queryClient = useQueryClient();


    const [senha, setSenha] = useState('');
    const [codigo, setCodigo] = useState('');

    const title = 'Autenticar';

    if (email === undefined) {
        onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath);

        return null;
    }

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: { email: string; codigo: string; senha: string }) =>
            createAuthNewPassword(payload),
        {
            mutationKey: QUERIES_AUTH.RECOVERY_CODE.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao redefinir a senha!');

                queryClient.refetchQueries(QUERIES_AUTH.RECOVERY_CODE.base);

                onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const onHandleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const dataSend = {
            email,
            senha,
            codigo,
        };

        const shapeSchema = {
            codigo: Yup.string().nullable().required('O código é obrigatório.'),
            senha: Yup.string().nullable().required('A senha é obrigatório.'),
            email: Yup.string().nullable().required('O e-mail é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            mutateCreate(dataSend);
        }
    };

    return (
        <Layout>
            <RecoveryCodeWrap className="password-recovery-template">
                <Container>
                    <H1>
                        <strong>Recuperação de Senha</strong>
                    </H1>
                    <form onSubmit={onHandleSubmit}>
                        <main>
                            <Shadow>
                                <TextForm model="primary" borderTop>
                                    Um código de 5 dígitos foi enviado ao
                                    contato {email}. Por favor, verifique sua
                                    caixa de entrada de e-mails. O código expira
                                    em 5 minutos. Caso não consiga utilizá-lo a
                                    tempo, solicite um novo código ou entre em
                                    contato com nossa equipe de atendimento.
                                </TextForm>

                                <InputCodeWrap>
                                    <InputCode
                                        name="recoveryCode"
                                        value={codigo}
                                        onChange={setCodigo}
                                    />
                                </InputCodeWrap>

                                <InputText
                                    required
                                    borderTop
                                    name="password"
                                    label="Nova Senha"
                                    value={senha}
                                    onChange={setSenha}
                                    password
                                    placeholder="Digite sua nova senha"
                                />
                            </Shadow>

                            <ButtonForm
                                id="on-sign"
                                color="tertiary"
                                type="submit"
                                loading={isLoadingCreate}
                            >
                                Enviar
                            </ButtonForm>
                        </main>
                    </form>
                </Container>
            </RecoveryCodeWrap>
        </Layout>
    );
};

export default RecoveryCode;
