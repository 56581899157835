import { InputCapsule } from 'components/atoms';
import { checkEmpty } from 'utils';

import { InputTextAreaWrap } from './styles';

import type { InputTextAreaInterface } from './interface';

const InputTextArea = ({
    id,
    label,
    required,
    model,
    name,
    placeholder,
    value,
    readOnly,
    onChange = () => null,
}: InputTextAreaInterface) => (
    <InputTextAreaWrap className="input-text-area-molecule">
        <InputCapsule
            htmlFor={id || name}
            label={label}
            required={required}
            empty={checkEmpty(value)}
            model={model}
        >
            <textarea
                id={id || name}
                placeholder={placeholder}
                name={name}
                onChange={(event) => onChange(event.target.value)}
                readOnly={readOnly}
                value={value}
            />
        </InputCapsule>
    </InputTextAreaWrap>
);

export default InputTextArea;
