import React, { useState, useEffect } from 'react';
import { ICModal } from '@types';

import { Background, Content, Offset, Row } from './styles';

const ModalOffset: React.FC<ICModal> = ({
    open: openOutside = false,
    onCloseModal: onCloseModalOutside = () => null,
    size,
    children,
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(openOutside);
    }, [openOutside]);

    function onCloseModal() {
        onCloseModalOutside();
        setOpen(false);
    }

    return (
        <Background open={open}>
            <Offset onClick={() => onCloseModal()} />
            <Row>
                <Offset onClick={() => onCloseModal()} />
                <Content className="modal-content" size={size || 'md'}>
                    {children}
                </Content>
                <Offset onClick={() => onCloseModal()} />
            </Row>
            <Offset onClick={() => onCloseModal()} />
        </Background>
    );
};

export default ModalOffset;
