import type { IUOptions } from '@types'

class ExpenseEnums {
  public typeDebit = [
    { label: 'Locatário', value: 'LOCATARIO' },
    { label: 'Imobiliária', value: 'IMOBILIARIA' },
    { label: 'Proprietário', value: 'PROPRIETARIO' }
  ]

  public typeCredit = [
    { label: 'Locatário', value: 'LOCATARIO' },
    { label: 'Imobiliária', value: 'IMOBILIARIA' },
    { label: 'Proprietário', value: 'PROPRIETARIO' },
    // { label: 'Terceiro', value: 'TERCEIRO' }
  ]

  public typeExpense = [
    { label: 'Fixo', value: 'FIXO' },
    { label: 'Avulso', value: 'AVULSO' }
  ]

  public fiscalType = [
    { label: 'Normal', value: 'NORMAL' },
    { label: 'Desconto', value: 'DESCONTO' }
  ]

  public status = [
    { label: 'Em aberto', value: 'EM_ABERTO' },
    { label: 'Cancelado', value: 'CANCELADO' },
    { label: 'Faturado', value: 'FATURADO' },
    { label: 'Pago', value: 'PAGO' }
  ]

  public getTypeDebit(param?: string): IUOptions {
    const filtered = this.typeDebit.find(item => item.value === param)

    return !filtered ? this.typeDebit[0] : filtered
  }

  public getTypeCredit(param?: string): IUOptions {
    const filtered = this.typeCredit.find(item => item.value === param)

    return !filtered ? this.typeCredit[0] : filtered
  }

  public getTypeExpense(param?: string): IUOptions {
    const filtered = this.typeExpense.find(item => item.value === param)

    return !filtered ? this.typeExpense[0] : filtered
  }

  public getFiscalType(param?: string): IUOptions {
    const filtered = this.fiscalType.find(item => item.value === param)

    return !filtered ? this.fiscalType[0] : filtered
  }

  public getStatus(param?: string): IUOptions {
    const filtered = this.status.find(item => item.value === param)

    return !filtered ? this.status[0] : filtered
  }

}

export default new ExpenseEnums()
