import styled from 'styled-components'

export const SelectModalFilterFetchWrap = styled.div`
 
`


export const InputWrap = styled.div`
  display: flex;
  align-items: center;

  & > :nth-child(n) {
    margin-right: 1rem;
  }
`;
