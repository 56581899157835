/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useQuery, useMutation } from 'react-query';

import {
    QUERIES_REAL_STATE,
    QUERIES_EXTERNAL,
    QUERIES_MISCELLANEOUS,
} from '@constants';

import { Button, HelperButton, Row } from 'components/atoms';
import {
    InputMask,
    InputNumber,
    InputText,
    InputToggle,
    Modal,
    Select,
    TextHtmlArea,
} from 'components/molecules';
import { SelectModalPerson, Table } from 'components/organisms';
import { onErrorMessage, onSuccessMessage } from 'helpers';

import {
    getPublicPropertyTypeIndex,
    getRealStateOwnerIndex,
    consultRealStatePropertyByOwnersIds,
    inviteRealStateProperty,
    getAddressExternalApi,
    createMiscellaneousAddress,
} from 'services';

import { sweetAlert } from 'utils';

import type {
    PropertyNewFormGeneralInfoInterface,
    PropertyOwnerInterface,
} from './interface';

const PropertyNewFormGeneralInfo = ({
    values,
    onChange,
    isOpenForm,
    onOpenForm,
}: PropertyNewFormGeneralInfoInterface) => {
    const titleMessages = 'Imóveis';

    const [isOpenModalProperties, setIsOpenModalProperties] = useState(false);
    const [properties, setProperties] = useState([]);

    const [isValidatedAddress, setIsValidatedAddress] = useState(true);
    const [isManualNeighborhood, setIsManualNeighborhood] = useState(false);

    const useField = useCallback(
        (key: string) => {
            let value = null;

            if (values !== undefined) {
                value = values?.[key];
            }

            return {
                onChange: (newValue: string | boolean | number | any) =>
                    onChange({ ...values, [key]: newValue }),
                value,
                id: key,
                name: key,
            };
        },
        [values]
    );

    const { data: dataPropertyTypes } = useQuery(
        QUERIES_REAL_STATE.PROPERTY_TYPE.base,
        () => getPublicPropertyTypeIndex()
    );

    const { data: dataOwners } = useQuery(QUERIES_REAL_STATE.OWNER.base, () =>
        getRealStateOwnerIndex()
    );

    const { mutate: onConsult, isLoading: isLoadingConsult } = useMutation(
        () =>
            consultRealStatePropertyByOwnersIds({
                proprietariosIds: values?.proprietarios?.map(
                    (item: any) => item.proprietariosIds
                ),
            }),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.consult,
            onSuccess: (res) => {
                const totalPorcentagem = values?.proprietarios?.reduce(
                    (total: number, item: any) => total + item.porcentagem,
                    0
                );

                if (totalPorcentagem !== 100) {
                    sweetAlert.fire({
                        title: 'Cadastro!',
                        text: 'A soma da porcentagem dos proprietários deve ser igual a 100%',
                        icon: 'error',
                    });
                    return;
                }

                setIsOpenModalProperties(true);
                setProperties(res?.data);

                onSuccessMessage(titleMessages, res?.message);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onChangeOwners = (newValue: PropertyOwnerInterface[]) =>
        useField('proprietarios').onChange(newValue);

    const { mutate: onInvite, isLoading: isLoadingInvite } = useMutation(
        (propertyId: number) => inviteRealStateProperty(propertyId),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.consult,
            onSuccess: (res) => {
                onSuccessMessage(titleMessages, res?.message);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    function OnNewRegister() {
        onOpenForm(true);
        setIsOpenModalProperties(false);
    }

    const tableProperties = useMemo(
        () => (
            <Table
                columns={[
                    {
                        dataField: 'id',
                        text: 'Id',
                        style: { fontWeight: 700 },
                    },
                    {
                        dataField: 'titulo',
                        text: 'Título',

                        headerStyle: { width: '100%' },
                    },
                    {
                        dataField: 'matricula',
                        text: 'Matrícula',
                        headerStyle: { width: '100%' },
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        formatter: (fieldValue: number) => (
                            <HelperButton
                                isLoading={isLoadingInvite}
                                text="Solicitar"
                                color="active"
                                onClick={() => onInvite(fieldValue)}
                            />
                        ),
                    },
                ]}
                data={properties}
            />
        ),
        [properties, isLoadingInvite]
    );

    const { mutate: onCreateAddress } = useMutation(
        (payload: {
            bairro: string;
            cidade: string;
            uf: string;
            logradouro: string;
            complemento: string;
        }) => createMiscellaneousAddress(payload),
        {
            mutationKey: QUERIES_MISCELLANEOUS.ADDRESS.query,
            onSuccess: (res) => {
                onChange({ ...values, bairrosIds: res?.data?.bairrosIds });
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const { mutate: onGetAddress } = useMutation(
        () => getAddressExternalApi(values?.cep || ''),
        {
            mutationKey: QUERIES_EXTERNAL.ADDRESS.consult,
            onSuccess: (res) => {

                setIsValidatedAddress(true);

                const newAddress = {
                    bairros: {
                        nome: res.bairro,
                        cidade: {
                            nome: res.localidade,
                            uf: res.uf,
                        },
                    },
                    logradouro: res.logradouro,
                    complemento: res.complemento,
                };

                let requestData = res;

                if (isManualNeighborhood) {
                    requestData = {
                        ...requestData,
                        bairro: values?.bairro,
                    };
                } else {
                    setIsManualNeighborhood(res?.bairro === '');
                }


                onChange({ ...values, ...newAddress });
                onCreateAddress(requestData);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const inputLocalization = useMemo(() => {

        const onChangedCep = (newValue: string) => {
            if (isValidatedAddress) {
                setIsValidatedAddress(false);
            }

            onChange({ ...values, cep: newValue });
        }

        const onBlurCep = () => {
            if (values?.cep?.length === 8 && !isValidatedAddress) {
                onGetAddress();
            }
        }

        const onBlurNeighborhood = () => {
            if (values?.bairro !== '') {
                onGetAddress();
            }
        }

        let localizationValue = `${values?.bairros?.nome || ''} - ${values?.bairros?.cidade?.nome || ''
            } - ${values?.bairros?.cidade?.uf || ''}`

        if (isManualNeighborhood) {
            localizationValue = `${values?.bairros?.cidade?.nome || ''
                } - ${values?.bairros?.cidade?.uf || ''}`
        }

        return (
            <Row>
                <InputMask
                    model="sinary"
                    required
                    mask="99999-999"
                    label="Cep"
                    placeholder="Informe o cep"
                    {...useField('cep')}
                    onChange={onChangedCep}
                    onBlur={onBlurCep}
                />

                {isManualNeighborhood && (
                    <InputText
                        model="sinary"
                        required
                        label="Bairro"
                        placeholder="Informe o bairro"
                        {...useField('bairro')}
                        onBlur={onBlurNeighborhood}
                    />
                )}

                <InputText
                    model="sinary"
                    required
                    label={
                        isManualNeighborhood ? 'Cidade / UF' : 'Bairro / Cidade / UF'
                    }
                    name="localizacao"
                    placeholder="Preencha o cep primeiro"
                    value={localizationValue}
                    onChange={() => null}
                />
            </Row>
        );
    }, [values, isManualNeighborhood, isValidatedAddress]);

    return (
        <>
            <SelectModalPerson
                label="Proprietários"
                placeholder="Selecione os Proprietários"
                required
                options={
                    dataOwners?.map((owner: any) => ({
                        value: owner.id,
                        label: owner.nome,
                    })) || []
                }
                {...useField('proprietarios')}
                onChange={onChangeOwners}
            />

            {values?.proprietarios?.length > 0 && (
                <Button
                    color="octernary"
                    style={{ margin: '0 auto 0 auto', width: '14rem' }}
                    onClick={() => onConsult()}
                    loading={isLoadingConsult}
                >
                    <AiOutlineSearch />
                    Prosseguir
                </Button>
            )}

            {isOpenForm && (
                <>
                    <Select
                        model="sinary"
                        required
                        label="Tipo de Imóvel"
                        placeholder="Selecione o tipo do imóvel"
                        {...useField('imoveisTiposIds')}
                        options={
                            dataPropertyTypes?.map((type) => ({
                                label: type.descricao,
                                value: type.id,
                            })) || []
                        }
                    />

                    <InputText
                        model="sinary"
                        label="Matrícula"
                        placeholder="Informe a matrícula"
                        {...useField('matricula')}
                    />

                    <InputText
                        model="sinary"
                        required
                        label="Título"
                        placeholder="Informe o titulo"
                        {...useField('titulo')}
                    />

                    <TextHtmlArea
                        model="sinary"
                        required
                        label="Descrição"
                        placeholder="Informe a descrição"
                        {...useField('descricao')}
                    />

                    <Row>
                        <InputNumber
                            model="sinary"
                            required
                            label="Valor Aluguel"
                            {...useField('valorAluguel')}
                            prefix="R$ "
                            placeholder="Informe o valor do aluguel"
                        />

                        <InputNumber
                            model="sinary"
                            required
                            label="Valor Venda"
                            {...useField('valorVenda')}
                            prefix="R$ "
                            placeholder="Informe o valor da venda"
                        />
                    </Row>

                    <Row>
                        <InputToggle
                            model="sinary"
                            label="Editável mesmo depois de publicado"
                            labelTrue="Sim"
                            labelFalse="Não"
                            {...useField('editavel')}
                        />

                        <InputToggle
                            model="sinary"
                            label="Destaque no Site"
                            labelTrue="Sim"
                            labelFalse="Não"
                            {...useField('destaque')}
                        />
                    </Row>


                    {inputLocalization}


                    <Row>
                        <InputText
                            model="sinary"
                            required
                            label="Logradouro"
                            placeholder="Informe o logradouro"
                            {...useField('logradouro')}
                        />

                        <InputText
                            model="sinary"
                            required
                            label="Número"
                            placeholder="Informe o número"
                            {...useField('numero')}
                        />
                    </Row>

                    <Row>
                        <InputText
                            model="sinary"
                            label="Complemento"
                            placeholder="Informe o complemento"
                            {...useField('complemento')}
                        />

                        <InputText
                            model="sinary"
                            label="Referência"
                            {...useField('referencia')}
                            placeholder="Informe a referência"
                        />
                    </Row>

                    <Row>
                        <InputText
                            model="sinary"
                            label="Iframe do vídeo"
                            {...useField('video')}
                            placeholder="Informe o iframe de vídeo do imóvel"
                        />
                    </Row>

                    <Row>
                        <InputText
                            model="sinary"
                            label="Iframe do tour"
                            {...useField('tour')}
                            placeholder="Informe o tour virtual do imóvel"
                        />
                    </Row>
                </>
            )}

            <Modal
                title="Imóveis pertencentes aos proprietários"
                open={isOpenModalProperties}
                onCloseModal={() => setIsOpenModalProperties(false)}
                size="lg"
                footer={
                    <Button
                        color="septernary"
                        style={{ width: '15rem' }}
                        onClick={() => OnNewRegister()}
                    >
                        Cadastrar Novo Imóvel
                    </Button>
                }
            >
                {tableProperties}
            </Modal>
        </>
    );
};

export default PropertyNewFormGeneralInfo;
