import { ContainerWrap } from './styles';

import type { ContainerInterface } from './interface';

const Container = ({ id, children, style }: ContainerInterface) => (
    <ContainerWrap style={style} id={id} className="container-atom">
        {children}
    </ContainerWrap>
);

export default Container;
