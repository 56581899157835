import styled from 'styled-components'

export const EnsureWrap = styled.div`
  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 2.188rem 2.5rem;
    border-bottom: 0.063rem solid
      ${props => props.theme.colors.borderSepternary};

    .text {
      font-size: 1.063rem;
      font-weight: 300;
      color: ${props => props.theme.colors.textSecondary};
    }
    .value {
      font-size: 0.875rem;
      font-weight: 300;
      color: ${props => props.theme.colors.textSecondary};

      strong {
        color: ${props => props.theme.colors.textMoney};
        font-weight: 700;
      }
    }
  }
`
