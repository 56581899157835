import {
    FaInstagram,
    FaFacebookF,
    FaYoutube,
    FaWhatsapp,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

import { SocialIconWrap } from './styles';

import type { SocialIconInterface } from './interface';

const SocialIcon = ({
    solid = false,
    icon,
    href,
    variation = 'colorful',
    style,
}: SocialIconInterface) => {
    const Icons = {
        facebook: <FaFacebookF />,
        instagram: <FaInstagram />,
        youtube: <FaYoutube />,
        email: <HiOutlineMail />,
        whatsapp: <FaWhatsapp />,
    };

    return (
        <SocialIconWrap
            className="social-icon-atom"
            style={style}
            solid={solid}
            href={href}
            target="_blank"
            variation={variation}
            social={icon}
        >
            {Icons[icon]}
        </SocialIconWrap>
    );
};

export default SocialIcon;
