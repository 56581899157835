import { Helmet } from 'react-helmet';

import { RealStateInvoiceSettlementPrint } from 'components/templates';

const RealStateInvoiceSettlementPrintPage = () => (
    <>
        <Helmet>
            <title>Acertos - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateInvoiceSettlementPrint />
    </>
);

export default RealStateInvoiceSettlementPrintPage;
