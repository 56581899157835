import { BiBuildingHouse } from 'react-icons/bi';
import {
    FaUser,
    FaUserCog,
    FaUserTie,
    FaUserEdit,
    FaUserAlt,
    FaUserTag,
    FaUserNurse,
} from 'react-icons/fa';

import { userAccessEnums } from 'enums';

import { AccountTypeWrap } from './styles';
import Skeleton from '../Skeleton';

import type { AccountTypeInterface } from './interface';

const AccountType = ({
    tipo,
    loading,
    imobiliaria,
    onClick = () => null,
}: AccountTypeInterface) => {
    const userType = userAccessEnums.getTypeUserItem(tipo);

    const icons = {
        ADMINISTRADOR: <FaUserCog />,
        CLIENTE: <FaUser />,
        LOCATARIO: <FaUserAlt />,
        CORRETOR: <FaUserTie />,
        OPERADOR: <FaUserEdit />,
        IMOBILIARIA: <BiBuildingHouse />,
        PROPRIETARIO: <FaUserTag />,
        PRESTADOR_SERVICO: <FaUserNurse />,
    };

    if (loading) {
        return (
            <AccountTypeWrap className="account-type-atom">
                <span className="label">
                    <Skeleton height="0.938rem" width="100%" />
                </span>
                <span className="icon">
                    <Skeleton height="0.938rem" width="100%" />
                </span>
                <span className="realstate">
                    <Skeleton height="2.5rem" width="2.5rem" />
                </span>
                <span className="call">
                    <Skeleton height="0.938rem" width="100%" />
                </span>
            </AccountTypeWrap>
        );
    }

    return (
        <AccountTypeWrap className="account-type-atom" onClick={onClick}>
            <span className="label">{userType.label}</span>
            <span className="icon">{icons[tipo || 'CLIENTE']}</span>
            <span className="realstate">
                {imobiliaria || 'Sem Imobiliária'}
            </span>
            <span className="call">Acessar</span>
        </AccountTypeWrap>
    );
};

export default AccountType;
