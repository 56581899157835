import { FiTrash, FiEdit2 } from 'react-icons/fi';

import { HelperOptionsWrap } from './styles';

import type { HelperOptionsInterface } from './interface';

const HelperOptions = ({
    edit = true,
    remove = true,
    editFunction = () => null,
    removeFunction = () => null,
    editIcon = false,
}: HelperOptionsInterface) => (
    <HelperOptionsWrap>
        {edit && (
            <button type="button" onClick={() => editFunction()}>
                {editIcon || <FiEdit2 />}
            </button>
        )}

        {remove && (
            <button
                type="button"
                onClick={() => removeFunction()}
                className="danger"
            >
                <FiTrash />
            </button>
        )}
    </HelperOptionsWrap>
);

export default HelperOptions;
