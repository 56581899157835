import { useQueryClient } from 'react-query'
import { GrAttachment } from 'react-icons/gr'

import { Line, Text } from 'components/atoms'
import { UploadFiles } from 'components/organisms'

import { QUERIES_CUSTOMER } from '@constants'

import { CreditAnalysisWrap } from './styles'

import type { ICUploadFiles } from '@types';
import type { CreditAnalysisInterface } from './interface'


const CreditAnalysis = ({ id, arquivos }: CreditAnalysisInterface) => {
  const queryClient = useQueryClient();

  const onRequestData = () => {
    queryClient.invalidateQueries(
      [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(id)]
    )
  };

  function reducedFiles(param: 'rg&cpf' | 'comprovantes') {
    const files = arquivos?.reduce((accumulator, item) => {
      if (item.tipo === param)
        accumulator.push({

          ...item,
          name: item.nome,
          size: item.tamanho
        })

      return accumulator
    }, [] as ICUploadFiles[])

    return files || []
  }

  return (
    <CreditAnalysisWrap>
      <Text style={{ padding: '2.375rem 2.063rem' }}>
        Nesta etapa vamos precisar que você envie os documentos listados abaixo:
      </Text>
      <Line type="secondary" />

      <div className="images">
        <UploadFiles
          urlAdd={`/clientes/contratos/${id}/arquivo/campo/rg&cpf`}
          urlRemove={`/clientes/contratos/${id}/arquivo`}
          label="RG E CPF"
          subLabel="Adicione seus documentos para realizarmos sua análise de crédito"
          titleList="Documentos anexados"
          isRequired
          data={reducedFiles('rg&cpf')}
          variation="secondary"
          successedUpload={onRequestData}
          successedRemove={onRequestData}
        >
          <GrAttachment />
          Selecione os arquivos que deseja importar
        </UploadFiles>
      </div>

      <Line type="secondary" />

      <div className="images">
        <UploadFiles
          urlAdd={`/clientes/contratos/${id}/arquivo/campo/comprovantes`}
          urlRemove={`/clientes/contratos/${id}/arquivo`}
          label="Comprovante de Renda"
          subLabel="* Últimos 3 meses"
          titleList="Documentos anexados"
          isRequired
          data={reducedFiles('comprovantes')}
          variation="secondary"
          successedUpload={onRequestData}
          successedRemove={onRequestData}
        >
          <GrAttachment />
          Selecione os arquivos que deseja importar
        </UploadFiles>
      </div>
    </CreditAnalysisWrap>
  )
}

export default CreditAnalysis
