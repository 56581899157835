import { Helmet } from 'react-helmet';

import { AuthSignOut } from 'components/templates';

const SignOutPage = () => (
    <>
        <Helmet>
            <title>Sair - Imobiler</title>
        </Helmet>

        <AuthSignOut />
    </>
);

export default SignOutPage;
