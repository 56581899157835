import styled, { css } from 'styled-components'

interface LineLabelValueWrapInterface {
  total: boolean
}

const totalCss = css`

  span {
    font-weight: 700;
  }

  strong {
    color: ${props => props.theme.colors.textMoney};
  }
`

export const LineLabelValueWrap = styled.div<LineLabelValueWrapInterface>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;

  span {
    font-size: 0.813rem;
    color: ${props => props.theme.colors.tertiary};
    line-height: 120%;
    font-weight: 400;
  }

  strong {
    font-size: 0.813rem;
    color: ${props => props.theme.colors.textSecondary};
    line-height: 120%;
    font-weight: 700;
  }

  ${props => props.total && totalCss};
`
