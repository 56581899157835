import api from '../api';
import type { IARentContractValue } from '@types';

export const getRealStateRentContractValueIndex = async (
    idRentContract: number | string
): Promise<IARentContractValue[]> => {
    const url = `/imobiliarias/contratos_alugueis/${idRentContract}/valores`;

    const { data } = await api.get(url);

    return data;
};

export const createRealStateRentContractValueIndex = async (
    idRentContract: number | string,
    payload: Partial<IARentContractValue>
) => {
    const url = `imobiliarias/contratos_alugueis/${idRentContract}/valores`;

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateRentContractValueIndex = async (
    idRentContract: number | string,
    idRentContractValue: number | string,
    payload: Partial<IARentContractValue>
) => {
    const url = `imobiliarias/contratos_alugueis/${idRentContract}/valores/${idRentContractValue}`;

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateRentContractValueIndex = async (
    idRentContract: number | string,
    idRentContractValue: number | string
) => {
    const url = `imobiliarias/contratos_alugueis/${idRentContract}/valores/${idRentContractValue}`;

    const { data } = await api.delete(url);

    return data;
};

export const updateRealStateRentContractValueMassive = async (
    period: string,
    percentReadjust: number
): Promise<{
    message: string;
}> => {
    const url = `/imobiliarias/contratos_alugueis/valores/atualizar_em_massa/${period}`;

    const { data } = await api.post(url, {
        porcentagemReajuste: percentReadjust,
    });

    return data;
};
