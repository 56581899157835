import { ReactNode, useState, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import { StepCircle } from 'components/molecules';
import {
    getRealStateOwnerBankAccountIndex,
    getRealStateOwnerShow,
    updateRealStateOwner,
    updateRealStateOwnerResponsible,
    createRealStateOwnerBankAccountIndex,
    updateRealStateOwnerBankAccountIndex,
} from 'services';

import { convertDateENToBR } from 'utils'

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { OwnerFormWrap } from './styles';

import { bankAccountDataSchema, businessDataSchema, personDataSchema, documentCpfSchema } from '../validations';

import { BankAccountData, BusinessData, PersonData, ValidateDocument, Finish } from '../components'

import Layout from '../../Layout';

import type {
    IAPerson, IAPersonBankAccount,
} from '@types';


const OwnerForm = () => {
    const titleMessages = 'Formulário de Proprietário';

    const params = useParams();

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('BUSINESS_DATA');

    const [bankAccountData, setBankAccountData] = useState<Partial<IAPersonBankAccount>>();
    const [personData, setPersonData] = useState<Partial<IAPerson>>();
    const [ownerPersonData, setOwnerPersonData] = useState<Partial<IAPerson>>();

    const {
        isLoading: isLoadingBankAccountList,
        data: bankAccountDataApi
    } = useQuery([QUERIES_REAL_STATE.OWNER.BANK_ACCOUNT.base, params?.id], () =>
        getRealStateOwnerBankAccountIndex(String(params?.id)),
        {
            refetchOnMount: true,
            onSuccess: (res) => {
                const findedBankAccount = res?.find((bankAccount) => bankAccount?.ativa) || res?.[0] || undefined;

                if (findedBankAccount) {
                    setBankAccountData(findedBankAccount)
                }
            }
        }
    );

    const {
        isLoading: isLoadingRealStateDetails,
        isFetching: isFetchingRealStateDetails,
        data: personDataApi,
    } = useQuery([QUERIES_REAL_STATE.OWNER.base, params?.id], () =>
        getRealStateOwnerShow(String(params?.id)),
        {
            refetchOnMount: true,
            onSuccess: (res) => {

                const personPayload = res;

                if (personPayload?.nascimento) {
                    personPayload.nascimento = convertDateENToBR(personPayload?.nascimento);
                }

                setPersonData(personPayload);

                const ownerPayload = res?.responsaveis;

                if (ownerPayload?.nascimento) {
                    ownerPayload.nascimento = convertDateENToBR(ownerPayload?.nascimento);
                }
                setOwnerPersonData(ownerPayload);

                if (bankAccountData === undefined) {
                    setBankAccountData({
                        nomeTitular: res?.nome,
                        tipoConta: '',
                        banco: '',
                        agencia: '',
                        conta: '',
                        numeroDocumento: res?.documento,
                        ativa: true,
                    })
                } else {
                    setBankAccountData({
                        ...bankAccountData,
                        nomeTitular: res?.nome,
                        numeroDocumento: res?.documento,
                    })
                }
            }
        }
    );

    const { mutate: mutateUpdateData, isLoading: isLoadingUpdatePersonData } = useMutation(
        (payload: Partial<IAPerson>) => updateRealStateOwner(Number(params.id), payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, params?.id, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.OWNER.base, params?.id]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdateResponsibleData, isLoading: isLoadingUpdateOwnerData } = useMutation(
        (payload: Partial<IAPerson>) => updateRealStateOwnerResponsible(Number(params.id), payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, params?.id, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.OWNER.base, params?.id]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateCreateBankAccountData, isLoading: isLoadingCreateBankAccountData } = useMutation(
        (payload: Partial<IAPersonBankAccount>) => createRealStateOwnerBankAccountIndex(String(params?.id), payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.BANK_ACCOUNT.base, String(params?.id)],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.OWNER.BANK_ACCOUNT.base, String(params?.id)]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdateBankAccountData, isLoading: isLoadingUpdateBankAccountData } = useMutation(
        (payload: Partial<IAPersonBankAccount>) => updateRealStateOwnerBankAccountIndex(String(params?.id), String(payload?.id), payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.BANK_ACCOUNT.base, String(params?.id)],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.OWNER.BANK_ACCOUNT.base, String(params?.id)]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const handleSubmitToNext = async () => {
        if (step === 'VALIDATE_DOCUMENT') {
            setStep('BUSINESS_DATA');
        } else if (step === 'BUSINESS_DATA') {
            const { success } = await onValidationSchema(businessDataSchema, personData);

            if (success && personData) {
                mutateUpdateData(personData);
            }
        } else if (step === 'PERSON_DATA') {
            const { success } = await onValidationSchema(personDataSchema, personData);

            if (success && personData) {
                mutateUpdateData(personData);
            }
        } else if (step === 'OWNER_DATA') {
            const { success } = await onValidationSchema({ ...personDataSchema, documento: documentCpfSchema }, ownerPersonData);

            if (success && ownerPersonData) {
                const payload = ownerPersonData;

                if (payload?.nascimento) {
                    payload.nascimento = payload.nascimento.split('/').reverse().join('-');
                }

                mutateUpdateResponsibleData(payload);
            }

        } else if (step === 'BANK_ACCOUNT_DATA') {

            const { success } = await onValidationSchema(bankAccountDataSchema, bankAccountData);

            if (success && bankAccountData) {
                if (bankAccountData?.id) {
                    mutateUpdateBankAccountData(bankAccountData);
                } else {
                    mutateCreateBankAccountData(bankAccountData);
                }

            }

        }
    }


    const valueChecked = useMemo(() => {
        const checked: string[] = ['VALIDATE_DOCUMENT'];

        const personDataLocal = personDataApi;
        const ownerPersonDataLocal = personDataApi?.responsaveis;
        const bankAccountDataLocal = bankAccountDataApi?.find((bankAccount) => bankAccount?.ativa) || bankAccountDataApi?.[0] || undefined;

        let currentStep = 'BUSINESS_DATA';

        if (
            !!personDataLocal?.nome &&
            !!personDataLocal?.telefone &&
            !!personDataLocal?.email &&
            !!personDataLocal?.documento &&
            !!personDataLocal?.cep &&
            !!personDataLocal?.logradouro &&
            !!personDataLocal?.bairrosIds &&
            !!personDataLocal?.numero
        ) {

            if (personData?.documento?.length === 11) {
                checked.push('PERSON_DATA');
                currentStep = 'BANK_ACCOUNT_DATA';

            } else {

                checked.push('BUSINESS_DATA');
                currentStep = 'OWNER_DATA';
            }
        }

        if (
            !!ownerPersonDataLocal?.nome &&
            !!ownerPersonDataLocal?.telefone &&
            !!ownerPersonDataLocal?.email &&
            !!ownerPersonDataLocal?.documento &&
            !!ownerPersonDataLocal?.nascimento &&
            !!ownerPersonDataLocal?.cep &&
            !!ownerPersonDataLocal?.logradouro &&
            !!ownerPersonDataLocal?.bairrosIds &&
            !!ownerPersonDataLocal?.numero
        ) {
            checked.push('OWNER_DATA');

            currentStep = 'BANK_ACCOUNT_DATA';
        }

        if (!!bankAccountDataLocal?.nomeTitular &&
            !!bankAccountDataLocal?.tipoConta &&
            !!bankAccountDataLocal?.banco &&
            !!bankAccountDataLocal?.agencia &&
            !!bankAccountDataLocal?.conta &&
            !!bankAccountDataLocal?.numeroDocumento) {

            checked.push('BANK_ACCOUNT_DATA');

            currentStep = 'FINISH';
        }

        if (personDataLocal?.integracoesPagamentosVendedoresIds) {
            checked.push('FINISH');
        }

        setStep(currentStep)

        return checked;

    }, [personDataApi, bankAccountDataApi]);

    const stepsOptions = useMemo(() => {
        if (personData?.documento?.length === 11) {
            return [
                { label: 'Etapa 1', subLabel: "Consulta de Dados", value: 'VALIDATE_DOCUMENT' },
                { label: 'Etapa 2', subLabel: "Dados da Pessoa", value: 'PERSON_DATA' },
                { label: 'Etapa 3', subLabel: 'Dados Bancários', value: 'BANK_ACCOUNT_DATA' },
                { label: 'Etapa 4', subLabel: 'Concluir Integração', value: 'FINISH' }
            ]
        }

        return [
            { label: 'Etapa 1', subLabel: "Consulta de Dados", value: 'VALIDATE_DOCUMENT' },
            { label: 'Etapa 2', subLabel: "Dados da Empresa", value: 'BUSINESS_DATA' },
            { label: 'Etapa 3', subLabel: "Dados do Responsável", value: 'OWNER_DATA' },
            { label: 'Etapa 4', subLabel: 'Dados Bancários', value: 'BANK_ACCOUNT_DATA' },
            { label: 'Etapa 5', subLabel: 'Concluir Integração', value: 'FINISH' }
        ]
    }, [personData?.documento]);


    const tabsComponent: Record<string, ReactNode> = {
        VALIDATE_DOCUMENT: (<ValidateDocument
            isAvailable
            isConsulted
            isLoadingConsult={false}
            isLoadingInvite={false}
            typeDocument={personData?.documento?.length === 11 ? 'CPF' : 'CNPJ'}
            values={personData}
        />),
        PERSON_DATA: <PersonData values={personData} onChangeValues={setPersonData} isResponsible={false} />,
        BUSINESS_DATA: <BusinessData values={personData} onChangeValues={setPersonData} />,
        OWNER_DATA: <PersonData values={ownerPersonData} onChangeValues={setOwnerPersonData} isResponsible />,
        BANK_ACCOUNT_DATA: <BankAccountData
            values={bankAccountData}
            onChangeValues={setBankAccountData}
        />,
        FINISH: <Finish usuariosAcessosIds={params?.id} integracoesPagamentosVendedoresIds={personData?.integracoesPagamentosVendedoresIds} />,
    };

    const buttonsActions: Record<string, ReactNode> = {
        VALIDATE_DOCUMENT: (<Button color="octernary" onClick={() => handleSubmitToNext()}>Avançar</Button>),
        PERSON_DATA: (<Button color="octernary" onClick={() => handleSubmitToNext()} loading={isLoadingUpdatePersonData}>Avançar</Button>),
        BUSINESS_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingUpdatePersonData}>Avançar</Button>),
        OWNER_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingUpdateOwnerData}>Avançar</Button>),
        BANK_ACCOUNT_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingCreateBankAccountData || isLoadingUpdateBankAccountData}>Avançar</Button>),
        FINISH: null,
    };

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Proprietários',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.OWNER.fullPath),
                },
                {
                    label: 'Formulário do Proprietário',
                    href: getLinkWithSlug(`${ROUTES_REAL_STATE.OWNER.FORM.fullPath}/${params?.id}`),
                },
            ]}
            title="Formulário do Proprietário"
            buttonTitle={buttonsActions[step]}
        >
            <OwnerFormWrap className="real-state-panel-owner-register">
                <Shadow>
                    <Loading
                        active={
                            isLoadingBankAccountList ||
                            isLoadingRealStateDetails ||
                            isFetchingRealStateDetails
                        }
                    />

                    <StepCircle
                        options={stepsOptions}
                        valueActive={step}
                        onChangeActive={setStep}
                        typeOnClick="isActive"
                        valueChecked={valueChecked}
                    />

                    {tabsComponent[step]}
                </Shadow>
            </OwnerFormWrap>
        </Layout>
    );
};

export default OwnerForm;
