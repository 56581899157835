import { Helmet } from 'react-helmet';

import { AdministratorValueDetailList } from 'components/templates';

const AdministratorValueDetailsListPage = () => (
    <>
        <Helmet>
            <title>Detalhes de Valores - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorValueDetailList />
    </>
);

export default AdministratorValueDetailsListPage;
