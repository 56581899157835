import { generateURL } from './prefix';
import api from '../api';

import type { IAPricesIndexs } from '@types';

export const getAdministratorPricesIndexsIndex = async () => {
    const url = generateURL(`/indices_de_precos`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorPricesIndexsShow = async (id: number | string) => {
    const url = generateURL(`/indices_de_precos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorPricesIndexs = async (
    payload: Partial<IAPricesIndexs>
) => {
    const url = generateURL(`/indices_de_precos`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorPricesIndexs = async (
    id: number | string,
    payload: Partial<IAPricesIndexs>
) => {
    const url = generateURL(`/indices_de_precos/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorPricesIndexs = async (id: number | string) => {
    const url = generateURL(`/indices_de_precos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
