import { LineLabelValueInterface } from './interface'

import { LineLabelValueWrap } from './styles'

const LineLabelValue = ({
  label,
  value,
  total = false
}: LineLabelValueInterface) => (
  <LineLabelValueWrap className="line-label-value-atom" total={total}>
    <span className="number">{label}</span>
    <strong className="number">{value}</strong>
  </LineLabelValueWrap>
)

export default LineLabelValue
