import styled from 'styled-components';

export const ModalHeaderWrap = styled.div`
    width: 100%;
    height: 4.063rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 0.063rem solid
        ${(props) => props.theme.colors.borderSepternary};

    div {
        padding: 0 1.875rem;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-direction: center;
        flex: 1;
        background-color: ${(props) => props.theme.colors.background};

        h3 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 100%;
            color: ${(props) => props.theme.colors.textPrimary};
            margin-bottom: 0px;

            text-transform: uppercase;
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.063rem;
        width: 4.063rem;

        border-left: 0.063rem solid
            ${(props) => props.theme.colors.borderSepternary};

        svg {
            font-weight: 400;
            font-size: 2rem;
            color: ${(props) => props.theme.colors.textTertiary};
        }
    }
`;
