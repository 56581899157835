import { AiOutlineCheck } from 'react-icons/ai'

import { StepCircleItemWrap } from './styles'
import type { StepCircleItemInterface } from './interface'


const StepCircleItem = ({
  label,
  subLabel,
  isChecked = false,
  onChecked = () => null,
  isActive = false,
  onActive = () => null,
  typeOnClick = 'isChecked'
}: StepCircleItemInterface) => {
  const onClick = () => {
    if (typeOnClick === 'isActive') {
      onActive(!isActive)
    } else {
      onChecked(!isChecked)
    }
  }

  return (
    <StepCircleItemWrap
      className="step-circle-item-atom"
      isChecked={isChecked}
      isActive={isActive}
      onClick={onClick}
    >
      <i className="circle">
        <AiOutlineCheck />
      </i>

      <div className="step-text">
        <span className="label">{label}</span>
        <span className="sub-label">{subLabel}</span>
      </div>
    </StepCircleItemWrap>
  )
}

export default StepCircleItem
