import { Helmet } from 'react-helmet';

import { AdministratorRealStateForm } from 'components/templates';

const AdministratorRealStateFormPage = () => (
    <>
        <Helmet>
            <title>Imobiliárias - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorRealStateForm />
    </>
);

export default AdministratorRealStateFormPage;
