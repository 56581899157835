import styled from 'styled-components'

export const LoadingWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.loading};

  &.active {
    display: flex;
  }
`
