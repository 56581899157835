import { generateURL } from './prefix'
import api from '../api';

import type { IAInvoice } from '@types';

export const getRealStateInvoiceIndex = async (): Promise<IAInvoice[]> => {
    const url = generateURL(`/faturas`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateInvoiceGenerate = async (
    period: string,
    rentContractId?: string
): Promise<{
    message: string;
}> => {
    let url = generateURL(`/faturamento/gerar/${period}`);

    if(rentContractId) {
        url = `${url}?contratosAlugueisIds=${rentContractId}`;
    }


    const { data } = await api.get(url);

    return data;
};

export const getRealStateInvoicePayMannually = async (
    invoiceId?: string
): Promise<{
    message: string;
}> => {
    const url = generateURL(`/faturas/${invoiceId}/pagar`);
    
    const { data } = await api.get(url);

    return data;
};



export const getRealStateInvoiceCancel = async (
    invoiceId?: string
): Promise<{
    message: string;
}> => {
    const url = generateURL(`/faturas/${invoiceId}/cancelar`);
    
    const { data } = await api.get(url);

    return data;
};
