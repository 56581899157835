import { Helmet } from 'react-helmet';

import { AdministratorPersonTagList } from 'components/templates';

const AdministratorPersonTagListPage = () => (
    <>
        <Helmet>
            <title>Tags da Pessoa - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPersonTagList />
    </>
);

export default AdministratorPersonTagListPage;
