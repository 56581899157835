import styled, { css } from 'styled-components'

const variations = {
  primary: css`
    color: ${props => props.theme.colors.primary};

    background-color: ${props => props.theme.colors.backgroundPrimary};
  `
}

interface BoxInfoWrapInterface {
  variation: 'primary'
}

export const BoxInfoWrap = styled.div<BoxInfoWrapInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.375rem;

  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;

  -webkit-box-shadow: 5px -3px 16px 0px rgba(126, 157, 184, 0.1);
  -moz-box-shadow: 5px -3px 16px 0px rgba(126, 157, 184, 0.1);
  box-shadow: 5px -3px 16px 0px rgba(126, 157, 184, 0.1);

  padding: 1rem;

  ${props => variations[props.variation]};
`
