import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components'

import { LoadingWrap } from './styles';

import type { LoadingInterface } from './interface';

const Loading = ({ active }: LoadingInterface) => {
    const theme = useTheme();

    return (
        <LoadingWrap className={`loading-atom ${active ? 'active' : ''}`}>
            <ReactLoading
                type="bubbles"
                color={theme.colors.secondary}
                height="5rem"
                width="5rem"
            />
        </LoadingWrap>
    );
}

export default Loading;
