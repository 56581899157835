import styled from 'styled-components';

export const NavWrap = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5rem;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 30%;
        background-color: ${props => props.theme.colors.senary};
        z-index: -1;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 70%;
        background-color: ${(props) => props.theme.colors.senary};
        z-index: 99;
        box-shadow: 0.062rem -0.062rem 0px 0px rgba(72, 72, 93, 0.2);
    }

    @media (max-width: 992px) {
        display: block;
        height: auto;

        &:after,
        &:before {
            display: none;
        }
    }
`;
