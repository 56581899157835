import type { IUOptions } from '@types'

class InvoiceEnums {

  public status = [
    { label: 'Paga', value: 'PAGA' },
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Cancelada', value: 'CANCELADA' },
    { label: 'Vencida', value: 'VENCIDA' }
  ]


  public getStatus(param?: string): IUOptions {
    const filtered = this.status.find(item => item.value === param)

    return !filtered ? this.status[0] : filtered
  }
}

export default new InvoiceEnums()
