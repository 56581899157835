import {
  Card,
  Grid,
  LineLabelValue,
  Attributes,
  CardImage,
  Button
} from 'components/atoms'
import { convertFloatToBR } from 'utils'
import { PropertyHorizontalInterface } from './interface'


import { PropertyHorizontalWrap } from './styles'


const PropertyHorizontal = ({
  addressCity,
  addressNeightborhood,
  addressState,
  attributes,
  buttonLabel,
  buttonLink,
  iconSize = "large",
  imageUrl,
  imageCode,
  imageDescription,
  valueDetails,
  valueProperty,
  typeValueProperty
}: PropertyHorizontalInterface) => (
  <div className="property-horizontal-molecule">
    <Card>
      <PropertyHorizontalWrap className="wrap">
        <CardImage
          code={`COD${String(imageCode).padStart(4, '0')}`}
          type={imageDescription || ''}
          image={imageUrl || `/images/empty_property.png`}
        />

        <Grid
          numberColumns={2}
          gridTemplateColumns="3fr 2fr"
          className="box-content"
        >
          <div className="column-left">
            <Grid numberColumns={3}>
              {attributes.map(attribute => (
                <Attributes
                  key={attribute.label}
                  label={attribute.label}
                  value={attribute.value}
                  type={attribute.type || 'DEFAULT'}
                  size={iconSize}
                />
              ))}
            </Grid>


            <Attributes
              label="Endereço"
              value={`${addressNeightborhood} - ${addressCity} - ${addressState}`}
              style={{ margin: '20px 0 0 0' }}
              type="ADDRESS"
              size={iconSize}
            />

            {buttonLabel && buttonLink && (
              <Button
                color="septernary"
                size="mini"
                className="button-action"
                href={buttonLink}
              >
                {buttonLabel}
              </Button>
            )}
          </div>

          <div className="column-right">
            {valueDetails?.map(
              ({ label, value }) => (
                <LineLabelValue
                  key={label}
                  label={label || ''}
                  value={`R$ ${convertFloatToBR(value)}`}
                />
              )
            )}

            <LineLabelValue
              label={typeValueProperty || ''}
              value={`R$ ${convertFloatToBR(valueProperty)}`}
              total
            />
          </div>
        </Grid>
      </PropertyHorizontalWrap>
    </Card>
  </div>
)

export default PropertyHorizontal
