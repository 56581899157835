import { generateURL } from './prefix';
import api from '../api';

export const createCustomerRentContractVisit = async (dataParam: any) => {
    const url = generateURL(`/contratos/visitas`);


    const { data } = await api.post(url, dataParam);

    return data;
};

export const updateCustomerRentContractVisit = async (
    id: number,
    dataParam: any
) => {
    const url = generateURL(`/contratos/visitas/${id}`);


    const { data } = await api.put(url, dataParam);

    return data;
};

export const removeCustomerRentContractVisit = async (id: number) => {
    const url = generateURL(`/contratos/visitas/${id}`);


    const { data } = await api.delete(url);

    return data;
};
