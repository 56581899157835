import { WhatsAppIcon } from 'components/atoms'
import { ContractInterface } from './interface'

import * as S from './styles'

const Contract = ({ whatsapp }: ContractInterface) => (
  <S.ContractWrap>
    <div className="description">Solicitar contrato para imobiliária</div>

    <div className="content">
      <h2>CONTRATO DE ALUGUEL</h2>
      <span>
        Estamos quase lá, após a sua confirmação, a imobiliária analisará as
        suas informações, gerar seu contrato e lhe contatar para assinatura!
      </span>
    </div>

    <div className="footer">
      {whatsapp && (
        <WhatsAppIcon
          value={whatsapp}
          href={`https://api.whatsapp.com/send?phone=55${whatsapp}`}
        />
      )}
    </div>
  </S.ContractWrap>
)

export default Contract
