import { generateURL } from './prefix'
import api from '../api';

import type { IARentContract } from '@types';

export const getRealStateRentContractIndex = async (): Promise<
    IARentContract[]
> => {
    const url = generateURL(`/contratos_alugueis`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateRentContractShow = async (
    id: number | string
): Promise<IARentContract> => {
    const url = generateURL(`/contratos_alugueis/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const updateRealStateRentContract = async (
    id: number | string,
    payload: Partial<IARentContract>
): Promise<IARentContract> => {
    const url = generateURL(`/contratos_alugueis/${id}`);

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateRentContract = async (id: number) => {
    const url = generateURL(`/contratos_alugueis/${id}`);

    const { data } = await api.delete(url);

    return data;
};

export const changeStatusRealStateRentContract = async (
    id: number | string,
    { status }: Pick<IARentContract, 'status'>,
    { multa }: { multa?: number }
): Promise<{ message: string }> => {
    const url = generateURL(`/contratos_alugueis/${id}/status/${status}`);

    const { data } = await api.post(url, { multa });

    return data;
};


export const updatePersonDataIntegrationRealStateRentContract = async (
    id: number | string,
    personId: number | string,
) => {
    const url = generateURL(`/contratos_alugueis/${id}/atualizar_integracao/${personId}`);

    const { data } = await api.post(url);

    return data;
};

