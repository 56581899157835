import styled, { css } from 'styled-components'

interface OptionCircleWrapInterface {
  checked: boolean
}

export const OptionCircleWrap = styled.button<OptionCircleWrapInterface>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 7.5rem;
  width: 100%;
  padding: 0 2.5rem;
  border-bottom: 0.063rem solid ${props => props.theme.colors.borderSecondary};

  .circle {
    position: relative;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 3.125rem;
    border: 0.313rem solid ${props => props.theme.colors.borderSecondary};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    ${props =>
    props.checked &&
    css`
        background-color: ${props.theme.colors.tertiaryExtraLight};

        svg {
          display: block;
          color: ${props.theme.colors.senary};
          font-size: 1.25rem;
        }

        &:after {
          content: '';
          position: absolute;

          display: block;
          height: 2.375rem;
          width: 2.375rem;
          border-radius: 2.375rem;
          border: 0.063rem solid ${props.theme.colors.senary};
        }
      `}
  }

  .option-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.938rem;

    .label {
      display: block;
      font-size: 0.938rem;
      font-weight: 800;
      color: ${props => props.theme.colors.textSecondary};
    }

    .sub-label {
      display: block;
      margin-top: 0.313rem;
      font-size: 0.938rem;
      font-weight: 300;
      color: ${props => props.theme.colors.textSecondary};
    }
  }
`
