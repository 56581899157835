import { Helmet } from 'react-helmet';

import { RealStateInsuranceCompanyForm } from 'components/templates';

const RealStateInsuranceCompanyFormPage = () => (
    <>
        <Helmet>
            <title>Seguradoras - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateInsuranceCompanyForm />
    </>
);

export default RealStateInsuranceCompanyFormPage;
