import { Helmet } from 'react-helmet';

import { CustomerRentContract } from 'components/templates';

const CustomerRentContractPage = () => (
    <>
        <Helmet>
            <title>Contrato de Aluguel - Painel Cliente - Imobiler</title>
        </Helmet>

        <CustomerRentContract />
    </>
);

export default CustomerRentContractPage;
