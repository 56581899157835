import { useEffect, useState } from 'react';

import { NavBar } from 'components/molecules';

import { NavWrap } from './styles';

import type { NavInterface } from './interface';
import type { ICNavBarItem } from '@types';

const Nav = ({
    possuiImoveisAluguel = false,
    possuiImoveisVenda = false,
    getLinkWithSlug = (param) => param,
}: NavInterface) => {
    const [items, setItems] = useState<ICNavBarItem[]>([]);

    useEffect(() => {
        const newItems: ICNavBarItem[] = [];

        if (possuiImoveisAluguel) {
            newItems.push({
                label: 'Para Alugar',
                href: getLinkWithSlug('/properties?option=aluguel', true),
                color: 'white',
                lineRight: true,
            });
        }

        if (possuiImoveisVenda) {
            newItems.push({
                label: 'Para Comprar',
                href: getLinkWithSlug('/properties?option=venda', true),
                color: 'white',
                lineLeft: true,
                lineRight: true,
            });
        }

        newItems.push({
            label: 'Sobre',
            href: getLinkWithSlug('/about', true),
            color: 'white',
            lineLeft: newItems.length !== 0,
            lineRight: true,
        });

        newItems.push({
            label: 'Contato',
            href: getLinkWithSlug('/contact', true),
            color: 'white',
            lineLeft: true,
        });

        setItems(newItems);
    }, [possuiImoveisAluguel, possuiImoveisVenda]);

    return (
        <NavWrap className="nav-organism">
            <NavBar data={items} />
        </NavWrap>
    );
};

export default Nav;
