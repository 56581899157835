import styled from 'styled-components'

export const ToggleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.75rem;

  span {
    display: block;
    margin-left: 0.75rem;
    font-size: 0.688rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  button {
    width: 3.75rem;
    height: 1.875rem;
    border-radius: 0.938rem;
    background-color: ${props => props.theme.colors.tertiaryBold};
    border: 0.188rem solid ${props => props.theme.colors.tertiaryBold};
    opacity: 0.3;
    position: relative;
    transition: opacity linear 0.2s;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0.035rem;
      left: 0.063rem;
      height: 1.499rem;
      width: 1.499rem;
      border-radius: 100%;
      background-color: ${props => props.theme.colors.senary};
      transition: left linear 0.2s;
    }

    &.active {
      background-color: ${props => props.theme.colors.toggle};
      border-color: ${props => props.theme.colors.toggle};
      opacity: 1;

      &:before {
        margin-left: auto;
        left: 1.85rem;
      }
    }
  }
`
