import { FiArrowRight, FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
    ROUTES_REAL_STATE,
    ROUTES_ACCOUNT,
    QUERIES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, HelperOptions } from 'components/atoms';

import { Table } from 'components/organisms';

import { getRealStateOwnerIndex } from 'services';
import { maskCPF, maskCNPJ } from 'utils';

import { OwnerListWrap } from './styles';
import Layout from '../../Layout';

const Owner = () => {
    const navigate = useNavigate();
    const { getLinkWithSlug } = useRealState();

    const { data, isLoading } = useQuery(QUERIES_REAL_STATE.OWNER.base, () =>
        getRealStateOwnerIndex(),
        { refetchOnMount: true }
    );

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function newRegister() {
        onHandleNavigate(
            getLinkWithSlug(ROUTES_REAL_STATE.OWNER.NEW_FORM.fullPath)
        );
    }

    function viewRegister(ownerId: string | number) {
        onHandleNavigate(
            getLinkWithSlug(
                `${ROUTES_REAL_STATE.OWNER.FORM.fullPath}/${ownerId}`
            )
        );
    }

    const getIntegrationCompleted = (
        tags: {
            ligaTags: {
                slug: string;
            };
        }[]
    ) => {
        const tagsRequired = [
            'cadastro-inicial',
            'conta-bancaria-ativa',
            'envio-de-documentos',
            'cadastro-aprovado',
        ];

        const completed = tagsRequired?.every((tagRequiredItem) =>
            tags?.some((tagItem) => tagItem.ligaTags.slug === tagRequiredItem)
        );

        return completed;
    };

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Proprietários',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.OWNER.fullPath),
                },
            ]}
            title="Proprietários"
            buttonTitle={
                <Button color="nonary" onClick={() => newRegister()}>
                    Novo Registro
                </Button>
            }
        >
            <OwnerListWrap className="owner-template">
                <Table
                    isLoading={isLoading}
                    columns={[
                        {
                            dataField: 'documento',
                            text: 'Documento',
                            sort: true,
                            headerStyle: { minWidth: '20rem' },
                            style: { fontWeight: 700 },
                            formatter: (fieldValue: string) =>
                                fieldValue?.length > 11
                                    ? maskCNPJ(fieldValue)
                                    : maskCPF(fieldValue),
                        },
                        {
                            dataField: 'nome',
                            text: 'Nome',
                            sort: true,
                            headerStyle: { width: '100%' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue, _, rowValue) =>
                                HelperOptions({
                                    edit: true,
                                    remove: false,
                                    editFunction: () =>
                                        viewRegister(fieldValue),
                                    editIcon: getIntegrationCompleted(
                                        rowValue.tags
                                    ) ? (
                                        <FiCheck />
                                    ) : (
                                        <FiArrowRight />
                                    ),
                                }),
                        },
                    ]}
                    data={data}
                />
            </OwnerListWrap>
        </Layout>
    );
};

export default Owner;
