import { useRealState } from 'contexts/RealStateContext';

import { ROUTES_CUSTOMER } from '@constants';

import { Container, TitlePage, Row } from 'components/atoms';
import { SidebarList } from 'components/molecules';
import { HeaderPages } from 'components/organisms';

import { CustomerLayoutWrap } from './styles';

import type { CustomerLayoutInterface } from './interface';

const CustomerLayout = ({
    titlePage,
    breadcrumbs,
    title,
    children,
    buttonTitle,
}: CustomerLayoutInterface) => {
    const { getLinkWithSlug } = useRealState();

    return (
        <CustomerLayoutWrap className="customer-layout-template">
            <HeaderPages label={titlePage} breadcrumbs={breadcrumbs} />

            <Container>
                <Row>
                    <SidebarList
                        data={[
                            {
                                label: 'Minha Conta',
                                route: getLinkWithSlug(
                                    ROUTES_CUSTOMER.ACCOUNT.fullPath
                                ),
                            },

                        ]}
                    />

                    <div className="customer-layout-template-content">
                        {title && <TitlePage button={buttonTitle}>{title}</TitlePage>}
                        {children}
                        <div className="customer-layout-template-footer">
                            {buttonTitle}
                        </div>
                    </div>
                </Row>
            </Container>
        </CustomerLayoutWrap>
    );
};

export default CustomerLayout;
