import { useState, useRef } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
    ImBold,
    ImEye,
    ImPageBreak,
    ImPencil,
    ImStrikethrough,
    ImUnderline,
} from 'react-icons/im';
import { useTheme } from 'styled-components';

import { InputCapsule } from 'components/atoms';
import { checkEmpty } from 'utils';

import { Container } from './styles';

import type { TextHTMLAreaInterface } from './interface';

const TextHtmlArea = ({
    id,
    label,
    required,
    name,
    placeholder,
    value,
    onChange = () => null,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
    model = 'primary',
}: TextHTMLAreaInterface) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [yourRef] = useAutoAnimate<HTMLDivElement>();

    const theme = useTheme();
    const [preview, setPreview] = useState(false);

    function includeHTML(firstElement: string, secondElement: string) {
        const selectionStart = textAreaRef?.current?.selectionStart;
        const selectionEnd = textAreaRef?.current?.selectionEnd;

        if (selectionStart !== 0 || selectionEnd !== 0) {
            const initText = value.slice(0, selectionStart);
            const selectedText = value.slice(selectionStart, selectionEnd);
            const endText = value.slice(selectionEnd);

            return `${initText}${firstElement}${selectedText}${secondElement}${endText}`;
        }

        return `${firstElement}${secondElement}${value}`;
    }

    function textBold() {
        const newValue = includeHTML('[negrito]', '[/negrito]');
        onChange(newValue);
    }

    function textUnderline() {
        const newValue = includeHTML('[sublinhado]', '[/sublinhado]');
        onChange(newValue);
    }

    function textStrike() {
        const newValue = includeHTML('[riscado]', '[/riscado]');
        onChange(newValue);
    }

    function breakLine() {
        const newValue = includeHTML('[linha]', '');
        onChange(newValue);
    }

    function getFormattedHtml() {
        const objectHTML = [
            {
                edit: '[negrito]',
                html: '<strong>',
            },
            {
                edit: '[/negrito]',
                html: '</strong>',
            },
            {
                edit: '[sublinhado]',
                html: '<u>',
            },
            {
                edit: '[/sublinhado]',
                html: '</u>',
            },
            {
                edit: '[riscado]',
                html: '<s>',
            },
            {
                edit: '[/riscado]',
                html: '</s>',
            },
            {
                edit: '[linha]',
                html: '<br/>',
            },
            {
                edit: '\n',
                html: '<br/>',
            },
        ];

        let newValue = value;

        if (newValue !== null && newValue !== undefined && newValue !== '') {
            objectHTML.forEach((item) => {
                newValue = newValue.replaceAll(item.edit, item.html);
            });
        }

        return newValue;
    }

    return (
        <Container>
            <InputCapsule
                htmlFor={id}
                label={label}
                required={required}
                borderLeft={borderLeft}
                borderRight={borderRight}
                borderTop={borderTop}
                borderBottom={borderBottom}
                empty={checkEmpty(value)}
                model={model}
            >
                <div ref={yourRef}>
                    <div className="buttons">
                        <button
                            type="button"
                            onClick={() => textBold()}
                            title="Negrito"
                        >
                            <ImBold color={theme.colors.tertiary} />
                        </button>
                        <button
                            type="button"
                            onClick={() => textUnderline()}
                            title="Sublinhado"
                        >
                            <ImUnderline color={theme.colors.tertiary} />
                        </button>
                        <button
                            type="button"
                            onClick={() => textStrike()}
                            title="Riscado"
                        >
                            <ImStrikethrough color={theme.colors.tertiary} />
                        </button>
                        <button
                            type="button"
                            onClick={() => breakLine()}
                            title="Quebra de Linha"
                        >
                            <ImPageBreak color={theme.colors.tertiary} />
                        </button>
                        <button
                            type="button"
                            onClick={() => setPreview(!preview)}
                        >
                            {preview ? (
                                <>
                                    <ImPencil
                                        className="icon-space"
                                        color={theme.colors.tertiary}
                                    />
                                    Editar
                                </>
                            ) : (
                                <>
                                    <ImEye
                                        className="icon-space"
                                        color={theme.colors.tertiary}
                                    />
                                    Pré-Visualização
                                </>
                            )}
                        </button>
                    </div>
                    {preview ? (
                        <div
                            className="box"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: getFormattedHtml(),
                            }}
                        />
                    ) : (
                        <textarea
                            id={id}
                            placeholder={placeholder}
                            className="form-control"
                            ref={textAreaRef}
                            onChange={({ target }) => onChange(target.value)}
                            value={value}
                            name={name}
                        />
                    )}
                </div>
            </InputCapsule>
        </Container>
    );
};

export default TextHtmlArea;
