import { Helmet } from 'react-helmet';

import { AdministratorPlanForm } from 'components/templates';

const AdministratorPlanFormPage = () => (
    <>
        <Helmet>
            <title>Planos - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPlanForm />
    </>
);

export default AdministratorPlanFormPage;
