import { generateURL } from './prefix'
import api from '../api';

import type { IARealState } from '@types';

export const getRealStateSettingShow = async (): Promise<
    IARealState
> => {
    const url = generateURL(`/configuracoes`);

    const { data } = await api.get(url);

    return data;
};

export const updateRealStateSetting = async (step: string, payload: Partial<IARealState>): Promise<
    IARealState
> => {
    const url = generateURL(`/configuracoes/${step}`);

    const { data } = await api.put(url, payload);

    return data;
};