import { InputCapsuleOptionWrap } from './styles';

import type { ICInputCapsuleOption } from '@types';

const InputCapsuleOption = ({
    label,
    required,
    children,
    htmlFor,
    model = 'primary',
    row,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
}: ICInputCapsuleOption) => {
    function getLabel() {
        if (model === 'primary' && typeof label === 'string') {
            const labels = label.split(' ');

            if (labels.length > 1) {
                const firtWord = labels[0];
                delete labels[0];
                return (
                    <>
                        {firtWord}
                        <strong>{labels.join(' ')}</strong>
                    </>
                );
            }

            return label;
        }

        return label;
    }

    return (
        <InputCapsuleOptionWrap
            model={model}
            className="input-capsule-option-atom"
            row={row || false}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            <label htmlFor={htmlFor}>
                {getLabel()} {required && <span>*</span>}
            </label>
            <ul className="input">{children}</ul>
        </InputCapsuleOptionWrap>
    );
};

export default InputCapsuleOption;
