import { useState } from 'react';

import {
  InputColor,
} from 'components/molecules';


import { Grid } from 'components/atoms';

import theme from 'styles/theme'
import type {
  SettingColorsInterface,
} from './interface';



const SettingColors = ({
  onChangeValues,
  values
}: SettingColorsInterface
) => {

  const [colors, setColors] = useState<Record<string, string>>({
    ...theme.colors,
    ...values
  })

  const getColors = (param: string) => colors?.[param] || ''

  const onHandleColors = (param: string, value: string) => {
    const newColors = {
      ...colors,
      [param]: value
    }

    setColors(newColors);
    onChangeValues(newColors);
  }

  return (<div>
    <Grid numberColumns={3}>
      <InputColor
        label="Cores Primárias - 1"
        value={getColors('primary')}
        onChange={value => onHandleColors('primary', value)}
        placeholder="Informe a cor"
        name="primary"
        model="sinary"
      />

      <InputColor
        label="Cores Primárias - 2"
        value={getColors('primaryLight')}
        onChange={value => onHandleColors('primaryLight', value)}
        placeholder="Informe a cor"
        name="primaryLight"
        model="sinary"
      />

      <InputColor
        label="Cores Primárias - 3"
        value={getColors('textQuarterback')}
        onChange={value => onHandleColors('textQuarterback', value)}
        placeholder="Informe a cor"
        name="textQuarterback"
        model="sinary"
      />
    </Grid>

    <Grid numberColumns={3}>
      <InputColor
        label="Cores Secundárias - 1"
        value={getColors('secondary')}
        onChange={value => onHandleColors('secondary', value)}
        placeholder="Informe a cor"
        name="secondary"
        model="sinary"
      />

      <InputColor
        label="Cores Secundárias - 2"
        value={getColors('secondaryBold')}
        onChange={value => onHandleColors('secondaryBold', value)}
        placeholder="Informe a cor"
        name="secondaryBold"
        model="sinary"
      />

      <InputColor
        label="Cores Secundárias - 3"
        value={getColors('secondaryExtraBold')}
        onChange={value => onHandleColors('secondaryExtraBold', value)}
        placeholder="Informe a cor"
        name="secondaryExtraBold"
        model="sinary"
      />
    </Grid>

    <Grid numberColumns={3}>
      <InputColor
        label="Cores Secundárias - 4"
        value={getColors('toggle')}
        onChange={value => onHandleColors('toggle', value)}
        placeholder="Informe a cor"
        name="toggle"
        model="sinary"
      />

      <InputColor
        label="Cores Secundárias - 5"
        value={getColors('check')}
        onChange={value => onHandleColors('check', value)}
        placeholder="Informe a cor"
        name="check"
        model="sinary"
      />

      <InputColor
        label="Cores Secundárias - 6"
        value={getColors('octernary')}
        onChange={value => onHandleColors('octernary', value)}
        placeholder="Informe a cor"
        name="octernary"
        model="sinary"
      />
    </Grid>

    <Grid numberColumns={3}>
      <InputColor
        label="Cores Terciárias - 1"
        value={getColors('tertiary')}
        onChange={value => onHandleColors('tertiary', value)}
        placeholder="Informe a cor"
        name="tertiary"
        model="sinary"
      />

      <InputColor
        label="Cores Terciárias - 2"
        value={getColors('quaternary')}
        onChange={value => onHandleColors('quaternary', value)}
        placeholder="Informe a cor"
        name="quaternary"
        model="sinary"
      />

      <InputColor
        label="Cores Terciárias - 3"
        value={getColors('sexternary')}
        onChange={value => onHandleColors('sexternary', value)}
        placeholder="Informe a cor"
        name="sexternary"
        model="sinary"
      />
    </Grid>
  </div>
  );

};

export default SettingColors;
