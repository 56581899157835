import { convertFloatToBR, convertDateENToBR, maskCellphone } from 'utils';

import { InvoiceSettlementDetailsWrap } from './styles';

import type { InvoiceSettlementDetailsInterface } from './interface';

const InvoiceSettlementDetails = ({
    data,
}: InvoiceSettlementDetailsInterface) => {
    const faturasAcertosDetalhes = [...(data?.faturasAcertosDetalhes || [])];

    const credores = data?.credores;
    const imobiliarias = data?.imobiliarias;

    const logo = data?.imobiliarias?.imagens?.find(
        (itemImage) => itemImage.tipo === 'logoHeader'
    );

    return (
        <InvoiceSettlementDetailsWrap className="invoice-settlement-details-organism number">
            <div className="header">
                <div>
                    <span className="title">{imobiliarias?.pessoas?.nome}</span>
                    <span className="values">
                        {imobiliarias?.pessoas?.logradouro || 'Endereço N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.numero || 'n° N/I'}
                    </span>
                    <span className="values">
                        {imobiliarias?.pessoas?.bairros?.nome || 'Bairro N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.bairros?.cidade?.nome ||
                            'Cidade N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.bairros?.cidade?.uf ||
                            'Estado N/I'}
                    </span>
                    <span className="values">
                        {imobiliarias?.pessoas?.telefone
                            ? maskCellphone(imobiliarias?.pessoas?.telefone)
                            : 'Telefone N/I'}
                    </span>
                </div>

                {logo && (
                    <div className="logo">
                        <img src={logo.url} alt={imobiliarias?.pessoas?.nome} />
                    </div>
                )}
            </div>

            <div className="description">
                <div>
                    <span className="title">Acerto de: </span>
                    <span className="values">{credores?.nome}</span>
                    <span className="values">
                        {credores?.logradouro || 'Endereço N/I'}
                        {' - '}
                        {credores?.numero || 'n° N/I'}
                    </span>
                    <span className="values">
                        {credores?.bairros?.nome || 'Bairro N/I'}
                        {' - '}
                        {credores?.bairros?.cidade?.nome || 'Cidade N/I'}
                        {' - '}
                        {credores?.bairros?.cidade?.uf || 'Estado N/I'}
                    </span>
                    <span className="values">
                        {credores?.telefone
                            ? maskCellphone(credores?.telefone)
                            : 'Telefone N/I'}
                    </span>
                </div>
                <div>
                    <span className="values">
                        Fatura de Acerto n°: {String(data?.id).padStart(9, '0')}
                    </span>
                    <span className="values">
                        Emitida: {convertDateENToBR(data?.createdAt)}
                    </span>
                </div>
            </div>

            <table className="number">
                <thead>
                    <tr>
                        <th>descrição</th>
                        <th className="value">valor</th>
                    </tr>
                </thead>

                <tbody>
                    {faturasAcertosDetalhes?.map(
                        (faturasAcertosDetalhesItem) => (
                            <tr>
                                <td>{faturasAcertosDetalhesItem?.descricao}</td>
                                <td className="value">
                                    {convertFloatToBR(
                                        faturasAcertosDetalhesItem?.valor,
                                        2,
                                        'R$ '
                                    )}
                                </td>
                            </tr>
                        )
                    )}
                    <tr>
                        <td colSpan={2} className="value">
                            <strong>
                                Saldo:{' '}
                                {convertFloatToBR(data?.valor, 2, 'R$ ')}
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </InvoiceSettlementDetailsWrap>
    );
};

export default InvoiceSettlementDetails;
