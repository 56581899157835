import { H1Wrap } from './styles'

import type { H1Interface } from './interface'

const H1 = ({ children, className }: H1Interface) => (
  <H1Wrap className={className ? `${className} h1-atom` : 'h1-atom'}>
    {children}
  </H1Wrap>
)

export default H1
