import { Helmet } from 'react-helmet';

import { AdministratorPropertiesAttributesForm } from 'components/templates';

const AdministratorPropertiesAttributesFormPage = () => (
    <>
        <Helmet>
            <title>Atributos dos Imóveis - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPropertiesAttributesForm />
    </>
);

export default AdministratorPropertiesAttributesFormPage;
