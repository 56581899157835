// eslint-disable @typescript-eslint/no-unused-vars
import Cookies from 'js-cookie';
import { ReactNode, useState } from 'react';

import { ButtonForm, Container, H1, Shadow, TextForm } from 'components/atoms';
import { InputText } from 'components/molecules';

import { enviroments, sweetAlert } from 'utils';

import { LayoutWrap } from './styles';

interface StagingMiddlewareProps {
    children: ReactNode;
}

const StagingMiddleware = ({ children }: StagingMiddlewareProps) => {
    const initStagingPass = Cookies.get('staging-pass');

    const [password, setPassword] = useState('');
    const [, setAccessGranted] = useState(
        initStagingPass === enviroments.STAGING_PASSWORD
    );

    const onHandleLogin = (event: any) => {
        event.preventDefault();

        if (password === enviroments.STAGING_PASSWORD) {
            Cookies.set('staging-pass', password);

            setAccessGranted(true);

            sweetAlert.fire({
                title: 'Acesso Liberado!',
                text: 'Tacá-le pau nesses testes!',
                icon: 'success',
            });
        } else {
            sweetAlert.fire({
                title: 'Acesso Negado!',
                text: 'Senha inválida!',
                icon: 'error',
            });
        }
    };

    return <div>{children}</div>;
    
    // const unlockedAccess =
    // accessGranted || !enviroments.IS_STAGING;
    
    // if (unlockedAccess) {
    //     return <div>{children}</div>;
    // }

    return (
        <LayoutWrap>
            <Container>
                <H1>
                    Validar <strong>Acesso</strong>
                </H1>
                <form onSubmit={onHandleLogin}>
                    <main>
                        <Shadow>
                            <TextForm model="primary" borderTop>
                                Você está acessando um ambiente de testes,
                                informe as credencias para continuar.
                            </TextForm>

                            <InputText
                                required
                                label="Senha"
                                id="password"
                                name="password"
                                placeholder="Digite sua senha..."
                                value={password}
                                onChange={setPassword}
                                borderTop
                                password
                            />
                        </Shadow>

                        <ButtonForm id="on-sign" color="tertiary" type="submit">
                            Entrar
                        </ButtonForm>
                    </main>
                </form>
            </Container>
        </LayoutWrap>
    );
};

export default StagingMiddleware;
