import { Helmet } from 'react-helmet';

import { AdministratorAccount } from 'components/templates';

const AdministratorAccountPage = () => (
    <>
        <Helmet>
            <title>Minha Conta - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <AdministratorAccount />
    </>
);

export default AdministratorAccountPage;
