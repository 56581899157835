/* eslint-disable react/destructuring-assignment, react/no-did-update-set-state, react/no-access-state-in-setstate, react/state-in-constructor, react/jsx-no-useless-fragment, radix, arrow-body-style */
import { Component } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';

import { UploadDropBox } from 'components/atoms';
import { ImageListLine, ImageListSquare } from 'components/molecules';

import { api } from 'services';
import { sweetAlert } from 'utils';
import theme from 'styles/theme';

import { UploadFilesWrap, Content } from './styles';

import type { ICUploadFiles } from '@types';
import type { MyProps, MyState } from './interface';

export default class UploadFiles extends Component<MyProps, MyState> {
    state: MyState = {
        uploadedFiles: [],
    };

    async componentDidMount(): Promise<void> {
        const { props } = this;


        this.setState({
            uploadedFiles: props.data?.map((file) => ({
                id: file.id,
                key: String(file.id),
                name: file.name,
                preview: file.url,
                readableSize: filesize(file?.size || 0),
                size: file?.size || 0,
                uploaded: true,
                url: file.url,
                destaque: file?.destaque || false,
            })),
        });
        
    }

    componentDidUpdate(prevProps: any) {
        // Uso típico, (não esqueça de comparar as props):
        if (
            this.props.data !== prevProps.data &&
            Array.isArray(this.props.data)
        ) {
            this.setState({
                uploadedFiles: this.props.data?.map((file) => ({
                    id: file.id,
                    key: String(file.id),
                    name: file.name,
                    preview: file.url,
                    readableSize: filesize(file?.size || 0),
                    size: file?.size || 0,
                    uploaded: true,
                    url: file.url,
                    destaque: file?.destaque || false,
                })),
            });
        }
    }

    componentWillUnmount(): void {
        this.state.uploadedFiles?.forEach((file) =>
            URL.revokeObjectURL(file?.preview || '')
        );
    }

    handleUpload = (files: any): void => {
        const listFiles = files.map((file: any) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(listFiles),
        });

        listFiles.forEach(this.processUpload);
    };

    processUpload = (uploadedFile: any): void => {
        const data = new FormData();

        data.append('file', uploadedFile.file, uploadedFile.name);

        api.post(this.props.urlAdd, data, {
            onUploadProgress: ({ loaded, total }) => {
                const progress = parseInt(
                    String(Math.round((loaded * 100) / total))
                );

                this.updateFile(uploadedFile.id, {
                    progress,
                });
            },
        })
            .then((response) => {
                this.updateFile(uploadedFile.id, {
                    uploaded: true,
                    id: response.data.id,
                    url: response.data.url,
                });

                if (this.props.successedUpload) {
                    this?.props?.successedUpload();
                }
            })
            .catch(() => {
                this.updateFile(uploadedFile.id, {
                    error: true,
                });
            });
    };

    updateFile = (id: number | string, data: Partial<ICUploadFiles>): void => {
        this.setState({
            uploadedFiles: this.state.uploadedFiles.map((file) => {
                return id === file.id ? { ...file, ...data } : file;
            }),
        });
    };

    handleDelete = async (id: number): Promise<void> => {
        await api.delete(`${this.props.urlRemove}/${id}`);

        this.setState({
            uploadedFiles: this.state.uploadedFiles.filter(
                (file) => file.id !== id
            ),
        });

        if (this.props.successedUpload) {
            this.props.successedUpload();
        }
    };

    handleFeature = async (id: number): Promise<void> => {
        await api.post(`${this.props.urlFeature}/${id}`);

        this.setState({
            uploadedFiles: this.state.uploadedFiles.map((file) =>
                file.id === id
                    ? {
                          ...file,
                          destaque: true,
                      }
                    : {
                          ...file,
                          destaque: false,
                      }
            ),
        });
    };

    removeRegister = (id: number) => {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar esta imagem será removida e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.primary,
                cancelButtonColor: theme.colors.danger,
                confirmButtonText: 'Sim, remover imagem!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.handleDelete(id);
                }
            });
    };

    render(): JSX.Element {
        const {
            variation = 'primary',
            label = null,
            subLabel = null,
            isRequired,
            titleList = null,
        } = this.props;

        const VariationComponentUpload =
            variation === 'primary' ? ImageListSquare : ImageListLine;

        return (
            <UploadFilesWrap
                className="upload-images-organism"
                variation={variation}
            >
                <Content variation={variation}>
                    {label !== null && subLabel !== null && (
                        <>
                            <div className="box-label">
                                <label htmlFor="file-upload">
                                    {label}
                                    {isRequired && <span>*</span>}
                                </label>
                                <span className="sub-label">{subLabel}</span>
                            </div>
                        </>
                    )}
                    <UploadDropBox
                        onDropAccepted={this.handleUpload}
                        variation={variation}
                    >
                        {this.props.children}
                    </UploadDropBox>

                    {titleList && (
                        <>
                            <span className="title-list">{titleList}</span>
                        </>
                    )}

                    {this.state.uploadedFiles?.length ? (
                        <VariationComponentUpload
                            files={this.state.uploadedFiles}
                            onDelete={this.removeRegister}
                            feature={!!this.props.urlFeature}
                            onFeature={this.handleFeature}
                        />
                    ) : (
                        <div className="empty-box">
                            Não há nenhum documento anexado
                        </div>
                    )}
                </Content>
            </UploadFilesWrap>
        );
    }
}
