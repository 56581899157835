import styled from 'styled-components';

interface GridWrapInterface {
    numberColumns: number;
    gridTemplateColumns?: string;
    gridGap?: string;
}

export const GridWrap = styled.div<GridWrapInterface>`
    display: grid;
    grid-template-columns: ${({ gridTemplateColumns, numberColumns }) =>
        gridTemplateColumns || `repeat(${numberColumns}, 1fr)`};
    grid-gap: ${(props) => (props.gridGap ? props.gridGap : '0.938rem')};
`;
