import api from 'services/api';
import { generateURL } from './prefix';

import type { IAPaymentMethod } from '@types';

export const getPublicPaymentMethodIndex = async (): Promise<
    IAPaymentMethod[]
> => {
    const url = generateURL(`/formas_de_pagamentos`);

    const { data } = await api.get(url);

    return data;
};
