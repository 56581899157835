/* eslint-disable  no-lonely-if, react/no-unstable-nested-components */
import { useState } from 'react';

import { InputCapsule, Button, HelperButton, Text } from 'components/atoms';

import { Modal } from 'components/molecules';

import { SelectModalFilterFetchWrap, InputWrap } from './styles';
import TableFetch from '../TableFetch';

import type { SelectModalFilterFetchInterface } from './interface';

const SelectModalFilterFetch = ({
    placeholder,
    value,
    onChange = () => null,
    options,
    columns,
    filter,
    onChangeFilter,
    keyLabel,
    keyValue,
    totalItems,
    ...rest
}: SelectModalFilterFetchInterface) => {
    const [isOpen, setIsOpen] = useState(false);

    function onCloseModal() {
        setIsOpen(false);
    }

    function onHandleValue(newValue: string) {
        onChange(newValue);
        onCloseModal();
    }

    function isActive(fieldValue: string) {
        return String(fieldValue) === String(value);
    }

    function getLabel() {
        const activeOption = options?.find((option) =>
            isActive(String(option?.[keyValue] ))
        );

        if (activeOption) {
            return activeOption?.[keyLabel];
        }

        return placeholder;
    }

    return (
        <SelectModalFilterFetchWrap className="select-modal-filter-organism">
            <Modal
                open={isOpen}
                onCloseModal={() => onCloseModal()}
                title="Selecione uma opção"
                size="lg"
                footer={
                    <Button color="octernary" onClick={() => onCloseModal()}>
                        Concluir
                    </Button>
                }
            >
                <TableFetch
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                    columns={[
                        ...columns,
                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) => (
                                <HelperButton
                                    color={
                                        isActive(fieldValue)
                                            ? 'neuter'
                                            : 'active'
                                    }
                                    text={
                                        isActive(fieldValue)
                                            ? 'Selecionado'
                                            : 'Selecionar'
                                    }
                                    onClick={() => onHandleValue(fieldValue)}
                                />
                            ),
                        },
                    ]}
                    data={options}
                    totalItems={totalItems}
                />
            </Modal>

            <InputCapsule {...rest}>
                <InputWrap className='input-fake'>
                    <Text>{getLabel()}</Text>
                    <Button
                        className="button-filter"
                        onClick={() => setIsOpen(true)}
                        color="septernary"
                        size="mini"
                    >
                        Selecionar
                    </Button>
                </InputWrap>
            </InputCapsule>
        </SelectModalFilterFetchWrap>
    );
};

export default SelectModalFilterFetch;
