import styled from 'styled-components'

export const CalendarWrap = styled.div`
  width: 20rem;

  .row-month-year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.938rem;
    padding-bottom: 0.938rem;
    border-bottom: 0.125rem solid ${props => props.theme.colors.borderPrimary};

    span {
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      color: ${props => props.theme.colors.textPrimary};
    }

    svg {
      font-size: 0.8rem;
    }
  }

  .row-week-days {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.375rem;

    span {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${props => props.theme.colors.textQuaternary};
    }
  }

  .row-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1.05rem;

    .days-number {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 2rem;
      height: 2rem;
      border-radius: 2rem;

      border: 0.125rem solid ${props => props.theme.colors.borderSepternary};

      font-size: 1rem;
      color: ${props => props.theme.colors.textTertiary};
      font-weight: 500;

      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.colors.check};
        border-color: ${props => props.theme.colors.borderSepternary};
        color: ${props => props.theme.colors.textSenary};
      }

      &.inactive {
        background-color: ${props => props.theme.colors.borderSepternary};
        color: ${props => props.theme.colors.textQuaternary};

        opacity: 0.5;
        cursor: initial;
      }

      &.active {
        position: relative;
        background-color: ${props => props.theme.colors.check};
        border-color: ${props => props.theme.colors.borderSepternary};
        color: ${props => props.theme.colors.textSenary};
        z-index: 999;

        &:before {
          content: '';
          position: absolute;
          top: -0.565rem;
          left: -0.565rem;

          display: block;
          border: 0.45rem solid ${props => props.theme.colors.check};
          opacity: 0.25;

          width: 2rem;
          height: 2rem;
          border-radius: 2rem;
        }
      }
    }
  }
`
