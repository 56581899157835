import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FiEye } from 'react-icons/fi';


import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Table } from 'components/organisms';
import {
    Button,
    HelperDate,
    HelperMoney,
    HelperOptions,
} from 'components/atoms';
import { propertyExpenseEnums } from 'enums';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    getRealStatePropertyExpenseIndex,
    removeRealStatePropertyExpense,
} from 'services';
import { sweetAlert } from 'utils';

import { PropertyExpenseListWrap } from './styles';
import Layout from '../../Layout';
import type { PropertyExpenseMutationInterface } from './interface';
import type { ButtonInterface } from 'components/atoms/Button/interface';
import type { IAPropertyExpense } from '@types';

const PropertyExpense = () => {
    const navigate = useNavigate();
    const title = 'Lançamentos de Imóveis';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function newRegister() {
        onHandleNavigate(
            getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY_EXPENSE.FORM.fullPath)
        );
    }

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.PROPERTY_EXPENSE.list,
        () => getRealStatePropertyExpenseIndex(),
        { refetchOnMount: true }
    );

    const { mutate: removeRegister }: PropertyExpenseMutationInterface =
        useMutation(({ id }) => removeRealStatePropertyExpense(Number(id)), {
            mutationKey: QUERIES_REAL_STATE.PROPERTY_EXPENSE.remove,
            onSuccess: (response) => {
                onSuccessMessage(title, response?.data?.message || "Registro removido com sucesso!");

                queryClient.invalidateQueries(
                    QUERIES_REAL_STATE.PROPERTY_EXPENSE.list
                );
            },
            onError: (err) => onErrorMessage(title, err),
        });

    function editRegister(id: string | number) {
        onHandleNavigate(
            getLinkWithSlug(
                `${ROUTES_REAL_STATE.PROPERTY_EXPENSE.FORM.fullPath}/${id}`
            )
        );
    }

    function removeRegisterRequest(id: number) {

        const register = dataList?.find((item: IAPropertyExpense) => item.id === id);

        if(register?.status === "FATURADO") {
            sweetAlert.fire({
                title: 'Atenção',
                text: 'Não é possível remover um lançamento faturado!',
                icon: 'error'
            });

            return;
        }

        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    const statusComponent = useCallback((fieldValue) => {
        const statusItem = propertyExpenseEnums.getStatus(fieldValue);
        const buttonColors: Record<string, ButtonInterface['color']> = {
            EM_ABERTO: 'septernary',
            CANCELADO: 'danger',
            FATURADO: 'octernary',
            PAGO: 'octernary',
        };

        return (
            <Button size="mini" color={buttonColors[fieldValue]}>
                {statusItem.label}
            </Button>
        );
    }, []);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.PROPERTY_EXPENSE.fullPath
                    ),
                },
            ]}
            title={title}
            buttonTitle={
                <Button color="nonary" onClick={() => newRegister()}>
                    Novo Registro
                </Button>
            }
        >
            <PropertyExpenseListWrap className="property-expense-template">
                <Table
                    isLoading={isLoadingList}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'Id',
                            sort: true,
                            headerStyle: { width: '10%' },
                        },
                        {
                            dataField: 'descricao',
                            text: 'Descrição',
                            sort: true,
                            headerStyle: { width: '20%' },
                        },

                        {
                            dataField: 'imoveis',
                            text: 'Imóvel Cod.',
                            sort: true,
                            formatter: (fieldValue) => fieldValue?.personalizacoes?.[0]?.id,
                            headerStyle: { width: '10%' },
                        },
                        {
                            dataField: 'data',
                            text: 'Data',
                            sort: true,
                            formatter: (fieldValue) =>
                                HelperDate({ value: fieldValue }),
                            headerStyle: { minWidth: '8%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Status',
                            sort: true,
                            headerStyle: { width: '14.5%' },
                            formatter: (fieldValue) =>
                                statusComponent(fieldValue),
                        },

                        {
                            dataField: 'valor',
                            text: 'Valor',
                            sort: true,
                            formatter: (fieldValue) =>
                                HelperMoney({ value: fieldValue }),
                            headerStyle: { minWidth: '32.5%' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue, _, row) => 
                                HelperOptions({
                                    editFunction: () =>
                                        editRegister(fieldValue),
                                        editIcon: row.status === 'EM_ABERTO' ? undefined : <FiEye />,
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue)
                                }),
                            headerStyle: { minWidth: '10%' },
                        },
                    ]}
                    data={dataList}
                />
            </PropertyExpenseListWrap>
        </Layout>
    );
};

export default PropertyExpense;
