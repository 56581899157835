import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Grid, Loading, Shadow } from 'components/atoms';
import {
    InputMask,
    InputNumber,
    InputText,
    OptionBlock,
    Select,
} from 'components/molecules';
import { SelectModalFilterFetch } from 'components/organisms';

import { propertyExpenseEnums } from 'enums';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    createRealStatePropertyExpense,
    getRealStatePropertyExpenseShow,
    updateRealStatePropertyExpense,
    getRealStatePropertyIndex,
} from 'services';
import { convertDateENToBR } from 'utils';

import { PropertyExpenseFormWrap } from './styles';
import Layout from '../../Layout';

import type { PropertyExpenseMutationInterface } from './interface';

const PropertyExpenseForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [filterProperties, setFilterProperties] = useState({
        page: 1,
        limit: 10,
        search: '',
        sort: 'DESC',
        order: 'id'
    });

    const [data, setData] = useState(convertDateENToBR(new Date()));
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState(0);
    const [competencia, setCompetencia] = useState('');
    const [tipoDebito, setTipoDebito] = useState('');
    const [tipoCredito, setTipoCredito] = useState('');
    const [tipoLancamento, setTipoLancamento] = useState('');
    const [tipoFiscal, setTipoFiscal] = useState('NORMAL');
    const [status, setStatus] = useState('EM_ABERTO');
    const [imoveisIds, setImoveisIds] = useState('');

    const title = 'Lançamentos dos Imóveis';

    const { data: dataDetail, isLoading: isLoadingGet } = useQuery(
        [QUERIES_REAL_STATE.PROPERTY_EXPENSE.show, id],
        () => getRealStatePropertyExpenseShow(String(id)),
        {
            enabled: !!id,
            refetchOnMount: true,
        }
    );

    useEffect(() => {
        if (dataDetail) {
            setData(
                dataDetail?.data ? convertDateENToBR(dataDetail?.data) : ''
            );
            setDescricao(dataDetail?.descricao);
            setValor(dataDetail?.valor);
            setCompetencia(
                dataDetail?.competencia
                    ? dataDetail.competencia.split('-').reverse().join('/')
                    : ''
            );
            setTipoDebito(dataDetail?.tipoDebito);
            setTipoCredito(dataDetail?.tipoCredito);
            setTipoLancamento(dataDetail?.tipoLancamento);
            setStatus(
                !dataDetail?.status || dataDetail?.status === ''
                    ? 'EM_ABERTO'
                    : dataDetail?.status
            );
            setImoveisIds(
                dataDetail?.imoveisIds ? String(dataDetail.imoveisIds) : ''
            );
            setTipoFiscal(!dataDetail?.tipoFiscal || dataDetail?.tipoFiscal === ''
                ? 'NORMAL'
                : dataDetail?.tipoFiscal)
        }
    }, [dataDetail]);

    const {
        mutate: mutateCreate,
        isLoading: isLoadingCreate,
    }: PropertyExpenseMutationInterface = useMutation(
        (payload) => createRealStatePropertyExpense(payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY_EXPENSE.show,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.PROPERTY_EXPENSE.list
                );
                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.PROPERTY_EXPENSE.list
                );

                navigate(
                    getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY_EXPENSE.fullPath)
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const {
        mutate: mutateUpdate,
        isLoading: isLoadingUpdate,
    }: PropertyExpenseMutationInterface = useMutation(
        (payload) => updateRealStatePropertyExpense(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY_EXPENSE.show,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.PROPERTY_EXPENSE.list
                );

                queryClient.refetchQueries([
                    QUERIES_REAL_STATE.PROPERTY_EXPENSE.show,
                    id,
                ]);
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async () => {
        const dataSend = {
            id,
            data: data ? data.split('/').reverse().join('-') : '',
            descricao,
            valor,
            competencia: competencia
                ? competencia.split('/').reverse().join('-')
                : '',
            tipoDebito,
            tipoCredito,
            tipoLancamento,
            tipoFiscal,
            status,
            imoveisIds,
        };

        let shapeSchema = {
            data: Yup.string().nullable().required('A data é obrigatória.'),
            competencia: Yup.string().nullable(),
            descricao: Yup.string()
                .nullable()
                .required('A descrição é obrigatória.'),
            valor: Yup.string().nullable().required('O valor é obrigatório.'),
            tipoDebito: Yup.string()
                .nullable()
                .required('O tipo débito é obrigatório.'),
            tipoCredito: Yup.string()
                .nullable()
                .required('O tipo crédito é obrigatório.'),
            tipoLancamento: Yup.string()
                .nullable()
                .required('O tipo lançamento é obrigatório.'),
            imoveisIds: Yup.string()
                .nullable()
                .required('O imóvel é obrigatório.'),
        };

        if (tipoLancamento !== 'FIXO') {
            shapeSchema = {
                ...shapeSchema,
                competencia: Yup.string()
                    .nullable()
                    .required('A competência é obrigatório.'),
            };
        }

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };

    const filtersPropertiesKey = Object.values(filterProperties).join(',');
    const { data: dataPropertiesList } = useQuery(
        `${QUERIES_REAL_STATE.PROPERTY.list}-${filtersPropertiesKey}`,
        () => getRealStatePropertyIndex({
            pagina: filterProperties.page,
            limite: filterProperties.limit,
            pesquisa: filterProperties.search,
            ordenar: filterProperties.order,
            ordenarDirecao: filterProperties.sort,
        })
    );

    const selectTypeCredit = useMemo(() => {
        if (tipoDebito === tipoCredito && tipoDebito !== '') {
            setTipoDebito('');
        }

        const options = propertyExpenseEnums.typeCredit.filter(
            (typeCreditItem) => typeCreditItem.value !== tipoDebito
        );

        return (
            <Select
                required
                label="Tipo de Crédito"
                name="tipoCredito"
                placeholder="Informe a quem será creditado"
                options={options}
                value={tipoCredito}
                onChange={setTipoCredito}
                model="sinary"
            />
        );
    }, [propertyExpenseEnums, tipoDebito, tipoCredito]);

    const selectTypeDebit = useMemo(() => {
        if (tipoDebito === tipoCredito && tipoDebito !== '') {
            setTipoDebito('');
        }

        const options = propertyExpenseEnums.typeDebit.filter(
            (typeDebitItem) => typeDebitItem.value !== tipoCredito
        );

        return (
            <Select
                required
                label="Tipo de Debito"
                name="tipoDebito"
                placeholder="Informe de quem será o débito"
                options={options}
                value={tipoDebito}
                onChange={setTipoDebito}
                model="sinary"
            />
        );
    }, [propertyExpenseEnums, tipoCredito, tipoDebito]);

    const selectTypeExpense = useMemo(() => {
        let options = propertyExpenseEnums.typeExpense;

        if (
            (tipoCredito === 'IMOBILIARIA' && tipoDebito === 'PROPRIETARIO') ||
            (tipoCredito === 'PROPRIETARIO' && tipoDebito === 'IMOBILIARIA')
        ) {
            options = propertyExpenseEnums.typeExpense.filter(
                (typeDebitItem) => typeDebitItem.value !== 'FIXO'
            );

            if (tipoLancamento !== 'AVULSO') {
                setTipoLancamento('');
            }
        }

        return (
            <Select
                required
                label="Tipo de Lançamento"
                name="tipoLancamento"
                placeholder="Informe o tipo de lançamento"
                options={options}
                value={tipoLancamento}
                onChange={setTipoLancamento}
                model="sinary"
            />
        );
    }, [propertyExpenseEnums, tipoDebito, tipoCredito, tipoLancamento]);

    const inputCompetence = useMemo(() => {
        if (tipoLancamento === 'FIXO') {
            return null;
        }

        return (
            <InputMask
                valueMasked
                required
                mask="99/9999"
                label="Competência"
                name="competencia"
                placeholder="mês/ano"
                value={competencia}
                onChange={setCompetencia}
                model="sinary"
            />
        );
    }, [competencia, tipoLancamento]);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.PROPERTY_EXPENSE.fullPath
                    ),
                },
                {
                    label: `Formulário ${title}`,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.PROPERTY_EXPENSE.FORM.fullPath
                    ),
                },
            ]}
            title={`Formulário ${title}`}
            buttonTitle={
                (status === 'EM_ABERTO' || status === '') && (
                    <Button
                        color="denary"
                        onClick={() => onSubmit()}
                        loading={
                            isLoadingGet || isLoadingCreate || isLoadingUpdate
                        }
                    >
                        <AiFillSave />
                        Salvar
                    </Button>
                )
            }
        >
            <PropertyExpenseFormWrap className="real-state-panel-property-expense-form">
                <Shadow>
                    <Loading active={isLoadingGet} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <SelectModalFilterFetch
                        filter={filterProperties}
                        onChangeFilter={setFilterProperties}
                        columns={[
                            {
                                dataField: 'id',
                                text: 'Id',
                                sort: true,
                                style: { fontWeight: 700 },
                            },
                            {
                                dataField: 'titulo',
                                text: 'Título',
                                sort: true,
                                headerStyle: { width: '100%' },
                            }
                        ]}
                        required
                        label="Imóvel"
                        name="imoveisIds"
                        placeholder="Informe o imóvel do lançamento"
                        options={dataPropertiesList?.data}
                        value={imoveisIds}
                        onChange={setImoveisIds}
                        model="sinary"
                        keyLabel='titulo'
                        keyValue='id'
                        totalItems={dataPropertiesList?.count || 0}
                    />

                    <InputText
                        required
                        label="Descrição"
                        name="descricao"
                        placeholder="Informe a descrição"
                        value={descricao}
                        onChange={setDescricao}
                        model="sinary"
                    />

                    <Grid numberColumns={2}>
                        <InputMask
                            required
                            valueMasked
                            mask="99/99/9999"
                            label="Data"
                            name="data"
                            placeholder="Informe a data"
                            value={data}
                            onChange={setData}
                            model="sinary"
                        />
                    </Grid>

                    <Grid numberColumns={3}>
                        {selectTypeCredit}

                        {selectTypeDebit}

                        {selectTypeExpense}
                    </Grid>

                    <Grid numberColumns={3}>
                        <InputNumber
                            required
                            label="Valor"
                            name="valor"
                            placeholder="Informe o valor"
                            prefix="R$ "
                            value={valor}
                            onChange={setValor}
                            model="sinary"
                        />

                        {inputCompetence}

                        <Select
                            required
                            label="Tipo Fiscal"
                            name="tipoFiscal"
                            placeholder="Informe o tipo fiscal"
                            options={propertyExpenseEnums.fiscalType}
                            value={tipoFiscal}
                            onChange={setTipoFiscal}
                            model="sinary"
                        />
                    </Grid>
                </Shadow>
            </PropertyExpenseFormWrap>
        </Layout>
    );
};

export default PropertyExpenseForm;
