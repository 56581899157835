import styled from 'styled-components';

export const HeaderWrap = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 7.5rem !important;
    width: 100%;
    padding: 0 0.25rem;
    background-color: ${(props) => props.theme.colors.senary};

    .row-header {
        align-items: center;
    }

    .row-header-logo {
        margin-top: 6.25rem;
        margin-left: 1rem;
        max-height: 6.5rem;
        max-width: 18rem;
    }

    .row-header-social {
        margin: 0 0 0 auto;
        align-items: center;
    }

    .social-icon-atom {
        margin: 0 0 0 0.65rem;
    }

    .row-header-buttons :first-child button {
        margin: 0 1.25rem 0 3.5rem;
    }

    @media (max-width: 992px) {
        display: block;
        height: auto !important;

        .row-header {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .row-header-logo {
            max-height: 5rem;
            max-width: 15rem;
            margin: 2.75rem 0 2.25rem 0.5rem;
            padding: 0;
        }

        .row-header-social {
            display: none;
          }

        .row-header-buttons {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1rem;
            margin: 0.625rem 0;

            .button-atom {
                flex: 1;

                a,
                button {
                    margin: 0;
                    flex: 1;
                    width: 100%;
                }
            }
        }
    }
`;
