import { Helmet } from 'react-helmet';

import { AdministratorMethodsPaymentsForm } from 'components/templates';

const AdministratorMethodsPaymentsFormPage = () => (
    <>
        <Helmet>
            <title>Métodos de Pagamento - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorMethodsPaymentsForm />
    </>
);

export default AdministratorMethodsPaymentsFormPage;
