import {
  FaInstagram,
  FaPhoneAlt,
  FaFacebookF,
  FaYoutube,
  FaWhatsapp,
  FaMapMarkerAlt
} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'

import { TextIconWrap } from './styles'

import type { TextIconInterface } from './interface'


const TextIcon = ({
  href,
  children,
  icon,
  colorIcon,
  style,
  className
}: TextIconInterface) => {
  const Icons = {
    facebook: <FaFacebookF color={colorIcon} />,
    instagram: <FaInstagram color={colorIcon} />,
    youtube: <FaYoutube color={colorIcon} />,
    email: <HiOutlineMail color={colorIcon} />,
    whatsapp: <FaWhatsapp color={colorIcon} />,
    phone: <FaPhoneAlt color={colorIcon} />,
    map: <FaMapMarkerAlt color={colorIcon} />
  }

  return (
    <TextIconWrap style={style} href={href} icon={icon} className={className}>
      {icon !== null && Icons[icon]}
      <span>{children}</span>
    </TextIconWrap>
  )
}

export default TextIcon
