import { OptionCircle } from 'components/atoms'

import { SelectCircleWrap } from './styles'

import type { SelectCircleInterface } from './interface'

const SelectCircle = ({
  options = [],
  value: valueOut,
  onChange
}: SelectCircleInterface) => (
  <SelectCircleWrap className="select-circle-molecule">
    {options.map(({ label, subLabel, value }) => (
      <OptionCircle
        label={label}
        subLabel={subLabel}
        checked={value === valueOut}
        onChecked={() => onChange(value)}
      />
    ))}
  </SelectCircleWrap>
)

export default SelectCircle
