import styled from 'styled-components';

export const OwnerValidateDocumentWrap = styled.div`
    padding: 1.875rem;

    p {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.tertiaryBold};
        line-height: 150%;

        margin-bottom: 2rem;
    }
`;
