import { useAutoAnimate } from '@formkit/auto-animate/react';

import { useCallback, useMemo, useState } from 'react';
import {
    AiFillCaretDown,
    AiFillCaretLeft,
    AiFillCaretUp,
    AiFillCaretRight,
} from 'react-icons/ai';

import { Skeleton } from 'components/atoms';
import { MySearch } from 'components/molecules';

import { TableWrap } from './styles';

import type { TableInterface } from './interface';

const Table = ({
    columns,
    data = [],
    isLoading,
    noMargins = false,
    noShadow = false,
}: TableInterface) => {
    const [yourRef] = useAutoAnimate<HTMLTableSectionElement>();

    const [textFilter, setTextFilter] = useState('');
    const [sortObject, setSortObject] = useState({
        key: '',
        order: 'ASC',
    });

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageActive, setPageActive] = useState(0);

    const applyFilter = (dataParam: Record<string, string>[]) => {
        if (textFilter === '') {
            return dataParam?.filter((item) => item !== null);
        }

        const textFilterLower = textFilter.toLowerCase();

        return dataParam?.filter((item) => {
            const resSome = Object.keys(item).some(
                (item2) =>
                    String(item[item2]).toLowerCase().indexOf(textFilterLower) >
                    -1
            );

            return !!resSome;
        });
    };

    const applySorter = (dataParam: Record<string, string>[]) => {
        if (sortObject.key === '') {
            return dataParam;
        }

        if (sortObject.order === 'ASC') {
            return dataParam?.sort((item1, item2) => {
                if (item1[sortObject.key] < item2[sortObject.key]) {
                    return -1;
                }
                if (item1[sortObject.key] > item2[sortObject.key]) {
                    return 1;
                }
                return 0;
            });
        }

        return dataParam?.sort((item1, item2) => {
            if (item1[sortObject.key] > item2[sortObject.key]) {
                return -1;
            }
            if (item1[sortObject.key] < item2[sortObject.key]) {
                return 1;
            }
            return 0;
        });
    };

    const applyPaginate = (dataParam: Record<string, string>[]) => {
        const initialPosition = pageActive * itemsPerPage;

        return dataParam?.slice(
            initialPosition,
            initialPosition + itemsPerPage
        );
    };

    const getData = useCallback(() => {
        if (isLoading) {
            return [
                { label: '1' },
                { label: '2' },
                { label: '3' },
                { label: '4' },
                { label: '5' },
                { label: '6' },
                { label: '7' },
                { label: '8' },
                { label: '9' },
                { label: '10' },
            ];
        }

        if (!Array.isArray(data)) {
            return [];
        }

        const dataFilter = applyFilter(data);

        const dataSorter = applySorter(dataFilter);

        const dataPaginate = applyPaginate(dataSorter);

        return dataPaginate;
    }, [data, itemsPerPage, pageActive, sortObject, textFilter]);

    const totalItems = useMemo(
        () => applyFilter(data)?.length || 0,
        [data, textFilter]
    );

    const handleSortObject = (paramKey: string) => {
        if (paramKey === sortObject.key) {
            if (sortObject.order === 'DESC') {
                setSortObject({
                    key: '',
                    order: 'ASC',
                });
            } else {
                setSortObject({
                    key: paramKey,
                    order: 'DESC',
                });
            }
        } else {
            setSortObject({
                key: paramKey,
                order: 'ASC',
            });
        }
    };

    const verifyOrderActive = useCallback(
        (column: string, order) =>
            column === sortObject.key && sortObject.order === order
                ? 'active'
                : '',
        [sortObject]
    );

    const handleChangeItemPerPage = (valueParam: number) => {
        setItemsPerPage(valueParam);
        setPageActive(0);
    };

    return (
        <TableWrap noShadow={noShadow} noMargins={noMargins}>
            <MySearch onSearch={setTextFilter} />

            <table>
                <thead>
                    <tr>
                        {columns?.map((column) => (
                            <th
                                key={column.text}
                                style={column?.headerStyle}
                                onClick={() =>
                                    column.sort &&
                                    handleSortObject(column.dataField)
                                }
                                className={column.sort ? 'sort' : ''}
                            >
                                <div className="content-header">
                                    {column.formatterText ? column.formatterText :column.text}

                                    {column.sort && (
                                        <div className="carets">
                                            <AiFillCaretUp
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'ASC'
                                                )}
                                            />

                                            <AiFillCaretDown
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'DESC'
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody ref={yourRef}>
                    {getData().map((row, indexRow) => {
                        const keyRow = `index_row_${indexRow}`;

                        return (
                            <tr key={keyRow}>
                                {columns.map((column, indexColumn) => {
                                    const keyColumn = `index_column_${indexColumn}`;

                                    if (isLoading) {
                                        return (
                                            <td
                                                key={keyColumn}
                                                style={column?.style}
                                            >
                                                <Skeleton height="1.3rem" />
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            key={keyColumn}
                                            style={column?.style}
                                        >
                                            {column.formatter
                                                ? column.formatter(
                                                      row[column.dataField],
                                                      indexRow,
                                                      row
                                                  )
                                                : row[column.dataField]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="footer">
                <div className="box-left">
                    <ul className="pagination">
                        {pageActive > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 1
                                        )
                                    }
                                >
                                    <AiFillCaretLeft />
                                </button>
                            </li>
                        )}

                        {pageActive - 1 > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 2
                                        )
                                    }
                                >
                                    {pageActive - 1}
                                </button>
                            </li>
                        )}

                        {pageActive > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 1
                                        )
                                    }
                                >
                                    {pageActive}
                                </button>
                            </li>
                        )}

                        <li className="page-item active">
                            <button type="button" onClick={() => null}>
                                {pageActive + 1}
                            </button>
                        </li>

                        {pageActive * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 1
                                        )
                                    }
                                >
                                    {pageActive + 2}
                                </button>
                            </li>
                        )}

                        {(pageActive + 1) * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 2
                                        )
                                    }
                                >
                                    {pageActive + 3}
                                </button>
                            </li>
                        )}

                        {pageActive * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 1
                                        )
                                    }
                                >
                                    <AiFillCaretRight />
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="box-right">
                    <span className="description">
                        Mostrando {pageActive * itemsPerPage + 1} até{' '}
                        {pageActive * itemsPerPage + itemsPerPage} de{' '}
                        {totalItems} registros.
                    </span>

                    <select
                        value={itemsPerPage}
                        onChange={(event) =>
                            handleChangeItemPerPage(Number(event.target.value))
                        }
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={totalItems}>Todos</option>
                    </select>
                </div>
            </div>
        </TableWrap>
    );
};

export default Table;
