import { BoxInfoInterface } from './interface'

import { BoxInfoWrap } from './styles'

const BoxInfo = ({ children, variation = 'primary', style }: BoxInfoInterface) => (
  <BoxInfoWrap variation={variation} className="box-info-atom" style={style}>
    {children}
  </BoxInfoWrap>
)

export default BoxInfo
