import { useCallback } from "react";
import { AiOutlineSearch, AiOutlineUserAdd } from "react-icons/ai";
import { Button } from "components/atoms";
import { InputMask, Select } from "components/molecules";

import { OwnerValidateDocumentWrap } from './styles';

import type { OwnerValidateDocumentInterface } from './interface';
import type { IAPerson } from '@types';

export const ValidateDocument = ({ onChangeValues = () => null, values, isAvailable, isConsulted, isLoadingConsult, isLoadingInvite, onConsult = () => null, onInvite = () => null, setTypeDocument = () => null, typeDocument }: OwnerValidateDocumentInterface) => {

    const useField = useCallback(
        (key: keyof IAPerson) => {
            let value = null;

            if (values !== undefined) {
                value = values?.[key];
            }

            return {
                onChange: (newValue: string) =>
                    onChangeValues({ ...values, [String(key)]: newValue }),
                value: value ? String(value) : '',
                id: String(key),
                name: String(key),
            };
        },
        [values]
    );


    return (
        <OwnerValidateDocumentWrap>
            <Select
                model="sinary"
                id="typeDocument"
                name="typeDocument"
                label="Tipo de Documento"
                placeholder="Selecione o tipo de documento"
                options={[
                    { label: 'CPF', value: 'CPF' },
                    { label: 'CNPJ', value: 'CNPJ' },
                ]}
                value={typeDocument}
                onChange={setTypeDocument}
            />

            <InputMask
                model="sinary"
                required
                mask={
                    typeDocument === 'CNPJ'
                        ? '99.999.999/9999-99'
                        : '999.999.999-99'
                }
                label={`Documento (${typeDocument})`}
                placeholder="Informe o documento"
                {...useField('documento')}
            />

            {!isAvailable && !isConsulted && (
                <Button
                    color="denary"
                    style={{ margin: '0 auto 0 auto' }}
                    onClick={() => onConsult()}
                    loading={isLoadingConsult}
                >
                    <AiOutlineSearch />
                    Consultar
                </Button>
            )}

            {!isAvailable && isConsulted && (
                <Button
                    color="denary"
                    style={{ margin: '0 auto 0 auto' }}
                    onClick={() => onInvite()}
                    loading={isLoadingInvite}
                >
                    <AiOutlineUserAdd />
                    Convidar
                </Button>
            )}

        </OwnerValidateDocumentWrap>
    )
}

export default ValidateDocument;