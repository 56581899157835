// eslint-disable @typescript-eslint/explicit-module-boundary-types
import { Toggle, InputCapsule } from 'components/atoms';

import { InputMultiToggleWrap } from './styles';

import type { InputMultiToggleInterface } from './interface';

const InputMultiToggle = ({
    label,
    id,
    items,
    value,
    onChange,
    indexLabel,
    indexValue,
}: InputMultiToggleInterface) => {
    function checkItemAttributeActive(param: any): boolean {
        const filterItem = value.find((item) => item[indexValue] === param);
        return filterItem !== undefined;
    }

    function addItemAttribute(param: any) {
        const newItem = {
            [indexValue]: param,
        };

        return onChange([...value, newItem]);
    }

    function removeItemAttribute(param: any) {
        const newItem = value.filter((item) => item[indexValue] !== param);
        return onChange(newItem);
    }

    function onChangeAttribute(param: any) {
        if (checkItemAttributeActive(param)) {
            removeItemAttribute(param);
        } else {
            addItemAttribute(param);
        }
    }
    return (
        <InputMultiToggleWrap className="input-multi-toggle-panel-molecule">
            <InputCapsule label={label} htmlFor={id}>
                {Array.isArray(items) &&
                    items.map((item) => (
                        <Toggle
                            id={id}
                            key={item[indexValue]}
                            labelTrue={item[indexLabel]}
                            labelFalse={item[indexLabel]}
                            value={checkItemAttributeActive(item[indexValue])}
                            onChange={() => onChangeAttribute(item[indexValue])}
                        />
                    ))}
            </InputCapsule>
        </InputMultiToggleWrap>
    );
};

export default InputMultiToggle;
