import React from 'react';
import Dropzone from 'react-dropzone';

import { UploadDropBoxWrap, DropContainer, UploadMessage } from './styles';

import type { UploadDropBoxInterface } from './interface';

const UploadDropBox = ({
    children,
    onDropAccepted,
    variation,
}: UploadDropBoxInterface) => {
    function renderDragMessage(isDragActive: boolean, isDragReject: boolean) {
        if (!isDragActive) {
            return (
                <UploadMessage type="default" className="number">
                    {children}
                </UploadMessage>
            );
        }

        if (isDragReject) {
            return (
                <UploadMessage type="error">
                    Arquivo não suportado!
                </UploadMessage>
            );
        }

        return (
            <UploadMessage type="success">
                Solte os arquivos aqui!
            </UploadMessage>
        );
    }

    return (
        <UploadDropBoxWrap className="upload-drop-box-atom">
            <Dropzone accept="image/*" onDropAccepted={onDropAccepted}>
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                }) => (
                    <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                        variation={variation}
                    >
                        <input {...getInputProps()} />

                        {renderDragMessage(isDragActive, isDragReject)}
                    </DropContainer>
                )}
            </Dropzone>
        </UploadDropBoxWrap>
    );
};

export default UploadDropBox;
