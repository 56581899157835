import { ROUTES_OWNER } from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Container, TitlePage, Row } from 'components/atoms';
import { SidebarList } from 'components/molecules';
import { HeaderPages } from 'components/organisms';

import { OwnerLayoutWrap } from './styles';

import type { OwnerLayoutInterface } from './interface';

const RealStateLayout = ({
    titlePage,
    breadcrumbs,
    title,
    children,
    buttonTitle,
}: OwnerLayoutInterface) => {
    const { getLinkWithSlug } = useRealState();



    return (
        <OwnerLayoutWrap className="real-state-layout-template">
            <HeaderPages label={titlePage} breadcrumbs={breadcrumbs} />

            <Container>
                <Row>
                    <SidebarList
                        data={[
                            {
                                label: 'Minha Conta',
                                route: getLinkWithSlug(
                                    ROUTES_OWNER.ACCOUNT.fullPath
                                ),
                            },
                            {
                                label: 'Acertos',
                                route: getLinkWithSlug(
                                    ROUTES_OWNER.INVOICE_SETTLEMENT.fullPath
                                ),
                            },
                            {
                                label: 'Convites Imobiliárias',
                                route: getLinkWithSlug(
                                    ROUTES_OWNER.REAL_STATE_INVITE.fullPath
                                ),
                            },
                            {
                                label: 'Solicitações Imóveis',
                                route: getLinkWithSlug(
                                    ROUTES_OWNER.PROPERTY_INVITE.fullPath
                                ),
                            },
                        ]}
                    />

                    <div className="owner-layout-template-content">
                        <TitlePage button={buttonTitle}>{title}</TitlePage>
                        {children}
                        <div className="owner-layout-template-footer">
                            {buttonTitle}
                        </div>
                    </div>
                </Row>
            </Container>
        </OwnerLayoutWrap>
    );
};

export default RealStateLayout;
