import ReactLoading from 'react-loading';

import { ButtonWrap } from './styles';

import type { ButtonInterface } from './interface';

const Button = ({
    color = 'primary',
    children,
    href,
    loading,
    onClick = () => null,
    size = 'normal',
    style,
    className = '',
}: ButtonInterface) => {
    if (!href) {
        return (
            <ButtonWrap
                color={color}
                className={`button-atom ${className}`}
                size={size}
            >
                <button onClick={() => onClick()} type="button" style={style}>
                    {loading ? (
                        <ReactLoading
                            type="spinningBubbles"
                            height={size === 'normal' ? 18 : 10}
                            width={size === 'normal' ? 18 : 10}
                            className="loader"
                        />
                    ) : children}
                </button>
            </ButtonWrap>
        );
    }

    return (
        <ButtonWrap color={color} className={`button-atom ${className}`}
            size={size}>
            <a href={href} style={style} target="_blank" rel="noreferrer">
                {loading ? (
                    <ReactLoading
                        type="spinningBubbles"
                        height={size === 'normal' ? 25 : 10}
                        width={size === 'normal' ? 25 : 10}
                        className="loader"
                    />
                ) : children}
            </a>
        </ButtonWrap>
    );
};

export default Button;
