/* eslint-disable react/no-unstable-nested-components, @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react';
import { format, addMonths } from 'date-fns';
import { GiPayMoney } from 'react-icons/gi';
import { FiEye } from 'react-icons/fi';
import { BiPrinter } from 'react-icons/bi';
import { RiBarcodeBoxLine } from 'react-icons/ri';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import {
    Button,
    CheckBox,
    HelperBoolean,
    HelperDate,
    HelperMoney,
    HelperOptions,
    Row,
    Text,
} from 'components/atoms';
import { InputToggle, Modal, Select } from 'components/molecules';
import { InvoiceSettlementDetails, Table } from 'components/organisms';
import { onErrorMessage, onSuccessMessage } from 'helpers';

import {
    payRealStateInvoiceSettlement,
    getRealStateInvoiceSettlementGenerate,
    getRealStateInvoiceSettlementIndex,
    getRealStateOwnerIndex,
} from 'services';
import { sweetAlert } from 'utils';

import { InvoiceListWrap } from './styles';
import Layout from '../../Layout';

import type { IAInvoiceSettlement } from '@types';

const InvoiceSettlement = () => {
    const titleMessages = 'Acertos!';
    const { getLinkWithSlug } = useRealState();

    const [isOpenModalShowInvoices, setIsOpenModalShowInvoices] =
        useState(false);
    const [itemModalShowInvoices, setItemModalShowInvoices] =
        useState<IAInvoiceSettlement>();

    const [isOpenModalGenerateInvoices, setIsOpenModalGenerateInvoices] =
        useState(false);

    const [period, setPeriod] = useState('');
    const [enablePrintSelected, setEnabledPrintSelected] = useState(false);
    const [printSelectedItems, setPrintSelectedItems] = useState<number[]>([]);

    const [isSingleGenerate, setIsSingleGenerate] = useState(false);
    const [ownersIds, setOwnersIds] = useState('');

    const onOpenModalShowInvoices = (item: IAInvoiceSettlement) => {
        setIsOpenModalShowInvoices(true);
        setItemModalShowInvoices(item);
    };

    const onCloseModalShowInvoices = () => {
        setIsOpenModalShowInvoices(false);
        setItemModalShowInvoices(undefined);
    };

    const onOpenModalGenerateInvoices = () => {
        setIsOpenModalGenerateInvoices(true);
    };

    const onCloseModalGenerateInvoices = () => {
        setIsOpenModalGenerateInvoices(false);
    };

    const onHandlePrintSelected = () => {
        setEnabledPrintSelected((state) => !state);
    };

    const getActiveRow = (id: number) =>
        printSelectedItems.some((item) => item === id);

    const onHandleActivePrintSelectedItems = (id: number) => {
        if (getActiveRow(id)) {
            setPrintSelectedItems((state) =>
                state.filter((item) => item !== id)
            );
        } else {
            setPrintSelectedItems((state) => [...state, id]);
        }
    };

    const onRedirectPrint = () => {
        setEnabledPrintSelected(false);
        setPrintSelectedItems([]);

        window.open(
            `${
                ROUTES_REAL_STATE.INVOICE_SETTLEMENT.PRINT.fullPath
            }/${printSelectedItems.join(',')}`,
            '_blank'
        );
    };

    const queryClient = useQueryClient();

    const { data, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.INVOICE_SETTLEMENT.base,
        () => getRealStateInvoiceSettlementIndex(),
        {
            refetchOnMount: true,
            onSuccess() {
                if (itemModalShowInvoices) {
                    onOpenModalShowInvoices(itemModalShowInvoices);
                }
            },
        }
    );

    const { isLoading: isLoadingGenerate, mutate: generateInvoices } =
        useMutation(() => getRealStateInvoiceSettlementGenerate(period, isSingleGenerate ? ownersIds : undefined), {
            mutationKey: QUERIES_REAL_STATE.INVOICE_SETTLEMENT.generate,
            onSuccess: ({ message }) => {
                onSuccessMessage(titleMessages, message);
                onCloseModalGenerateInvoices();

                queryClient.invalidateQueries(
                    QUERIES_REAL_STATE.INVOICE_SETTLEMENT.base
                );
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    const { isLoading: isLoadingPay, mutate: payInvoices } = useMutation(
        (id: number | string) => payRealStateInvoiceSettlement(id),
        {
            mutationKey: QUERIES_REAL_STATE.INVOICE_SETTLEMENT.base,
            onSuccess: ({ message }) => {
                onSuccessMessage(titleMessages, message);

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.INVOICE_SETTLEMENT.base
                );
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const { data: dataOwners } = useQuery(QUERIES_REAL_STATE.OWNER.base, () =>
        getRealStateOwnerIndex(),
        {
            select(response) {
                if (Array.isArray(response)) {

                    return response?.map((item) => ({
                        label: item.nome,
                        value: item.id,
                    }))
                }

                return []
            },
        }
    );


    function payRegisterRequest(id: number | string) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro mudará seu status para pago e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, mudar registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    payInvoices(id);
                }
            });
    }

    const getSelectedAllItems = () =>
        printSelectedItems.length === data?.length || false;

    const onHandleSelectedAllItems = () => {
        if (getSelectedAllItems()) {
            setPrintSelectedItems([]);
        } else {
            setPrintSelectedItems(data?.map((item) => item.id) || []);
        }
    };

    const optionsPeriod = useMemo(() => {
        const currentDate = new Date();
        const nextMonth = addMonths(currentDate, 1);

        return [
            {
                label: format(currentDate, 'MM/yyyy'),
                value: format(currentDate, 'yyyy-MM'),
            },

            {
                label: format(nextMonth, 'MM/yyyy'),
                value: format(nextMonth, 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 2), 'MM/yyyy'),
                value: format(addMonths(currentDate, 2), 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 3), 'MM/yyyy'),
                value: format(addMonths(currentDate, 3), 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 4), 'MM/yyyy'),
                value: format(addMonths(currentDate, 4), 'yyyy-MM'),
            },
        ];
    }, []);

    const columns = useMemo(() => {
        const defaultColumns = [
            {
                dataField: 'id',
                text: 'ID',
                sort: true,
                headerStyle: { width: '10%' },
            },
            {
                dataField: 'credores',
                text: 'Credor',
                sort: true,
                formatter: (fieldValue: IAInvoiceSettlement['credores']) =>
                    fieldValue?.nome,
                headerStyle: { width: '35%' },
            },
            {
                dataField: 'data',
                text: 'Data do acerto',
                headerStyle: { width: '15%' },
                formatter: (fieldValue: IAInvoiceSettlement['data']) => (
                    <HelperDate value={fieldValue} />
                ),
            },
            {
                dataField: 'valor',
                text: 'Valor',
                formatter: (fieldValue: IAInvoiceSettlement['valor']) => (
                    <HelperMoney value={fieldValue} />
                ),
                headerStyle: { width: '20%' },
            },

            {
                dataField: 'status',
                text: 'Paga',
                formatter: (fieldValue: IAInvoiceSettlement['status']) => (
                    <HelperBoolean value={fieldValue === 'PAGA'} />
                ),
                headerStyle: { width: '8rem' },
            },

            {
                dataField: 'id',
                text: 'Opções',
                formatter: (
                    fieldValue: IAInvoiceSettlement['id'],
                    index: any,
                    row: IAInvoiceSettlement
                ) => (
                    <HelperOptions
                        editFunction={() => onOpenModalShowInvoices(row)}
                        editIcon={<FiEye />}
                        remove={false}
                    />
                ),
            },
        ];

        if (enablePrintSelected) {
            return [
                {
                    dataField: 'id',
                    text: '',
                    formatterText: (
                        <CheckBox
                            onClick={onHandleSelectedAllItems}
                            isActive={getSelectedAllItems()}
                        />
                    ),
                    headerStyle: { width: '1rem', height: '25px' },
                    formatter: (fieldValue: IAInvoiceSettlement['id']) => (
                        <CheckBox
                            key={fieldValue}
                            onClick={() =>
                                onHandleActivePrintSelectedItems(fieldValue)
                            }
                            isActive={getActiveRow(fieldValue)}
                        />
                    ),
                },
                ...defaultColumns,
            ];
        }

        return defaultColumns;
    }, [data, enablePrintSelected, printSelectedItems]);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Acertos',
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.INVOICE_SETTLEMENT.fullPath
                    ),
                },
            ]}
            title="Acertos"
            buttonTitle={
                <Row>
                    {enablePrintSelected &&
                        printSelectedItems &&
                        printSelectedItems.length > 0 && (
                            <Button
                                color="septernary"
                                onClick={() => onRedirectPrint()}
                            >
                                <BiPrinter />
                                Imprimir
                            </Button>
                        )}
                    <Button
                        onClick={onHandlePrintSelected}
                        color={enablePrintSelected ? 'danger' : 'septernary'}
                    >
                        <BiPrinter />
                        {enablePrintSelected ? 'Cancelar' : 'Selecionar'}
                    </Button>
                    <Button
                        onClick={onOpenModalGenerateInvoices}
                        color="octernary"
                        loading={isLoadingGenerate}
                    >
                        Gerar Acertos
                    </Button>
                </Row>
            }
        >
            <InvoiceListWrap className="invoices-template">
                <Table
                    isLoading={isLoadingList || isLoadingGenerate}
                    columns={columns}
                    data={data}
                />
            </InvoiceListWrap>

            <Modal
                open={isOpenModalShowInvoices}
                onCloseModal={() => onCloseModalShowInvoices()}
                title="Fatura"
                size="lg"
                footer={
                    <>
                        {itemModalShowInvoices?.urlBoleto && (
                            <Button
                                color="octernary"
                                onClick={() =>
                                    window.open(
                                        itemModalShowInvoices?.urlBoleto
                                    )
                                }
                            >
                                <RiBarcodeBoxLine />
                                Boleto
                            </Button>
                        )}
                        {itemModalShowInvoices?.status !== 'PAGA' && (
                            <Button
                                loading={isLoadingPay}
                                color="octernary"
                                onClick={() =>
                                    payRegisterRequest(
                                        `${itemModalShowInvoices?.id}`
                                    )
                                }
                            >
                                <GiPayMoney />
                                Pagar
                            </Button>
                        )}
                    </>
                }
            >
                <InvoiceSettlementDetails data={itemModalShowInvoices} />
            </Modal>

            <Modal
                open={isOpenModalGenerateInvoices}
                onCloseModal={() => onCloseModalGenerateInvoices()}
                title="Gerar Acerto"
                footer={
                    <Button
                        color="octernary"
                        onClick={generateInvoices}
                        loading={isLoadingGenerate}
                    >
                        Gerar
                    </Button>
                }
            >
                <Text style={{ padding: '1.875rem' }}>
                    O acerto do proprietário irá calcular o balanço de todas as
                    transações e verificar o saldo. Para isso necessitamos da
                    competência para acionar possíveis pendências referentes ao
                    proprietário.
                </Text>

                <Select
                    required
                    label="Competência do acerto"
                    placeholder="selecione uma competência"
                    value={period}
                    onChange={setPeriod}
                    name="competencia"
                    model="sinary"
                    options={optionsPeriod}
                />

                <InputToggle
                    labelTrue="Gerar acertos unitários"
                    labelFalse="Gerar todos os acertos da competência"
                    value={isSingleGenerate}
                    id="single-generate"
                    name="single-generate"
                    onChange={setIsSingleGenerate}
                />

                {isSingleGenerate && (
                    <Select
                        required
                        label="Proprietários"
                        placeholder="selecione um proprietário"
                        value={ownersIds}
                        onChange={setOwnersIds}
                        name="competencia"
                        model="sinary"
                        options={dataOwners}
                    />
                )}
            </Modal>
        </Layout>
    );
};

export default InvoiceSettlement;
