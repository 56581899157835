import styled from 'styled-components';

export const AccountAccessSelectWrap = styled.div`
    display: block;
    width: 100%;
    overflow: auto;

    .h3-atom {
        text-align: center;
        margin-top: 1.875rem;
    }

    #row-atom-account-type {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 1.875rem;
    }
`;
