import { generateURL } from './prefix'
import api from '../api';

import type { IAPerson, IARealState } from '@types';

export const getRealStateIntegrationRegister = async (): Promise<
    IARealState
> => {
    const url = generateURL(`/registro-integracao`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateIntegrationRegisterInit = async (
) => {
    const url = generateURL(`/registro-integracao/integrar/zoop`);

    const { data } = await api.get(url);

    return data;
};

export const updateRealStateIntegrationRegisterBusinessData = async (payload: Partial<IAPerson>): Promise<
    IARealState
> => {
    const url = generateURL(`/registro-integracao/pessoas`);

    const { data } = await api.put(url, payload);

    return data;
};

export const updateRealStateIntegrationRegisterOwnerData = async (payload: Partial<IAPerson>): Promise<
    IARealState
> => {
    const url = generateURL(`/registro-integracao/responsaveis`);

    const { data } = await api.put(url, payload);

    return data;
};