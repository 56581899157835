import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import {
    QUERIES_ADMIN,
    ROUTES_ACCOUNT,
    ROUTES_ADMIN,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, HelperOptions } from 'components/atoms';
import { Table } from 'components/organisms';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    getPublicPaymentMethodIndex, removeAdministratorMethodsPayments
} from 'services';
import { sweetAlert } from 'utils';

import { MethodsPaymentsListWrap } from './styles';
import Layout from '../../Layout';

const MethodsPaymentsList = () => {
    const titleMessages = 'Métodos de Pagamentos!';
    const theme = useTheme();
    const navigate = useNavigate();
    const { getLinkWithSlug } = useRealState();

    const queryClient = useQueryClient();

    const { data, isLoading: isLoadingList } = useQuery(
        QUERIES_ADMIN.METHODS_PAYMENTS.base,
        () => getPublicPaymentMethodIndex()
    );

    const { mutate: removeRegister } =
        useMutation((id: number) => removeAdministratorMethodsPayments(String(id)), {
            mutationKey: QUERIES_ADMIN.METHODS_PAYMENTS.base,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(QUERIES_ADMIN.METHODS_PAYMENTS.base);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function newRegister() {
        onHandleNavigate(getLinkWithSlug(ROUTES_ADMIN.METHODS_PAYMENTS.FORM.fullPath));
    }

    function editRegister(param: string) {
        onHandleNavigate(
            getLinkWithSlug(`${ROUTES_ADMIN.METHODS_PAYMENTS.FORM.fullPath}/${param}`)
        );
    }

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.primary,
                cancelButtonColor: theme.colors.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister(id);
                }
            });
    }

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Métodos de Pagamentos',
                    href: getLinkWithSlug(ROUTES_ADMIN.METHODS_PAYMENTS.fullPath),
                },
            ]}
            title="Métodos de Pagamentos"
            buttonTitle={
                <Button color="nonary" onClick={() => newRegister()}>
                    Novo Registro
                </Button>
            }
        >
            <MethodsPaymentsListWrap className="methods-payments-template">
                <Table
                    isLoading={isLoadingList}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'ID',
                            sort: true,
                            style: { fontWeight: 700 }
                        },
                        {
                            dataField: 'nome',
                            text: 'Nome',
                            sort: true,
                            headerStyle: { width: '100%' }
                        },
                        {
                            dataField: 'numero',
                            text: 'Número',
                            sort: true,
                            headerStyle: { width: '100%' }
                        },
                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    edit: true,
                                    editFunction: () =>
                                        editRegister(fieldValue),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                            headerStyle: { width: '10%' }
                        },
                    ]}
                    data={data}
                />
            </MethodsPaymentsListWrap>
        </Layout>
    );
};

export default MethodsPaymentsList;
