import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BreadcrumbWrap = styled(Link)`
    display: flex;
    align-items: center;

    font-size: 0.81rem;
    line-height: 120%;
    font-weight: 300;
    color: ${(props) => props.theme.colors.textSecondary};
    text-transform: uppercase;
    text-decoration: none;

    &:before {
        content: '/';
        display: block;
        margin: 1rem;
    }

    &.first {
        font-weight: 900;

        &:before {
            display: none;
        }
    }
`;
