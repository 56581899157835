import { useRoutes } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

import routes from './routes';

const Routes = () => {
    const { isSigned } = useAuth();

    const routesSlugged = routes(isSigned).map((route) => ({
        ...route,
        path: `/r/:slug${route.path}`,
    }));

    const routing = useRoutes([...routesSlugged, ...routes(isSigned)]);

    return routing;
};

export default Routes;
