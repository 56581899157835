/* eslint-disable  no-lonely-if, react/no-unstable-nested-components */
import { useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { Row, Line, Button, HelperButton } from 'components/atoms';

import { InputNumber, InputText, Modal } from 'components/molecules';

import { SelectModalPersonWrap } from './styles';
import Table from '../Table';

import type { SelectModalPersonInterface } from './interface';

const SelectModalPerson = ({
    value,
    onChange = () => null,
    options,
}: SelectModalPersonInterface) => {
    const [open, setOpen] = useState(false);
    const [yourRef] = useAutoAnimate<HTMLUListElement>();

    function onCloseModal() {
        setOpen(false);
    }

    function handleValue(param: number) {
        const remove =
            Array.isArray(value) &&
            value.findIndex(
                (item) => Number(item.proprietariosIds) === Number(param)
            ) > -1;

        if (remove) {
            onChange(
                value.filter(
                    (item) => Number(item.proprietariosIds) !== Number(param)
                )
            );
        } else {
            if (!Array.isArray(value)) {
                onChange([
                    {
                        proprietariosIds: Number(param),
                        porcentagem: 0,
                    },
                ]);
            } else {
                onChange([
                    ...value,
                    {
                        proprietariosIds: Number(param),
                        porcentagem: 0,
                    },
                ]);
            }
        }
    }

    function getOwner(param: number) {
        const owner =
            Array.isArray(options) &&
            options.find((item) => item.value === param);

        if (owner) {
            return owner;
        }

        return {
            label: '',
            value: null,
        };
    }

    function changePercent(param: number, newValue: number) {
        const newArr = value.map((item) =>
            item.proprietariosIds === param
                ? { ...item, porcentagem: newValue }
                : item
        );

        onChange(newArr);
    }

    function isActive(param: number) {
        if (!Array.isArray(value)) {
            return false;
        }

        return value.findIndex((item) => item.proprietariosIds === param) > -1;
    }

    return (
        <SelectModalPersonWrap className="select-modal-person-molecule">
            <Modal
                open={open}
                onCloseModal={() => onCloseModal()}
                title="Selecione os proprietários do imóvel"
                size="lg"
                footer={
                    <Button color="octernary" onClick={() => onCloseModal()}>
                        Concluir
                    </Button>
                }
            >
                <Table
                    columns={[
                        {
                            text: 'Nome',
                            dataField: 'label',
                            headerStyle: { width: '100%' },
                            style: { fontWeight: 700 },
                        },
                        {
                            dataField: 'value',
                            text: 'Opções',
                            formatter: (fieldValue) => (
                                <HelperButton
                                    color={
                                        isActive(Number(fieldValue))
                                            ? 'inactive'
                                            : 'active'
                                    }
                                    text={
                                        isActive(Number(fieldValue))
                                            ? 'Remover'
                                            : 'Selecionar'
                                    }
                                    onClick={() =>
                                        handleValue(Number(fieldValue))
                                    }
                                />
                            ),
                        },
                    ]}
                    data={options}
                />
            </Modal>

            <span className="title">Proprietários e Porcentagens</span>
            <ul ref={yourRef}>
                {Array.isArray(value) &&
                    value.map((item) => (
                        <li key={item?.proprietariosIds}>
                            <Row className="item-person">
                                <InputText
                                    model="sinary"
                                    label="Proprietário"
                                    value={
                                        getOwner(item.proprietariosIds).label
                                    }
                                    onChange={() => null}
                                    name="owner"
                                    placeholder="Selecione os proprietários"
                                />
                                <InputNumber
                                    model="sinary"
                                    label="Porcentagem do proprietário"
                                    min={0}
                                    max={100}
                                    name="percent_property"
                                    placeholder="Informe a porcentagem do imóvel"
                                    value={item.porcentagem}
                                    onChange={(newValue) =>
                                        changePercent(
                                            item.proprietariosIds,
                                            newValue
                                        )
                                    }
                                    postfix="%"
                                />
                            </Row>
                        </li>
                    ))}
            </ul>

            <button
                type="button"
                className="button-person"
                onClick={() => setOpen(true)}
            >
                Selecionar Proprietários
            </button>

            <Line type="secondary" />
        </SelectModalPersonWrap>
    );
};

export default SelectModalPerson;
