import { FaWhatsapp } from 'react-icons/fa';

import { maskCellphone } from 'utils';

import { WhatsAppIconWrap } from './styles';

import type { WhatsAppIconInterface } from './interface';

const WhatsAppIcon = ({ value, href, style }: WhatsAppIconInterface) => (
    <WhatsAppIconWrap
        className="whatsapp-icon-atom"
        style={style}
        href={href}
        target="_blank"
    >
        <i>
            <FaWhatsapp />
        </i>

        <div>
            <span className="label">Whatsapp</span>
            <span className="value number">
                ({value.substring(0, 2)}){' '}
                <strong>{maskCellphone(value).substring(5)}</strong>
            </span>
        </div>
    </WhatsAppIconWrap>
);


export default WhatsAppIcon;
