/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { AiOutlineSave } from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import { OptionBlock } from 'components/molecules';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    getRealStateSettingShow,
    removeRealStateFile,
    updateRealStateSetting,
    uploadRealStateFile
} from 'services';

import { Address, Colors, GeneralInfo, Maps } from './components';
import { SettingWrap } from './styles';
import Layout from '../Layout';

import type { IAPerson, IARealState } from '@types';

const Setting = () => {

    const queryClient = useQueryClient();
    const { getLinkWithSlug } = useRealState();

    const title = 'Configurações';


    const [step, setStep] = useState('GENERAL_INFO');
    const [realStateData, setRealStateData] = useState<Partial<IARealState>>();
    const [personData, setPersonData] = useState<Partial<IAPerson>>();

    const [optionsBlock] = useState([
        { label: 'Informações Gerais', value: 'GENERAL_INFO' },
        { label: 'Endereço', value: 'ADDRESS' },
        { label: 'Cores', value: 'COLORS' },
        { label: 'Mapa', value: 'MAPS' }
    ])


    const { isLoading: isLoadingGet, isFetching: isFetchingGet } = useQuery(
        [QUERIES_REAL_STATE.SETTING.base],
        () => getRealStateSettingShow(),
        {
            onSuccess: (res) => {
                setRealStateData(res);
                setPersonData({ ...res?.pessoas, bairro: res?.pessoas?.bairros?.nome || '' });
            },
            refetchOnMount: true,
        }
    );

    const {
        mutate: mutateUpdate,
        isLoading: isLoadingUpdate,
    } = useMutation(
        (payload: Partial<IARealState>) => updateRealStateSetting(step, payload),
        {
            mutationKey: QUERIES_REAL_STATE.SETTING.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.SETTING.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );


    async function onSubmit() {

        const dataSend = {
            ...realStateData,
            pessoas: personData
        }

        let shapeSchema = {}

        if (step === 'GENERAL_INFO') {
            shapeSchema = {
                pessoas: Yup.object().shape({
                    nome: Yup.string().required('O nome é obrigatório'),
                    creci: Yup.string().required('O creci é obrigatório'),
                    telefone: Yup.string().required('O telefone é obrigatório'),
                    whatsapp: Yup.string().required('O whatsapp é obrigatório'),
                    documento: Yup.string().required('O documento é obrigatório'),
                }),
                slug: Yup.string().required('O slug é obrigatório'),
            };
        }

        if (step === 'ADDRESS') {
            shapeSchema = {
                pessoas: Yup.object().shape({
                    bairrosIds: Yup.string()
                        .nullable()
                        .required('Campos de endereço são obrigatórios'),
                    numero: Yup.string().nullable().required('O número é obrigatório')
                })
            };
        }

        if (step === 'COLORS') {
            shapeSchema = {
                cores: Yup.string()
                    .nullable()
                    .required('Campos de cores são obrigatórios')
            };
        }

        if (step === 'MAPS') {
            shapeSchema = {
                latitude: Yup.string().required('Campo de latitude é obrigatório'),
                longitude: Yup.string().required('Campo de longitude é obrigatório')
            };
        }

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            mutateUpdate(dataSend);
        }
    }



    const {
        mutate: mutateRemoveImage,
        isLoading: isLoadingRemoveImage,
    } = useMutation(
        (imageIdParam: number) => removeRealStateFile(imageIdParam),
        {
            mutationKey: QUERIES_REAL_STATE.SETTING.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao remover imagem do registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.SETTING.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const {
        mutate: mutateUploadImage,
        isLoading: isLoadingUploadImage,
    } = useMutation(
        (payload: { file: File, field: 'logoHeader' | 'logoFooter' }) => uploadRealStateFile(payload.file, payload.field),
        {
            mutationKey: QUERIES_REAL_STATE.SETTING.base,
            onSuccess: (data, variables) => {

                if (realStateData?.[variables?.field]?.id) {
                    mutateRemoveImage(Number(realStateData?.[variables?.field]?.id));
                }

                if (variables?.field === 'logoHeader') {
                    setRealStateData({
                        ...realStateData,
                        'logoHeader': {
                            id: data.id,
                            url: data.url
                        }
                    });
                } else {
                    setRealStateData({
                        ...realStateData,
                        'logoFooter': {
                            id: data.id,
                            url: data.url
                        }
                    });
                }

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.SETTING.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onRemoveImage = (field: 'logoHeader' | 'logoFooter') => {
        const idImage = realStateData?.[field]?.id;

        if (idImage) {
            mutateRemoveImage(idImage);
        }
    }



    const tabsComponent: Record<string, ReactNode> = {
        GENERAL_INFO: (
            <GeneralInfo
                realStateData={realStateData}
                onChangeRealStateData={setRealStateData}
                personData={personData}
                onChangePersonData={setPersonData}
                onRemoveImage={(field) => onRemoveImage(field)}
                onUploadImage={(field, file) => mutateUploadImage({
                    file,
                    field
                })}
            />
        ),
        ADDRESS: (
            <Address
                values={personData}
                onChangeValues={setPersonData}
            />
        ),
        COLORS: (
            <Colors onChangeValues={(colors) => setRealStateData({ ...realStateData, cores: JSON.stringify(colors) })} values={realStateData?.cores ? JSON.parse(realStateData?.cores) : {}} />
        ),
        MAPS: (
            <Maps
                onChange={(newValue) =>
                    setRealStateData({ ...realStateData, ...newValue })
                }
                value={{ latitude: realStateData?.latitude, longitude: realStateData?.longitude }}
            />
        )
    };

    return (
        <SettingWrap>
            <Layout
                titlePage="Painel Imobiliário"
                breadcrumbs={[
                    {
                        label: 'Home',
                        href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                    },
                    {
                        label: title,
                        href: getLinkWithSlug(
                            ROUTES_REAL_STATE.PROPERTY.fullPath
                        ),
                    },
                ]}
                title={title}
                buttonTitle={
                    <Button
                        color="septernary"
                        onClick={() => onSubmit()}
                        loading={isLoadingGet || isLoadingUpdate}
                    >
                        <AiOutlineSave />
                        Salvar
                    </Button>
                }
            >
                <SettingWrap className="real-state-panel-setting">
                    <Shadow>
                        <Loading active={isLoadingGet || isLoadingUploadImage || isLoadingRemoveImage || isFetchingGet} />

                        <OptionBlock
                            active={step}
                            onHandleActive={(param) => setStep(param)}
                            data={optionsBlock}
                            style={{ margin: '0 auto 0 0' }}
                        />

                        {tabsComponent[step]}
                    </Shadow>
                </SettingWrap>
            </Layout>
        </SettingWrap>
    );
};

export default Setting;
