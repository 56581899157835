const CUSTOMER_BASE = `/customer/`;
const CUSTOMER_ACCOUNT = `account`;

const CUSTOMER_RENT_CONTRACT = `rent-contract`;
const CUSTOMER_RENT_CONTRACT_NEW = `new`;

export const ROUTES_CUSTOMER = {
    path: CUSTOMER_BASE,
    fullPath: CUSTOMER_BASE,
    ACCOUNT: {
        path: CUSTOMER_ACCOUNT,
        fullPath: `${CUSTOMER_BASE}${CUSTOMER_ACCOUNT}`,
    },
    RENT_CONTRACT: {
        path: CUSTOMER_RENT_CONTRACT,
        fullPath: `${CUSTOMER_BASE}${CUSTOMER_RENT_CONTRACT}`,
        NEW: {
            path: `${CUSTOMER_RENT_CONTRACT}/${CUSTOMER_RENT_CONTRACT_NEW}`,
            fullPath: `${CUSTOMER_BASE}${CUSTOMER_RENT_CONTRACT}/${CUSTOMER_RENT_CONTRACT_NEW}`,
        },
    }
};
