import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { ROUTES_AUTH } from '@constants'

import { useAuth } from 'contexts/AuthContext';
import {
    ButtonForm,
    Container,
    H1,
    Row,
    Shadow,
    Text,
    TextForm,
} from 'components/atoms';
import { InputText, InputRadio } from 'components/molecules';

import { userAccessEnums } from 'enums';
import { SignUpWrap } from './styles';
import Layout from '../Layout';

const SignUp = () => {
    const { loading, signUp, onHandleNavigateWithNext } = useAuth();

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [tipo, setTipo] = useState<string>('');


    function onHandleRegister() {
        signUp({
            nome,
            email,
            senha,
            confirmarSenha,
            tipo,
        });
    }
    return (
        <Layout>
            <SignUpWrap className="sign-up-template">
                <Container>
                    <H1>
                        <>
                            <strong>Entre</strong> ou <strong>Cadastre-se</strong>
                        </>
                    </H1>
                    <main>
                        <Shadow>
                            <Row>
                                <ButtonForm
                                    color="secondary"
                                    onClick={() => onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath)}
                                >
                                    Entrar
                                </ButtonForm>

                                <ButtonForm
                                    color="secondary"
                                    active
                                    onClick={() => null}
                                >
                                    Cadastrar-se
                                </ButtonForm>
                            </Row>

                            <TextForm model="primary" borderTop>
                                Preencha os campos abaixo para realizar seu
                                cadastro em nosso portal:
                            </TextForm>

                            <InputText
                                required
                                label="Nome"
                                id="nome"
                                name="nome"
                                placeholder="Digite seu nome..."
                                value={nome}
                                onChange={setNome}
                                borderTop
                            />
                            <InputText
                                required
                                label="E-mail"
                                id="email"
                                name="email"
                                placeholder="Digite seu e-mail..."
                                value={email}
                                onChange={setEmail}
                                borderTop
                            />

                            <InputText
                                required
                                label="Senha"
                                id="senha"
                                name="senha"
                                placeholder="Digite sua senha..."
                                value={senha}
                                onChange={setSenha}
                                borderTop
                                password
                            />

                            <InputText
                                required
                                label="Confirmar sua Senha"
                                id="confirmar_senha"
                                name="confirmar_senha"
                                placeholder="Confirme sua senha..."
                                value={confirmarSenha}
                                onChange={setConfirmarSenha}
                                borderTop
                                borderBottom
                                password
                            />

                            <InputRadio
                                required
                                label={<strong>O que você é?</strong>}
                                id="tipo"
                                name="tipo"
                                options={userAccessEnums.typeUserDataSelect}
                                value={tipo}
                                onChange={setTipo}
                                row
                                model="secondary"
                            />
                        </Shadow>

                        {tipo !== null && (
                            <ButtonForm
                                id="on-sign"
                                onClick={() => onHandleRegister()}
                                color="tertiary"
                                icon={
                                    tipo === 'IMOBILIARIA' ? (
                                        <AiOutlineArrowRight />
                                    ) : null
                                }
                                type="button"
                                loading={loading}
                            >
                                {tipo === 'IMOBILIARIA'
                                    ? 'IR PARA SELEÇÃO DE PLANO'
                                    : 'Cadastrar'}
                            </ButtonForm>
                        )}

                        {tipo !== null && tipo !== 'IMOBILIARIA' && (
                            <Text uppercase>
                                Ao clicar em <strong>“Cadastrar”</strong>, você
                                concorda com nossos{' '}
                                <strong>Termos de Uso</strong> e nossa{' '}
                                <strong>Política de Privacidade.</strong>
                            </Text>
                        )}
                    </main>
                </Container>
            </SignUpWrap>
        </Layout>
    );
};

export default SignUp;
