import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';

export const MySearchWrap = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.875rem;
    font-family: 'Work Sans', sans-serif !important;

    .input-icon {
        position: relative;
        margin-right: 0.938rem;
        flex: 1;

        .icon {
            height: 3rem;
            width: 3rem;
            font-size: 1rem;
            left: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            width: 100%;

            color: ${(props) => props.theme.colors.textPrimary};

            height: 3rem;
            padding: 0.5rem 0.813rem 0.5rem 3rem;
            font-size: 1.2rem;
            line-height: 1.5;
            font-weight: 400;

            transition: color 0.15s ease, background-color 0.15s ease,
                border-color 0.15s ease, box-shadow 0.15s ease,
                -webkit-box-shadow 0.15s ease;

            color: ${(props) => props.theme.colors.textSecondary};
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderSecondary};
            background-color: ${(props) => props.theme.colors.senary};

            &:hover {
                border: 0.062rem solid ${(props) => props.theme.colors.quinary};
                background-color: ${(props) => props.theme.colors.quinary};
            }

            &:focus,
            &.is-valid,
            &.is-invalid {
                background-color: ${(props) => props.theme.colors.background};
                border-color: ${(props) =>
                    props.theme.colors.background} !important;
                color: ${(props) => props.theme.colors.textPrimary};
                box-shadow: none !important;
                outline: 0;
            }
        }
    }

    button {
        height: 3rem;
        padding: 8.45px 0.813rem;
        font-size: 0.813rem;
    }
`;

export const IconSearch = styled(MdSearch)`
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.tertiary};
`;
