import styled from 'styled-components'

export const AccountWrap = styled.div`
  display: block;
  width: 100%;
  overflow: auto;

  .property-horizontal-molecule,
  .property-horizontal-options-molecule {
    margin-bottom: 1rem;
  }

  .row-title {
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    border-bottom: 0.063rem solid ${props => props.theme.colors.borderSecondary};

    &:first-child {
      margin-top: 0;
    }

    span.number-items {
      font-size: 0.813rem;
      font-weight: 300;
      color: ${props => props.theme.colors.textSecondary};
      text-transform: uppercase;

      strong {
        font-weight: 700;
      }
    }
  }
`
