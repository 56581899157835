import { AiOutlineCheck } from 'react-icons/ai'

import { OptionCircleInterface } from './interface'

import { OptionCircleWrap } from './styles'

const OptionCircle = ({
  label,
  subLabel,
  checked,
  onChecked = () => null
}: OptionCircleInterface) => {
  const onClick = () => {
    onChecked(!checked)
  }

  return (
    <OptionCircleWrap
      className="option-circle-atom"
      checked={checked}
      onClick={onClick}
    >
      <i className="circle">
        <AiOutlineCheck />
      </i>

      <div className="option-text">
        <span className="label">{label}</span>
        <span className="sub-label">{subLabel}</span>
      </div>
    </OptionCircleWrap>
  )
}

export default OptionCircle
