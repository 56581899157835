import { CardImageInterface } from './interface'

import { CardImageWrap } from './styles'

const CardImage = ({ code, type, image }: CardImageInterface) => (
  <CardImageWrap
    className="card-image-atom"
    style={image ? { backgroundImage: `url("${image}")` } : {}}
  >
    <span className="code number">{code}</span>
    <span className="type">{type}</span>
  </CardImageWrap>
)

export default CardImage
