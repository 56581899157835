const REAL_STATE_BASE = `/real-state/`;
const REAL_STATE_ACCOUNT = `account`;

const REAL_STATE_OWNER = `owner`;
const REAL_STATE_OWNER_FORM = `form`;
const REAL_STATE_OWNER_NEW_FORM = `new-form`;
const REAL_STATE_OWNER_SIMPLIFIED_FORM = `simplified-form`;

const REAL_STATE_RENT_CONTRACT = 'rent-contract';
const REAL_STATE_RENT_CONTRACT_FORM = 'form';

const REAL_STATE_PROPERTY_EXPENSE = 'property-expense';
const REAL_STATE_PROPERTY_EXPENSE_FORM = 'form';

const REAL_STATE_INVOICE_PROPERTY = `invoice-property`;
const REAL_STATE_INVOICE_SETTLEMENT = `invoice-settlement`;
const REAL_STATE_INVOICE_SETTLEMENT_PRINT = `print`;

const REAL_STATE_PROPERTY = `property-management`;
const REAL_STATE_PROPERTY_FORM = `form`;
const REAL_STATE_PROPERTY_NEW_FORM = `new-form`;

const REAL_STATE_BANNER = `banner`;
const REAL_STATE_BANNER_FORM = `form`;

const REAL_STATE_CONTACT = `contact`;

const REAL_STATE_INSURANCE_COMPANY = `insurance-company`;
const REAL_STATE_INSURANCE_COMPANY_FORM = `form`;

const REAL_STATE_SETTING = `setting`;

const REAL_STATE_INTEGRATION_REGISTER = `integration-register`;

const REAL_STATE_FINANCIAL = `financial`;
const REAL_STATE_FINANCIAL_FORM = `form`;

export const ROUTES_REAL_STATE = {
    path: REAL_STATE_BASE,
    fullPath: REAL_STATE_BASE,
    ACCOUNT: {
        path: REAL_STATE_ACCOUNT,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_ACCOUNT}`,
    },
    INVOICE_SETTLEMENT: {
        path: REAL_STATE_INVOICE_SETTLEMENT,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_INVOICE_SETTLEMENT}`,
        PRINT: {
            path: `${REAL_STATE_INVOICE_SETTLEMENT}/${REAL_STATE_INVOICE_SETTLEMENT_PRINT}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_INVOICE_SETTLEMENT}/${REAL_STATE_INVOICE_SETTLEMENT_PRINT}`,
        }
    },
    OWNER: {
        path: REAL_STATE_OWNER,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_OWNER}`,
        FORM: {
            path: `${REAL_STATE_OWNER}/${REAL_STATE_OWNER_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_OWNER}/${REAL_STATE_OWNER_FORM}`,
        },
        NEW_FORM: {
            path: `${REAL_STATE_OWNER}/${REAL_STATE_OWNER_NEW_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_OWNER}/${REAL_STATE_OWNER_NEW_FORM}`,
        },
        SIMPLIFIED_FORM: {
            path: `${REAL_STATE_OWNER}/${REAL_STATE_OWNER_SIMPLIFIED_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_OWNER}/${REAL_STATE_OWNER_SIMPLIFIED_FORM}`,
        },
    },
    PROPERTY_EXPENSE: {
        path: REAL_STATE_PROPERTY_EXPENSE,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_PROPERTY_EXPENSE}`,
        FORM: {
            path: `${REAL_STATE_PROPERTY_EXPENSE}/${REAL_STATE_PROPERTY_EXPENSE_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_PROPERTY_EXPENSE}/${REAL_STATE_PROPERTY_EXPENSE_FORM}`,
        },
    },
    INVOICE_PROPERTY: {
        path: REAL_STATE_INVOICE_PROPERTY,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_INVOICE_PROPERTY}`,
    },
    RENT_CONTRACT: {
        path: REAL_STATE_RENT_CONTRACT,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_RENT_CONTRACT}`,
        FORM: {
            path: `${REAL_STATE_RENT_CONTRACT}/${REAL_STATE_RENT_CONTRACT_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_RENT_CONTRACT}/${REAL_STATE_RENT_CONTRACT_FORM}`,
        },
    },
    PROPERTY: {
        path: REAL_STATE_PROPERTY,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_PROPERTY}`,
        FORM: {
            path: `${REAL_STATE_PROPERTY}/${REAL_STATE_PROPERTY_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_PROPERTY}/${REAL_STATE_PROPERTY_FORM}`,
        },
        NEW_FORM: {
            path: `${REAL_STATE_PROPERTY}/${REAL_STATE_PROPERTY_NEW_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_PROPERTY}/${REAL_STATE_PROPERTY_NEW_FORM}`,
        },
    },
    BANNER: {
        path: REAL_STATE_BANNER,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_BANNER}`,
        FORM: {
            path: `${REAL_STATE_BANNER}/${REAL_STATE_BANNER_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_BANNER}/${REAL_STATE_BANNER_FORM}`,
        },
    },
    CONTACT: {
        path: REAL_STATE_CONTACT,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_CONTACT}`,
    },
    INSURANCE_COMPANY: {
        path: REAL_STATE_INSURANCE_COMPANY,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_INSURANCE_COMPANY}`,
        FORM: {
            path: `${REAL_STATE_INSURANCE_COMPANY}/${REAL_STATE_INSURANCE_COMPANY_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_INSURANCE_COMPANY}/${REAL_STATE_INSURANCE_COMPANY_FORM}`,
        },
    },
    SETTING: {
        path: REAL_STATE_SETTING,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_SETTING}`,
    },
    INTEGRATION_REGISTER: {
        path: REAL_STATE_INTEGRATION_REGISTER,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_INTEGRATION_REGISTER}`,
    },
    FINANCIAL: {
        path: REAL_STATE_FINANCIAL,
        fullPath: `${REAL_STATE_BASE}${REAL_STATE_FINANCIAL}`,
        FORM: {
            path: `${REAL_STATE_FINANCIAL}/${REAL_STATE_FINANCIAL_FORM}`,
            fullPath: `${REAL_STATE_BASE}${REAL_STATE_FINANCIAL}/${REAL_STATE_FINANCIAL_FORM}`,
        },
    },
};
