import styled from 'styled-components';

export const FooterWrap = styled.footer`
    section.content {
        margin-top: 4.06rem;
        border-top: 0.312rem solid ${(props) => props.theme.colors.secondary};
        padding: 3.44rem 0;
        background-color: ${(props) => props.theme.colors.quaternary};
    }

    section.copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6.25rem;
        width: 100%;
        border-top: 0.062rem solid
            ${(props) => props.theme.colors.borderQuaterback};
        background-color: ${(props) => props.theme.colors.quaternary};

        span {
            font-size: 0.812rem;
            font-weight: 700;
            color: ${(props) => props.theme.colors.textSenary};
            text-transform: uppercase;
        }
    }

    .social-icon-atom {
        margin: 0 0 0 0.875rem;
    }

    @media (max-width: 992px) {
        img {
            display: block;
            height: 3rem;
            margin: 1rem 0;
            text-align: center;
        }

        .row-list-footer {
            flex-direction: column;

            & > div {
                margin-bottom: 1.88rem;
            }
        }
    }
`;
