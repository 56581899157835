import { NavBarItem } from 'components/atoms'
import { NavBarWrap } from './styles'

import type { NavBarInterface } from './interface'

const NavBar = ({ data }: NavBarInterface) => (
  <NavBarWrap className="nav-bar-molecule">
    {data !== null &&
      data.map((item) => <NavBarItem key={item.href} {...item} />)}
  </NavBarWrap>
)

export default NavBar
