import React from 'react'

import { H2Interface } from './interface'

import { H2Wrap } from './styles'

const H2 = ({ children, className }: H2Interface) => (
  <H2Wrap className={className ? `h2-atom ${className}` : 'h2-atom'}>
    {children}
  </H2Wrap>
)

export default H2
