import styled, { css } from 'styled-components';

interface HelperButtonWrapInterface {
    color: 'active' | 'inactive' | 'neuter';
}

const buttons = {
    active: css`
        border: 0.062rem solid ${(props) => props.theme.colors.secondary};
        background-color: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.tertiary};

        &:hover {
            color: ${(props) => props.theme.colors.secondary};
            border: 0.062rem solid ${(props) => props.theme.colors.tertiary};
            background-color: ${(props) => props.theme.colors.tertiary};
        }
    `,

    inactive: css`
        color: ${(props) => props.theme.colors.danger};
        border: 0.062rem solid ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.senary};

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.danger};
            background-color: ${(props) => props.theme.colors.danger};
            color: ${(props) => props.theme.colors.textSenary};
        }
    `,

    neuter: css`
        color: ${(props) => props.theme.colors.textPrimary};
        border: 0.062rem solid ${(props) => props.theme.colors.borderPrimary};
        background-color: ${(props) => props.theme.colors.senary};

        cursor: default;
    `,
};

export const HelperButtonWrap = styled.div<HelperButtonWrapInterface>`
    display: flex;
    flex-direction: row;
    align-items: center;

    button,
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.15rem;
        padding: 0 0.625rem;

        font-size: 0.81rem;
        font-weight: 900;
        text-transform: uppercase;
        text-decoration: none;

        cursor: pointer;

        ${(props) => buttons[props.color]}
    }
`;
