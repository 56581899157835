import { Helmet } from 'react-helmet';

import { AdministratorPropertiesTypesList } from 'components/templates';

const AdministratorPropertiesTypesListPage = () => (
    <>
        <Helmet>
            <title>Tipos dos Imóveis - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPropertiesTypesList />
    </>
);

export default AdministratorPropertiesTypesListPage;
