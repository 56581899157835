import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillSave } from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Row, Shadow } from 'components/atoms';
import {
    InputImage,
    InputNumber,
    InputText,
    OptionBlock,
} from 'components/molecules';


import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    createRealStateBanner,
    getRealStateBannerShow,
    updateRealStateBanner,
    uploadRealStateFile,
    removeRealStateFile
} from 'services';

import { BannerFormWrap, AlignImage } from './styles';
import Layout from '../../Layout';

import type { IARealStateBanner } from '@types';

const BannerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [titulo, setTitulo] = useState('')
    const [subTitulo, setSubTitulo] = useState('')
    const [textoBotao, setTextoBotao] = useState('')
    const [hrefBotao, setHrefBotao] = useState('')
    const [ordem, setOrdem] = useState(0)
    const [imagem, setImagem] = useState('')
    const [idImagem, setIdImagem] = useState<number | null>(null)

    const [status, setStatus] = useState(false)
    const title = 'Banners';

    const { isLoading: isLoadingGet, isFetching: isFetchingGet } = useQuery(
        [QUERIES_REAL_STATE.BANNER.base, id],
        () => getRealStateBannerShow(String(id)),
        {
            refetchOnMount: true,
            enabled: !!id,
            onSuccess: (data) => {
                setTitulo(data?.titulo ? data?.titulo : '')
                setSubTitulo(data?.subTitulo ? data?.subTitulo : '')
                setTextoBotao(data?.textoBotao ? data?.textoBotao : '')
                setHrefBotao(data?.hrefBotao ? data?.hrefBotao : '')
                setOrdem(data.ordem ? data.ordem : 0)
                setImagem(data.imagens?.url || '')
                setIdImagem(data?.imagens?.id ? Number(data?.imagens?.id) : null)

                setStatus(data.status ? data.status : false)
            }
        }
    );
    const {
        mutate: mutateCreate,
        isLoading: isLoadingCreate,
    } = useMutation(
        (payload: IARealStateBanner) => createRealStateBanner(payload),
        {
            mutationKey: QUERIES_REAL_STATE.BANNER.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.BANNER.base
                );

                navigate(
                    getLinkWithSlug(ROUTES_REAL_STATE.BANNER.fullPath)
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const {
        mutate: mutateUpdate,
        isLoading: isLoadingUpdate,
    } = useMutation(
        (payload: IARealStateBanner) => updateRealStateBanner(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.BANNER.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.BANNER.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async (publish: boolean | null = null) => {

        const dataSend = {
            id,
            titulo,
            subTitulo,
            textoBotao,
            hrefBotao,
            ordem,
            status: publish === null ? status : publish
        }

        const shapeSchema = {
            titulo: Yup.string().nullable().required('O título é obrigatório.')
        }

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };

    const {
        mutate: mutateRemoveImage,
        isLoading: isLoadingRemoveImage,
    } = useMutation(
        (imageIdParam: number) => removeRealStateFile(imageIdParam),
        {
            mutationKey: QUERIES_REAL_STATE.BANNER.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao remover imagem do registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.BANNER.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const {
        mutate: mutateUploadImage,
        isLoading: isLoadingUploadImage,
    } = useMutation(
        (payload: { file: File, field: string }) => uploadRealStateFile(payload.file, payload.field),
        {
            mutationKey: QUERIES_REAL_STATE.BANNER.base,
            onSuccess: (data) => {

                if (idImagem) {
                    mutateRemoveImage(idImagem)
                }

                setImagem(data.url)
                setIdImagem(data.id)

                mutateUpdate({
                    titulo,
                    subTitulo,
                    textoBotao,
                    hrefBotao,
                    ordem,
                    status,
                    imobiliariasArquivosIds: data.id,
                })

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.BANNER.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );


    function handlePublish() {
        onSubmit(!status)
    }

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.BANNER.fullPath
                    ),
                },
                {
                    label: `Formulário ${title}`,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.BANNER.FORM.fullPath
                    ),
                },
            ]}
            title={`Formulário ${title}`}
            buttonTitle={
                <>
                    <Button
                        color="denary"
                        onClick={() => onSubmit()}
                        loading={
                            isLoadingGet || isLoadingCreate || isLoadingUpdate
                        }
                    >
                        <AiFillSave />
                        Salvar
                    </Button>

                    <Button
                        color={status ? 'danger' : 'septernary'}
                        onClick={() => handlePublish()}
                        loading={
                            isLoadingGet || isLoadingCreate || isLoadingUpdate
                        }
                    >
                        {status ? <AiFillCloseCircle /> : <AiFillCheckCircle />}
                        {status ? 'Despublicar' : 'Publicar'}
                    </Button>
                </>
            }
        >
            <BannerFormWrap className="real-state-panel-banner-form">
                <Shadow>
                    <Loading active={isLoadingGet || isFetchingGet || isLoadingRemoveImage || isLoadingUploadImage} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    {id && (
                        <AlignImage>
                            <InputImage
                                id="image_banner"
                                label="Imagem"
                                value={imagem}
                                onChange={file => mutateUploadImage({ file, field: 'banners' })}
                                loading={isLoadingUploadImage || isLoadingRemoveImage}
                                onDelete={idImagem === null ? undefined : () => mutateRemoveImage(idImagem)}
                                suggestion="Tamanho sugerido: 1920x415"
                            />
                        </AlignImage>
                    )}

                    <InputText
                        required
                        label="Título"
                        name="titulo"
                        placeholder="Informe o título"
                        value={titulo}
                        onChange={setTitulo}
                        model="sinary"
                    />

                    <InputText
                        required={false}
                        label="Sub Título"
                        name="subTitulo"
                        placeholder="Informe o sub título"
                        value={subTitulo}
                        onChange={setSubTitulo}
                        model="sinary"

                    />

                    <Row>
                        <InputText
                            required={false}
                            label="Texto Botão"
                            name="textoBotao"
                            placeholder="Preencha o texto do botão"
                            value={textoBotao}
                            onChange={setTextoBotao}
                            model="sinary"

                        />

                        <InputText
                            required={false}
                            label="Link Botão"
                            name="hrefBotao"
                            placeholder="Informe o link do botão"
                            value={hrefBotao}
                            onChange={setHrefBotao}
                            model="sinary"

                        />
                    </Row>

                    <InputNumber
                        required
                        label="Ordem"
                        name="ordem"
                        placeholder="Informe a ordem"
                        value={ordem}
                        onChange={setOrdem}
                        precision={0}
                        model="sinary"
                    />

                </Shadow>
            </BannerFormWrap>
        </Layout>
    );
};

export default BannerForm;
