import styled, { css } from 'styled-components';

interface OptionBlockItemWrapInterface {
    active: boolean;
    transparent: boolean;
}

const activeStyle = {
    active: css<OptionBlockItemWrapInterface>`
        background-color: ${(props) =>
            props.transparent ? 'transparent' : props.theme.colors.quaternary};
        color: ${(props) => props.theme.colors.textSenary};
        border-bottom: 0.062rem solid ${(props) => props.theme.colors.primary};

        &:hover {
            color: ${(props) => props.theme.colors.textSenary};
        }
    `,
    inactive: css<OptionBlockItemWrapInterface>`
        background-color: ${(props) =>
            props.transparent ? 'transparent' : props.theme.colors.senary};
        color: ${(props) => props.theme.colors.tertiaryBold};
        border-bottom: 0.062rem solid ${(props) => props.theme.colors.senary};
        font-weight: 300;

        &:hover {
            color: ${(props) => props.theme.colors.textSecondary};
        }
    `,
};

export const OptionBlockItemWrap = styled.li<OptionBlockItemWrapInterface>`
    flex: 1;
    width: 100%;
    font-family: 'Work Sans', sans-serif !important;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        width: 100%;
        height: 5rem;
        padding: 0 3rem;

        font-size: 0.81rem;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        ${(props) => activeStyle[props.active ? 'active' : 'inactive']};

        strong {
            font-weight: 800;
            margin-right: 0.313rem;
        }
    }

    @media (max-width: 992px) {
        button {
            display: block;
            width: 100%;
        }
    }
`;
