/* eslint-disable react/no-unstable-nested-components */
import { AiFillSave } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { format } from 'date-fns';

import { QUERIES_REAL_STATE } from '@constants';

import {
    Button,
    Grid,
    HelperDate,
    HelperOptions,
    HelperMoney,
    HelperPercent,
} from 'components/atoms';
import { InputNumber, InputMask, Modal } from 'components/molecules';
import { Table } from 'components/organisms';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { useField } from 'hooks';
import {
    getRealStateRentContractValueIndex,
    createRealStateRentContractValueIndex,
    updateRealStateRentContractValueIndex,
    removeRealStateRentContractValueIndex,
} from 'services';
import { sweetAlert, convertDateENToBR } from 'utils';

import { ValuesRentContractWrap } from './styles';

import type { IARentContractValue } from '@types';


import type {
    ValuesRentContractInterface,
} from './interface';

const ValuesRentContract = ({
    rentContractId,
    onChange = () => null,
    values,
    isOpenModalForm,
    onOpenModalForm = () => null,
}: ValuesRentContractInterface) => {
    const queryClient = useQueryClient();

    const titleMessages = 'Valor de Contratos de Aluguel!';

    const { data: contractRentValueList, isLoading } = useQuery(
        [QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.list, String(rentContractId)],
        () => getRealStateRentContractValueIndex(String(rentContractId)),
        {
            enabled: !!rentContractId,
        }
    );

    const { mutate: mutateCreate, isLoading: isLoadingCreate } =
        useMutation(
            (payload: Partial<IARentContractValue>) =>
                createRealStateRentContractValueIndex(
                    String(rentContractId),
                    payload
                ),
            {
                mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.create,
                onSuccess: () => {
                    onSuccessMessage(
                        titleMessages,
                        'Sucesso ao criar o registro!'
                    );

                    queryClient.refetchQueries(
                        [QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.list, String(rentContractId)]
                    );

                    onOpenModalForm(false);
                },
                onError: (err) => {
                    onErrorMessage(titleMessages, err);
                },
            }
        );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
        useMutation(
            (payload: Partial<IARentContractValue>) =>
                updateRealStateRentContractValueIndex(
                    String(rentContractId),
                    values?.id,
                    payload
                ),
            {
                mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.update,
                onSuccess: () => {
                    onSuccessMessage(
                        titleMessages,
                        'Sucesso ao atualizar o registro!'
                    );

                    queryClient.refetchQueries(
                        [QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.list, String(rentContractId)]
                    );

                    onOpenModalForm(false);
                },
                onError: (err) => {
                    onErrorMessage(titleMessages, err);
                },
            }
        );

    const onSubmit = async () => {
        const data = {
            ...values,
            dataInicial: values?.dataInicial
                ? format(new Date(values?.dataInicial), 'yyyy-MM-dd')
                : undefined,
            dataFinal: values?.dataFinal
                ? format(new Date(values?.dataFinal), 'yyyy-MM-dd')
                : undefined,
            contratosAlugueisIds: rentContractId,
        };

        const shapeSchema = {
            dataInicial: Yup.string().required('Data inicial é obrigatória'),
            valor: Yup.number().required('Valor é obrigatório'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (values?.id) {
                mutateUpdate(data);
            } else {
                mutateCreate(data);
            }
        }
    };

    const openRegister = (idRentContractValue: number) => {
        onOpenModalForm(true);

        const item = contractRentValueList?.find(
            (contractRentValueItem) =>
                contractRentValueItem.id === idRentContractValue
        );

        onChange({
            id: item?.id,
            dataInicial: convertDateENToBR(item?.dataInicial),
            dataFinal: convertDateENToBR(item?.dataFinal),
            valor: item?.valor || 0,
            porcentagemReajuste: item?.porcentagemReajuste || 0,
            contratosAlugueisIds: item?.contratosAlugueisIds,
        });
    };

    const { mutate: removeRegister } =
        useMutation(
            (id: number) =>
                removeRealStateRentContractValueIndex(
                    String(rentContractId),
                    String(id)
                ),
            {
                mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.remove,
                onSuccess: () => {
                    onSuccessMessage(
                        titleMessages,
                        'Registro removido com sucesso!'
                    );

                    queryClient.invalidateQueries(
                        [QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.list, String(rentContractId)]
                    );
                },
                onError: (err) => {
                    onErrorMessage(titleMessages, err);
                },
            }
        );

    function removeRegisterRequest(id: string | number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister(Number(id));
                }
            });
    }

    return (
        <ValuesRentContractWrap className="values-rent-contract-panel-template">
            <Table
                columns={[
                    {
                        dataField: 'id',
                        text: 'Cód.',
                        headerStyle: { width: '5% ' },
                    },
                    {
                        dataField: 'dataInicial',
                        text: 'Início',
                        headerStyle: { width: '20%' },
                        formatter: (fieldValue) => (
                            <HelperDate value={fieldValue} />
                        ),
                    },
                    {
                        dataField: 'dataFinal',
                        text: 'Fim',
                        headerStyle: { width: '20%' },
                        formatter: (fieldValue) => (
                            <HelperDate value={fieldValue} />
                        ),
                    },
                    {
                        dataField: 'valor',
                        text: 'Valor',
                        headerStyle: { width: '25%' },
                        formatter: (fieldValue) => (
                            <HelperMoney
                                value={fieldValue}
                                style={{ justifyContent: 'flex-start' }}
                            />
                        ),
                    },

                    {
                        dataField: 'porcentagemReajuste',
                        text: 'Ajuste',
                        headerStyle: { width: '15%' },
                        formatter: (fieldValue) => (
                            <HelperPercent
                                value={fieldValue}
                                style={{ justifyContent: 'flex-start' }}
                            />
                        ),
                    },

                    {
                        dataField: 'id',
                        text: 'Opções',
                        headerStyle: { width: '25%' },
                        formatter: (fieldValue) =>
                            HelperOptions({
                                editFunction: () => openRegister(fieldValue),
                                removeFunction: () =>
                                    removeRegisterRequest(fieldValue),
                            }),
                    },
                ]}
                data={contractRentValueList}
                isLoading={isLoading}
            />

            <Modal
                size="lg"
                open={isOpenModalForm}
                onCloseModal={() => onOpenModalForm(false)}
                title="Contratos Aluguel Valor"
                footer={
                    <Button
                        color="septernary"
                        onClick={() => onSubmit()}
                        loading={isLoading || isLoadingCreate || isLoadingUpdate}
                    >
                        <AiFillSave size="1.5rem" />
                        Salvar
                    </Button>
                }
            >
                <Grid numberColumns={2}>
                    <InputMask
                        label="Data Inicial"
                        placeholder="Informe a data inicial"
                        valueMasked
                        mask="99/99/9999"
                        required
                        model="sinary"
                        {...useField(values, onChange, 'dataInicial')}
                    />

                    <InputMask
                        label="Data Final"
                        placeholder="Informe a data final"
                        valueMasked
                        mask="99/99/9999"
                        model="sinary"
                        {...useField(values, onChange, 'dataFinal')}
                    />
                </Grid>

                <Grid numberColumns={2}>
                    <InputNumber
                        label="Valor"
                        placeholder="Informe o valor"
                        prefix="R$ "
                        required
                        model="sinary"
                        {...useField(values, onChange, 'valor')}
                    />

                    <InputNumber
                        label="Porcentagem Correção"
                        placeholder="Informe o valor"
                        postfix="%"
                        model="sinary"
                        {...useField(values, onChange, 'porcentagemReajuste')}
                    />
                </Grid>
            </Modal>
        </ValuesRentContractWrap>
    );
};

export default ValuesRentContract;
