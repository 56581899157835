import { useEffect, useState } from 'react';

import { Button } from 'components/atoms';

import { MySearchWrap, IconSearch } from './styles';

import type { MySearchInterface } from './interface';

const MySearch = ({ onSearch = () => null }: MySearchInterface) => {
    const [text, setText] = useState('');
    const [debouncedText, setDebouncedText] = useState(text);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedText(text);
        }, 1000); 

        return () => {
            clearTimeout(handler); 
        };
    }, [text]);

    useEffect(() => {
        onSearch(debouncedText);
    }, [debouncedText]);

    return (
        <MySearchWrap className="my-search-molecule">
            <div className="input-icon">
                <span className="icon">
                    <IconSearch />
                </span>
                <input
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    type="text"
                    placeholder="Pesquisar..."
                />
            </div>
            <Button color="onedenary" onClick={() => setText('')}>
                Limpar
            </Button>
        </MySearchWrap>
    );
};

export default MySearch;
