import { ReactNode, useState, useMemo, useEffect } from 'react';
import { useMutation } from 'react-query';

import { useNavigate } from 'react-router-dom';
import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Shadow } from 'components/atoms';
import { StepCircle } from 'components/molecules';

import { onWarningMessage, onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { createRealStateOwner, getRealStateOwnerCheckUserExist, getRealStateOwnerInviteUserExist } from 'services'

import { OwnerFormWrap } from './styles';
import { businessDataSchema, personDataSchema, documentCnpjSchema, documentCpfSchema } from '../validations';

import { ValidateDocument, BusinessData, PersonData } from '../components'

import Layout from '../../Layout';
import type { IAPerson } from '@types';

const OwnerNewForm = () => {
    const titleMessages = 'Novo Proprietário';

    const navigate = useNavigate();


    const [step, setStep] = useState('VALIDATE_DOCUMENT');

    const [isAvailable, setIsAvailable] = useState(false);
    const [isConsulted, setIsConsulted] = useState(false);

    const [personData, setPersonData] = useState<Partial<IAPerson>>();

    const [typeDocument, setTypeDocument] = useState('CPF');

    const { getLinkWithSlug } = useRealState();

    const { mutate: onMutateCheckUserExist, isLoading: isLoadingOnCheckUserExist } = useMutation(
        () => getRealStateOwnerCheckUserExist(String(personData?.documento)),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, 'consult', personData?.documento],
            onSuccess: (res) => {

                setIsConsulted(true);

                if (res.exist) {
                    onWarningMessage(titleMessages, res.message);
                } else {
                    onSuccessMessage(
                        titleMessages,
                        res.message
                    );

                    setIsAvailable(true);
                }
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const onSubmitCheckUserExist = async () => {
        const { success } = await onValidationSchema({ documento: typeDocument === 'CPF' ? documentCpfSchema : documentCnpjSchema }, personData);

        if (success) {
            onMutateCheckUserExist();
        }
    }


    const { mutate: onMutateInviteUserExist, isLoading: isLoadingOnInviteUserExist } = useMutation(
        () => getRealStateOwnerInviteUserExist(String(personData?.documento)),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, 'invite', personData?.documento],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    "Convite enviado com sucesso!"
                );

                navigate(ROUTES_REAL_STATE.OWNER.fullPath);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: onMutateCreateRealStateOwner, isLoading: isLoadingCreateRealStateOwner } = useMutation(
        (payload: Partial<IAPerson>) => createRealStateOwner(payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, 'create', personData?.documento],
            onSuccess: (res) => {
                onSuccessMessage(
                    titleMessages,
                    res.message
                );


                navigate(
                    getLinkWithSlug(`${ROUTES_REAL_STATE.OWNER.FORM.fullPath}/${res?.data?.usuariosAcessos?.id}`)
                );
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const handleSubmitToNext = async () => {
        if (step === 'BUSINESS_DATA') {
            const { success } = await onValidationSchema(businessDataSchema, personData);

            if (success && personData) {
                onMutateCreateRealStateOwner(personData);
            }
        } else if (step === 'PERSON_DATA') {
            const { success } = await onValidationSchema(personDataSchema, personData);

            if (success && personData) {
                const payload = personData;

                if (payload?.nascimento) {
                    payload.nascimento = payload.nascimento.split('/').reverse().join('-');
                }

                onMutateCreateRealStateOwner(payload);
            }

        }
    }


    const tabsComponent: Record<string, ReactNode> = {
        VALIDATE_DOCUMENT: (<ValidateDocument
            isAvailable={isAvailable}
            isConsulted={isConsulted}
            isLoadingConsult={isLoadingOnCheckUserExist}
            isLoadingInvite={isLoadingOnInviteUserExist}
            onConsult={onSubmitCheckUserExist}
            onInvite={onMutateInviteUserExist}
            setTypeDocument={setTypeDocument}
            typeDocument={typeDocument}
            onChangeValues={setPersonData}
            values={personData}
        />),
        PERSON_DATA: <PersonData values={personData} onChangeValues={setPersonData} isResponsible={false} />,
        BUSINESS_DATA: <BusinessData values={personData} onChangeValues={setPersonData} />,

    };

    const buttonsActions: Record<string, ReactNode> = {
        VALIDATE_DOCUMENT: (<Button color="octernary" onClick={() => navigate(ROUTES_REAL_STATE.OWNER.SIMPLIFIED_FORM.fullPath)} loading={isLoadingCreateRealStateOwner}>Cadastro Simplificado</Button>),
        PERSON_DATA: (<Button color="octernary" onClick={() => handleSubmitToNext()} loading={isLoadingCreateRealStateOwner}>Avançar</Button>),
        BUSINESS_DATA: (<Button color="octernary" onClick={() => handleSubmitToNext()} loading={isLoadingCreateRealStateOwner}>Avançar</Button>),
    };


    const stepsOptions = useMemo(() => {
        if (typeDocument === 'CPF') {
            return [
                { label: 'Etapa 1', subLabel: "Consulta de Dados", value: 'VALIDATE_DOCUMENT' },
                { label: 'Etapa 2', subLabel: "Dados da Pessoa", value: 'PERSON_DATA' },
                { label: 'Etapa 3', subLabel: 'Dados Bancários', value: 'BANK_ACCOUNT_DATA' },
                { label: 'Etapa 4', subLabel: 'Concluir Integração', value: 'FINISH' }
            ]
        }

        return [
            { label: 'Etapa 1', subLabel: "Consulta de Dados", value: 'VALIDATE_DOCUMENT' },
            { label: 'Etapa 2', subLabel: "Dados da Empresa", value: 'BUSINESS_DATA' },
            { label: 'Etapa 3', subLabel: "Dados do Responsável", value: 'OWNER_DATA' },
            { label: 'Etapa 4', subLabel: 'Dados Bancários', value: 'BANK_ACCOUNT_DATA' },
            { label: 'Etapa 5', subLabel: 'Concluir Integração', value: 'FINISH' }
        ]
    }, [typeDocument]);


    const valueChecked = useMemo(() => {
        const checked: string[] = [];

        let currentStep = 'VALIDATE_DOCUMENT';

        if (
            isAvailable
        ) {
            checked.push('VALIDATE_DOCUMENT')

            currentStep = typeDocument === 'CPF' ? 'PERSON_DATA' : 'BUSINESS_DATA'
        }

        setStep(currentStep)

        return checked;

    }, [isAvailable]);

    useEffect(() => {
        setIsAvailable(false);
        setIsConsulted(false);
    }, [personData?.documento]);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Proprietários',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.OWNER.fullPath),
                },
                {
                    label: 'Novo Proprietário',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.OWNER.NEW_FORM.fullPath),
                },
            ]}
            title="Formulário Novo Proprietário"
            buttonTitle={buttonsActions[step]}
        >
            <OwnerFormWrap className="real-state-panel-owner-new-form">
                <Shadow>

                    <StepCircle
                        options={stepsOptions}
                        valueActive={step}
                        typeOnClick="isActive"
                        valueChecked={valueChecked}
                    />

                    {tabsComponent[step]}


                </Shadow>
            </OwnerFormWrap>
        </Layout>
    );
};

export default OwnerNewForm;
