import styled from 'styled-components';

export const InputImageWrap = styled.div`
    display: flex;

    align-items: center;
    justify-content: space-around;

    margin-top: 1rem;

`;