import { Helmet } from 'react-helmet';

import { RealStatePropertyForm } from 'components/templates';

const RealStatePropertyFormPage = () => (
    <>
        <Helmet>
            <title>Imóveis - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStatePropertyForm />
    </>
);

export default RealStatePropertyFormPage;
