import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_ADMIN,
    ROUTES_ACCOUNT,
    ROUTES_ADMIN,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow, Grid } from 'components/atoms';
import { OptionBlock, InputNumber, Select } from 'components/molecules';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';

import {
    createAdministratorPricesIndexs,
    getAdministratorPricesIndexsShow,
    updateAdministratorPricesIndexs,
} from 'services';

import { PricesIndexsFormWrap } from './styles';
import Layout from '../../Layout';

import type { IAPricesIndexs } from '@types';

const PricesIndexsForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [ano, setAno] = useState(0)
    const [mes, setMes] = useState(0)
    const [valor, setValor] = useState(0)
    const [valorDozeMeses, setValorDozeMeses] = useState(0)
    const [tipo, setTipo] = useState('')

    const titleMessages = 'Índices de Preços!';

    const { data: dataDetail, isLoading: isLoadingGet } = useQuery(
        [QUERIES_ADMIN.PRICES_INDEXS.base, id],
        () => getAdministratorPricesIndexsShow(String(id)),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (dataDetail) {
            setAno(dataDetail?.ano);
            setMes(dataDetail?.mes);
            setValor(dataDetail?.valor);
            setValorDozeMeses(dataDetail?.valorDozeMeses);
            setTipo(dataDetail?.tipo);
        }
    }, [dataDetail]);

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAPricesIndexs>) => createAdministratorPricesIndexs(payload),
        {
            mutationKey: [QUERIES_ADMIN.PRICES_INDEXS.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PRICES_INDEXS.base);

                navigate(getLinkWithSlug(ROUTES_ADMIN.PRICES_INDEXS.fullPath));
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAPricesIndexs>) => updateAdministratorPricesIndexs(String(id), payload),
        {
            mutationKey: [QUERIES_ADMIN.PRICES_INDEXS.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PRICES_INDEXS.base);
                queryClient.refetchQueries([QUERIES_ADMIN.PRICES_INDEXS.base, id]);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onSubmit = async () => {
        const data = {
            id: Number(id),
            ano,
            mes,
            valor,
            valorDozeMeses,
            tipo
        };

        const shapeSchema = {
            ano: Yup.number().nullable().required('O ano é obrigatório.'),
            mes: Yup.number().nullable().required('O mês é obrigatório.'),
            valor: Yup.number().nullable().required('O valor é obrigatório.'),
            valorDozeMeses: Yup.number().nullable().required('A variação dos últimos doze meses é obrigatória'),
            tipo: Yup.string().nullable().required('O tipo é obrigatório.'),
        }

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (!id) {
                mutateCreate(data);
            } else {
                mutateUpdate(data);
            }
        }
    };

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Índices de Preços',
                    href: getLinkWithSlug(ROUTES_ADMIN.PRICES_INDEXS.fullPath),
                },
                {
                    label: 'Formulário índices de Preços',
                    href: getLinkWithSlug(ROUTES_ADMIN.PRICES_INDEXS.FORM.fullPath),
                },
            ]}
            title="Formulário índices de Preços"
            buttonTitle={
                <Button color="denary" onClick={() => onSubmit()} loading={isLoadingGet || isLoadingUpdate || isLoadingCreate}>
                    <AiFillSave />
                    Salvar
                </Button>
            }
        >
            <PricesIndexsFormWrap className="administrator-panel-prices-indexs-form">
                <Shadow>
                    <Loading active={isLoadingGet || isLoadingUpdate || isLoadingCreate} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <Select
                        model="sinary"
                        id="tipo"
                        required
                        label="Tipo"
                        name="tipo"
                        placeholder="Informe o tipo"
                        value={tipo}
                        onChange={setTipo}
                        options={[
                            { label: 'INCC', value: 'INCC' },
                            { label: 'IGPM', value: 'IGPM' },
                            { label: 'CUB', value: 'CUB' }
                        ]}
                    />

                    <Grid numberColumns={2}>
                        <InputNumber
                            model="sinary"
                            id="ano"
                            label="Ano"
                            name="ano"
                            placeholder="Informe o ano"
                            value={ano}
                            onChange={setAno}
                            precision={0}
                        />

                        <InputNumber
                            model="sinary"
                            id="mes"
                            label="Mes"
                            name="mes"
                            placeholder="Informe o mes"
                            value={mes}
                            onChange={setMes}
                            precision={0}
                        />
                    </Grid>

                    <Grid numberColumns={2}>
                        <InputNumber
                            model="sinary"
                            id="valor"
                            label="Valor"
                            name="valor"
                            placeholder="Informe o valor"
                            value={valor}
                            onChange={setValor}
                            precision={4}
                        />

                        <InputNumber
                            model="sinary"
                            id="valorDozeMeses"
                            label="Valor da Variação Últimos 12 meses"
                            name="valorDozeMeseses"
                            placeholder="Informe o valor"
                            value={valorDozeMeses}
                            onChange={setValorDozeMeses}
                            precision={4}
                        />
                    </Grid>
                </Shadow>
            </PricesIndexsFormWrap>
        </Layout>
    );
};

export default PricesIndexsForm;
