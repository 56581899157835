import styled, { css } from 'styled-components';

interface UploadFilesWrapInterface {
    variation: 'primary' | 'secondary';
}

const variationsContent = {
    primary: css`
        margin: 1.875rem;
        padding: 1.25rem;
    `,
    secondary: css``,
};

export const UploadFilesWrap = styled.section<UploadFilesWrapInterface>`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-list {
        display: block;
        margin-top: 1.875rem;

        font-size: 0.857rem;
        font-weight: 800;
        color: ${(props) => props.theme.colors.textQuaternary};
    }
`;

export const Content = styled.div<UploadFilesWrapInterface>`
    width: 100%;
    font-size: 1rem;

    .box-label {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        label {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;

            span {
                color: #f64e60;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .sub-label {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 120%;
            color: ${(props) => props.theme.colors.tertiary};
        }
    }

    ${(props) => variationsContent[props.variation]};

    .empty-box {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 0.938rem;

        height: 3.75rem;
        width: 100%;

        background-color: ${(props) => props.theme.colors.background};
        border: 0.068rem solid ${(props) => props.theme.colors.borderSepternary};

        font-size: 0.875rem;
        color: ${(props) => props.theme.colors.textEneaternary};
        font-weight: 800;
    }
`;
