import styled from 'styled-components';

export const CustomerLayoutWrap = styled.div`
    display: block;
    width: 100%;
    overflow-y: auto;

    .header-pages-organism {
        margin-bottom: 4.688rem;
    }

    .customer-layout-template-content {
        flex: 1;
        margin-left: 1.875rem;
    }

    .customer-layout-template-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1.675rem;

        button {
            margin-left: 0.938rem;
        }
    }
`;
