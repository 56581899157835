import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FiEye } from 'react-icons/fi';
import { GiPayMoney } from 'react-icons/gi';
import { RiBarcodeBoxLine } from 'react-icons/ri';

import { QUERIES_ADMIN } from '@constants';

import {
    Button,
    HelperBoolean,
    HelperDate,
    HelperMoney,
    HelperOptions,
    Row,
} from 'components/atoms';
import { Modal } from 'components/molecules';
import { InvoiceSettlementDetails, Table } from 'components/organisms';

import { onErrorMessage, onSuccessMessage } from 'helpers';

import {
    getAdministratorInvoicePay,
} from 'services';

import type { IAInvoiceSettlement } from '@types';
import type { InvoiceSettlementInterface } from './interface';

const InvoiceSettlement = ({ id, data = [], isLoading }: InvoiceSettlementInterface) => {
    const titleMessages = 'Faturas de Acerto!';

    const queryClient = useQueryClient();

    const [isOpenModalShowInvoices, setIsOpenModalShowInvoices] =
        useState(false);
    const [itemModalShowInvoices, setItemModalShowInvoices] =
        useState<IAInvoiceSettlement>();

    const onOpenModalShowInvoices = (item: IAInvoiceSettlement) => {
        setIsOpenModalShowInvoices(true);
        setItemModalShowInvoices(item);
    };

    const onCloseModalShowInvoices = () => {
        setIsOpenModalShowInvoices(false);
        setItemModalShowInvoices(undefined);
    };

    const { mutate: mutatePay, isLoading: isLoadingPay } = useMutation(
        (idForPay: number) =>
            getAdministratorInvoicePay('FATURA_ACERTO', idForPay),
        {
            mutationKey: [QUERIES_ADMIN.REAL_STATE.base, `${id}`],
            onSuccess: (res) => {
                onSuccessMessage(titleMessages, res.message);

                onCloseModalShowInvoices();

                queryClient.refetchQueries([
                    QUERIES_ADMIN.REAL_STATE.base,
                    `${id}`,
                ]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const footerModalButtons = useMemo(() => {
        if (
            itemModalShowInvoices?.status === 'PAGA' ||
            !itemModalShowInvoices?.id
        ) {
            return undefined;
        }

        return (
            <Row>
                {itemModalShowInvoices?.status !== 'PAGA' && (
                    <Button
                        color="septernary"
                        onClick={() => mutatePay(itemModalShowInvoices?.id)}
                        loading={isLoadingPay}
                    >
                        <GiPayMoney />
                        Pagar
                    </Button>
                )}

                {itemModalShowInvoices?.urlBoleto && (
                    <Button
                        color="octernary"
                        onClick={() =>
                            window.open(itemModalShowInvoices?.urlBoleto)
                        }
                    >
                        <RiBarcodeBoxLine />
                        Boleto
                    </Button>
                )}
            </Row>
        );
    }, [itemModalShowInvoices, isLoadingPay]);

    return (
        <div>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'devedores',
                        text: 'Devedor',
                        sort: true,
                        formatter: (fieldValue) => fieldValue?.nome,
                        headerStyle: { width: '40%' },
                    },

                    {
                        dataField: 'dataVencimento',
                        text: 'Vencimento',
                        headerStyle: { width: '20%' },
                        formatter: (fieldValue) => (
                            <HelperDate value={fieldValue} />
                        ),
                    },

                    {
                        dataField: 'competencia',
                        text: 'Competência',
                        headerStyle: { width: '20%' },
                        formatter: (fieldValue) =>
                            fieldValue?.split('-').reverse().join('/'),
                    },

                    {
                        dataField: 'valor',
                        text: 'Valor',
                        formatter: (fieldValue) => (
                            <HelperMoney value={fieldValue} />
                        ),
                        headerStyle: { width: '20%' },
                    },

                    {
                        dataField: 'status',
                        text: 'Paga',
                        formatter: (fieldValue) => (
                            <HelperBoolean value={fieldValue === 'PAGA'} />
                        ),
                        headerStyle: { width: '8rem' },
                    },

                    {
                        dataField: 'id',
                        text: 'Opções',
                        formatter: (fieldValue, index, row) => (
                            <HelperOptions
                                editFunction={() =>
                                    onOpenModalShowInvoices(row)
                                }
                                editIcon={<FiEye />}
                                remove={false}
                            />
                        ),
                    },
                ]}
                data={data}
            />

            <Modal
                open={isOpenModalShowInvoices}
                onCloseModal={() => onCloseModalShowInvoices()}
                title="Fatura"
                size="lg"
                footer={footerModalButtons}
            >
                <InvoiceSettlementDetails data={itemModalShowInvoices} />
            </Modal>
        </div>
    );
};

export default InvoiceSettlement;
