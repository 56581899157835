import styled from 'styled-components'

export const InputRadioWrap = styled.div`
  width: 100%;

  ul {
    li {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`
