import styled, { CSSProperties} from 'styled-components'

interface ColumnWrapProps {
  xl?: number | string
  lg?: number | string
  md?: number | string
  sm?: number | string
  basis?: boolean | undefined
  style?: CSSProperties
}

export const ColumnWrap = styled.div<ColumnWrapProps>`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex: 1;
  max-width: 100%;
  padding: 0 0.93rem;

  @media (max-width: 1200px) {
    flex: ${props => (props.basis ? 'unset' : props.xl)};
    flex-basis: ${props => (props.basis ? props.xl : 'unset')};
  }

  @media (max-width: 992px) {
    flex: ${props => (props.basis ? 'unset' : props.lg)};
    flex-basis: ${props => (props.basis ? props.lg : 'unset')};
  }

  @media (max-width: 768px) {
    flex: ${props => (props.basis ? 'unset' : props.md)};
    flex-basis: ${props => (props.basis ? props.md : 'unset')};
  }

  @media (max-width: 576px) {
    flex: ${props => (props.basis ? 'unset' : props.sm)};
    flex-basis: ${props => (props.basis ? props.sm : 'unset')};
  }
`
