import { generateURL } from './prefix';
import api from '../api';

import type { IAValueDetail } from '@types';

export const getAdministratorValueDetailsIndex = async () => {
    const url = generateURL(`/imoveis/detalhes_de_valores`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorValueDetailsShow = async (id: number | string) => {
    const url = generateURL(`/imoveis/detalhes_de_valores/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorValueDetails = async (
    payload: Partial<IAValueDetail>
) => {
    const url = generateURL(`/imoveis/detalhes_de_valores`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorValueDetails = async (
    id: number | string,
    payload: Partial<IAValueDetail>
) => {
    const url = generateURL(`/imoveis/detalhes_de_valores/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorValueDetails = async (id: number | string) => {
    const url = generateURL(`/imoveis/detalhes_de_valores/${id}`);


    const { data } = await api.delete(url);

    return data;
};
