/* eslint-disable arrow-body-style */
import axios from 'axios';

import { enviroments, sweetAlert } from 'utils';

const api = axios.create({
    baseURL: enviroments.BASE_URL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;

        if (status === 403) {
            sweetAlert.fire({
                title: 'Servidor!',
                text:
                    error?.response?.data?.message ||
                    'Falha ao realizar requisição!',
                icon: 'error',
            });
        }

        if (status === 401) {
            sweetAlert.fire({
                title: 'Servidor!',
                text:
                    error?.response?.data?.message ||
                    'Falha ao realizar requisição!',
                icon: 'error',
            });
        }

        return Promise.reject(error);
    }
);

export default api;
