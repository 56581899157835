import styled from 'styled-components'

export const H3Wrap = styled.h3`
  font-size: 1.625rem;
  line-height: 120%;
  font-weight: 300;
  color: ${props => props.theme.colors.textPrimary};

  strong {
    font-weight: 900;
  }
`
