import styled, { css } from 'styled-components';
import { CircularProgressbar as RCP } from 'react-circular-progressbar';

export const ImageListLineWrap = styled.section`
    margin-top: 1.25rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 3.75rem;
    gap: 1.563rem;
    max-width: 100%;
    font-family: 'Work Sans';
    list-style: none;
`;

interface PreviewInterface {
    src?: string;
    error: boolean;
    uploaded: boolean;
}

export const File = styled.li<PreviewInterface>`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.senary};

    display: flex;
    flex-direction: row;

    .box-left {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: 100%;
        padding: 1.25rem;
        margin-right: 0.25rem;

        border: 0.063rem solid ${(props) => props.theme.colors.borderPrimary};

        ${(props) =>
            props.uploaded &&
            css`
                border-color: ${props.theme.colors.borderSuccess};
            `}

        ${(props) =>
            props.error &&
            css`
                border-color: ${props.theme.colors.danger};
            `}

    .extension {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 1.75rem;
            width: 3.125rem;
            margin-right: 1rem;
            background-color: ${(props) => props.theme.colors.quaternaryBold};

            font-size: 0.688rem;
            color: ${(props) => props.theme.colors.textSenary};
            font-weight: 700;
            text-transform: uppercase;
        }

        .title {
            font-size: 0.813rem;
            color: ${(props) => props.theme.colors.quaternaryBold};
            font-weight: 800;
        }
    }

    .box-right {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 7.188rem;
        margin-left: 0.25rem;

        border: 0.063rem solid ${(props) => props.theme.colors.borderPrimary};

        ${(props) =>
            props.uploaded &&
            css`
                border-color: ${props.theme.colors.borderSuccess};
            `};

        ${(props) =>
            props.error &&
            css`
                border-color: ${props.theme.colors.danger};
            `};

        .percentage {
            font-size: 0.813rem;
            ${(props) =>
                props.error
                    ? css`
                          color: ${props.theme.colors.danger};
                      `
                    : css`
                          color: ${props.theme.colors.textSuccess};
                      `};
            font-weight: 700;
        }

        .size {
            font-size: 0.75rem;
            color: ${(props) => props.theme.colors.quaternaryBold};
        }

        button.close {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.063rem;

            height: 1.125rem;
            width: 1.125rem;
            border-radius: 100%;

            background-color: ${(props) => props.theme.colors.danger};

            position: absolute;
            top: calc(50% - 0.563rem);
            right: -0.563rem;

            color: ${(props) => props.theme.colors.senary};
        }
    }
`;

export const CircularProgressbar = styled(RCP).attrs((props) => ({
    styles: {
        root: { width: 150 },
        path: { stroke: props.theme.colors.secondaryExtraBold },
    },
    strokeWidth: 5,
}))``;

interface PreviewInterface {
    src?: string;
}

export const Preview = styled.div<PreviewInterface>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 0.625rem;
`;
