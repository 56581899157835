import { IoMdArrowDropright } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { ItemListWrap } from './styles';

import type { ICItemList } from '@types';

const ItemList = ({ label, href }: ICItemList) => (
    <ItemListWrap className="item-list-atom">
        <Link to={href}>
            <IoMdArrowDropright />
            {label}
        </Link>
    </ItemListWrap>
);

export default ItemList;
