import styled from 'styled-components'

export const HelperPercentWrap = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.75rem;
    color: ${props => props.theme.colors.danger};
  }

  &.active {
    svg {
      color: ${props => props.theme.colors.check};
    }
  }
`
