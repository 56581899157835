import { MdCheckCircle, MdError, MdLink, MdStars } from 'react-icons/md';
import { useTheme } from 'styled-components';

import { ImageListSquareWrap, File, CircularProgressbar } from './styles';

import type { ImageListSquareInterface } from './interface';

const ImageListSquare = ({
    files,
    onDelete,
    feature,
    onFeature,
}: ImageListSquareInterface) => {
    const theme = useTheme();

    return (
        <ImageListSquareWrap className="image-list-square-molecule">
            {Array.isArray(files) &&
                files?.map((uploadedFile) => (
                    <File
                        key={uploadedFile.id}
                        src={uploadedFile.preview || uploadedFile.url}
                    >
                        <div className="image">
                            {!uploadedFile.uploaded && !uploadedFile.error && (
                                <CircularProgressbar
                                    value={uploadedFile?.progress || 0}
                                />
                            )}

                            {uploadedFile.url && (
                                <a
                                    href={uploadedFile.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MdLink
                                        size={30}
                                        color={theme.colors.secondary}
                                    />
                                </a>
                            )}

                            <div className="absolute-icons">
                                {uploadedFile.destaque && (
                                    <MdStars
                                        size={24}
                                        color={theme.colors.secondary}
                                        className="left"
                                    />
                                )}

                                {uploadedFile.uploaded && (
                                    <MdCheckCircle
                                        size={24}
                                        color={theme.colors.secondaryExtraBold}
                                        className="right"
                                    />
                                )}

                                {uploadedFile.error && (
                                    <MdError
                                        size={24}
                                        className="right"
                                        color={theme.colors.danger}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="content">
                            <span className="name" title={uploadedFile.name}>
                                {String(uploadedFile?.name).substr(0, 30)}{' '}
                                {String(uploadedFile?.name).length > 30 &&
                                    '...'}
                            </span>
                            <span className="size">
                                {uploadedFile.readableSize}
                            </span>
                            <div className="row">
                                {!!uploadedFile.url &&
                                    !uploadedFile?.destaque &&
                                    feature && (
                                        <button
                                            type="button"
                                            title="Definir como capa"
                                            onClick={() =>
                                                onFeature(uploadedFile.id)
                                            }
                                        >
                                            Def. Capa
                                        </button>
                                    )}

                                {!!uploadedFile.url && (
                                    <button
                                        type="button"
                                        className="btn-delete"
                                        onClick={() =>
                                            onDelete(uploadedFile.id)
                                        }
                                    >
                                        Excluir
                                    </button>
                                )}
                            </div>
                        </div>
                    </File>
                ))}
        </ImageListSquareWrap>
    );
};

export default ImageListSquare;
