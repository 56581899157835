import styled from 'styled-components';

export const SidebarItemWrap = styled.li`
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 5rem;
        padding: 0 2.375rem;

        font-size: 0.875rem;
        line-height: 120%;
        font-weight: 500;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.textPrimary};
        text-decoration: none;
        border-bottom: 0.063rem solid
            ${(props) => props.theme.colors.borderSepternary};
        background-color: ${(props) => props.theme.colors.senary};

        &:hover {
            background-color: ${(props) => props.theme.colors.secondary};
        }

        &.active {
            font-weight: 900;
            background-color: ${(props) => props.theme.colors.secondary};
        }

        svg {
            font-size: 1rem;
            g [fill] {
                color: ${(props) => props.theme.colors.textPrimary};
            }
        }
    }
`;
