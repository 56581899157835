/* eslint-disable react/no-unstable-nested-components, @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useRealState } from 'contexts/RealStateContext';

import { Button, HelperOptions, HelperBoolean } from 'components/atoms';
import { TableFetch } from 'components/organisms';

import { RealStateLayout } from 'components/templates';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getRealStatePropertyIndex, removeRealStateProperty } from 'services';
import { sweetAlert } from 'utils';

import {
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
} from '@constants';

import { PropertyListWrap } from './styles';

import type { IAPropertyCustom } from '@types';
import type { PropertyMutationInterface } from './interface';

const PropertyList = () => {
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        search: '',
        sort: 'DESC',
        order: 'id'
    });
    const [totalPages, setTotalPages] = useState(0);

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const title = 'Imóveis';

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    const filters = Object.values(filter).join(',');

    const { data, isLoading } = useQuery(`${QUERIES_REAL_STATE.PROPERTY.list}-${filters}`, () =>
        getRealStatePropertyIndex({
            pagina: filter.page,
            limite: filter.limit,
            pesquisa: filter.search,
            ordenar: filter.order,
            ordenarDirecao: filter.sort,
        }),
        {
            refetchOnMount: true,
            onSuccess(response) {
                setTotalPages(response.count);
            },
        },
    );

    function newRegister() {
        onHandleNavigate(
            getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.NEW_FORM.fullPath)
        );
    }

    function editRegister(row: IAPropertyCustom) {
        if (row.imoveis.publicado) {
            onHandleNavigate(
                getLinkWithSlug(
                    `${ROUTES_REAL_STATE.PROPERTY.FORM.fullPath}/${row.id}`
                )
            );
        } else {
            onHandleNavigate(
                getLinkWithSlug(
                    `${ROUTES_REAL_STATE.PROPERTY.NEW_FORM.fullPath}/${row.id}`
                )
            );
        }
    }

    const { mutate: removeRegister }: PropertyMutationInterface = useMutation(
        (id) => removeRealStateProperty(Number(id)),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.remove,
            onSuccess: () => {
                onSuccessMessage(title, "Registro removido com sucesso!");

                queryClient.refetchQueries(`${QUERIES_REAL_STATE.PROPERTY.list}-${filters}`);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister(id);
                }
            });
    }

    return (
        <RealStateLayout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.fullPath),
                },
            ]}
            title="Imóveis"
            buttonTitle={
                <Button onClick={() => newRegister()}>Novo Registro</Button>
            }
        >
            <PropertyListWrap className="properties-template">
                <TableFetch
                    filter={filter}
                    onChangeFilter={setFilter}
                    totalItems={totalPages}
                    isLoading={isLoading}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'Id',
                            sort: true,
                            style: { fontWeight: 700 },
                        },
                        {
                            dataField: 'titulo',
                            text: 'Título',
                            sort: true,
                            headerStyle: { width: '100%' },
                        },

                        {
                            dataField: 'imoveis',
                            text: 'Tipo',
                            formatter: (fieldValue) =>
                                fieldValue?.tipos?.descricao,
                        },

                        {
                            dataField: 'destaque',
                            text: 'Destaque',
                            formatter: (fieldValue) => (
                                <HelperBoolean value={fieldValue} />
                            ),
                            style: { width: '10rem' },
                        },

                        {
                            dataField: 'publicado',
                            text: 'Publicado',
                            formatter: (fieldValue) => (
                                <HelperBoolean value={fieldValue} />
                            ),
                            style: { width: '10rem' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (
                                fieldValue,
                                _index,
                                row: IAPropertyCustom
                            ) =>
                                HelperOptions({
                                    editFunction: () => editRegister(row),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                        },
                    ]}
                    data={data?.data}
                />
            </PropertyListWrap>
        </RealStateLayout>
    );
};

export default PropertyList;
