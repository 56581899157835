import { Helmet } from 'react-helmet';

import { RealStateOwnerList } from 'components/templates';

const RealStateOwnerListPage = () => (
    <>
        <Helmet>
            <title>Proprietários - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateOwnerList />
    </>
);

export default RealStateOwnerListPage;
