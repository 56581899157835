import { OptionBlockItemWrap } from './styles';

import type { OptionBlockItemLocalInterface } from './interface';

const OptionBlockItem = ({
    label,
    value,
    active,
    onHandleActive = () => null,
    transparent = false,
    optional = false,
}: OptionBlockItemLocalInterface) => (
    <OptionBlockItemWrap active={active} transparent={transparent}>
        <button
            type="button"
            title={label}
            onClick={() => onHandleActive(value)}
        >
            <strong>{label}</strong> {optional ? '(OPCIONAL)' : ''}
        </button>
    </OptionBlockItemWrap>
);

export default OptionBlockItem;
