import styled from 'styled-components';

export const FinishWrap = styled.div`
    padding: 1.875rem;

    .column-text {

        display: flex;
        flex-direction: column;
        justify-content: center;
        
        p {
            font-size: 1rem;
            color: ${(props) => props.theme.colors.tertiaryBold};
            line-height: 150%;
            
            margin-bottom: 2rem;
        }
    }
`;
