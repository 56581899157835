import { generateURL } from './prefix';
import api from '../api';

export const createAuthPasswordRecovery = async (
    payload: { email: string}
) => {
    const url = generateURL(`/password_recovery`);


    const { data } = await api.post(url, payload);

    return data;
};

export const createAuthNewPassword = async (
    payload: {
        email: string;
        codigo: string;
        senha: string;
    }
) => {
    const url = generateURL(`/recovery_code`);


    const { data } = await api.post(url, payload);

    return data;
};
