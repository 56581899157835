import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_ADMIN,
    ROUTES_ACCOUNT,
    ROUTES_ADMIN,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow, Grid } from 'components/atoms';
import { OptionBlock, InputText, TextHtmlArea, InputNumber, InputToggle } from 'components/molecules';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';

import {
    createAdministratorPlan,
    getAdministratorPlanShow,
    updateAdministratorPlan,
} from 'services';

import { PlanFormWrap } from './styles';
import Layout from '../../Layout';

import type { IAPlan } from '@types';

const PlanForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [nome, setNome] = useState('');
    const [destaque, setDestaque] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [vantagens, setVantagens] = useState('');
    const [valorMensal, setValorMensal] = useState(0);
    const [valorTrimestral, setValorTrimestral] = useState(0);
    const [valorAnual, setValorAnual] = useState(0);

    const titleMessages = 'Plano!';

    const { data: dataDetail, isLoading: isLoadingGet } = useQuery(
        [QUERIES_ADMIN.PLAN.base, id],
        () => getAdministratorPlanShow(String(id)),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (dataDetail) {
            setNome(dataDetail?.nome);
            setDescricao(dataDetail?.descricao);
            setDestaque(dataDetail?.destaque);
            setVantagens(dataDetail?.vantagens);
            setValorMensal(dataDetail?.valorMensal);
            setValorTrimestral(dataDetail?.valorTrimestral);
            setValorAnual(dataDetail?.valorAnual);
        }
    }, [dataDetail]);

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAPlan>) => createAdministratorPlan(payload),
        {
            mutationKey: [QUERIES_ADMIN.PLAN.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PLAN.base);

                navigate(getLinkWithSlug(ROUTES_ADMIN.PLAN.fullPath));
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAPlan>) => updateAdministratorPlan(String(id), payload),
        {
            mutationKey: [QUERIES_ADMIN.PLAN.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PLAN.base);
                queryClient.refetchQueries([QUERIES_ADMIN.PLAN.base, id]);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onSubmit = async () => {
        const data = {
            nome,
            destaque,
            descricao,
            vantagens,
            valorMensal,
            valorTrimestral,
            valorAnual,
        };

        const shapeSchema = {
            valorMensal: Yup.number().nullable().required('O valor mensal é obrigatório.'),
            descricao: Yup.string().nullable().required('A descrição é obrigatória.'),
            nome: Yup.string().nullable().required('O nome é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (!id) {
                mutateCreate(data);
            } else {
                mutateUpdate(data);
            }
        }
    };

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Planos',
                    href: getLinkWithSlug(ROUTES_ADMIN.PLAN.fullPath),
                },
                {
                    label: 'Formulário Planos',
                    href: getLinkWithSlug(ROUTES_ADMIN.PLAN.FORM.fullPath),
                },
            ]}
            title="Formulário Planos"
            buttonTitle={
                <Button color="denary" onClick={() => onSubmit()} loading={isLoadingGet || isLoadingUpdate || isLoadingCreate}>
                    <AiFillSave />
                    Salvar
                </Button>
            }
        >
            <PlanFormWrap className="administrator-panel-plan-form">
                <Shadow>
                    <Loading active={isLoadingGet || isLoadingUpdate || isLoadingCreate} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <Grid gridTemplateColumns='2fr 1fr'>

                        <InputText
                            model="sinary"
                            id="nome"
                            required
                            label="Nome"
                            name="nome"
                            placeholder="Informe o nome"
                            value={nome}
                            onChange={setNome}
                        />

                        <InputToggle
                            model="sinary"
                            id="destaque"
                            label="Destaque"
                            name="destaque"
                            value={destaque}
                            onChange={setDestaque}
                            labelFalse="Não"
                            labelTrue='Sim'
                        />

                    </Grid>

                    <InputText
                        model="sinary"
                        id="descricao"
                        required
                        label="Descrição"
                        name="descricao"
                        placeholder="Informe a descrição"
                        value={descricao}
                        onChange={setDescricao}
                    />

                    <TextHtmlArea
                        model="sinary"
                        id="vantagens"
                        required
                        label="Vantagens"
                        name="vantagens"
                        placeholder="Informe as vantagens"
                        value={vantagens}
                        onChange={setVantagens}
                    />

                    <Grid numberColumns={3}>
                        <InputNumber
                            model="sinary"
                            id="valorMensal"
                            required
                            label="Valor Mensal"
                            name="valorMensal"
                            placeholder="Informe o valor mensal"
                            value={valorMensal}
                            onChange={setValorMensal}
                            prefix="R$ "
                        />

                        <InputNumber
                            model="sinary"
                            id="valorTrimestral"
                            label="Valor Trimestral"
                            name="valorTrimestral"
                            placeholder="Informe o valor trimestral"
                            value={valorTrimestral}
                            onChange={setValorTrimestral}
                            prefix="R$ "
                        />

                        <InputNumber
                            model="sinary"
                            id="valorAnual"
                            label="Valor Anual"
                            name="valorAnual"
                            placeholder="Informe o valor anual"
                            value={valorAnual}
                            onChange={setValorAnual}
                            prefix="R$ "
                        />
                    </Grid>
                </Shadow>
            </PlanFormWrap>
        </Layout>
    );
};

export default PlanForm;
