import styled from 'styled-components'

export const H2Wrap = styled.h2`
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 300;
  color: ${props => props.theme.colors.textPrimary};
  text-transform: uppercase;

  strong {
    font-weight: 900;
  }
`
