import api from 'services/api';
import { generateURL } from './prefix';

import type { IAPropertyType } from '@types';

export const getPublicPropertyTypeIndex = async (): Promise<
    IAPropertyType[]
> => {
    const url = generateURL(`/imoveis/tipos`);

    const { data } = await api.get(url);

    return data;
};
