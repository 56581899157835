import { generateURL } from './prefix'
import api from '../api';

import type { IAPerson, IARentContract } from '@types';

export const getCustomerRentContractList = async (): Promise<IARentContract[]> => {
    const url = generateURL(`/contratos`);

    const { data } = await api.get(url);

    return data;
};

export const getNewCustomerRentContract = async (slug: string): Promise<IARentContract> => {
    const url = generateURL(`/contratos/novo/${slug}`);

    const { data } = await api.get(url);

    return data;
};

export const getCustomerRentContractOne = async (id: number): Promise<IARentContract> => {
    const url = generateURL(`/contratos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const changeStatusCustomerRentContract = async (
    id: number,
    { status }: Pick<IARentContract, 'status'>
) => {
    const url = generateURL(`/contratos/${id}/status/${status}`);

    const { data } = await api.patch(url);

    return data;
};

export const changeStepCustomerRentContract = async (
    id: number,
    { etapa }: Pick<IARentContract, 'etapa'>
) => {
    const url = generateURL(`/contratos/${id}/etapa/${etapa}`);

    const { data } = await api.patch(url);

    return data;
};

export const patchCustomerRentContract = async (
    id: number,
    payload: Partial<IARentContract>
) => {
    const url = generateURL(`/contratos/${id}`);

    const { data } = await api.patch(url, payload);

    return data;
};



export const patchPersonDataCustomerRentContract = async (
    rentContractId: number,
    payload: Partial<IAPerson>
) => {
    const url = generateURL(`/contratos/${rentContractId}/dados_pessoais`);

    const { data } = await api.patch(url, payload);

    return data;
};