import styled from 'styled-components';

export const InvoiceSettlementPrintWrap = styled.div`
    width: 750px;
    margin: 1vh auto;

    background-color: ${(props) => props.theme.colors.senary};

    .page-break {
        page-break-before: always;
    }

    @media print {

        body {
            margin: 0;
            padding: 0;
            line-height: 1.4em;
        }

        @page {
            margin: 0.5cm;
        }
    }
`;
