import { Helmet } from 'react-helmet';

import { RealStatePropertyExpenseForm } from 'components/templates';

const RealStatePropertyExpenseFormPage = () => (
    <>
        <Helmet>
            <title>Lançamentos Imóveis - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStatePropertyExpenseForm />
    </>
);

export default RealStatePropertyExpenseFormPage;
