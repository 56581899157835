import InputMask from 'react-input-mask';

import { InputCapsule } from 'components/atoms';

import { checkEmpty, justNumber } from 'utils';

import { InputMaskWrap } from './styles';

import type { InputMaskInterface } from './interface';

const InputMaskComp = ({
    id,
    label,
    name,
    placeholder,
    readOnly,
    required,
    value,
    mask,
    model,
    valueMasked = false,
    onChange = () => null,
    onKeyDown,
    ...rest
}: InputMaskInterface) => (
    <InputMaskWrap className="input-text-molecule">
        <InputCapsule
            htmlFor={id || name}
            label={label}
            required={required}
            empty={checkEmpty(value)}
            model={model}
        >
            <InputMask
                id={id || name}
                mask={mask}
                placeholder={placeholder}
                name={name}
                value={value}
                readOnly={readOnly}
                onChange={(event) =>
                    onChange(
                        valueMasked
                            ? event.target.value
                            : justNumber(event.target.value)
                    )
                }
                onKeyDown={onKeyDown}
                {...rest}
            />
        </InputCapsule>
    </InputMaskWrap>
);

export default InputMaskComp;
