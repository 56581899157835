import { Helmet } from 'react-helmet';

import { RealStateInvoicePropertyList } from 'components/templates';

const RealStateInvoicePropertyListPage = () => (
    <>
        <Helmet>
            <title>Faturas - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateInvoicePropertyList />
    </>
);

export default RealStateInvoicePropertyListPage;
