import { Link, useLocation } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import { SidebarItemWrap } from './styles';

import type { SidebarItemInterface } from './interface';

const SidebarItem = ({ label, route, style }: SidebarItemInterface) => {
    const location = useLocation();

    return (
        <SidebarItemWrap className="sidebar-item-atom" style={style}>
            <Link
                to={route}
                className={location?.pathname?.includes(route) ? 'active' : ''}
            >
                {label}
                <AiFillCaretRight />
            </Link>
        </SidebarItemWrap>
    );
};

export default SidebarItem;
