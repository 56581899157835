import { Helmet } from 'react-helmet';

import { AdministratorMethodsPaymentsList } from 'components/templates';

const AdministratorMethodsPaymentsListPage = () => (
    <>
        <Helmet>
            <title>Métodos de Pagamento - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorMethodsPaymentsList />
    </>
);

export default AdministratorMethodsPaymentsListPage;
