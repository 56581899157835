import styled from 'styled-components'

export const CardImageWrap = styled.div`
  width: 100%;
  height: 11.875rem;

  position: relative;

  background-image: url('/images/empty_property.png');
  background-position: center;
  background-size: cover;

  span {
    position: absolute;

    display: inline-block;
    padding: 0.5rem;

    font-size: 0.68rem;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    color: ${props => props.theme.colors.textSenary};
  }

  .code {
    top: 1.25rem;
    right: 1.25rem;

    background-color: ${props => props.theme.colors.quaternary};
  }

  .type {
    bottom: 1.25rem;
    left: 1.25rem;

    background-color: ${props => props.theme.colors.secondary};
  }
`
