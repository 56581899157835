import { generateURL } from './prefix'
import api from '../api';


export const uploadRealStateFile = async (file: File, field: string) => {
    const url = generateURL(`/imagem/campo/${field}`);

    const formData = new FormData()

    formData.append('file', file)


    const { data } = await api.post(url, formData);

    return data;
};

export const removeRealStateFile = async (imageId: number) => {
    const url = generateURL(`/imagem/${imageId}`);

    const { data } = await api.delete(url);

    return data;
};
