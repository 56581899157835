import { Helmet } from 'react-helmet';

import { RealStateSetting } from 'components/templates';

const RealStateSettingPage = () => (
    <>
        <Helmet>
            <title>Configurações - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateSetting />
    </>
);

export default RealStateSettingPage;
