import styled, { css } from 'styled-components';

interface NavBarItemWrapInterface {
    color: 'blue' | 'white';
    lineLeft: boolean;
    lineRight: boolean;
}

const colors = {
    blue: css`
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.textSenary};

        &:before,
        &:after {
            background-color: ${(props) => props.theme.colors.borderPrimary};
        }

        &:hover {
            background-color: ${(props) => props.theme.colors.senary};
            color: ${(props) => props.theme.colors.textQuinary};

            &:before,
            &:after {
                background-color: ${(props) =>
                    props.theme.colors.borderSecondary};
            }
        }
    `,
    white: css`
        background-color: ${(props) => props.theme.colors.senary};
        color: ${(props) => props.theme.colors.quaternaryBold};

        &:before,
        &:after {
            background-color: ${(props) => props.theme.colors.borderSecondary};
        }

        &:hover {
            background-color: ${(props) => props.theme.colors.quaternaryBold};
            color: ${(props) => props.theme.colors.senary};

            &:before,
            &:after {
                background-color: ${(props) =>
                    props.theme.colors.borderPrimary};
            }
        }
    `,
};

export const NavBarItemWrap = styled.li<NavBarItemWrapInterface>`
    a {
        display: inline-block;
        height: 5rem;
        letter-spacing: 0.06rem;
        line-height: 5rem;
        padding: 0 3.75rem;

        ${(props) => colors[props.color]};

        font-size: 0.85rem;
        font-weight: 800;
        text-decoration: none;
        text-transform: uppercase;

        position: relative;

        transition: background-color 0.5s ease;

        &:before {
            content: '';
            display: ${(props) => (props.lineLeft ? 'block' : 'none')};
            height: 0.062rem;
            width: 1.09rem;
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            left: 0;
        }

        &:after {
            content: '';
            display: ${(props) => (props.lineRight ? 'block' : 'none')};
            height: 0.062rem;
            width: 1.09rem;
            transform: translateY(-50%);

            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    @media (max-width: 992px) {
        width: 100%;
        a {
            width: 100%;
            text-align: center;

            &:after {
                display: block;
                top: 0;
                width: 100%;
            }

            &:before {
                display: block;
                top: auto;
                bottom: 0;
                width: 100%;
            }
        }
    }
`;
