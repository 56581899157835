import * as Yup from 'yup'

import { validateCPF, validateCNPJ } from 'validations-br';

export const documentCnpjSchema = Yup.string()
    .test('is-cnpj', 'CNPJ inválido', (value) =>
        validateCNPJ(String(value))
    )
    .required('O documento é obrigatório');

export const documentCpfSchema = Yup.string()
    .test('is-cpf', 'CPF inválido', (value) =>
        validateCPF(String(value))
    )
    .required('O documento é obrigatório');

export const businessDataSchema = {
    numero: Yup.string().nullable().required('O número é obrigatório'),
    logradouro: Yup.string().nullable().required('O logradouro é obrigatório'),
    bairrosIds: Yup.string().nullable().required(
        'Campos de endereço são obrigatórios'
    ),
    cep: Yup.string().nullable().required('O cep é obrigatório'),
    email: Yup.string().nullable().required('O e-mail é obrigatório'),
    telefone: Yup.string().nullable().required('O telefone é obrigatório'),
    nome: Yup.string().nullable().required('O nome é obrigatório'),
    documento: documentCnpjSchema,
};

export const personDataSchema = {
    numero: Yup.string().nullable().required('O número é obrigatório'),
    logradouro: Yup.string().nullable().required('O logradouro é obrigatório'),
    bairrosIds: Yup.string().nullable().required(
        'Campos de endereço são obrigatórios'
    ),
    cep: Yup.string().nullable().required('O cep é obrigatório'),
    telefone: Yup.string().nullable().required('O telefone é obrigatório'),
    nascimento: Yup.string().nullable().required('A data de nascimento é obrigatório'),
    email: Yup.string().nullable().required('O e-mail é obrigatório'),
    nome: Yup.string().nullable().required('O nome é obrigatório'),
    documento: documentCpfSchema
};

export const bankAccountDataSchema = {
    nomeTitular: Yup.string().nullable().required(
        'O nome do títular é obrigatório'
    ),
    tipoConta: Yup.string().nullable().required('O tipo da conta é obrigatório'),
    banco: Yup.string().nullable().required('O banco é obrigatório'),
    agencia: Yup.string().nullable().required('A agência é obrigatória'),
    conta: Yup.string().nullable().required('A conta é obrigatória'),
    numeroDocumento: Yup.string().nullable().required(
        'O número do documento é obrigatório'
    ),
};

export const finishSchema = {
    integracoesPagamentosVendedoresIds: Yup.string().nullable().required('Esta conta ainda não foi integrada'),
};

export const simplifiedPersonDataSchema = {
    email: Yup.string().nullable().required('O e-mail é obrigatório'),
    nome: Yup.string().nullable().required('O nome é obrigatório'),
};