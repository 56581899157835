import { useMemo, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { BiPrinter } from 'react-icons/bi';
import { useQuery } from 'react-query';

import {
    QUERIES_OWNER,
    ROUTES_ACCOUNT,
    ROUTES_OWNER,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import {
    Button,
    CheckBox,
    HelperBoolean,
    HelperDate,
    HelperMoney,
    HelperOptions,
    Row,
} from 'components/atoms';
import { Modal } from 'components/molecules';
import { InvoiceSettlementDetails, Table } from 'components/organisms';

import {
    getOwnerInvoiceSettlementIndex,
} from 'services';

import { InvoiceListWrap } from './styles';
import Layout from '../../Layout';

import type { IAInvoiceSettlement } from '@types';

const InvoiceSettlement = () => {
    const { getLinkWithSlug } = useRealState();

    const [isOpenModalShowInvoices, setIsOpenModalShowInvoices] =
        useState(false);
    const [itemModalShowInvoices, setItemModalShowInvoices] =
        useState<IAInvoiceSettlement>();

    const [enablePrintSelected, setEnabledPrintSelected] = useState(false);
    const [printSelectedItems, setPrintSelectedItems] = useState<number[]>([]);

    const onOpenModalShowInvoices = (item: IAInvoiceSettlement) => {
        setIsOpenModalShowInvoices(true);
        setItemModalShowInvoices(item);
    };

    const onCloseModalShowInvoices = () => {
        setIsOpenModalShowInvoices(false);
        setItemModalShowInvoices(undefined);
    };


    const onHandlePrintSelected = () => {
        setEnabledPrintSelected((state) => !state);
    };

    const getActiveRow = (id: number) =>
        printSelectedItems.some((item) => item === id);

    const onHandleActivePrintSelectedItems = (id: number) => {
        if (getActiveRow(id)) {
            setPrintSelectedItems((state) =>
                state.filter((item) => item !== id)
            );
        } else {
            setPrintSelectedItems((state) => [...state, id]);
        }
    };

    const onRedirectPrint = () => {
        setEnabledPrintSelected(false);
        setPrintSelectedItems([]);

        window.open(
            `${ROUTES_OWNER.INVOICE_SETTLEMENT.PRINT.fullPath
            }/${printSelectedItems.join(',')}`,
            '_blank'
        );
    };


    const { data, isLoading: isLoadingList } = useQuery(
        [QUERIES_OWNER.INVOICE_SETTLEMENT.base],
        () => getOwnerInvoiceSettlementIndex(),
        { refetchOnMount: true }

    );

    const getSelectedAllItems = () =>
        printSelectedItems.length === data?.length || false;

    const onHandleSelectedAllItems = () => {
        if (getSelectedAllItems()) {
            setPrintSelectedItems([]);
        } else {
            setPrintSelectedItems(data?.map((item) => item.id) || []);
        }
    };


    const columns = useMemo(() => {
        const defaultColumns = [
            {
                dataField: 'id',
                text: 'ID',
                sort: true,
                headerStyle: { width: '10%' },
            },
            {
                dataField: 'credores',
                text: 'Credor',
                sort: true,
                formatter: (fieldValue: IAInvoiceSettlement['credores']) =>
                    fieldValue?.nome,
                headerStyle: { width: '35%' },
            },
            {
                dataField: 'data',
                text: 'Data do acerto',
                headerStyle: { width: '15%' },
                formatter: (fieldValue: IAInvoiceSettlement['data']) => (
                    <HelperDate value={fieldValue} />
                ),
            },
            {
                dataField: 'valor',
                text: 'Valor',
                formatter: (fieldValue: IAInvoiceSettlement['valor']) => (
                    <HelperMoney value={fieldValue} />
                ),
                headerStyle: { width: '20%' },
            },

            {
                dataField: 'status',
                text: 'Paga',
                formatter: (fieldValue: IAInvoiceSettlement['status']) => (
                    <HelperBoolean value={fieldValue === 'PAGA'} />
                ),
                headerStyle: { width: '10%' },
            },

            {
                dataField: 'id',
                text: 'Opções',
                formatter: (
                    fieldValue: IAInvoiceSettlement['id'],
                    index: any,
                    row: IAInvoiceSettlement
                ) => (
                    <HelperOptions
                        editFunction={() => onOpenModalShowInvoices(row)}
                        editIcon={<FiEye />}
                        remove={false}
                    />
                ),
                headerStyle: { width: '10%' },

            },
        ];

        if (enablePrintSelected) {
            return [
                {
                    dataField: 'id',
                    text: '',
                    formatterText: (
                        <CheckBox
                            onClick={onHandleSelectedAllItems}
                            isActive={getSelectedAllItems()}
                        />
                    ),
                    headerStyle: { width: '1rem', height: '25px' },
                    formatter: (fieldValue: IAInvoiceSettlement['id']) => (
                        <CheckBox
                            key={fieldValue}
                            onClick={() =>
                                onHandleActivePrintSelectedItems(fieldValue)
                            }
                            isActive={getActiveRow(fieldValue)}
                        />
                    ),
                },
                ...defaultColumns,
            ];
        }

        return defaultColumns;
    }, [data, enablePrintSelected, printSelectedItems]);

    return (
        <Layout
            titlePage="Painel Proprietário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Acertos',
                    href: getLinkWithSlug(
                        ROUTES_OWNER.INVOICE_SETTLEMENT.fullPath
                    ),
                },
            ]}
            title="Acertos"
            buttonTitle={
                <Row>
                    {enablePrintSelected &&
                        printSelectedItems &&
                        printSelectedItems.length > 0 && (
                            <Button
                                color="septernary"
                                onClick={() => onRedirectPrint()}
                            >
                                <BiPrinter />
                                Imprimir
                            </Button>
                        )}
                    <Button
                        onClick={onHandlePrintSelected}
                        color={enablePrintSelected ? 'danger' : 'septernary'}
                    >
                        <BiPrinter />
                        {enablePrintSelected ? 'Cancelar' : 'Selecionar'}
                    </Button>

                </Row>
            }
        >
            <InvoiceListWrap className="invoices-template">
                <Table
                    isLoading={isLoadingList}
                    columns={columns}
                    data={data}
                />
            </InvoiceListWrap>

            <Modal
                open={isOpenModalShowInvoices}
                onCloseModal={() => onCloseModalShowInvoices()}
                title="Fatura"
                size="lg"
            >
                <InvoiceSettlementDetails data={itemModalShowInvoices} />
            </Modal>

        </Layout>
    );
};

export default InvoiceSettlement;
