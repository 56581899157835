import styled from 'styled-components';

export const PersonTagFormWrap = styled.div`
    display: block;
    width: 100%;
    overflow: auto;

    .shadow-atom {
        padding-bottom: 0.5rem;
    }
`;
