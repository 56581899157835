import { generateURL } from './prefix';
import api from '../api';

export const getAdministratorRealStateIndex = async () => {
    const url = generateURL(`/imobiliarias`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorRealStateShow = async (id: number | string) => {
    const url = generateURL(`/imobiliarias/${id}`);

    const { data } = await api.get(url);

    return data;
};
