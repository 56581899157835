import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { QUERIES_REAL_STATE, ROUTES_REAL_STATE } from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Container, TitlePage, Row, Button } from 'components/atoms';
import { SidebarList } from 'components/molecules';
import { HeaderPages } from 'components/organisms';

import { getRealStateIntegrationRegister } from 'services';
import { RealStateLayoutWrap } from './styles';

import type { RealStateLayoutInterface } from './interface';

const RealStateLayout = ({
    titlePage,
    breadcrumbs,
    title,
    children,
    buttonTitle,
}: RealStateLayoutInterface) => {
    const { getLinkWithSlug } = useRealState();

    const {
        data: dataRealState,
    } = useQuery([QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base], () =>
        getRealStateIntegrationRegister(),
        { refetchOnMount: true }
    );

    const menus = useMemo(() => {
        const defaultMenus = [

            {
                label: 'Minha Conta',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.ACCOUNT.fullPath
                ),
            },
            {
                label: 'Imóveis',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.PROPERTY.fullPath
                ),
            },
            {
                label: 'Financeiro',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.FINANCIAL.fullPath
                ),
            },
            {
                label: 'Contratos Alugueis',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.RENT_CONTRACT.fullPath
                ),
            },
            {
                label: 'Lançamentos',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.PROPERTY_EXPENSE.fullPath
                ),
            },
            {
                label: 'Faturas',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.INVOICE_PROPERTY.fullPath
                ),
            },
            {
                label: 'Acertos',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.INVOICE_SETTLEMENT.fullPath
                ),
            },
            {
                label: 'Proprietários',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.OWNER.fullPath
                ),
            },
            {
                label: 'Contatos',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.CONTACT.fullPath
                ),
            },
            {
                label: 'Banners',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.BANNER.fullPath
                ),
            },
            {
                label: 'Seguradoras',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.INSURANCE_COMPANY.fullPath
                ),
            },
            {
                label: 'Configurações',
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.SETTING.fullPath
                ),
            },
        ];

        if (dataRealState?.pessoas?.tags?.some((tag) => tag?.ligaTags?.slug === 'cadastro-aprovado')) {
            return defaultMenus;
        }

        return [
            {
                label: <Row
                    style={{
                        alignItems: 'center',
                    }}
                >Integração <Button size='mini' color='septernary' style={{ margin: '0 0 0 1rem' }}>Pendente</Button></Row>,
                route: getLinkWithSlug(
                    ROUTES_REAL_STATE.INTEGRATION_REGISTER.fullPath
                ),
            },
            ...defaultMenus,
        ]
    }, [dataRealState])

    return (
        <RealStateLayoutWrap className="real-state-layout-template">
            <HeaderPages label={titlePage} breadcrumbs={breadcrumbs} />

            <Container>
                <Row>
                    <SidebarList
                        data={menus}
                    />

                    <div className="real-state-layout-template-content">
                        <TitlePage button={buttonTitle}>{title}</TitlePage>
                        {children}
                        <div className="real-state-layout-template-footer">
                            {buttonTitle}
                        </div>
                    </div>
                </Row>
            </Container>
        </RealStateLayoutWrap>
    );
};

export default RealStateLayout;
