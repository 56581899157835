import { generateURL } from './prefix'
import api from '../api';

import type { IARealStateContact } from '@types';

export const getRealStateContactIndex = async (): Promise<
    IARealStateContact[]
> => {
    const url = generateURL(`/contatos`);

    const { data } = await api.get(url);

    return data;
};

export const removeRealStateContact = async (id: number) => {
    const url = generateURL(`/contatos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
