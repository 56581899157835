import { Navigate } from 'react-router-dom';
import { MasterLayout, NoLayout } from 'components/templates';

import { ROUTES_ACCOUNT, ROUTES_ADMIN, ROUTES_AUTH, ROUTES_REAL_STATE, ROUTES_CUSTOMER, ROUTES_OWNER } from '@constants';

import AccountAccess from 'pages/AccountAccess';
import AuthPasswordRecovery from 'pages/Auth/PasswordRecovery';
import AuthRecoveryCode from 'pages/Auth/RecoveryCode';
import AuthSignIn from 'pages/Auth/SignIn';
import AuthSignOut from 'pages/Auth/SignOut';
import AuthSignUp from 'pages/Auth/SignUp';

import RealStateAccount from 'pages/RealState/Account';

import RealStateInvoicePropertyList from 'pages/RealState/InvoiceProperty/List';
import RealStateInvoiceSettlementList from 'pages/RealState/InvoiceSettlement/List';
import RealStateInvoiceSettlementPrint from 'pages/RealState/InvoiceSettlement/Print';

import RealStateOwnerList from 'pages/RealState/Owner/List';
import RealStateOwnerForm from 'pages/RealState/Owner/Form';
import RealStateOwnerNewForm from 'pages/RealState/Owner/NewForm';
import RealStateOwnerSimplifiedForm from 'pages/RealState/Owner/SimplifiedForm';

import RealStatePropertyExpenseForm from 'pages/RealState/PropertyExpense/Form';
import RealStatePropertyExpenseList from 'pages/RealState/PropertyExpense/List';

import RealStateRentContractForm from 'pages/RealState/RentContract/Form';
import RealStateRentContractList from 'pages/RealState/RentContract/List';

import RealStatePropertyList from 'pages/RealState/Property/List';
import RealStatePropertyForm from 'pages/RealState/Property/Form';
import RealStatePropertyNewForm from 'pages/RealState/Property/NewForm';

import RealStateBannerForm from 'pages/RealState/Banner/Form';
import RealStateBannerList from 'pages/RealState/Banner/List';

import RealStateContactList from 'pages/RealState/Contact/List';

import RealStateInsuranceCompanyForm from 'pages/RealState/InsuranceCompany/Form';
import RealStateInsuranceCompanyList from 'pages/RealState/InsuranceCompany/List';

import RealStateSetting from 'pages/RealState/Setting';

import RealStateIntegrationRegister from 'pages/RealState/IntegrationRegister';

import RealStateFinancialForm from 'pages/RealState/Financial/Form';
import RealStateFinancialList from 'pages/RealState/Financial/List';


import AdministratorAccount from 'pages/Administrator/Account';

import AdministratorPersonTagForm from 'pages/Administrator/PersonTag/Form';
import AdministratorPersonTagList from 'pages/Administrator/PersonTag/List';

import AdministratorRealStateList from 'pages/Administrator/RealState/List';
import AdministratorRealStateForm from 'pages/Administrator/RealState/Form';

import AdministratorPlanList from 'pages/Administrator/Plan/List';
import AdministratorPlanForm from 'pages/Administrator/Plan/Form';

import AdministratorMethodsPaymentsList from 'pages/Administrator/MethodsPayments/List';
import AdministratorMethodsPaymentsForm from 'pages/Administrator/MethodsPayments/Form';

import AdministratorPricesIndexsList from 'pages/Administrator/PricesIndexs/List';
import AdministratorPricesIndexsForm from 'pages/Administrator/PricesIndexs/Form';

import AdministratorPropertiesAttributesList from 'pages/Administrator/PropertiesAttributes/List';
import AdministratorPropertiesAttributesForm from 'pages/Administrator/PropertiesAttributes/Form';

import AdministratorPropertiesTypesList from 'pages/Administrator/PropertiesTypes/List';
import AdministratorPropertiesTypesForm from 'pages/Administrator/PropertiesTypes/Form';

import AdministratorValueDetailList from 'pages/Administrator/ValueDetail/List';
import AdministratorValueDetailForm from 'pages/Administrator/ValueDetail/Form';

import CustomerAccount from 'pages/Customer/Account';
import CustomerRentContract from 'pages/Customer/RentContract';

import OwnerAccount from 'pages/Owner/Account';
import OwnerInvoiceSettlementList from 'pages/Owner/InvoiceSettlement/List';
import OwnerInvoiceSettlementPrint from 'pages/Owner/InvoiceSettlement/Print';

import OwnerPropertyInviteList from 'pages/Owner/PropertyInvite/List';
import OwnerRealStateInviteList from 'pages/Owner/RealStateInvite/List';

const routes = (isSigned: boolean) => [
    {
        path: '/',
        element: <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
    },
    // SIGNIN ROUTES
    {
        path: ROUTES_AUTH.path,
        element: <MasterLayout />,
        children: [
            { path: ROUTES_AUTH.SIGN_IN.path, element: <AuthSignIn /> },
            { path: ROUTES_AUTH.PASSWORD_RECOVERY.path, element: <AuthPasswordRecovery/> },
            { path: `${ROUTES_AUTH.RECOVERY_CODE.path}/:email`, element: <AuthRecoveryCode/>},
            { path: ROUTES_AUTH.SIGN_OUT.path, element: <AuthSignOut /> },
            { path: ROUTES_AUTH.SIGN_UP.path, element: <AuthSignUp /> },
        ],
    },
    {
        path: ROUTES_ACCOUNT.path,
        element: isSigned ? <MasterLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            { path: ROUTES_ACCOUNT.SELECTED.path, element: <AccountAccess /> },
        ],
    },
    // REAL STATE NO LAYOUT
    {
        path: ROUTES_REAL_STATE.path,
        element: isSigned ? <NoLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: `${ROUTES_REAL_STATE.INVOICE_SETTLEMENT.PRINT.path}/:ids`,
                element: <RealStateInvoiceSettlementPrint />,
            },
        ]
    },
    // REAL STATE ROUTES
    {
        path: ROUTES_REAL_STATE.path,
        element: isSigned ? <MasterLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: ROUTES_REAL_STATE.ACCOUNT.path,
                element: <RealStateAccount />,
            },
            {
                path: ROUTES_REAL_STATE.INVOICE_PROPERTY.path,
                element: <RealStateInvoicePropertyList />,
            },
            {
                path: ROUTES_REAL_STATE.INVOICE_SETTLEMENT.path,
                element: <RealStateInvoiceSettlementList />,
            },

            {
                path: ROUTES_REAL_STATE.OWNER.path,
                element: <RealStateOwnerList />,
            },
            {
                path: `${ROUTES_REAL_STATE.OWNER.FORM.path}/:id`,
                element: <RealStateOwnerForm />,
            },
            {
                path: ROUTES_REAL_STATE.OWNER.NEW_FORM.path,
                element: <RealStateOwnerNewForm />,
            },
            {
                path: ROUTES_REAL_STATE.OWNER.SIMPLIFIED_FORM.path,
                element: <RealStateOwnerSimplifiedForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.PROPERTY_EXPENSE.FORM.path}`,
                element: <RealStatePropertyExpenseForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.PROPERTY_EXPENSE.FORM.path}/:id`,
                element: <RealStatePropertyExpenseForm />,
            },
            {
                path: ROUTES_REAL_STATE.PROPERTY_EXPENSE.path,
                element: <RealStatePropertyExpenseList />,
            },
            {
                path: `${ROUTES_REAL_STATE.RENT_CONTRACT.FORM.path}/:id`,
                element: <RealStateRentContractForm />,
            },
            {
                path: ROUTES_REAL_STATE.RENT_CONTRACT.path,
                element: <RealStateRentContractList />,
            },
            {
                path: `${ROUTES_REAL_STATE.PROPERTY.NEW_FORM.path}`,
                element: <RealStatePropertyNewForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.PROPERTY.NEW_FORM.path}/:id`,
                element: <RealStatePropertyNewForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.PROPERTY.FORM.path}/:id`,
                element: <RealStatePropertyForm />,
            },
            {
                path: ROUTES_REAL_STATE.PROPERTY.path,
                element: <RealStatePropertyList />,
            },

            {
                path: `${ROUTES_REAL_STATE.BANNER.FORM.path}`,
                element: <RealStateBannerForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.BANNER.FORM.path}/:id`,
                element: <RealStateBannerForm />,
            },
            {
                path: ROUTES_REAL_STATE.BANNER.path,
                element: <RealStateBannerList />,
            },

            {
                path: ROUTES_REAL_STATE.CONTACT.path,
                element: <RealStateContactList />,
            },

            {
                path: `${ROUTES_REAL_STATE.INSURANCE_COMPANY.FORM.path}`,
                element: <RealStateInsuranceCompanyForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.INSURANCE_COMPANY.FORM.path}/:id`,
                element: <RealStateInsuranceCompanyForm />,
            },
            {
                path: ROUTES_REAL_STATE.INSURANCE_COMPANY.path,
                element: <RealStateInsuranceCompanyList />,
            },

            {
                path: ROUTES_REAL_STATE.SETTING.path,
                element: <RealStateSetting />,
            },
            {
                path: ROUTES_REAL_STATE.INTEGRATION_REGISTER.path,
                element: <RealStateIntegrationRegister />,
            },

            {
                path: `${ROUTES_REAL_STATE.FINANCIAL.FORM.path}`,
                element: <RealStateFinancialForm />,
            },
            {
                path: `${ROUTES_REAL_STATE.FINANCIAL.FORM.path}/:id`,
                element: <RealStateFinancialForm />,
            },
            {
                path: ROUTES_REAL_STATE.FINANCIAL.path,
                element: <RealStateFinancialList />,
            },
        ],
    },
    // ADMINISTRATOR ROUTES
    {
        path: ROUTES_ADMIN.path,
        element: isSigned ? <MasterLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: ROUTES_ADMIN.ACCOUNT.path,
                element: <AdministratorAccount />,
            },
            {
                path: ROUTES_ADMIN.USER_TAG.FORM.path,
                element: <AdministratorPersonTagForm />,
            },
            {
                path: `${ROUTES_ADMIN.USER_TAG.FORM.path}/:id`,
                element: <AdministratorPersonTagForm />,
            },
            {
                path: ROUTES_ADMIN.USER_TAG.path,
                element: <AdministratorPersonTagList />,
            },
            {
                path: ROUTES_ADMIN.REAL_STATE.path,
                element: <AdministratorRealStateList />,
            },
            {
                path: `${ROUTES_ADMIN.REAL_STATE.FORM.path}/:id`,
                element: <AdministratorRealStateForm />,
            },
            {
                path: ROUTES_ADMIN.PLAN.path,
                element: <AdministratorPlanList />,
            },
            {
                path: `${ROUTES_ADMIN.PLAN.FORM.path}`,
                element: <AdministratorPlanForm />,
            },
            {
                path: `${ROUTES_ADMIN.PLAN.FORM.path}/:id`,
                element: <AdministratorPlanForm />,
            },
            {
                path: ROUTES_ADMIN.METHODS_PAYMENTS.path,
                element: <AdministratorMethodsPaymentsList />,
            },
            {
                path: `${ROUTES_ADMIN.METHODS_PAYMENTS.FORM.path}`,
                element: <AdministratorMethodsPaymentsForm />,
            },
            {
                path: `${ROUTES_ADMIN.METHODS_PAYMENTS.FORM.path}/:id`,
                element: <AdministratorMethodsPaymentsForm />,
            },
            {
                path: ROUTES_ADMIN.PRICES_INDEXS.path,
                element: <AdministratorPricesIndexsList />,
            },
            {
                path: `${ROUTES_ADMIN.PRICES_INDEXS.FORM.path}`,
                element: <AdministratorPricesIndexsForm />,
            },
            {
                path: `${ROUTES_ADMIN.PRICES_INDEXS.FORM.path}/:id`,
                element: <AdministratorPricesIndexsForm />,
            },
            {
                path: ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.path,
                element: <AdministratorPropertiesAttributesList />,
            },
            {
                path: `${ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.FORM.path}`,
                element: <AdministratorPropertiesAttributesForm />,
            },
            {
                path: `${ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.FORM.path}/:id`,
                element: <AdministratorPropertiesAttributesForm />,
            },
            {
                path: ROUTES_ADMIN.PROPERTIES_TYPES.path,
                element: <AdministratorPropertiesTypesList />,
            },
            {
                path: `${ROUTES_ADMIN.PROPERTIES_TYPES.FORM.path}`,
                element: <AdministratorPropertiesTypesForm />,
            },
            {
                path: `${ROUTES_ADMIN.PROPERTIES_TYPES.FORM.path}/:id`,
                element: <AdministratorPropertiesTypesForm />,
            },
            {
                path: ROUTES_ADMIN.VALUE_DETAIL.path,
                element: <AdministratorValueDetailList />,
            },
            {
                path: `${ROUTES_ADMIN.VALUE_DETAIL.FORM.path}`,
                element: <AdministratorValueDetailForm />,
            },
            {
                path: `${ROUTES_ADMIN.VALUE_DETAIL.FORM.path}/:id`,
                element: <AdministratorValueDetailForm />,
            }
        ],
    },
    // CUSTOMER ROUTES
    {
        path: ROUTES_CUSTOMER.path,
        element: isSigned ? <MasterLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: ROUTES_CUSTOMER.ACCOUNT.path,
                element: <CustomerAccount />,
            },
            {
                path: `${ROUTES_CUSTOMER.RENT_CONTRACT.path}/:id`,
                element: <CustomerRentContract />,
            },
            {
                path: `${ROUTES_CUSTOMER.RENT_CONTRACT.NEW.path}/:slug`,
                element: <CustomerRentContract />,
            },
        ],
    },
    // OWNER ROUTES NO LAYOUT
    {
        path: ROUTES_OWNER.path,
        element: isSigned ? <NoLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: `${ROUTES_OWNER.INVOICE_SETTLEMENT.PRINT.path}/:ids`,
                element: <OwnerInvoiceSettlementPrint />,
            },
            {
                path: `${ROUTES_OWNER.PROPERTY_INVITE.path}`,
                element: <OwnerPropertyInviteList />,
            },
            {
                path: `${ROUTES_OWNER.REAL_STATE_INVITE.path}`,
                element: <OwnerRealStateInviteList />,
            },
        ]
    },

    // OWNER ROUTES
    {
        path: ROUTES_OWNER.path,
        element: isSigned ? <MasterLayout /> : <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />,
        children: [
            {
                path: ROUTES_OWNER.ACCOUNT.path,
                element: <OwnerAccount />,
            },
            {
                path: ROUTES_OWNER.INVOICE_SETTLEMENT.path,
                element: <OwnerInvoiceSettlementList />,
            },

        ],
    },
];

export default routes;
