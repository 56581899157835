import styled from 'styled-components';

export const RecoveryCodeWrap = styled.section``;

export const InputCodeWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 4.5rem;

`;
