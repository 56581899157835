import { Helmet } from 'react-helmet';

import { RealStateRentContractForm } from 'components/templates';

const RealStateRentContractFormPage = () => (
    <>
        <Helmet>
            <title>Proprietários - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateRentContractForm />
    </>
);

export default RealStateRentContractFormPage;
