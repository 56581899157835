import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { BoxInfo, H2, Row } from 'components/atoms';

import {
  PropertyHorizontal,
  PropertyHorizontalOptions,
} from 'components/molecules';

import { useRealState } from 'contexts/RealStateContext';
import {
  getCustomerRentContractList,
  changeStatusCustomerRentContract,
} from 'services';

import { sweetAlert } from 'utils';

import { QUERIES_CUSTOMER, ROUTES_ACCOUNT, ROUTES_CUSTOMER } from '@constants';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { AccountWrap } from './styles';
import Layout from '../Layout';

const Account = () => {
  const { getLinkWithSlug } = useRealState();
  const navigate = useNavigate();

  const theme = useTheme();

  const queryClient = useQueryClient();

  const title = 'Processo de Aluguel';

  const { data: dataList, isLoading: isLoadingGet } = useQuery(
    QUERIES_CUSTOMER.RENT_CONTRACT.base,
    () => getCustomerRentContractList(),
    { refetchOnMount: true }
  );

  function onClickNavigate(id: number) {
    navigate(
      getLinkWithSlug(`${ROUTES_CUSTOMER.RENT_CONTRACT.fullPath}/${id}`)
    );
  }

  const { mutate: mutateCancelRegister } = useMutation(
    (proccessId: number) =>
      changeStatusCustomerRentContract(proccessId, {
        status: 'CANCELADO',
      }),
    {
      mutationKey: QUERIES_CUSTOMER.RENT_CONTRACT.base,
      onSuccess: () => {
        onSuccessMessage(
          title,
          'Sucesso ao cancelar o processo de aluguel!'
        );

        queryClient.refetchQueries(QUERIES_CUSTOMER.RENT_CONTRACT.base);
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  function cancelRegisterRequest(id: number) {
    sweetAlert
      .fire({
        title: 'Você tem certeza?',
        text: 'Se você confirmar este contrato será cancelado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2ba2e3',
        cancelButtonColor: '#FF4D4D',
        confirmButtonText: 'Sim, remover registro!',
        cancelButtonText: 'Não, cliquei errado!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          mutateCancelRegister(id);
        }
      });
  }

  function getContractsFromStatus(status: string[]) {
    return (
      (Array.isArray(dataList) &&
        dataList?.filter((item) => status.includes(item.status))) ||
      []
    );
  }

  return (
    <Layout
      titlePage="Painel do Cliente"
      breadcrumbs={[
        {
          label: 'Home',
          href: ROUTES_ACCOUNT.SELECTED.fullPath,
        },
        {
          label: 'Painel do Cliente',
          href: ROUTES_CUSTOMER.ACCOUNT.fullPath,
        },
      ]}
    >
      <AccountWrap className="account-template">
        <Row className="row-title">
          <H2 className="number">
            Imóveis <strong>em processo de aluguel</strong>
          </H2>

          <span className="number-items number">
            <strong>
              {getContractsFromStatus(['PENDENTE']).length}
            </strong>{' '}
            imóveis
          </span>
        </Row>

        {getContractsFromStatus(['PENDENTE']).length === 0 ? (
          <BoxInfo>
            {isLoadingGet ? (
              <ReactLoading
                type="spinningBubbles"
                height={18}
                width={18}
                className="loader"
                color={theme.colors.primary}
              />
            ) : (
              'NÃO HÁ NENHUM IMÓVEL EM PROCESSO DE ALUGUEL'
            )}
          </BoxInfo>
        ) : (
          <>
            {getContractsFromStatus(['PENDENTE']).map(
              (rentContractItem) =>
                rentContractItem.imoveisPersonalizacoes.map(
                  ({ ligaImoveisPersonalizacoes }) => (
                    <PropertyHorizontalOptions
                      addressCity={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.cidade
                          ?.nome
                      }
                      addressNeightborhood={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.nome
                      }
                      addressState={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.cidade?.uf
                      }
                      attributes={ligaImoveisPersonalizacoes?.imoveis?.atributos
                        .filter(
                          (item) =>
                            item?.ligaAtributos
                              ?.destaque
                        )
                        .slice(0, 3)
                        .map((atributo) => ({
                          label: atributo
                            ?.ligaAtributos
                            ?.descricao,
                          value: atributo?.valor,
                          type:
                            atributo?.ligaAtributos
                              ?.icone ||
                            'DEFAULT',
                        }))}
                      typeValueProperty="VALOR ALUGUEL"
                      valueDetails={ligaImoveisPersonalizacoes?.imoveis?.detalhesDeValores?.map(
                        (detalheValores) => ({
                          label:
                            detalheValores
                              ?.ligaDetalhes
                              ?.nome || '',
                          value:
                            detalheValores?.valor ||
                            '',
                        })
                      )}
                      valueProperty={
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.valorAluguel
                      }
                      imageCode={
                        ligaImoveisPersonalizacoes?.id
                      }
                      imageDescription={
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.tipos?.descricao
                      }
                      imageUrl={
                        ligaImoveisPersonalizacoes?.imoveis?.imagens?.find(
                          (item) => item?.destaque
                        )?.url ||
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.imagens?.[0]?.url
                      }
                      key={ligaImoveisPersonalizacoes?.id}
                      onClickScheduled={() =>
                        onClickNavigate(
                          rentContractItem?.id
                        )
                      }
                      onClickContinue={() =>
                        onClickNavigate(
                          rentContractItem?.id
                        )
                      }
                      onClickCancel={() =>
                        cancelRegisterRequest(
                          rentContractItem?.id
                        )
                      }
                    />
                  )
                )
            )}
          </>
        )}

        <Row className="row-title">
          <H2 className="number">
            Imóveis <strong>alugados</strong>
          </H2>

          <span className="number-items number">
            <strong>
              {
                getContractsFromStatus(['FINALIZADO', 'ATIVO'])
                  .length
              }
            </strong>{' '}
            imóveis
          </span>
        </Row>

        {getContractsFromStatus(['FINALIZADO', 'ATIVO']).length ===
          0 ? (

          <BoxInfo>
            {isLoadingGet ? (
              <ReactLoading
                type="spinningBubbles"
                height={18}
                width={18}
                className="loader"
                color={theme.colors.primary}

              />
            ) : (
              'NÃO HÁ NENHUM IMÓVEL COM ALUGUEL ATIVO'
            )}</BoxInfo>
        ) : (
          <>
            {getContractsFromStatus(['FINALIZADO', 'ATIVO']).map(
              (rentContractItem) =>
                rentContractItem.imoveisPersonalizacoes.map(
                  ({ ligaImoveisPersonalizacoes }) => (
                    <PropertyHorizontal
                      addressCity={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.cidade
                          ?.nome
                      }
                      addressNeightborhood={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.nome
                      }
                      addressState={
                        ligaImoveisPersonalizacoes
                          ?.imoveis.bairros?.cidade?.uf
                      }
                      attributes={ligaImoveisPersonalizacoes?.imoveis?.atributos
                        ?.filter(
                          (item) =>
                            item?.ligaAtributos
                              ?.destaque
                        )
                        ?.slice(0, 3)
                        ?.map((atributo) => ({
                          label: atributo
                            ?.ligaAtributos
                            ?.descricao,
                          value: atributo?.valor,
                          type:
                            atributo?.ligaAtributos
                              ?.icone ||
                            'DEFAULT',
                        }))}
                      typeValueProperty="VALOR ALUGUEL"
                      valueDetails={ligaImoveisPersonalizacoes?.imoveis?.detalhesDeValores?.map(
                        (detalheValores) => ({
                          label:
                            detalheValores
                              ?.ligaDetalhes
                              ?.nome || '',
                          value:
                            detalheValores?.valor ||
                            '',
                        })
                      )}
                      valueProperty={
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.valorAluguel
                      }
                      imageCode={
                        ligaImoveisPersonalizacoes.id
                      }
                      imageDescription={
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.tipos?.descricao
                      }
                      imageUrl={
                        ligaImoveisPersonalizacoes?.imoveis?.imagens?.find(
                          (item) => item?.destaque
                        )?.url ||
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.imagens?.[0]?.url
                      }
                      key={ligaImoveisPersonalizacoes.id}
                    />
                  )
                )
            )}
          </>
        )}

        <Row className="row-title">
          <H2 className="number">
            Imóveis <strong>com Processos cancelados</strong>
          </H2>

          <span className="number-items number">
            <strong>
              {getContractsFromStatus(['CANCELADO']).length}
            </strong>{' '}
            processos
          </span>
        </Row>

        {getContractsFromStatus(['CANCELADO']).length === 0 ? (
          <BoxInfo>
            {isLoadingGet ? (
              <ReactLoading
                type="spinningBubbles"
                height={18}
                width={18}
                className="loader"
                color={theme.colors.primary}
              />
            ) : (
              'NÃO HÁ NENHUM IMÓVEL COM PROCESSO CANCELADO'
            )}

          </BoxInfo>
        ) : (
          <>
            {getContractsFromStatus(['CANCELADO']).map(
              (rentContractItem) =>
                rentContractItem.imoveisPersonalizacoes.map(
                  ({ ligaImoveisPersonalizacoes }) => (
                    <PropertyHorizontal
                      addressCity={
                        ligaImoveisPersonalizacoes
                          .imoveis.bairros?.cidade
                          ?.nome
                      }
                      addressNeightborhood={
                        ligaImoveisPersonalizacoes
                          .imoveis.bairros?.nome
                      }
                      addressState={
                        ligaImoveisPersonalizacoes
                          .imoveis.bairros?.cidade?.uf
                      }
                      attributes={ligaImoveisPersonalizacoes.imoveis.atributos
                        .filter(
                          (item) =>
                            item?.ligaAtributos
                              ?.destaque
                        )
                        .slice(0, 3)
                        .map((atributo) => ({
                          label: atributo
                            ?.ligaAtributos
                            ?.descricao,
                          value: atributo?.valor,
                          type:
                            atributo?.ligaAtributos
                              ?.icone ||
                            'DEFAULT',
                        }))}
                      typeValueProperty="VALOR ALUGUEL"
                      valueDetails={ligaImoveisPersonalizacoes.imoveis.detalhesDeValores?.map(
                        (detalheValores) => ({
                          label:
                            detalheValores
                              ?.ligaDetalhes
                              ?.nome || '',
                          value:
                            detalheValores?.valor ||
                            '',
                        })
                      )}
                      valueProperty={
                        ligaImoveisPersonalizacoes
                          .imoveis.valorAluguel
                      }
                      imageCode={
                        ligaImoveisPersonalizacoes.id
                      }
                      imageDescription={
                        ligaImoveisPersonalizacoes
                          .imoveis.tipos.descricao
                      }
                      imageUrl={
                        ligaImoveisPersonalizacoes?.imoveis?.imagens?.find(
                          (item) => item?.destaque
                        )?.url ||
                        ligaImoveisPersonalizacoes
                          ?.imoveis?.imagens?.[0]?.url
                      }
                      key={ligaImoveisPersonalizacoes.id}
                    />
                  )
                )
            )}
          </>
        )}
      </AccountWrap>
    </Layout>
  );
};

export default Account;
