import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .buttons {
        border-radius: 0.313rem;
        margin-bottom: 1.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 3.281rem;

        button,
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            padding: 0.313rem 0.625rem;
            background-color: ${(props) => props.theme.colors.senary};
            border-radius: 0.25rem;
            margin-right: 0.625rem;
            border: 0.063rem solid
                ${(props) => props.theme.colors.borderPrimary};

            svg {
                height: 1rem;
                width: 1rem;
            }
        }

        .icon-space {
            margin-left: auto;
            margin-right: 0.469rem;
        }
    }

    textarea,
    .box {
        width: 100%;
        height: 25rem !important;
        font-weight: 400 !important;
        font-size: 1rem;

        strong {
            font-weight: 700 !important;
        }
    }

    textarea {
        height: 24.63rem !important;
    }
`;
