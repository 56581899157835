import styled from 'styled-components';

export const AccountTypeWrap = styled.button`
    margin-right: 0.938rem;
    padding: 2.5rem 4.5rem;
    border: 0.063rem solid ${(props) => props.theme.colors.borderQuaternary};
    background-color: ${(props) => props.theme.colors.senaryLight};
    position: relative;

    transition: background-color 0.5s ease;

    > .label {
        font-size: 0.938rem;
        line-height: 110%;
        color: ${(props) => props.theme.colors.tertiaryLight};
        font-weight: 900;
        text-transform: uppercase;

        &:after {
            content: '';
            display: block;
            width: 3.125rem;
            height: 0.188rem;
            margin: 0.938rem auto 1.25rem auto;
            background-color: ${(props) => props.theme.colors.secondary};
        }
    }

    > .icon {
        display: block;
        margin-bottom: 0.625rem;
        font-size: 2.25rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.tertiaryLight};

        strong {
            font-size: 3.125rem;
            font-weight: 900;
        }
    }
    > .realstate {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.125rem;
        font-size: 1.063rem;
        font-weight: 300;
        color: ${(props) => props.theme.colors.tertiaryLight};
        width: 9.375rem;
        height: 2.5rem;
    }

    > .call {
        font-size: 0.938rem;
        font-weight: 900;
        color: ${(props) => props.theme.colors.secondaryBold};

        &.none {
            font-weight: 400;
            color: ${(props) => props.theme.colors.textQuaternary};
        }
    }

    &.active,
    &:hover {
        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0.438rem;
            bottom: 0.438rem;
            right: 0.438rem;
            left: 0.438rem;
            border: 1px solid ${(props) => props.theme.colors.secondary};
        }

        background-color: ${(props) => props.theme.colors.tertiary};
        > .label {
            color: ${(props) => props.theme.colors.secondary};
        }

        > .icon {
            color: ${(props) => props.theme.colors.textSenary};
        }

        > .realstate {
            color: ${(props) => props.theme.colors.textSenary};
        }
    }
`;
