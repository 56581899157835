import { InputCapsule } from 'components/atoms';

import { checkEmpty } from 'utils';

import { InputColorWrap } from './styles';

import type { InputColorInterface } from './interface';

const InputColorComponent = ({
    id,
    label,
    name,
    placeholder,
    required,
    value,
    model,
    onChange = () => null,
}: InputColorInterface) => (
    <InputColorWrap className="input-color-molecule">
        <InputCapsule
            htmlFor={id || name}
            label={label}
            required={required}
            empty={checkEmpty(value)}
            model={model}
        >
            <input
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />

            <input
                id={id || name}
                type="color"
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />
        </InputCapsule>
    </InputColorWrap>
);

export default InputColorComponent;
