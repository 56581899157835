import { InputCapsule, Toggle } from 'components/atoms';

import { InputToggleWrap } from './styles';

import type { InputToggleInterface } from './interface';

const InputToggle = ({
    label,
    required,
    name,
    value,
    readOnly,
    labelTrue,
    labelFalse,
    model,
    onChange = () => null,
}: InputToggleInterface) => (
    <InputToggleWrap className="input-text-molecule">
        <InputCapsule label={label} required={required} empty={value} model={model}>
            <Toggle
                labelTrue={labelTrue}
                labelFalse={labelFalse}
                value={value}
                onChange={(param) => onChange(param)}
                readOnly={readOnly}
                name={name}
            />
        </InputCapsule>
    </InputToggleWrap>
);

export default InputToggle;
