import { Helmet } from 'react-helmet';

import { RealStateRentContractList } from 'components/templates';

const RealStateRentContractListPage = () => (
    <>
        <Helmet>
            <title>Contratos de Aluguel - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateRentContractList />
    </>
);

export default RealStateRentContractListPage;
