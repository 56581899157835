import styled, { css } from 'styled-components';

interface InputCapsuleOptionWrapInterface {
    borderLeft: boolean;
    borderRight: boolean;
    borderTop: boolean;
    borderBottom: boolean;

    model: 'primary' | 'secondary';
    row: boolean;
}

const models = {
    primary: css`
        padding: 0;

        label {
            color: ${(props) => props.theme.colors.textSecondary};
            font-weight: 300;
            strong {
                font-weight: 900;
                color: ${(props) => props.theme.colors.textQuinary};
            }
        }
    `,
    secondary: css`
        border-color: ${(props) => props.theme.colors.borderQuaternary};

        label {
            color: ${(props) => props.theme.colors.textSecondary};

            font-weight: 300;
            strong {
                font-weight: 900;
                color: ${(props) => props.theme.colors.textSecondary};
            }
        }
    `,
};

export const InputCapsuleOptionWrap = styled.div<InputCapsuleOptionWrapInterface>`
    width: 100%;
    padding: 1.25rem 1.88rem;
    display: flex;
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
    align-items: ${(props) => (props.row ? 'center' : 'initial')};

    border-left: ${(props) =>
        props.borderLeft ? '0.062rem solid transparent' : 0};
    border-right: ${(props) =>
        props.borderRight ? '0.062rem solid transparent' : 0};
    border-bottom: ${(props) =>
        props.borderBottom ? '0.062rem solid transparent' : 0};
    border-top: ${(props) =>
        props.borderTop ? '0.062rem solid transparent' : 0};

    label {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        line-height: 120%;
        text-transform: uppercase;

        margin-bottom: ${(props) => (props.row ? 0 : '0.68rem')};
        margin-right: ${(props) => (props.row ? '1rem' : 0)};

        span {
            color: #f64e60;
            font-size: 1.5rem;
            font-weight: 700;
        }

        strong {
            margin-left: 0.313rem;
        }
    }

    .input {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ${(props) => models[props.model]};
`;
