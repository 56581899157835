import { Helmet } from 'react-helmet';

import { RealStateBannerForm } from 'components/templates';

const RealStateBannerFormPage = () => (
    <>
        <Helmet>
            <title>Banner - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateBannerForm />
    </>
);

export default RealStateBannerFormPage;
