/* eslint-disable react/jsx-no-constructed-context-values */
import Cookies from 'js-cookie';
import {
    createContext,
    ReactNode,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ROUTES_ACCOUNT, ROUTES_ADMIN, ROUTES_AUTH, ROUTES_CUSTOMER, ROUTES_OWNER, ROUTES_REAL_STATE } from '@constants'

import { useRealState } from 'contexts/RealStateContext';
import { api } from 'services';
import { isAxiosError } from 'helpers';
import { sweetAlert } from 'utils';

import type { IAUser } from '@types';

interface UserSignUpProps {
    nome: string;
    email: string;
    senha: string;
    confirmarSenha: string;
    tipo: string;
}

interface UserSignInProps {
    email: string;
    senha: string;
}

interface AuthContextData {
    isSigned: boolean;
    user: IAUser | undefined;
    token?: string;
    loading: boolean;
    idAccess?: number;
    setIdAccess: (arg1: number) => void;
    signUp: (user: UserSignUpProps) => void;
    signIn: (user: UserSignInProps) => void;
    signOut: () => void;
    onNavigateAccountAccess: (param: string, idAccess: number) => void;
    onHandleNavigateWithNext: (link: string) => void;
    getCredentials: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AuthProvider = ({ children }: AuthProviderProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { getLinkWithSlug } = useRealState();

    const [initStarted, setInitStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idAccess, setIdAccess] = useState<number | undefined>(undefined);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<IAUser | undefined>(undefined);

    function onHandleNavigate(
        param: '/sign/in' | '/sign/up' | '/sign/up/plans' | string
    ) {
        navigate(getLinkWithSlug(param));
    }

    function onHandleNavigateWithNext(link: string) {
        const next = searchParams.get('next');

        const newLink = next ? `${link}?next=${next}` : link;

        onHandleNavigate(newLink);
    }

    function onNavigateAccountAccess(param: string, idAccessParam: number) {
        setIdAccess(idAccessParam);

        const next = searchParams.get('next');

        if (typeof next === 'string') {
            navigate(getLinkWithSlug(next));
            return;
        }

        switch (param) {
            case 'ADMINISTRADOR':
                navigate(getLinkWithSlug(ROUTES_ADMIN.ACCOUNT.fullPath));
                break;

            case 'IMOBILIARIA':
                navigate(getLinkWithSlug(ROUTES_REAL_STATE.ACCOUNT.fullPath));
                break;

            case 'CLIENTE':
                navigate(getLinkWithSlug(ROUTES_CUSTOMER.ACCOUNT.fullPath));
                break;

            case 'PROPRIETARIO':
                navigate(getLinkWithSlug(ROUTES_OWNER.ACCOUNT.fullPath));
                break;

            default:
                navigate(getLinkWithSlug(`/`));
                break;
        }
    }

    async function signUp(data: UserSignUpProps) {
        setLoading(true);

        const schema = Yup.object().shape({
            tipo: Yup.string().nullable().required('O tipo é obrigatório.'),
            confirmarSenha: Yup.string().oneOf(
                [Yup.ref('senha'), null],
                'Senhas não batem!'
            ),
            senha: Yup.string().nullable().required('A senha é obrigatória.'),
            email: Yup.string()
                .nullable()
                .required('O e-mail é obrigatório.')
                .email('Informe um e-mail válido.'),
            nome: Yup.string().nullable().required('O nome é obrigatório.'),
        });

        schema
            .validate(data)
            .then(async () => {
                const res = await api.post('auth/sign_up', data);

                if (res.status === 201) {
                    sweetAlert.fire({
                        title: 'Cadastro!',
                        text: res.data.message,
                        icon: 'success',
                    });

                    const next = searchParams.get('next');

                    if (data.tipo === 'IMOBILIARIA') {
                        // const link = next ? `${ROUTES_AUTH.SIGN_UP.PLANS.fullPath}?next=${next}` : ROUTES_AUTH.SIGN_UP.PLANS.fullPath;
                        const link = next ? `${ROUTES_AUTH.SIGN_IN.fullPath}?next=${next}` : ROUTES_AUTH.SIGN_IN.fullPath;

                        onHandleNavigate(link);
                    } else {
                        const link = next ? `${ROUTES_AUTH.SIGN_IN.fullPath}?next=${next}` : ROUTES_AUTH.SIGN_IN.fullPath;

                        onHandleNavigate(link);
                    }
                } else {
                    sweetAlert.fire({
                        title: 'Cadastro!',
                        text: res.data.message,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                sweetAlert.fire({
                    title: 'Cadastro!',
                    text: err?.response?.data?.message || err?.message,
                    icon: 'error',
                });
            });

        setLoading(false);
    }

    async function signIn(data: UserSignInProps) {
        setLoading(true);

        const schema = Yup.object().shape({
            senha: Yup.string().nullable().required('A senha é obrigatória.'),
            email: Yup.string()
                .nullable()
                .required('O e-mail é obrigatório.')
                .email('Informe um e-mail válido.'),
        });

        schema
            .validate(data)
            .then(async () => {
                const res = await api.post('auth/sign_in', data);

                setUser(res.data.user);
                setToken(res.data.token);

                if (res.status === 200) {
                    sweetAlert.fire({
                        title: 'Acesso!',
                        text: res.data.message,
                        icon: 'success',
                    });

                    const next = searchParams.get('next');

                    const link = next ? `${ROUTES_ACCOUNT.SELECTED.fullPath}?next=${next}` : ROUTES_ACCOUNT.SELECTED.fullPath;

                    onHandleNavigate(link);
                } else {
                    sweetAlert.fire({
                        title: 'Acesso!',
                        text:
                            res.data && res.data.message
                                ? res.data.message
                                : 'Acesso negado!',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                if (isAxiosError(err)) {
                    sweetAlert.fire({
                        title: 'Acesso!',
                        text:
                            err?.response?.data?.message ||
                            err?.message ||
                            'Acesso negado!',
                        icon: 'error',
                    });
                }
            });

        setLoading(false);
    }

    function signOut() {
        setUser(undefined);
        setToken(undefined);
        setIdAccess(undefined);

        Cookies.remove('token');
        Cookies.remove('idAccess');

        api.defaults.headers.common.Authorization = '';
        api.defaults.headers.common['Account-Access'] = '';

        onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath);
    }

    async function getCredentials() {
        setLoading(true);

        try {
            const res = await api.get('/auth/user');

            setUser(res.data);
        } catch (err) {
            if (isAxiosError(err)) {
                if (err?.response?.status) {
                    signOut();
                }

                sweetAlert.fire({
                    title: 'Usuário!',
                    text:
                        err?.response?.data?.message ||
                        err?.message ||
                        'Falha ao buscar dados do usuário!',
                    icon: 'error',
                });
            }
        } finally {
            setLoading(false);
        }
    }

    useLayoutEffect(() => {
        if (initStarted && token !== undefined) {
            Cookies.set('token', String(token));
        }

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }, [token]);

    useLayoutEffect(() => {
        if (initStarted && idAccess !== undefined) {
            Cookies.set('idAccess', String(idAccess));
        }
        api.defaults.headers.common['Account-Access'] = String(idAccess);
    }, [idAccess]);

    useLayoutEffect(() => {
        const initToken = Cookies.get('token');
        const initIdAccess = Cookies.get('idAccess');

        setToken(initToken ? String(initToken) : undefined);
        setIdAccess(initIdAccess ? Number(initIdAccess) : undefined);

        api.defaults.headers.common.Authorization = `Bearer ${initToken}`;
        api.defaults.headers.common['Account-Access'] = String(initIdAccess);

        setInitStarted(true);

        const noAuthRoutes = [ROUTES_AUTH.SIGN_IN.fullPath, ROUTES_AUTH.SIGN_UP.fullPath, ROUTES_AUTH.SIGN_UP.PLANS.fullPath, ROUTES_AUTH.PASSWORD_RECOVERY.fullPath, ROUTES_AUTH.RECOVERY_CODE.fullPath];

        if (
            noAuthRoutes.every(
                (noAuthRoute) => noAuthRoute !== location.pathname
            )
        ) {
            getCredentials();
        }
    }, []);


    return (
        <AuthContext.Provider value={{
            isSigned: Boolean(token || !initStarted),
            user,
            token,
            idAccess,
            loading,
            setIdAccess,
            signUp,
            signIn,
            signOut,
            onNavigateAccountAccess,
            onHandleNavigateWithNext,
            getCredentials,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextData => {
    const context = useContext(AuthContext);

    return context;
};

export { AuthProvider, useAuth };
