interface UserFiles {
    label: string;
    value: string;
}

class UserFilesEnums {
    public userFilesData = [
        {
            label: 'Documento de Identificação',
            value: 'identificacao',
        },
        { label: 'Comprovante de Atividade', value: 'atividade' },
        { label: 'Comprovante de Residência', value: 'residencia' },
    ];

    public getUserFilesItem(param?: string): UserFiles {
        const filtered = this.userFilesData.filter(
            (item) => item.value === param
        )[0];

        return !filtered ? this.userFilesData[0] : filtered;
    }
}

export default new UserFilesEnums();
