import React from 'react'
import {
  Button,
  Card,
  Grid,
  LineLabelValue,
  Attributes,
  CardImage
} from 'components/atoms'
import { convertFloatToBR } from 'utils'
import { PropertyHorizontalOptionsInterface } from './interface'


import { PropertyHorizontalOptionsWrap } from './styles'


const PropertyHorizontalOptions = ({
  addressCity,
  addressNeightborhood,
  addressState,
  attributes,
  imageUrl,
  imageCode,
  imageDescription,
  valueDetails,
  valueProperty,
  typeValueProperty,
  onClickCancel = () => null,
  onClickContinue = () => null,
  onClickScheduled = () => null,
}: PropertyHorizontalOptionsInterface) => (
  <div className="property-horizontal-options-molecule">
    <Card>
      <PropertyHorizontalOptionsWrap className="wrap">
        <CardImage
          code={`COD${String(imageCode).padStart(4, '0')}`}
          type={imageDescription || ''}
          image={imageUrl || `/images/empty_property.png`}
        />

        <Grid
          numberColumns={3}
          gridTemplateColumns="9fr 9fr 10fr"
          className="box-content"
        >
          <div className="column-left">
            <Grid numberColumns={3}>
              {attributes.map(attribute => (
                <Attributes
                  key={attribute.label}
                  label={attribute.label}
                  value={attribute.value}
                  type={attribute.type || 'DEFAULT'}
                  size="normal"
                />
              ))}
            </Grid>

            <Attributes
              label="Endereço"
              value={`${addressNeightborhood} - ${addressCity} - ${addressState}`}
              style={{ margin: '20px 0 0 0' }}
              type="ADDRESS"
              size="normal"
            />

            <Button
              color="septernary"
              size="mini"
              className="button-scheduled"
              onClick={() => onClickScheduled()}
            >
              AGENDAR UMA VÍSITA
            </Button>
          </div>

          <div className="column-center">
            {valueDetails?.map(
              ({ label, value }) => (
                <LineLabelValue
                  key={label}
                  label={label || ''}
                  value={`R$ ${convertFloatToBR(value)}`}
                />
              )
            )}

            <LineLabelValue
              label={typeValueProperty || ''}
              value={`R$ ${convertFloatToBR(valueProperty)}`}
              total
            />
          </div>

          <div className="column-right">
            {onClickContinue && (
              <Button onClick={() => onClickContinue()} color="octernary">
                Continuar Processo de Aluguel
              </Button>
            )}

            {onClickCancel && (
              <Button onClick={() => onClickCancel()}>Cancelar</Button>
            )}
          </div>
        </Grid>
      </PropertyHorizontalOptionsWrap>
    </Card>
  </div>
)

export default PropertyHorizontalOptions
