import { Link } from 'react-router-dom';
import { NavBarItemWrap } from './styles';

import type { ICNavBarItem } from '@types';

const NavBarItem = ({
    label,
    href,
    color,
    lineLeft = false,
    lineRight = false,
}: ICNavBarItem) => (
    <NavBarItemWrap color={color} lineLeft={lineLeft} lineRight={lineRight}>
        <Link to={href} title={label}>
            {label}
        </Link>
    </NavBarItemWrap>
);

export default NavBarItem;
