import styled, { css } from 'styled-components';

interface ButtonFormWrapInterface {
    color: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
    size: 'medium' | 'large';
    icon: boolean;
    disabled: boolean;
}

const colorsStyles = {
    primary: css`
        background-color: ${(props) => props.theme.colors.tertiary};
        span {
            color: ${(props) => props.theme.colors.textSenary};
        }

        div.icon {
            color: ${(props) => props.theme.colors.secondary};
        }
        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.secondary};
            span {
                color: ${(props) => props.theme.colors.textSecondary};
            }

            div.icon {
                color: ${(props) => props.theme.colors.textSecondary};
                border-left: 1px solid ${(props) => props.theme.colors.tertiary};
            }
        }
    `,
    secondary: css`
        background-color: ${(props) => props.theme.colors.senary};

        span {
            color: ${(props) => props.theme.colors.textSecondary};
        }

        div.icon {
            color: ${(props) => props.theme.colors.textSecondary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.secondary};
            border-color: ${(props) => props.theme.colors.secondary};

            span {
                color: ${(props) => props.theme.colors.textSecondary};
            }

            div.icon {
                color: ${(props) => props.theme.colors.textSecondary};
                border-left: 1px solid ${(props) => props.theme.colors.tertiary};
            }
        }
    `,
    tertiary: css`
        background-color: ${(props) => props.theme.colors.tertiary};
        span {
            color: ${(props) => props.theme.colors.secondary};
        }

        div.icon {
            color: ${(props) => props.theme.colors.secondary};
        }
        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.secondary};
            span {
                color: ${(props) => props.theme.colors.textSecondary};
            }

            div.icon {
                color: ${(props) => props.theme.colors.textSecondary};
                border-left: 1px solid ${(props) => props.theme.colors.tertiary};
            }
        }
    `,
    quaternary: css`
      background-color: ${(props) => props.theme.colors.secondary};
      span {
        color: ${(props) => props.theme.colors.textSecondary};
      }

      div.icon {
        color: ${(props) => props.theme.colors.textSecondary};
        border-left: 1px solid ${(props) => props.theme.colors.tertiary};
      }
    }

    &:hover,
    &.active {
      background-color: ${(props) => props.theme.colors.tertiary};
    span {
      color: ${(props) => props.theme.colors.secondary};
    }

    div.icon {
      color: ${(props) => props.theme.colors.secondary};
    }
  `,
};

const sizes = {
    large: css`
        height: 5.63rem;

        div.icon {
            width: 5.63rem;
            height: 5.63rem;
        }
    `,
    medium: css`
        height: 4.375rem;

        div.icon {
            width: 4.375rem;
            height: 4.375rem;
        }
    `,
};

export const ButtonFormWrap = styled.button<ButtonFormWrapInterface>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    opacity: ${(props) => (props.disabled ? 0.35 : 1)};
    border: 0.063rem solid transparent;

    transition: background-color 0.5s ease;

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        font-size: 13px;
        line-height: 120%;
        font-weight: 900;
        text-transform: uppercase;
    }

    .loader {
        margin: 0 auto;
    }

    div.icon {
        display: ${(props) => (props.icon ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;

        font-size: 25px;

        border-left: 1px solid ${(props) => props.theme.colors.borderPrimary};
    }

    ${(props) => colorsStyles[props.color]};
    ${(props) => sizes[props.size]};
`;
