import { Helmet } from 'react-helmet';

import { RealStateFinancialForm } from 'components/templates';

const RealStateFinancialFormPage = () => (
    <>
        <Helmet>
            <title>Financeiro - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateFinancialForm />
    </>
);

export default RealStateFinancialFormPage;
