import { TextWrap } from './styles';

import type { TextInterface } from './interface';

const Text = ({ children, number, style, uppercase }: TextInterface) => (
    <TextWrap
        className={number ? 'text-atom number' : 'text-atom'}
        uppercase={uppercase || false}
        style={style}
    >
        {children}
    </TextWrap>
);

export default Text;
