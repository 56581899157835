import { convertFloatToBR } from 'utils'

import { HelperPercentWrap } from './styles'

import type { HelperPercentInterface } from './interface'

const HelperPercent = ({ value, style }: HelperPercentInterface) => (
  <HelperPercentWrap
    className={value ? 'helper-money-atom active' : 'helper-money-atom'}
    style={style}
  >
    {convertFloatToBR(value)}%
  </HelperPercentWrap>
)


export default HelperPercent
