/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'

import {
  startOfMonth,
  endOfMonth,
  getDay,
  subDays,
  format,
  addDays,
  addMonths,
  subMonths,
  isEqual,
  isDate,
} from 'date-fns'
import { pt } from 'date-fns/locale'

import CalendarInterface from './interface'

import { CalendarWrap } from './styles'

const Calendar = ({
  value = undefined,
  onChange = () => null,
  invalidDates = [],
  invalidDaysOfWeek = []
}: CalendarInterface) => {

  function getCurrentDay() {
    if (value !== undefined && isDate(value)) {
      return value
    }
    return new Date()
  }

  const [currentDate, setCurrentDate] = useState(getCurrentDay())

  useEffect(() => {
    if (value) {
      setCurrentDate(getCurrentDay())
    }
  }, [value])

  function onChangeMonth(operator: 'less' | 'sum') {
    if (operator === 'less') {
      setCurrentDate(subMonths(currentDate, 1))
    } else {
      setCurrentDate(addMonths(currentDate, 1))
    }
  }

  function getMonthYearExtensionName() {
    return format(currentDate, 'LLLL Y', { locale: pt })
  }

  function getDaysLastMonthForFirstWeek() {
    const startDayMonth = startOfMonth(currentDate)
    const initStartWeek = getDay(startDayMonth)

    const daysLastMonth = []

    for (let i = initStartWeek; i > 0; i--) {
      daysLastMonth.push(subDays(startDayMonth, i))
    }

    return daysLastMonth
  }

  function getDaysCurrentMonth() {
    const endDayMonth = endOfMonth(currentDate)
    let dayControl = startOfMonth(currentDate)

    const daysMonth = []

    while (endDayMonth > dayControl) {
      daysMonth.push(dayControl)

      dayControl = addDays(dayControl, 1)
    }

    return daysMonth
  }

  function getDaysNextMonthForFirstWeek() {
    const endDayMonth = endOfMonth(currentDate)
    const initStartWeek = getDay(endDayMonth)

    const daysNextMonth = []

    for (let i = 1; i <= 6 - initStartWeek; i++) {
      daysNextMonth.push(addDays(endDayMonth, i))
    }

    return daysNextMonth
  }

  function getFormattedNumberDay(date: Date) {
    return format(date, 'd')
  }

  function getDayInfo(date: Date) {
    const isInvalidDate = invalidDates.some(item => isEqual(item, date))
    const isInvalidDay = invalidDaysOfWeek.some(item => item === getDay(date))

    return {
      numberMonth: format(date, 'd'),
      isInvalid: isInvalidDate || isInvalidDay,
      isActive: isEqual(currentDate, date)
    }
  }

  function getWeekDays() {
    return [
      { name: 'Domingo', sigle: 'Dom' },
      { name: 'Segunda-feira', sigle: 'Seg' },
      { name: 'Terça-feira', sigle: 'Ter' },
      { name: 'Quarta-feira', sigle: 'Qua' },
      { name: 'Quinta-feira', sigle: 'Qui' },
      { name: 'Sexta-feira', sigle: 'Sex' },
      { name: 'Sábado', sigle: 'Sab' }
    ]
  }

  function onChangeDate(date: Date) {
    setCurrentDate(date)
    onChange(date)
  }

  return (
    <CalendarWrap className="calendar-molecule">
      <div className="row-month-year">
        <button type='button' onClick={() => onChangeMonth('less')}>
          <AiFillCaretLeft />
        </button>

        <span className="number">{getMonthYearExtensionName()}</span>

        <button type='button' onClick={() => onChangeMonth('sum')}>
          <AiFillCaretRight />
        </button>
      </div>

      <div className="row-week-days">
        {getWeekDays().map(weekDay => (
          <span key={weekDay.sigle} title={weekDay.name}>
            {weekDay.sigle}
          </span>
        ))}
      </div>

      <div className="row-days">
        {getDaysLastMonthForFirstWeek().map(item => (
          <span
            key={`last_month_${item}`}
            className="number days-number last-month inactive"
          >
            {getFormattedNumberDay(item)}
          </span>
        ))}

        {getDaysCurrentMonth().map(item => {
          const { numberMonth, isInvalid, isActive } = getDayInfo(item)

          if (isInvalid) {
            return (
              <span
                key={`current_month_${numberMonth}`}
                className="number days-number current-month inactive"
              >
                {numberMonth}
              </span>
            )
          }

          if (isActive) {
            return (
              <span
                key={`current_month_${numberMonth}`}
                className="number days-number current-month active"
              >
                {numberMonth}
              </span>
            )
          }

          return (
            <button
              type='button'
              key={`current_month_${numberMonth}`}
              className="number days-number current-month"
              onClick={() => onChangeDate(item)}
            >
              {numberMonth}
            </button>
          )
        })}

        {getDaysNextMonthForFirstWeek().map(item => (
          <span
            key={`next_month_${item}`}
            className="number days-number next-month inactive"
          >
            {getFormattedNumberDay(item)}
          </span>
        ))}
      </div>
    </CalendarWrap>
  )
}

export default Calendar
