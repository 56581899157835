import { InputCapsule } from 'components/atoms';
import { checkEmpty } from 'utils';

import { SelectWrap } from './styles';

import type { SelectInterface } from './interface';

const Select = ({
    id,
    label,
    required,
    name,
    placeholder,
    value,
    onChange = () => null,
    options,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
    model = 'primary',
}: SelectInterface) => (
    <SelectWrap className="select-molecule">
        <InputCapsule
            htmlFor={id || name}
            label={label}
            required={required}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderTop={borderTop}
            borderBottom={borderBottom}
            empty={checkEmpty(value)}
            model={model}
        >
            <select
                id={id || name}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                {!checkEmpty(placeholder) && (
                    <option key="placeholder" value="">{placeholder}</option>
                )}
                {Array.isArray(options) &&
                    options.map((option) => (
                        <option key={`${option.label}_${option.value}`} value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
        </InputCapsule>
    </SelectWrap>
);

export default Select;
