import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_PUBLIC,
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Grid, Loading, Shadow, Line } from 'components/atoms';
import {
    InputMask,
    InputNumber,
    InputText,
    OptionBlock,
    Select
} from 'components/molecules';


import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    getPublicPaymentMethodIndex,
    createRealStateFinance,
    getRealStateFinanceShow,
    updateRealStateFinance,
    getRealStateOwnerIndex,
} from 'services';


import { financeEnums } from 'enums';
import { convertDateENToBR } from 'utils';
import { FinancialFormWrap } from './styles';
import Layout from '../../Layout';

import type { IARealStateFinance } from '@types';

const FinancialForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [descricao, setDescricao] = useState('');
    const [tipoDeConta, setTipoDeConta] = useState('');
    const [dataVencimento, setDataVencimento] = useState('');
    const [dataPagamento, setDataPagamento] = useState('');
    const [valorPrevisto, setValorPrevisto] = useState(0);
    const [valorPago, setValorPago] = useState(0);

    const [formasPagamentosIds, setFormasPagamentosIds] = useState<number | string>('')
    const [status, setStatus] = useState('')
    const [pessoasIds, setPessoasIds] = useState<number | string>('')
    const [observacao, setObservacao] = useState('')

    const [numeroDocumento, setNumeroDocumento] = useState('')
    const [dataDocumento, setDataDocumento] = useState('')

    const title = 'Financeiro';

    const { isLoading: isLoadingGet, isFetching: isFetchingGet } = useQuery(
        [QUERIES_REAL_STATE.FINANCIAL.base, id],
        () => getRealStateFinanceShow(String(id)),
        {
            refetchOnMount: true,
            enabled: !!id,
            onSuccess: (data) => {
                setDescricao(data?.descricao ? data?.descricao : '');
                setTipoDeConta(data?.tipoDeConta ? data?.tipoDeConta : '');

                setDataVencimento(data?.dataVencimento ? convertDateENToBR(data?.dataVencimento) : '');
                setDataPagamento(data?.dataPagamento ? convertDateENToBR(data?.dataPagamento) : '');

                setValorPrevisto(data?.valorPrevisto ? data?.valorPrevisto : 0);
                setValorPago(data?.valorPago ? data?.valorPago : 0);
                setFormasPagamentosIds(data?.formasPagamentosIds ? data?.formasPagamentosIds : '');
                setStatus(data?.status ? data?.status : '');
                setPessoasIds(data?.pessoasIds ? data?.pessoasIds : '');
                setObservacao(data?.observacao ? data?.observacao : '');
                setNumeroDocumento(data?.numeroDocumento ? data?.numeroDocumento : '');
                setDataDocumento(data?.dataDocumento ? convertDateENToBR(data?.dataDocumento) : '');


            }
        },
    );

    const { data: paymentMethodList } = useQuery(
        QUERIES_PUBLIC.PAYMENT_METHOD.list,
        () => getPublicPaymentMethodIndex(),
        {
            select(data) {
                if (Array.isArray(data)) {
                    return data?.map((item) => ({
                        label: item.nome,
                        value: item.id,
                    }))
                }

                return []
            }
        }
    );

    const { data: dataOwners } = useQuery(QUERIES_REAL_STATE.OWNER.base, () =>
        getRealStateOwnerIndex(),
        {
            select(data) {
                if (Array.isArray(data)) {

                    return data?.map((item) => ({
                        label: item.nome,
                        value: item.id,
                    }))
                }

                return []
            },
        }
    );

    const {
        mutate: mutateCreate,
        isLoading: isLoadingCreate,
    } = useMutation(
        (payload: Partial<IARealStateFinance>) => createRealStateFinance(payload),
        {
            mutationKey: QUERIES_REAL_STATE.FINANCIAL.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.FINANCIAL.base
                );

                navigate(
                    getLinkWithSlug(ROUTES_REAL_STATE.FINANCIAL.fullPath)
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const {
        mutate: mutateUpdate,
        isLoading: isLoadingUpdate,
    } = useMutation(
        (payload: Partial<IARealStateFinance>) => updateRealStateFinance(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.FINANCIAL.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.FINANCIAL.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async () => {

        const dataSend = {
            descricao,
            tipoDeConta,
            dataVencimento: dataVencimento
                ? dataVencimento.split('/').reverse().join('-') : undefined,
            dataPagamento: dataPagamento
                ? dataPagamento.split('/').reverse().join('-') : undefined,
            valorPrevisto,
            valorPago,
            formasPagamentosIds: formasPagamentosIds ? Number(formasPagamentosIds) : undefined,
            status,
            pessoasIds: pessoasIds ? Number(pessoasIds) : undefined,
            observacao,
            numeroDocumento,
            dataDocumento: dataDocumento
                ? dataDocumento.split('/').reverse().join('-') : undefined,
        }

        const shapeSchema = {
            status: Yup.string().nullable().required('O status obrigatório.'),
            formasPagamentosIds: Yup.number().nullable().required('A forma de pagamento obrigatória.'),
            valorPrevisto: Yup.number().nullable().required('O valor previsto é obrigatório.'),
            dataVencimento: Yup.string().nullable().required('A data de vencimento é obrigatória.'),
            tipoDeConta: Yup.string().nullable().required('O tipo de conta é obrigatório.'),
            descricao: Yup.string().nullable().required('A descrição é obrigatória.'),
        }

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };




    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.FINANCIAL.fullPath
                    ),
                },
                {
                    label: `Formulário ${title}`,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.FINANCIAL.FORM.fullPath
                    ),
                },
            ]}
            title={`Formulário ${title}`}
            buttonTitle={
                <Button
                    color="denary"
                    onClick={() => onSubmit()}
                    loading={
                        isLoadingGet || isLoadingCreate || isLoadingUpdate
                    }
                >
                    <AiFillSave />
                    Salvar
                </Button>
            }
        >
            <FinancialFormWrap className="real-state-panel-insurance-company-form">
                <Shadow>
                    <Loading active={isLoadingGet || isFetchingGet} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <Grid numberColumns={2}>
                        <InputText
                            required
                            label="Descrição"
                            name="descricao"
                            placeholder="Informe o descrição"
                            value={descricao}
                            onChange={setDescricao}
                            model="sinary"
                        />

                        <Select
                            required
                            label="Tipo de Conta"
                            name="tipoDeConta"
                            placeholder="Informe o tipo de conta"
                            value={tipoDeConta}
                            onChange={setTipoDeConta}
                            model="sinary"
                            options={financeEnums.financeTypes}
                        />
                    </Grid>

                    <Grid numberColumns={2}>

                        <InputMask
                            required
                            valueMasked
                            name="dataVencimento"
                            mask="99/99/9999"
                            label="Data do Vencimento"
                            placeholder="Informe a data do vencimento"
                            value={dataVencimento}
                            onChange={setDataVencimento}
                            model="sinary"
                        />

                        <InputMask
                            valueMasked
                            name="dataPagamento"
                            mask="99/99/9999"
                            label="Data do Pagamento"
                            placeholder="Informe a data do pagamento"
                            value={dataPagamento}
                            onChange={setDataPagamento}
                            model="sinary"
                        />

                    </Grid>

                    <Grid numberColumns={2}>
                        <InputNumber
                            label="Valor Previsto"
                            placeholder="Informe o valor previsto"
                            name='valorPrevisto'
                            prefix="R$ "
                            required
                            model="sinary"
                            value={valorPrevisto}
                            onChange={setValorPrevisto}
                        />

                        <InputNumber
                            label="Valor Pago"
                            placeholder="Informe o valor pago"
                            name='valorPago'
                            prefix="R$ "
                            model="sinary"
                            value={valorPago}
                            onChange={setValorPago}
                        />
                    </Grid>

                    <Grid numberColumns={2}>
                        <Select
                            name='formasPagamentosIds'
                            required
                            label="Forma de Pagamento"
                            placeholder="Informe a forma de pagamento"
                            options={paymentMethodList}
                            model="sinary"
                            value={String(formasPagamentosIds)}
                            onChange={value => setFormasPagamentosIds(Number(value))}
                        />

                        <Select
                            required
                            label="Status"
                            name="status"
                            placeholder="Informe o status"
                            value={status}
                            onChange={setStatus}
                            model="sinary"
                            options={financeEnums.financeStatus}
                        />

                    </Grid>

                    <Line type='secondary' />

                    <Select
                        name='pessoasIds'
                        label="Pessoa"
                        placeholder="Informe a pessoa"
                        options={dataOwners}
                        model="sinary"
                        value={String(pessoasIds)}
                        onChange={value => setPessoasIds(Number(value))}
                    />

                    <Grid numberColumns={2}>
                        <InputText
                            label="N do Documento"
                            name="numeroDocumento"
                            placeholder="Informe o número do documento"
                            value={numeroDocumento}
                            onChange={setNumeroDocumento}
                            model="sinary"
                        />


                        <InputMask
                            valueMasked
                            name="dataDocumento"
                            mask="99/99/9999"
                            label="Data do documento"
                            placeholder="Informe a data do documento"
                            value={dataDocumento}
                            onChange={setDataDocumento}
                            model="sinary"
                        />
                    </Grid>


                </Shadow>
            </FinancialFormWrap>
        </Layout>
    );
};

export default FinancialForm;
