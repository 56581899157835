import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'contexts/AuthContext';
import { RealStateProvider } from 'contexts/RealStateContext';
// import { TIMES_UNITY } from 'globals';

import StagingMiddleware from 'middlewares/StagingMiddleware';

import theme from './styles/theme';
import GlobalStyle from './styles/global';
import Routes from './routes';

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                // refetchInterval: TIMES_UNITY.ONE_DAY,
                refetchIntervalInBackground: false,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                // staleTime: TIMES_UNITY.ONE_DAY,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <StagingMiddleware>
                        <RealStateProvider>
                            <AuthProvider>
                                <Routes />
                            </AuthProvider>
                        </RealStateProvider>
                    </StagingMiddleware>
                </ThemeProvider>
            </Router>
        </QueryClientProvider>
    );
};

export default App;
