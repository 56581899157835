import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    Button,
    Container,
    Row,
    SocialIcon,
    WhatsAppIcon,
} from 'components/atoms';
import logoDefault from 'assets/images/logo.png';
import { HeaderWrap } from './styles';

import type { HeaderInterface } from './interface';

const Header = ({
    isSigned,
    name,
    logo,
    instagram,
    facebook,
    whatsapp,
    youtube,
    getLinkWithSlug = (param) => param,
}: HeaderInterface) => {
    const navigate = useNavigate();

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    const buttons = useMemo(() => {
        if (isSigned) {
            return (
                <>
                    <Button
                        color="primary"
                        onClick={() =>
                            onHandleNavigate(
                                getLinkWithSlug('/account/selected')
                            )
                        }
                    >
                        Minha Conta
                    </Button>
                    <Button
                        color="danger"
                        onClick={() =>
                            onHandleNavigate(getLinkWithSlug('/sign/out'))
                        }
                    >
                        Sair
                    </Button>
                </>
            );
        }

        return (
            <>
                <Button
                    color="primary"
                    onClick={() =>
                        onHandleNavigate(getLinkWithSlug('/sign/in'))
                    }
                >
                    Entrar
                </Button>
                <Button
                    color="secondary"
                    onClick={() =>
                        onHandleNavigate(getLinkWithSlug('/sign/up'))
                    }
                >
                    Cadastrar-se
                </Button>
            </>
        );
    }, [isSigned]);

    return (
        <HeaderWrap className="header-organism">
            <Container>
                <Row className="row-header">
                    <Link to={getLinkWithSlug('/')}>
                        <img
                            className="row-header-logo"
                            src={!logo ? logoDefault : logo}
                            alt={!name ? 'Imobiler' : name}
                        />
                    </Link>

                    <Row className="row-header-social">
                        {whatsapp && (
                            <WhatsAppIcon
                                style={{ margin: '0 1.5rem 0 auto' }}
                                value={whatsapp}
                                href={`https://api.whatsapp.com/send?phone=55${whatsapp}`}
                            />
                        )}

                        {facebook && (
                            <SocialIcon
                                icon="facebook"
                                variation="header"
                                href={facebook}
                            />
                        )}

                        {instagram && (
                            <SocialIcon
                                icon="instagram"
                                variation="header"
                                href={instagram}
                            />
                        )}

                        {youtube && (
                            <SocialIcon
                                icon="youtube"
                                variation="header"
                                href={youtube}
                            />
                        )}
                    </Row>

                    <Row className="row-header-buttons">{buttons}</Row>
                </Row>
            </Container>
        </HeaderWrap>
    );
};

export default Header;
