import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { InvoiceSettlementDetails } from 'components/organisms';
import { getRealStateInvoiceSettlementFilter } from 'services';
import { QUERIES_REAL_STATE } from '@constants';

import { InvoiceSettlementPrintWrap} from './styles'

const InvoiceSettlementPrint = () => {
    const { ids } = useParams();

    const { data } = useQuery(
        [QUERIES_REAL_STATE.INVOICE_SETTLEMENT.base, ids],
        () => getRealStateInvoiceSettlementFilter(String(ids))
    );

    return (
        <InvoiceSettlementPrintWrap>
            {data?.map((item, index) => (
                <div key={item.id}>
                    <div className='settlement-item'>
                        <InvoiceSettlementDetails  data={item} />
                    </div>

                    {data?.length === index + 1 ? null : <div className="page-break" />}
                </div>
            ))}
        </InvoiceSettlementPrintWrap>
    );
};

export default InvoiceSettlementPrint;
