import { TextFormWrap } from './styles'

import type { TextFormInterface } from './interface'

const TextForm = ({
  children,
  className,
  borderLeft = false,
  borderRight = false,
  borderTop = false,
  borderBottom = false,
  model = 'primary'
}: TextFormInterface) => (
  <TextFormWrap
    className={className ? `${className} text-form-atom` : 'text-form-atom'}
    borderLeft={borderLeft}
    borderRight={borderRight}
    borderTop={borderTop}
    borderBottom={borderBottom}
    model={model}
  >
    {children}
  </TextFormWrap>
)

export default TextForm
