import { TitlePageWrap } from './styles';

import type { TitlePageInterface } from './interface';

const TitlePage = ({ children, button }: TitlePageInterface) => (
    <TitlePageWrap className="title-page-atom">
        <h2 className="left">{children}</h2>
        <div className="right">{button}</div>
    </TitlePageWrap>
);

export default TitlePage;
