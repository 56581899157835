import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { Loading } from 'components/atoms'

import theme from 'styles/theme';
import {
    getPublicRealStateDetail,
    getPublicRealStateImobilerDetail,
} from 'services';
import { enviroments } from 'utils';

import { IARealState, IABroker } from '@types';

interface ResponseRealStateApi extends Partial<IARealState> {
    data?: {
        corretores: IABroker[];
    };
}
interface RealStateContextData extends ResponseRealStateApi {
    getLinkWithSlug: (url: string, weebsite?: boolean) => string;
}

interface RealStateProviderProps {
    children: ReactNode;
}

export const RealStateContext = createContext({} as RealStateContextData);

const RealStateProvider = ({ children }: RealStateProviderProps) => {
    const location = useLocation();

    const realStateSlug = useMemo(() => {

        const host =  window?.location?.host;

        if(!host?.includes('imobiler.com.br') && !host.includes('localhost')) {
            return host.replace("app.", "");
        }

        if (location.pathname.includes('/r/')) {
            const splittedPath = location.pathname.split('/');
            const [, , slug] = splittedPath;

            return slug;
        }

        return undefined;
    }, [location, window]);

    const { data, isLoading } = useQuery(['public-real-state-detail', realStateSlug], () =>
        realStateSlug
            ? getPublicRealStateDetail(realStateSlug)
            : getPublicRealStateImobilerDetail()
    );

    const getLinkWithSlug = (param: string, isWebsiteRedirect: boolean = false) => {
        const slug = data?.slug;

        if(data?.usoDeDominio) {
            if(isWebsiteRedirect) {
                return `//${data?.dominio}${param}`
            }
            
            return param;
          }
  

        const initUrl = isWebsiteRedirect
            ? `${enviroments.WEBSITE_URL.slice(0, -1)}`
            : '';

        if (slug === 'imobiler' || (!realStateSlug && !slug)) {
            return `${initUrl}${param}`;
        }

        if (slug === '' || slug === null || slug === undefined) {
            return `${initUrl}${isWebsiteRedirect ? '/' : '/r/'}${realStateSlug}${param}`;
        }

        return `${initUrl}${isWebsiteRedirect ? '/' : '/r/'}${data?.slug}${param}`;
    };

    const getColorsTheme = useCallback(() => {
        if (data?.cores) {
            return {
                ...theme,
                colors: {
                    ...theme.colors,
                    ...JSON.parse(data?.cores),
                },
            };
        }

        return theme;
    }, [data]);

    const valueProvider = useMemo(
        () => ({
            ...data,
            getLinkWithSlug,
        }),
        [data]
    );

    if(isLoading) {
        return <Loading active />;
    }

    return (
        <RealStateContext.Provider value={valueProvider}>
            <ThemeProvider theme={getColorsTheme()}>{children}</ThemeProvider>
        </RealStateContext.Provider>
    );
};

const useRealState = (): RealStateContextData => {
    const context = useContext(RealStateContext);

    return context;
};

export { RealStateProvider, useRealState };
