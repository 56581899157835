import styled from 'styled-components'

interface TextWrapInterface {
  uppercase: boolean
}

export const TextWrap = styled.span<TextWrapInterface>`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  line-height: 120%;
  color: ${props => props.theme.colors.textSecondary};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'initial')};

  strong {
    font-weight: 900;
  }
`
