import { useRef, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { SidebarItem } from 'components/atoms';

import { SidebarButtonWrap, SidebarListWrap } from './styles';

import type { SidebarListInterface } from './interface';

const SidebarList = ({ data }: SidebarListInterface) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const ref = useRef<HTMLUListElement>(null);

    const onHandleDrawer = () => {
        setIsOpenDrawer((prevState) => !prevState);
    };

    return (
        <SidebarListWrap
            className="sidebar-account-molecule"
            isOpenDrawer={isOpenDrawer}
            height={ref?.current?.clientHeight || 0}
        >
            <ul ref={ref}>
                {data !== null &&
                    data.map((item) => (
                        <SidebarItem key={item.route} {...item} />
                    ))}
            </ul>
            <SidebarButtonWrap onClick={() => onHandleDrawer()}>
                {isOpenDrawer ? <BiChevronLeft /> : <BiChevronRight />}
            </SidebarButtonWrap>
        </SidebarListWrap>
    );
};

export default SidebarList;
