import React, { useState, useEffect } from 'react';

import { InputCalendarWrap } from './styles';
import Calendar from '../Calendar';
import InputMask from '../InputMask';

import type { InputCalendarInterface } from './interface';

const InputCalendar = ({
    id,
    label,
    name,
    placeholder,
    readOnly,
    required,
    value,
    mask,
    model,
    valueMasked = false,
    onChange,
    onKeyDown,
    invalidDates = [],
    invalidDaysOfWeek = [],
    ...rest
}: InputCalendarInterface) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    useEffect(() => {
        if (typeof value === 'string') {
            const date = new Date(value);
            if (!isNaN(date.getTime())) { // eslint-disable-line no-restricted-globals
                setSelectedDate(date);
            }
        } else if (value instanceof Date) {
            setSelectedDate(value);
        }
    }, [value]);

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setShowCalendar(false);
        onChange(date.toLocaleDateString());
    };

    let formattedValue = '';
    if (selectedDate) {
        formattedValue = selectedDate.toLocaleDateString('pt-BR');
    }

    return (
        <InputCalendarWrap className="input-calendar">
            <InputMask
                id={id}
                label={label}
                name={name}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                value={formattedValue}
                mask={mask}
                model={model}
                valueMasked={valueMasked}
                onChange={() => null}
                onKeyDown={onKeyDown}
                onClick={() => setShowCalendar(true)}
                {...rest}
            />
            {showCalendar && (
                <Calendar
                    value={selectedDate || new Date()}
                    onChange={handleDateChange}
                    invalidDates={invalidDates}
                    invalidDaysOfWeek={invalidDaysOfWeek}
                />
            )}

        </InputCalendarWrap>
    );
};

export default InputCalendar;
