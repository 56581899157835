import styled, { css } from 'styled-components'

interface AttributesInterface {
  type: string
  riskLabel: boolean
  size: 'normal' | 'medium' | 'large'
}

const valueCss = css`
  font-size: 1.25rem;
  font-weight: 900;
  color: ${props => props.theme.colors.textMoney};
`

const sizesCss = {
  normal: css`
    .icon {
      height: 2.25rem;
      width: 2.25rem;
      font-size: 1rem;
    }

    .content {
      .label {
        font-size: 0.68rem;
      }

      .value {
        font-size: 0.81rem;
      }
    }
  `,
  medium: css`
    .icon {
      height: 2.375rem;
      width: 2.375rem;
      font-size: 1rem;
    }

    .content {
      .label {
        font-size: 0.6rem;
      }

      .value {
        font-size: 1.15rem;
      }
    }
  `,
  large: css`
    .icon {
      height: 3.125rem;
      width: 3.125rem;
      font-size: 1.1rem;
    }

    .content {
      .label {
        font-size: 0.75rem;
      }

      .value {
        font-size: 0.938rem;
      }
    }
  `
}

export const AttributesWrap = styled.div<AttributesInterface>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;
    border: 0.062rem solid ${props => props.theme.colors.borderQuaternary};
    background-color: ${props => props.theme.colors.senary};
    color: #9fa3a9;
    box-shadow: 5px -5px 20px 0px rgba(64, 71, 83, 0.1);

    margin-right: 0.75rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .label {
      line-height: 120%;
      font-weight: 300;
      color: ${props => props.theme.colors.textTertiary};
      text-transform: uppercase;
      text-decoration: ${props =>
    props.riskLabel ? 'line-through' : 'initial'};
    }

    .value {
      line-height: 140%;
      font-weight: 900;
      color: ${props => props.theme.colors.textSecondary};

      ${props => props.type === 'VALUE' && valueCss};
    }
  }

  &:hover {
    i {
      background-color: transparent;
    }
  }

  ${props => sizesCss[props.size]};
`
