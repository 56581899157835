import { FormEvent, useState } from 'react';

import { ROUTES_AUTH } from '@constants';

import { useAuth } from 'contexts/AuthContext';

import {
    ButtonForm,
    ButtonLine,
    Container,
    H1,
    Row,
    Shadow,
    TextForm,
} from 'components/atoms';
import { InputText } from 'components/molecules';

import { SignInWrap } from './styles';
import Layout from '../Layout';

const SignIn = () => {
    const { loading, signIn, onHandleNavigateWithNext } = useAuth();

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const onHandleSign = () => {
        onHandleNavigateWithNext(ROUTES_AUTH.SIGN_UP.fullPath);
    };

    const onHandleRecoveryPassword = () => {
        onHandleNavigateWithNext(ROUTES_AUTH.PASSWORD_RECOVERY.fullPath);
    };

    const onHandleLogin = (e: FormEvent) => {
        e.preventDefault();

        signIn({
            email,
            senha,
        });
    };

    return (
        <Layout>
            <SignInWrap className="sign-in-template">
                <Container>
                    <H1>
                        <strong>Entre</strong> ou <strong>Cadastre-se</strong>
                    </H1>
                    <form onSubmit={onHandleLogin}>
                        <main>
                            <Shadow>
                                <Row>
                                    <ButtonForm
                                        color="secondary"
                                        active
                                        onClick={() => null}
                                    >
                                        Entrar
                                    </ButtonForm>

                                    <ButtonForm
                                        color="secondary"
                                        onClick={onHandleSign}
                                    >
                                        Cadastrar-se
                                    </ButtonForm>
                                </Row>

                                <TextForm model="primary" borderTop>
                                    Entre com seus dados de acesso para fazer
                                    login em nosso portal.
                                </TextForm>
                                <InputText
                                    required
                                    label="E-mail"
                                    id="email"
                                    name="email"
                                    placeholder="Digite seu e-mail..."
                                    value={email}
                                    onChange={setEmail}
                                    borderTop
                                />

                                <InputText
                                    required
                                    label="Senha"
                                    id="password"
                                    name="password"
                                    placeholder="Digite sua senha..."
                                    value={senha}
                                    onChange={setSenha}
                                    borderTop
                                    password
                                />

                                <TextForm model="primary" borderTop>
                                    Esqueceu sua senha?{' '}
                                    <ButtonLine
                                        color="septernary"
                                        style={{
                                            margin: '0 0.25rem',
                                            fontSize: '0.92rem',
                                        }}
                                        onClick={onHandleRecoveryPassword}
                                    >
                                        Clique Aqui!
                                    </ButtonLine>
                                </TextForm>
                            </Shadow>

                            <ButtonForm
                                id="on-sign"
                                color="tertiary"
                                type="submit"
                                loading={loading}
                            >
                                Entrar
                            </ButtonForm>
                        </main>
                    </form>
                </Container>
            </SignInWrap>
        </Layout>
    );
};

export default SignIn;
