import { Helmet } from 'react-helmet';

import { AdministratorPricesIndexsForm } from 'components/templates';

const AdministratorPricesIndexsFormPage = () => (
    <>
        <Helmet>
            <title>Preços de Índices - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPricesIndexsForm />
    </>
);

export default AdministratorPricesIndexsFormPage;
