import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AiOutlineEye } from 'react-icons/ai';

import { QUERIES_ADMIN, ROUTES_ACCOUNT, ROUTES_ADMIN } from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, HelperOptions } from 'components/atoms';
import { Table } from 'components/organisms';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getAdministratorRealStateIndex, getAdministratorWebhookProcessor } from 'services';

import { RealStateListWrap } from './styles';
import Layout from '../../Layout';

const RealStateList = () => {
    const navigate = useNavigate();
    const { getLinkWithSlug } = useRealState();

    const { data, isLoading: isLoadingList } = useQuery(
        QUERIES_ADMIN.REAL_STATE.base,
        () => getAdministratorRealStateIndex()
    );

    const { mutate: onMutateProcessing, isLoading: isLoadingProcessing } = useMutation(
        () => getAdministratorWebhookProcessor(),
        {
            mutationKey: [QUERIES_ADMIN.WEB_HOOK.base],
            onSuccess: (res) => {
                onSuccessMessage(
                    'Webhook',
                    JSON.stringify(res)
                );
            },
            onError: (err) => onErrorMessage('Webhook', err),
        }
    );

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function editRegister(param: string) {
        onHandleNavigate(
            getLinkWithSlug(`${ROUTES_ADMIN.REAL_STATE.FORM.fullPath}/${param}`)
        );
    }

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Imobiliárias',
                    href: getLinkWithSlug(ROUTES_ADMIN.REAL_STATE.fullPath),
                },
            ]}
            title="Imobiliárias"
            buttonTitle={<Button onClick={onMutateProcessing} loading={isLoadingProcessing}>Processar Webhook</Button>}
        >
            <RealStateListWrap className="real-state-template">
                <Table
                    isLoading={isLoadingList}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'ID',
                            sort: true,
                            headerStyle: { width: '10%' },
                        },
                        {
                            dataField: 'pessoas',
                            text: 'Nome',
                            sort: true,
                            headerStyle: { width: '50%' },
                            formatter: (fieldValue) => fieldValue?.nome,
                        },
                        {
                            dataField: 'slug',
                            text: 'Slug',
                            sort: true,
                            headerStyle: { width: '30%' },
                        },
                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    remove: false,
                                    edit: true,
                                    editIcon: <AiOutlineEye />,
                                    editFunction: () =>
                                        editRegister(fieldValue),
                                }),
                            headerStyle: { width: '10%' },
                        },
                    ]}
                    data={data}
                />
            </RealStateListWrap>
        </Layout>
    );
};

export default RealStateList;
