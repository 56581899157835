import { Helmet } from 'react-helmet';

import { OwnerInvoiceSettlementPrint } from 'components/templates';

const OwnerInvoiceSettlementPrintPage = () => (
    <>
        <Helmet>
            <title>Acertos - Painel do Proprietário - Imobiler</title>
        </Helmet>

        <OwnerInvoiceSettlementPrint />
    </>
);

export default OwnerInvoiceSettlementPrintPage;
