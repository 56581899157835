import { Grid } from 'components/atoms';
import {
    InputCalendar,
    InputNumber,
    InputTextArea,
    Select,
} from 'components/molecules';
import { rentContractEnums } from 'enums';

import { GeneralInfoRentContractWrap } from './styles';
import type { GeneralInfoRentContractInterface } from './interface';

const GeneralInfoRentContract = ({
    values,
    onChange,
}: GeneralInfoRentContractInterface) => {
    const useField = (index: string) => {
        let value = null;

        if (values !== undefined) {
            value = values?.[index];
        }

        return {
            onChange: (newValue: string | boolean | number) =>
                onChange((prevValues: any) => ({ ...prevValues, [index]: newValue })),
            value,
            id: index,
            name: index,
        };
    };

    return (
        <GeneralInfoRentContractWrap className="general-info-rent-contract-panel-template">
            <Grid numberColumns={3}>
                <Select
                    label="Etapa"
                    placeholder="Informe a etapa"
                    options={rentContractEnums.step}
                    model="sinary"
                    {...useField('etapa')}
                />
            </Grid>

            <Grid numberColumns={3}>
                <InputNumber
                    required
                    label="Dia do Vencimento"
                    placeholder="Informe o dia do vencimento"
                    precision={0}
                    model="sinary"
                    {...useField('diaVencimento')}
                />

                <InputNumber
                    required
                    label="Meses Prazo"
                    placeholder="Informe os meses do prazo"
                    precision={0}
                    model="sinary"
                    {...useField('mesesPrazo')}
                />

                <InputNumber
                    required
                    label="Meses de Carência"
                    placeholder="Informe a multa de meses de carência"
                    precision={0}
                    model="sinary"
                    {...useField('multaMesesCarencia')}
                />
            </Grid>

            <Grid numberColumns={3}>
                <div>
                    <InputCalendar
                        required
                        mask="99/99/9999"
                        label="Data Inicial"
                        placeholder="Informe a data inicial"
                        model="sinary"
                        {...useField('dataInicial')}
                    />
                </div>

                <div>
                    <InputCalendar
                        mask="99/99/9999"
                        label="Data Final"
                        placeholder="Informe a data final"
                        model="sinary"
                        {...useField('dataFinal')}
                    />
                </div>
            </Grid>

            <Grid numberColumns={3}>
                <Select
                    required
                    label="Tipo Multa Rescisória"
                    placeholder="Informe o tipo de multa rescisória"
                    options={rentContractEnums.terminationType}
                    model="sinary"
                    {...useField('tipoMultaRecisoria')}
                />

                <Select
                    required
                    label="Tipo de Taxa Administração"
                    placeholder="Informe o tipo de taxa de administração"
                    options={rentContractEnums.typeTaxAdministration}
                    model="sinary"
                    {...useField('tipoTaxaAdministracao')}
                />

                <InputNumber
                    required
                    label="Taxa de Administração"
                    placeholder="Informe a taxa de administração"
                    precision={1}
                    postfix="%  "
                    model="sinary"
                    {...useField('taxaAdministracao')}
                />
            </Grid>

            <Grid numberColumns={3}>
                <Select
                    required
                    label="Garantia"
                    placeholder="Informe a garantia"
                    options={rentContractEnums.ensure}
                    model="sinary"
                    {...useField('garantia')}
                />

                <Select
                    required
                    label="Repasse Tipo"
                    placeholder="Informe o tipo de repasse"
                    options={rentContractEnums.rehearse}
                    model="sinary"
                    {...useField('repasseTipo')}
                />

                <Select
                    required
                    label="Imposto de Renda"
                    placeholder="Informe o imposto de renda"
                    options={rentContractEnums.incomeTax}
                    model="sinary"
                    {...useField('impostoDeRenda')}
                />
            </Grid>

            <InputTextArea
                label="Observações"
                placeholder="Informe as observações"
                model="sinary"
                {...useField('observacao')}
            />
        </GeneralInfoRentContractWrap>
    );
};

export default GeneralInfoRentContract;
