import styled, { css } from 'styled-components';

interface SidebarListWrapInterface {
    isOpenDrawer: boolean;
    height: number;
}

export const SidebarListWrap = styled.div<SidebarListWrapInterface>`
    width: 24.063rem;

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-shadow: 0px -3px 23px 0px rgba(126, 157, 184, 0.2);
        background-color: ${(props) => props.theme.colors.senary};
        margin-bottom: auto;
    }

    @media (max-width: 992px) {
        position: fixed;
        top: 50%;
        margin-top: ${(props) => props.height / -2}px;
        bottom: 0;
        left: -24.063rem;
        z-index: 100;
        transition: transform 1s;

        ul {
            max-height: 100%;
            overflow-y: auto;
        }

        ${(props) =>
            props.isOpenDrawer &&
            css`
                transform: translateX(100%);
            `}
    }
`;

export const SidebarButtonWrap = styled.button.attrs({
    type: 'button',
})`
    display: none;

    border-bottom-right-radius: 3rem;
    border-top-right-radius: 3rem;

    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: -3rem;
    margin-top: -1.5rem;
    top: 50%;

    height: 3rem;
    width: 3rem;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => props.theme.colors.quaternary};
    color: ${(props) => props.theme.colors.secondary};
    z-index: 101;

    svg {
        font-size: 1.5rem;
    }

    @media (max-width: 992px) {
        display: flex;
    }
`;
