import { Helmet } from 'react-helmet';

import { AdministratorValueDetailForm } from 'components/templates';

const AdministratorValueDetailsFormPage = () => (
    <>
        <Helmet>
            <title>Detalhes de Valores - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorValueDetailForm />
    </>
);

export default AdministratorValueDetailsFormPage;
