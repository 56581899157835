import styled from 'styled-components';

export const WhatsAppIconWrap = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    i {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 3.13rem;
        width: 3.13rem;
        border-radius: 100%;
        border: 1px solid ${(props) => props.theme.colors.whatsapp};
        background-color: ${(props) => props.theme.colors.whatsapp};

        margin-right: 0.75rem;

        svg {
            font-size: 1.38rem;
            color: ${(props) => props.theme.colors.textSenary};
        }
    }

    div {
        display: flex;
        flex-direction: column;

        .label {
            font-size: 0.75rem;
            line-height: 140%;
            font-weight: 400;
            color: ${(props) => props.theme.colors.whatsapp};
            text-transform: uppercase;
        }

        .value {
            font-size: 1rem;
            line-height: 140%;
            font-weight: 400;
            color: ${(props) => props.theme.colors.textSecondary};

            strong {
                font-weight: 900;
            }
        }
    }

    &:hover {
        i {
            background-color: transparent;

            svg {
                color: ${(props) => props.theme.colors.whatsapp};
            }
        }
    }

    @media (max-width: 992px) {
        display: inline-block;
        margin: 0 !important;
        div {
            display: none;
        }
    }
`;
