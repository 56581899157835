import { Helmet } from 'react-helmet';

import { RealStatePropertyNewForm } from 'components/templates';

const RealStatePropertyNewFormPage = () => (
    <>
        <Helmet>
            <title>Imóveis - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStatePropertyNewForm />
    </>
);

export default RealStatePropertyNewFormPage;
