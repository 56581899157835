import { generateURL } from './prefix'
import api from '../api';

import type { IARealStateInsuranceCompany } from '@types';

export const getRealStateInsuranceCompanyIndex = async (): Promise<
    IARealStateInsuranceCompany[]
> => {
    const url = generateURL(`/seguradoras`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateInsuranceCompanyShow = async (
    id: number | string
): Promise<IARealStateInsuranceCompany> => {
    const url = generateURL(`/seguradoras/${id}`);


    const { data } = await api.get(url);

    return data;
};

export const createRealStateInsuranceCompany = async (
    payload: Partial<IARealStateInsuranceCompany>
) => {
    const url = generateURL(`/seguradoras`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateInsuranceCompany = async (
    id: number | string,
    payload: Partial<IARealStateInsuranceCompany>
): Promise<IARealStateInsuranceCompany> => {
    const url = generateURL(`/seguradoras/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateInsuranceCompany = async (id: number) => {
    const url = generateURL(`/seguradoras/${id}`);


    const { data } = await api.delete(url);

    return data;
};
