import styled from 'styled-components'

export const IntegrationRegisterWrap = styled.div`
  display: block;
  width: 100%;
  overflow: auto;

  .shadow-atom {
    padding-bottom: 0.5rem;
  }
`
