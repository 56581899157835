import { memo } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

import { HelperBooleanWrap } from './styles';

import type { HelperBooleanInterface } from './interface';

const HelperBoolean = ({ value }: HelperBooleanInterface) => (
    <HelperBooleanWrap
        className={value ? 'helper-boolean-atom active' : 'helper-boolean-atom'}
    >
        {value ? <MdCheck /> : <MdClose />}
    </HelperBooleanWrap>
);

export default memo(HelperBoolean);
