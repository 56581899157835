import { Helmet } from 'react-helmet';

import { AuthPasswordRecovery } from 'components/templates';

const PasswordRecoveryPage = () => (
    <>
        <Helmet>
            <title>Recuperar Senha - Imobiler</title>
        </Helmet>

        <AuthPasswordRecovery />
    </>
);

export default PasswordRecoveryPage;
