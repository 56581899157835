import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ROUTES_ACCOUNT, QUERIES_OWNER, ROUTES_OWNER } from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Modal } from 'components/molecules';
import { Table } from 'components/organisms';
import { Button, HelperDate, HelperOptions, Text } from 'components/atoms';

import { onErrorMessage, onSuccessMessage, onWarningMessage } from 'helpers';
import {
    getOwnerPropertyHasRealStateInviteIndex,
    acceptedOwnerRealStatePropertyInvite,
    removeOwnerRealStatePropertyInvite,
} from 'services';
import { sweetAlert } from 'utils';

import { PropertyInviteListWrap, ModalContent } from './styles';
import Layout from '../../Layout';

import type { IAPropertyHasRealStateInvite } from '@types';

const PropertyInvite = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [item, setItem] = useState<any>(null);

    const title = 'Solicitações dos Imóveis';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_OWNER.PROPERTY_INVITE.base,
        () => getOwnerPropertyHasRealStateInviteIndex(),
        { refetchOnMount: true }
    );

    const { mutate: acceptedRegister, isLoading: isLoadingAccepted } =
        useMutation(
            ({ id }: { id: number }) =>
                acceptedOwnerRealStatePropertyInvite(Number(id)),
            {
                mutationKey: QUERIES_OWNER.PROPERTY_INVITE.base,
                onSuccess: (response) => {
                    if (response.success) {
                        onSuccessMessage(title, response?.message);

                        setIsOpenModal(false);

                        queryClient.invalidateQueries(
                            QUERIES_OWNER.PROPERTY_INVITE.base
                        );
                    } else {
                        onWarningMessage(title, response?.message);
                    }
                },
                onError: (err) => onErrorMessage(title, err),
            }
        );

    const { mutate: removeRegister, isLoading: isLoadingRemove } = useMutation(
        ({ id }: { id: number }) => removeOwnerRealStatePropertyInvite(Number(id)),
        {
            mutationKey: QUERIES_OWNER.PROPERTY_INVITE.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(
                    QUERIES_OWNER.PROPERTY_INVITE.base
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    function onViewRegister(idParam: number) {
        setItem(
            dataList?.find(
                (dataItem: IAPropertyHasRealStateInvite) =>
                    dataItem.id === idParam
            )
        );
        setIsOpenModal(true);
    }

    return (
        <Layout
            titlePage="Painel Proprietário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_OWNER.PROPERTY_INVITE.fullPath
                    ),
                },
            ]}
            title={title}
        >
            <PropertyInviteListWrap className="owner-property-invite-template">
                <Table
                    isLoading={isLoadingList || isLoadingRemove}
                    columns={[
                        {
                            dataField: 'imobiliariasNomes',
                            text: 'Imobiliária',
                            sort: true,
                            headerStyle: { width: '30%' },
                        },

                        {
                            dataField: 'imoveisTitulos',
                            text: 'Imóvel',
                            sort: true,
                            headerStyle: { width: '30%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Status',
                            sort: true,
                            headerStyle: { width: '30%' },
                        },

                        {
                            dataField: 'createdAt',
                            text: 'Convidado em',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperDate value={fieldValue} distance />
                            ),
                            headerStyle: { minWidth: '15rem' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue, _index, row) =>
                                HelperOptions({
                                    edit: row.status === 'PENDENTE',
                                    editIcon: <FiEye />,
                                    editFunction: () =>
                                        onViewRegister(fieldValue),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                        },
                    ]}
                    data={dataList}
                />
            </PropertyInviteListWrap>

            <Modal
                size="lg"
                open={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                title={`Convite de ${item?.imobiliariasNomes}`}
                footer={
                    <>
                        <Button
                            color="octernary"
                            onClick={() => acceptedRegister({ id: item?.id })}
                            loading={isLoadingAccepted}
                        >
                            Aceitar
                        </Button>
                        <Button
                            color="septernary"
                            onClick={() => setIsOpenModal(false)}
                        >
                            Fechar
                        </Button>
                    </>
                }
            >
                <ModalContent>
                    <p>
                        <Text>Prezado(a),</Text>
                    </p>

                    <p>
                        <Text>
                            A imobiliária{' '}
                            <strong>{item?.imobiliariasNomes}</strong> gostaria
                            de solicitar sua autorização para incluir o imóvel{' '}
                            <strong>{item?.imoveisTitulos}</strong> em seu
                            portfólio de vendas. O objetivo é divulgar o imóvel
                            para um público mais amplo e assim aumentar as
                            chances de venda/aluguel.
                        </Text>
                    </p>

                    <p>
                        <Text>
                            Gostaríamos de ressaltar que, ao autorizar a
                            inclusão do imóvel no portfólio, não significa que
                            estamos comprometidos a vender ou alugar o imóvel
                            sem a sua aprovação prévia. Qualquer proposta de
                            compra ou aluguel será submetida à sua análise e
                            decisão final.
                        </Text>
                    </p>

                    <p>
                        <Text>
                            Caso tenha interesse em autorizar a inclusão do
                            imóvel clique no botão abaixo.
                        </Text>
                    </p>
                </ModalContent>
            </Modal>
        </Layout>
    );
};

export default PropertyInvite;
