import { generateURL } from './prefix'
import api from '../api';

import type { IARealStateBanner } from '@types';

export const getRealStateBannerIndex = async (): Promise<
    IARealStateBanner[]
> => {
    const url = generateURL(`/banners`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateBannerShow = async (
    id: number | string
): Promise<IARealStateBanner> => {
    const url = generateURL(`/banners/${id}`);


    const { data } = await api.get(url);

    return data;
};

export const createRealStateBanner = async (
    payload: Partial<IARealStateBanner>
) => {
    const url = generateURL(`/banners`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateBanner = async (
    id: number | string,
    payload: Partial<IARealStateBanner>
): Promise<IARealStateBanner> => {
    const url = generateURL(`/banners/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateBanner = async (id: number) => {
    const url = generateURL(`/banners/${id}`);


    const { data } = await api.delete(url);

    return data;
};
