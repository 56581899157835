import { Button, Line } from 'components/atoms'
import { Calendar, Modal, Select } from 'components/molecules'
import { VisitScheduleModalInterface } from './interface'

import { VisitScheduleModalWrap } from './styles'

const VisitScheduleModal = ({
  openModal,
  date = undefined,
  hour,
  onChangeDate = () => null,
  onChangeHour = () => null,
  onCloseModal = () => null,
  onClickButton = () => null
}: VisitScheduleModalInterface) => {
  function onHandleClick() {
    onClickButton()
  }
  return (
    <VisitScheduleModalWrap className="visit-schedule-modal-organism">
      <Modal
        open={openModal}
        onCloseModal={onCloseModal}
        size="sm"
        title={
          <>
            AGENDE UMA <strong>VISITA</strong>
          </>
        }
      >
        <div className="wrap-content">
          <Button color="quaternary" size="mini" className="button-select-date">
            SELECIONE A DATA
          </Button>

          <Calendar
            invalidDaysOfWeek={[0, 6]}
            onChange={onChangeDate}
            value={date}
          />

          <Line />

          <Button color="quaternary" size="mini" className="button-select-hour">
            SELECIONE O HORÁRIO
          </Button>

          <Select
            name="select-hour"
            placeholder="🕒 selecione uma hora"
            value={hour || ''}
            onChange={onChangeHour}
            model="tertiary"
            options={[
              { label: '🕒 09:00 - 10:00', value: '09:00 - 10:00' },
              { label: '🕒 10:00 - 11:00', value: '10:00 - 11:00' },
              { label: '🕒 11:00 - 12:00', value: '11:00 - 12:00' },
              { label: '🕒 14:00 - 15:00', value: '14:00 - 15:00' },
              { label: '🕒 15:00 - 16:00', value: '15:00 - 16:00' },
              { label: '🕒 17:00 - 18:00', value: '17:00 - 18:00' }
            ]}
          />

          <Button
            color="quinary"
            size="normal"
            className="button-scheduled"
            onClick={() => onHandleClick()}
          >
            AGENDAR
          </Button>
        </div>
      </Modal>
    </VisitScheduleModalWrap>
  )
}

export default VisitScheduleModal
