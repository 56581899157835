import { useEffect } from 'react';

import { useAuth } from 'contexts/AuthContext';
import { Container, H1, Shadow, TextForm } from 'components/atoms';

import { SignOutWrap } from './styles';
import Layout from '../Layout';

const SignOut = () => {
    const { signOut } = useAuth();

    useEffect(() => {
        signOut();
    }, []);

    return (
        <Layout>
            <SignOutWrap className="sign-out-template">
                <Container>
                    <H1>
                        <strong>Sessão encerrada</strong>
                    </H1>
                    <main>
                        <Shadow>
                            <TextForm>
                                Você será redirecionado para o login em breve!
                            </TextForm>
                        </Shadow>
                    </main>
                </Container>
            </SignOutWrap>
        </Layout>
    );
};

export default SignOut;
