import api from '../api';
import type { IAPersonBankAccount } from '@types';

export const getRealStateOwnerBankAccountIndex = async (
    idOwner: number | string
): Promise<IAPersonBankAccount[]> => {
    const url = `/imobiliarias/proprietarios/${idOwner}/contas_bancarias`;

    const { data } = await api.get(url);

    return data;
};

export const createRealStateOwnerBankAccountIndex = async (
    idOwner: number | string,
    payload: Partial<IAPersonBankAccount>
) => {
    const url = `imobiliarias/proprietarios/${idOwner}/contas_bancarias`;

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateOwnerBankAccountIndex = async (
    idOwner: number | string,
    idOwnerBankAccount: number | string,
    payload: Partial<IAPersonBankAccount>
) => {
    const url = `imobiliarias/proprietarios/${idOwner}/contas_bancarias/${idOwnerBankAccount}`;

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateOwnerBankAccountIndex = async (
    idOwner: number | string,
    idOwnerBankAccount: number | string
) => {
    const url = `imobiliarias/proprietarios/${idOwner}/contas_bancarias/${idOwnerBankAccount}`;

    const { data } = await api.delete(url);

    return data;
};
