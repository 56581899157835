import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_ADMIN,
    ROUTES_ACCOUNT,
    ROUTES_ADMIN,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import { OptionBlock, InputText, InputMultiToggle } from 'components/molecules';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';

import {
    createAdministratorPropertiesTypes,
    getAdministratorPropertiesTypesShow,
    updateAdministratorPropertiesTypes,
    getAdministratorPropertiesAttributesIndex
} from 'services';

import { PropertiesTypesFormWrap } from './styles';
import Layout from '../../Layout';

import type { IAPropertyType } from '@types';

const PropertiesTypesForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [descricao, setDescricao] = useState('');
    const [slug, setSlug] = useState('');
    const [atributos, setAtributos] = useState<any[]>([]);


    const titleMessages = 'Tipos de Imóveis!';

    const { data: dataAtributes } = useQuery(
        QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base,
        () => getAdministratorPropertiesAttributesIndex(),
    );

    const { data: dataDetail, isLoading: isLoadingGet } = useQuery(
        [QUERIES_ADMIN.PROPERTIES_TYPES.base, id],
        () => getAdministratorPropertiesTypesShow(String(id)),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (dataDetail) {
            setDescricao(dataDetail?.descricao);
            setSlug(dataDetail?.slug);
            setAtributos(dataDetail?.atributos);
        }
    }, [dataDetail]);

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAPropertyType>) => createAdministratorPropertiesTypes(payload),
        {
            mutationKey: [QUERIES_ADMIN.PROPERTIES_TYPES.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PROPERTIES_TYPES.base);

                navigate(getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_TYPES.fullPath));
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAPropertyType>) => updateAdministratorPropertiesTypes(String(id), payload),
        {
            mutationKey: [QUERIES_ADMIN.PROPERTIES_TYPES.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PROPERTIES_TYPES.base);
                queryClient.refetchQueries([QUERIES_ADMIN.PROPERTIES_TYPES.base, id]);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onSubmit = async () => {
        const data = {
            descricao,
            slug,
            atributos,
        };

        const shapeSchema = {
            descricao: Yup.string().nullable().required('A descrição é obrigatória.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (!id) {
                mutateCreate(data);
            } else {
                mutateUpdate(data);
            }
        }
    };

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Tipos de Imóveis',
                    href: getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_TYPES.fullPath),
                },
                {
                    label: 'Formulário Tipos de Imóveis',
                    href: getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_TYPES.FORM.fullPath),
                },
            ]}
            title="Formulário Tipos de Imóveis"
            buttonTitle={
                <Button color="denary" onClick={() => onSubmit()} loading={isLoadingGet || isLoadingUpdate || isLoadingCreate}>
                    <AiFillSave />
                    Salvar
                </Button>
            }
        >
            <PropertiesTypesFormWrap className="administrator-panel-properties-types-form">
                <Shadow>
                    <Loading active={isLoadingGet || isLoadingUpdate || isLoadingCreate} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <InputText
                        model="sinary"
                        id="descricao"
                        required
                        label="Descrição"
                        name="descricao"
                        placeholder="Informe a descrição"
                        value={descricao}
                        onChange={setDescricao}
                    />

                    <InputText
                        model="sinary"
                        id="slug"
                        required
                        label="Slug"
                        name="slug"
                        placeholder="Informe o slug, deve ser único"
                        value={slug}
                        onChange={setSlug}
                    />

                    <InputMultiToggle
                        id="atributos"
                        label="Atributos"
                        value={atributos}
                        onChange={setAtributos}
                        items={dataAtributes}
                        indexValue='id'
                        indexLabel='descricao'
                    />
                </Shadow>
            </PropertiesTypesFormWrap>
        </Layout>
    );
};

export default PropertiesTypesForm;
