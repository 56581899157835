import styled from 'styled-components'

export const HeaderPagesWrap = styled.section`
  display: flex;
  width: 100%;

  background: url('/images/banner_pages.jpg') no-repeat center;
  background-size: cover;
  box-shadow: 5px -5px 20px 0px rgba(64, 71, 83, 0.2);

  .alignament {
    height: 13.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .line-atom {
    margin: 1.25rem 0;
  }
`
