import { convertFloatToBR, convertDateENToBR, maskCellphone } from 'utils';

import { InvoiceDetailsWrap } from './styles';

import type { InvoiceDetailsInterface } from './interface';

const InvoiceDetails = ({ data }: InvoiceDetailsInterface) => {
    const faturasDetalhes = data?.faturasDetalhes || [];

    const devedores = data?.devedores;
    const imobiliarias = data?.imobiliarias;

    const logo = data?.imobiliarias?.imagens?.find(
        (itemImage) => itemImage.tipo === 'logoHeader'
    );

    return (
        <InvoiceDetailsWrap className="invoice-details-organism number">
            <div className="header">
                <div>
                    <span className="title">{imobiliarias?.pessoas?.nome}</span>
                    <span className="values">
                        {imobiliarias?.pessoas?.logradouro || 'Endereço N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.numero || 'n° N/I'}
                    </span>
                    <span className="values">
                        {imobiliarias?.pessoas?.bairros?.nome || 'Bairro N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.bairros?.cidade?.nome ||
                            'Cidade N/I'}
                        {' - '}
                        {imobiliarias?.pessoas?.bairros?.cidade?.uf ||
                            'Estado N/I'}
                    </span>
                    <span className="values">
                        {imobiliarias?.pessoas?.telefone
                            ? maskCellphone(imobiliarias?.pessoas?.telefone)
                            : 'Telefone N/I'}
                    </span>
                </div>

                {logo && (
                    <div className="logo">
                        <img src={logo.url} alt={imobiliarias?.pessoas?.nome} />
                    </div>
                )}
            </div>

            <div className="description">
                <div>
                    <span className="title">Cobrança para: </span>
                    <span className="values">{devedores?.nome}</span>
                    <span className="values">
                        {devedores?.logradouro || 'Endereço N/I'}
                        {' - '}
                        {devedores?.numero || 'n° N/I'}
                    </span>
                    <span className="values">
                        {devedores?.bairros?.nome || 'Bairro N/I'}
                        {' - '}
                        {devedores?.bairros?.cidade?.nome || 'Cidade N/I'}
                        {' - '}
                        {devedores?.bairros?.cidade?.uf || 'Estado N/I'}
                    </span>
                    <span className="values">
                        {devedores?.telefone
                            ? maskCellphone(devedores?.telefone)
                            : 'Telefone N/I'}
                    </span>
                </div>
                <div>
                    <span className="values">
                        Competência:{' '}
                        {data?.competencia?.split('-').reverse().join('/')}
                    </span>
                    <span className="values">
                        Fatura n°: {String(data?.id).padStart(9, '0')}
                    </span>
                    <span className="values">
                        Emitida: {convertDateENToBR(data?.createdAt)}
                    </span>

                    <span className="values">
                        Vencimento: {convertDateENToBR(data?.dataVencimento)}
                    </span>
                </div>
            </div>

            <table className="number">
                <thead>
                    <tr>
                        <th>descrição</th>
                        <th className="value">valor</th>
                    </tr>
                </thead>

                <tbody>
                    {faturasDetalhes?.map((faturasDetalhesItem) => (
                        <tr>
                            <td>{faturasDetalhesItem?.descricao}</td>
                            <td className="value">
                                {convertFloatToBR(
                                    faturasDetalhesItem?.valor,
                                    2,
                                    'R$ '
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={2} className="value">
                            <strong>
                                Total: {convertFloatToBR(data?.valor, 2, 'R$ ')}
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </InvoiceDetailsWrap>
    );
};

export default InvoiceDetails;
