import styled from 'styled-components'

export const InputMultiToggleWrap = styled.div`
  .input-capsule-atom {
    .input {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      border-bottom: 0.063rem solid
        ${props => props.theme.colors.borderSepternary}!important;
      padding-bottom: 0.625rem;
    }
  }
`
