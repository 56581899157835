import { Outlet } from 'react-router-dom';

import { NoLayoutWrap } from './styles';

const NoLayout = () => (
        <NoLayoutWrap className="no-layout-template">
        

            <Outlet />

        </NoLayoutWrap>
    );

export default NoLayout;