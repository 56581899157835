import styled from 'styled-components'

export const InputTextWrap = styled.div`
  width: 100%;

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-15%);
    right: 3.125rem;

    font-size: 1.25rem;
    color: ${props => props.theme.colors.textQuaternary};
  }
`
