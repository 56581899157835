import { Helmet } from 'react-helmet';

import { RealStateIntegrationRegister } from 'components/templates';

const RealStateIntegrationRegisterPage = () => (
    <>
        <Helmet>
            <title>Registro de Integração - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateIntegrationRegister />
    </>
);

export default RealStateIntegrationRegisterPage;
