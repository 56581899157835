import styled, { css } from 'styled-components';

const variations = {
    solid: {
        header: css`
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderPrimary};
            background-color: ${(props) => props.theme.colors.primary};
            color: ${(props) => props.theme.colors.textSenary};

            &:hover {
                background-color: transparent;
                color: ${(props) => props.theme.colors.primary};
            }
        `,
        colorful: {
            facebook: css`
                border: 0.062rem solid ${(props) => props.theme.colors.facebook};
                background-color: ${(props) => props.theme.colors.facebook};
                color: ${(props) => props.theme.colors.textSenary};

                &:hover {
                    background-color: transparent;
                    color: ${(props) => props.theme.colors.facebook};
                }
            `,
            youtube: css`
                border: 0.062rem solid ${(props) => props.theme.colors.youtube};
                background-color: ${(props) => props.theme.colors.youtube};
                color: ${(props) => props.theme.colors.textSenary};
                &:hover {
                    background-color: transparent;
                    color: ${(props) => props.theme.colors.youtube};
                }
            `,
            instagram: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.instagram};
                background-color: ${(props) => props.theme.colors.instagram};
                color: ${(props) => props.theme.colors.textSenary};
                &:hover {
                    background-color: transparent;
                    color: ${(props) => props.theme.colors.instagram};
                }
            `,
            whatsapp: css`
                border: 0.062rem solid ${(props) => props.theme.colors.whatsapp};
                background-color: ${(props) => props.theme.colors.whatsapp};
                color: ${(props) => props.theme.colors.textSenary};
                &:hover {
                    background-color: transparent;
                    color: ${(props) => props.theme.colors.whatsapp};
                }
            `,
            email: css`
                border: 0.062rem solid ${(props) => props.theme.colors.email};
                background-color: ${(props) => props.theme.colors.email};
                color: ${(props) => props.theme.colors.textSenary};

                &:hover {
                    background-color: transparent;
                    color: ${(props) => props.theme.colors.email};
                }
            `,
        },
        footer: css`
            border: 0.062rem solid ${(props) => props.theme.colors.secondary};
            background-color: ${(props) => props.theme.colors.secondary};
            color: ${(props) => props.theme.colors.textSenary};
            &:hover {
                background-color: transparent;
                color: ${(props) => props.theme.colors.secondary};
            }
        `,
    },
    nosolid: {
        header: css`
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderPrimary};
            color: ${(props) => props.theme.colors.primary};

            &:hover {
                background-color: ${(props) => props.theme.colors.primary};
                color: ${(props) => props.theme.colors.textSenary};
            }
        `,
        colorful: {
            facebook: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.borderPrimary};
                color: ${(props) => props.theme.colors.facebook};

                &:hover {
                    background-color: ${(props) => props.theme.colors.facebook};
                    color: ${(props) => props.theme.colors.textSenary};
                }
            `,
            youtube: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.borderPrimary};
                color: ${(props) => props.theme.colors.youtube};
                &:hover {
                    background-color: ${(props) => props.theme.colors.youtube};
                    color: ${(props) => props.theme.colors.textSenary};
                }
            `,
            instagram: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.borderPrimary};
                color: ${(props) => props.theme.colors.instagram};
                &:hover {
                    background-color: ${(props) =>
                        props.theme.colors.instagram};
                    color: ${(props) => props.theme.colors.textSenary};
                }
            `,
            whatsapp: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.borderPrimary};
                color: ${(props) => props.theme.colors.whatsapp};
                &:hover {
                    background-color: ${(props) => props.theme.colors.whatsapp};
                    color: ${(props) => props.theme.colors.textSenary};
                }
            `,
            email: css`
                border: 0.062rem solid
                    ${(props) => props.theme.colors.borderPrimary};
                color: ${(props) => props.theme.colors.email};
                &:hover {
                    background-color: ${(props) => props.theme.colors.email};
                    color: ${(props) => props.theme.colors.textSenary};
                }
            `,
        },
        footer: css`
            border: 0.062rem solid ${(props) => props.theme.colors.secondary};
            color: ${(props) => props.theme.colors.secondary};
            &:hover {
                background-color: ${(props) => props.theme.colors.secondary};
                color: ${(props) => props.theme.colors.textSenary};
            }
        `,
    },
};

function getColor(
    solid: 'solid' | 'nosolid',
    color: 'header' | 'colorful' | 'footer',
    social: 'facebook' | 'instagram' | 'youtube' | 'email' | 'whatsapp'
) {
    if (color !== 'colorful') {
        return variations[solid][color];
    }

    return variations[solid][color][social];
}

interface SocialIconWrapInterface {
    variation: 'header' | 'colorful' | 'footer';
    social: 'facebook' | 'instagram' | 'youtube' | 'email' | 'whatsapp';
    solid: boolean;
}

export const SocialIconWrap = styled.a<SocialIconWrapInterface>`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.13rem;
    width: 3.13rem;
    border-radius: 100%;

    font-size: 1.38rem;

    ${(props) =>
        getColor(
            props.solid ? 'solid' : 'nosolid',
            props.variation,
            props.social
        )};
`;
