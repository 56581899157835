import { MdClose } from 'react-icons/md';

import { ImageListLineWrap, File } from './styles';

import type { ICUploadFiles } from '@types';
import type { ImageListLineInterface } from './interface';

const ImageListLine = ({ files, onDelete }: ImageListLineInterface) => {
    function getNameAndExtension(file: ICUploadFiles): {
        name: string;
        extension: string;
    } {
        if(file?.name === undefined) {
            return {
                name: "N/A",
                extension: "N/A"
            }
        }

        const splitedName = file.name.split('.');

        const extension = splitedName[splitedName.length - 1];

        return {
            name: file.name.slice(-splitedName + extension.length),
            extension,
        };
    }

    return (
        <ImageListLineWrap className="image-list-line-molecule">
            {Array.isArray(files) &&
                files?.map((uploadedFile) => (
                    <File
                        key={uploadedFile.id}
                        src={uploadedFile.preview || uploadedFile.url}
                        error={!!uploadedFile.error}
                        uploaded={!!uploadedFile.uploaded}
                    >
                        <div className="box-left">
                            <span className="extension">
                                {getNameAndExtension(uploadedFile).extension ||
                                    'N/I'}
                            </span>

                            <span className="title">
                                {getNameAndExtension(uploadedFile).name}
                            </span>
                        </div>

                        <div className="box-right">
                            <span className="percentage">
                                {`${uploadedFile.progress || '100'}%`}
                            </span>
                            <span className="size">
                                {uploadedFile.readableSize}
                            </span>

                            <button
                                type="button"
                                className="close"
                                onClick={() => onDelete(uploadedFile.id)}
                            >
                                <MdClose size="0.838rem" />
                            </button>
                        </div>
                    </File>
                ))}
        </ImageListLineWrap>
    );
};

export default ImageListLine;
