import styled, { css } from 'styled-components';

interface InputCapsuleWrapInterface {
    borderLeft: boolean;
    borderRight: boolean;
    borderTop: boolean;
    borderBottom: boolean;

    empty: boolean;
    model: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'sinary';
}

const models = {
    primary: css`
        border-color: ${(props) => props.theme.colors.borderQuaternary};
        background-color: ${(props) => props.theme.colors.senary};

        label {
            color: ${(props) => props.theme.colors.textSecondary};
            font-weight: 800;
        }

        .input {
            input,
            select,
            textarea,
            .input-fake {
                background-color: ${(props) => props.theme.colors.senary};
                color: ${(props) => props.theme.colors.textSecondary};
            }

            select {
                margin-left: -0.25rem;
            }
        }
    `,
    secondary: css`
        border-color: ${(props) => props.theme.colors.tertiary};
        background-color: ${(props) => props.theme.colors.tertiary};

        label {
            color: ${(props) => props.theme.colors.textSenary};
            font-weight: 900;
        }

        .input {
            input,
            select,
            textarea,
            .input-fake {
                background-color: ${(props) => props.theme.colors.tertiary};
                color: ${(props) => props.theme.colors.textSenary};
            }
        }
    `,
    tertiary: css`
        padding: 0;

        label {
            color: ${(props) => props.theme.colors.textSecondary};
            font-weight: 300;
            strong {
                font-weight: 900;
                color: ${(props) => props.theme.colors.textQuinary};
                margin-left: 0.313rem;
            }
        }

        .input {
            input,
            select,
            textarea,
            .input-fake {
                padding: 1.2rem 1.88rem;
                height: 3.75rem;
                line-height: 3.75rem;
                color: ${(props) => props.theme.colors.textSecondary};
                border: 0.068rem solid
                    ${(props) => props.theme.colors.borderSenary};

                &:focus,
                &.is-valid,
                &.is-invalid {
                    border: 0.068rem solid
                        ${(props) => props.theme.colors.borderSenary};
                }
            }
        }
    `,
    quaternary: css`
        padding: 0;

        label {
            color: ${(props) => props.theme.colors.textSecondary};
            font-weight: 900;
        }

        .input {
            input,
            select,
            textarea,
            .input-fake {
                padding: 1.05rem 1.88rem;
                height: 3.75rem;
                line-height: 3.75rem;
                color: ${(props) => props.theme.colors.textSecondary};
                border: 0.068rem solid
                    ${(props) => props.theme.colors.borderSenary};

                &:focus,
                &.is-valid,
                &.is-invalid {
                    border: 0.068rem solid
                        ${(props) => props.theme.colors.borderSenary};
                }
            }
        }
    `,
    sinary: css`
        position: relative;

        label {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;

            span {
                color: #f64e60;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .input {
            input,
            select,
            textarea,
            .input-fake,
            .box {
                width: 100%;
                height: 1.813rem;
                border: 0;
                outline: 0;

                font-size: ${(props: InputCapsuleWrapInterface) =>
                    props.empty ? '0.938rem' : '1.125rem'};
                font-weight: 300;
                color: ${(props) => props.theme.colors.textPrimary};
                opacity: ${(props) => (props.empty ? 0.6 : 1)};
                background-color: transparent;
                border-bottom: 0.063rem solid
                    ${(props) => props.theme.colors.borderSepternary}!important;
                padding: 1.25rem 0;

                &:focus,
                &.is-valid,
                &.is-invalid {
                    border: 0;
                    outline: 0;
                }

                &:focus {
                    border-bottom: 0.063rem solid
                        ${(props) => props.theme.colors.tertiaryBold}!important;
                }
            }

            textarea,
            .box {
                height: 8.13rem;
            }

            select {
                padding: 0.6rem 0;
                height: auto;
            }
        }

        @media (max-width: 992px) {
            border: 0px solid transparent;
        }
    `,
};

export const InputCapsuleWrap = styled.div<InputCapsuleWrapInterface>`
    width: 100%;
    padding: 1.25rem 1.88rem;
    font-family: 'Work Sans', sans-serif !important;

    border-left: ${(props) =>
        props.borderLeft ? '0.062rem solid transparent' : 0};
    border-right: ${(props) =>
        props.borderRight ? '0.062rem solid transparent' : 0};
    border-bottom: ${(props) =>
        props.borderBottom ? '0.062rem solid transparent' : 0};
    border-top: ${(props) =>
        props.borderTop ? '0.062rem solid transparent' : 0};

    position: relative;

    label {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        line-height: 120%;
        text-transform: uppercase;

        margin-bottom: 0.68rem;

        span {
            color: #f64e60;
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    .input {
        input,
        select,
        textarea,
        .input-fake {
            font-family: 'Work Sans', sans-serif !important;
            width: 100%;
            height: 1.63rem;
            border: 0;
            outline: 0;

            font-size: 1.13rem;
            font-weight: ${(props) => (props.empty ? 300 : 700)};
            opacity: ${(props) => (props.empty ? 0.6 : 1)};

            &:focus,
            &.is-valid,
            &.is-invalid {
                border: 0;
                outline: 0;
            }
        }

        textarea {
            height: 8.13rem;
        }

        .input-fake {
            height: 2.6rem!important;
            padding: 0 0 0.5rem 0!important;
        }
    }

    @media (max-width: 992px) {
        border: 1px solid ${(props) => props.theme.colors.borderQuaternary};
    }

    ${(props) => models[props.model]};
`;
