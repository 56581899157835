import { generateURL } from './prefix'
import api from '../api';

import type { IAInvoiceSettlement } from '@types';

export const getRealStateInvoiceSettlementIndex = async (): Promise<
    IAInvoiceSettlement[]
> => {
    const url = generateURL(`/acertos`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateInvoiceSettlementFilter = async (ids: string): Promise<
    IAInvoiceSettlement[]
> => {
    const url = generateURL(`/acertos/${ids}`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateInvoiceSettlementGenerate = async (
    period: string,
    ownerId?: string
): Promise<{
    message: string;
}> => {
    let url = generateURL(`/acertos/gerar/${period}`);

    if(ownerId) {
        url = `${url}?proprietariosIds=${ownerId}`;
    }

    const { data } = await api.get(url);

    return data;
};

export const payRealStateInvoiceSettlement = async (
    id: string | number
): Promise<{
    message: string;
}> => {
    const url = generateURL(`/acertos/${id}/pagar`);

    const { data } = await api.post(url);

    return data;
};
