import { Helmet } from 'react-helmet';

import { RealStateFinancialList } from 'components/templates';

const RealStateFinancialListPage = () => (
    <>
        <Helmet>
            <title>Financeiro - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateFinancialList />
    </>
);

export default RealStateFinancialListPage;
