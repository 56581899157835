import styled from 'styled-components'

export const InputColorWrap = styled.div`
  width: 100%;

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-15%);
    right: 3.125rem;

    font-size: 1.25rem;
    color: ${props => props.theme.colors.textQuaternary};
  }

  .input {
    display: flex;
    flex-direction: row;

    input {
      padding: 0 !important;
      height: 2.5rem !important;
    }
  }
`
