import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useMemo, useState } from 'react';
import { GiMoneyStack } from 'react-icons/gi';
import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
    QUERIES_PUBLIC,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Table } from 'components/organisms';
import { Badge, Button, CardInfo, Grid, HelperBoolean, HelperDate, HelperMoney, HelperOptions, Line, Row } from 'components/atoms';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getPublicPaymentMethodIndex, getRealStateFinanceIndex, getRealStateOwnerIndex, removeRealStateFinance  } from 'services';
import { sweetAlert, convertFloatToBR } from 'utils';

import { InputMask, InputText, Modal, Select } from 'components/molecules';
import { financeEnums } from 'enums';
import { FinancialListWrap } from './styles';
import Layout from '../../Layout';

const defaultFilters = {
    descricao: '',
    numeroDocumento: '',
    pessoasIds: '',
    formasPagamentosIds: '',
    status: '',
    tipoDeConta: '',
    dataVencimentoPeriodo: '',
    dataPagamentoPeriodo: '',
    dataDocumentoPeriodo: '',
}

const Finance = () => {
    const navigate = useNavigate();
    const title = 'Financeiro';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
    const [controlledFilters, setControlledFilters] = useState(defaultFilters);
    const [applyFilters, setApplyFilters] = useState(defaultFilters);


    function onHandleNavigate(param: string) {
        navigate(param);
    }

    function newRegister() {
        onHandleNavigate(
            getLinkWithSlug(ROUTES_REAL_STATE.FINANCIAL.FORM.fullPath)
        );
    }

    const queryClientWithFilters = [
        QUERIES_REAL_STATE.FINANCIAL.base,
        `descricao=${applyFilters.descricao}`,
        `numeroDocumento=${applyFilters.numeroDocumento}`,
        `pessoasIds=${applyFilters.pessoasIds}`,
        `formasPagamentosIds=${applyFilters.formasPagamentosIds}`,
        `status=${applyFilters.status}`,
        `tipoDeConta=${applyFilters.tipoDeConta}`,
        `dataVencimentoPeriodo=${applyFilters.dataVencimentoPeriodo}`,
        `dataPagamentoPeriodo=${applyFilters.dataPagamentoPeriodo}`,
        `dataDocumentoPeriodo=${applyFilters.dataDocumentoPeriodo}`,
    ]

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        queryClientWithFilters,
        () => getRealStateFinanceIndex(applyFilters),
        {
            refetchOnMount: true,

        }
    );

    const { data: paymentMethodList } = useQuery(
        QUERIES_PUBLIC.PAYMENT_METHOD.list,
        () => getPublicPaymentMethodIndex(),
        {
            select(data) {
                if (Array.isArray(data)) {
                    return data.map((item) => ({
                        label: item.nome,
                        value: item.id,
                    }))
                }

                return []
            },
        }
    );

    const { data: dataOwners } = useQuery(QUERIES_REAL_STATE.OWNER.base, () =>
        getRealStateOwnerIndex(),
        {
            select(data) {
                if (Array.isArray(data)) {
                    return data?.map((item) => ({
                        label: item.nome,
                        value: item.id,
                    }))
                }

                return []
            },
        }
    );

    const { mutate: removeRegister, isLoading: isLoadingRemove } = useMutation(
        ({ id }: { id: number }) => removeRealStateFinance(Number(id)),
        {
            mutationKey: QUERIES_REAL_STATE.FINANCIAL.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(QUERIES_REAL_STATE.FINANCIAL.base);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function editRegister(id: string | number) {
        onHandleNavigate(
            getLinkWithSlug(`${ROUTES_REAL_STATE.FINANCIAL.FORM.fullPath}/${id}`)
        );
    }

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    const onHandleModal = () => {
        setIsOpenModalFilter(!isOpenModalFilter);
    }


    const onCleanFilters = () => {
        setControlledFilters(defaultFilters);
        setApplyFilters(defaultFilters);
        setIsOpenModalFilter(!isOpenModalFilter);
    }

    const onApplyFilters = () => {

        let dataDocumentoPeriodoFormatted = '';
        if (controlledFilters.dataDocumentoPeriodo) {
            const [initialDate, finalDate] = controlledFilters.dataDocumentoPeriodo.split(' à ');
            dataDocumentoPeriodoFormatted = `${initialDate.split('/').reverse().join('-')}~${finalDate.split('/').reverse().join('-')}`;
        }

        let dataPagamentoPeriodoFormatted = '';
        if (controlledFilters.dataPagamentoPeriodo) {
            const [initialDate, finalDate] = controlledFilters.dataPagamentoPeriodo.split(' à ');
            dataPagamentoPeriodoFormatted = `${initialDate.split('/').reverse().join('-')}~${finalDate.split('/').reverse().join('-')}`;
        }

        let dataVencimentoPeriodoFormatted = '';
        if (controlledFilters.dataVencimentoPeriodo) {
            const [initialDate, finalDate] = controlledFilters.dataVencimentoPeriodo.split(' à ');
            dataVencimentoPeriodoFormatted = `${initialDate.split('/').reverse().join('-')}~${finalDate.split('/').reverse().join('-')}`;
        }

        setApplyFilters({
            ...controlledFilters,
            dataDocumentoPeriodo: dataDocumentoPeriodoFormatted,
            dataPagamentoPeriodo: dataPagamentoPeriodoFormatted,
            dataVencimentoPeriodo: dataVencimentoPeriodoFormatted,
        });
        setIsOpenModalFilter(!isOpenModalFilter);
    }


    const handleFilters = (filterKey: string, filterValue: string) => {
        setControlledFilters({
            ...controlledFilters,
            [filterKey]: filterValue,
        })
    }

    const countApplyFilters = Object.values(applyFilters).filter((value: string) => value !== '').length;

    const amountsCalculated = useMemo(() => {
        const incomesPreview = dataList?.filter((item) => item.tipoDeConta === 'A_RECEBER' && item.status === 'PENDENTE');
        const incomesPayed = dataList?.filter((item) => item.tipoDeConta === 'A_RECEBER' && item.status === 'PAGO');
        const expensesPreview = dataList?.filter((item) => item.tipoDeConta === 'A_PAGAR' && item.status === 'PENDENTE');
        const expensesPayed = dataList?.filter((item) => item.tipoDeConta === 'A_PAGAR' && item.status === 'PAGO');

        return {
            totalIncomePreview: incomesPreview?.reduce((acc, item) => acc + Number(item.valorPrevisto), 0),
            totalIncomePayed: incomesPayed?.reduce((acc, item) => acc + Number(item.valorPago), 0),
            totalExpensePreview: expensesPreview?.reduce((acc, item) => acc + Number(item.valorPrevisto), 0),
            totalExpensePayed: expensesPayed?.reduce((acc, item) => acc + Number(item.valorPago), 0),
        }
    }, [dataList]);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_REAL_STATE.FINANCIAL.fullPath),
                },
            ]}
            title={title}
            buttonTitle={
                <Row>
                    <Button color="septernary" onClick={() => onHandleModal()}>
                        Filtros {countApplyFilters > 0 && <Badge color="denary" style={{ marginLeft: '1rem' }}>{countApplyFilters}</Badge>}
                    </Button>
                    <Button color="octernary" onClick={() => newRegister()}>
                        Novo Registro
                    </Button>
                </Row>
            }
        >
            <FinancialListWrap className="financial-template">

                <Grid numberColumns={4} gridGap='1.563rem' style={{ marginBottom: '1.563rem' }}>
                    <CardInfo
                        description='Receita Prevista'
                        icon={<GiMoneyStack />}
                        number={convertFloatToBR(amountsCalculated.totalIncomePreview, 2, "R$ ")}
                        variation='secondary'
                        isLoading={isLoadingList}
                    />

                    <CardInfo
                        description='Receita Realizada'
                        icon={<GiMoneyStack />}
                        number={convertFloatToBR(amountsCalculated.totalIncomePayed, 2, "R$ ")}
                        variation='secondary'
                        isLoading={isLoadingList}

                    />

                    <CardInfo
                        description='Despesa Prevista'
                        icon={<GiMoneyStack />}
                        number={convertFloatToBR(amountsCalculated.totalExpensePreview, 2, "R$ ")}
                        variation='danger'
                        isLoading={isLoadingList}

                    />

                    <CardInfo
                        description='Despesa Realizada'
                        icon={<GiMoneyStack />}
                        number={convertFloatToBR(amountsCalculated.totalExpensePayed, 2, "R$ ")}
                        variation='danger'
                        isLoading={isLoadingList}

                    />
                </Grid>


                <Table
                    isLoading={isLoadingList || isLoadingRemove}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'ID',
                            sort: true,
                            headerStyle: { width: '10%' }
                        },
                        {
                            dataField: 'descricao',
                            text: 'Descrição',
                            sort: true,
                            headerStyle: { width: '30%' }
                        },
                        {
                            dataField: 'valorPrevisto',
                            text: 'Previsto',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperMoney value={fieldValue} />
                            ),
                            headerStyle: { width: '20%' },
                        },
                        {
                            dataField: 'dataVencimento',
                            text: 'Vencimento',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperDate value={fieldValue} />
                            ),
                            headerStyle: { width: '20%' },
                        },

                        {
                            dataField: 'dataPagamento',
                            text: 'Pagamento',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperDate value={fieldValue} />
                            ),
                            headerStyle: { width: '20%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Pago',
                            sort: true,
                            formatter: (fieldValue) => (
                                <HelperBoolean value={fieldValue === 'PAGO'} />
                            ),
                            headerStyle: { width: '10%' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    editFunction: () => editRegister(fieldValue),
                                    removeFunction: () => removeRegisterRequest(fieldValue)
                                })
                        }
                    ]}
                    data={dataList}
                />

                <Modal
                    title="Filtros"
                    open={isOpenModalFilter}
                    onCloseModal={onHandleModal}
                    footer={
                        <Row>
                            <Button color="danger" onClick={() => onCleanFilters()}>Limpar</Button>
                            <Button color="octernary" onClick={() => onApplyFilters()}>Aplicar</Button>
                        </Row>
                    }
                    size='lg'
                >
                    <Grid numberColumns={2}>
                        <InputText
                            label="Descrição"
                            placeholder="Filtre por descrição"
                            name='descricao'
                            model="sinary"
                            value={controlledFilters.descricao}
                            onChange={(value) => handleFilters('descricao', value)}
                        />

                        <InputText
                            label="N Documento"
                            placeholder="Filtre por número do documento"
                            name='numeroDocumento'
                            model="sinary"
                            value={controlledFilters.numeroDocumento}
                            onChange={(value) => handleFilters('numeroDocumento', value)}
                        />
                    </Grid>

                    <Grid numberColumns={2}>
                        <Select
                            name='formasPagamentosIds'
                            label="Forma de Pagamento"
                            placeholder="Informe a forma de pagamento"
                            options={paymentMethodList}
                            model="sinary"
                            value={controlledFilters.formasPagamentosIds}
                            onChange={(value) => handleFilters('formasPagamentosIds', value)}

                        />

                        <Select
                            name='pessoasIds'
                            label="Pessoa"
                            placeholder="Informe a pessoa"
                            options={dataOwners}
                            model="sinary"
                            value={controlledFilters.pessoasIds}
                            onChange={(value) => handleFilters('pessoasIds', value)}
                        />

                    </Grid>


                    <Grid numberColumns={2}>
                        <Select
                            label="Status"
                            name="status"
                            placeholder="Filtre por status"
                            model="sinary"
                            options={financeEnums.financeStatus}
                            value={controlledFilters.status}
                            onChange={(value) => handleFilters('status', value)}
                        />


                        <Select
                            label="Tipo de Conta"
                            name="tipoDeConta"
                            placeholder="Filtre por tipo de conta"
                            model="sinary"
                            options={financeEnums.financeTypes}
                            value={controlledFilters.tipoDeConta}
                            onChange={(value) => handleFilters('tipoDeConta', value)}
                        />

                    </Grid>

                    <Line type='secondary' />

                    <Grid numberColumns={2}>
                        <InputMask
                            valueMasked
                            name="dataPagamento"
                            mask="99/99/9999 à 99/99/9999"
                            label="Data Pagamento"
                            placeholder="Filtre por data pagamento"
                            model="sinary"
                            value={controlledFilters.dataPagamentoPeriodo}
                            onChange={(value) => handleFilters('dataPagamentoPeriodo', value)}
                        />

                        <InputMask
                            valueMasked
                            name="dataVencimento"
                            mask="99/99/9999 à 99/99/9999"
                            label="Data Vencimento"
                            placeholder="Filtre por data vencimento"
                            model="sinary"
                            value={controlledFilters.dataVencimentoPeriodo}
                            onChange={(value) => handleFilters('dataVencimentoPeriodo', value)}
                        />

                    </Grid>
                </Modal>
            </FinancialListWrap>
        </Layout>
    );
};

export default Finance;
