import React from 'react';

import SVGInterface from '../interface';

const FinishIntegration: React.FC<SVGInterface> = ({
  color,
  height = 567,
  width = 920,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={width}
    height={height}
    viewBox="0 0 650.11366 477.61186"
  >
    <path
      d="M447.62579,563.92822c-95.21778,0-172.68262-77.46484-172.68262-172.68262S352.408,218.563,447.62579,218.563a173.06169,173.06169,0,0,1,141.16113,73.19336l-1.63379,1.15429A171.05768,171.05768,0,0,0,447.62579,220.563c-94.11426,0-170.68262,76.56836-170.68262,170.68261s76.56836,170.68262,170.68262,170.68262a169.5876,169.5876,0,0,0,135.49609-66.87207l1.58594,1.2168A171.569,171.569,0,0,1,447.62579,563.92822Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#e6e6e6"
    />
    <path
      d="M519.01551,362.328a9.572,9.572,0,1,1,9.572-9.572A9.572,9.572,0,0,1,519.01551,362.328Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#e6e6e6"
    />
    <path
      d="M522.35382,354.759l-2.003-2.003,2.003-2.003a.94424.94424,0,1,0-1.33535-1.33535l-2.003,2.003-2.003-2.003a.94422.94422,0,0,0-1.33533,1.33533l2.003,2.003-2.003,2.003a.94424.94424,0,0,0,1.33535,1.33535l2.003-2.003,2.003,2.003a.94422.94422,0,1,0,1.33533-1.33533Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <circle cx="196.46801" cy="19.04874" r="19.04875" fill="#e6e6e6" />
    <path
      d="M468.81254,240.1568a2.1183,2.1183,0,0,1-1.695-.84774l-5.19679-6.92916a2.11886,2.11886,0,1,1,3.39026-2.54253l3.39992,4.53288,8.73224-13.0982a2.119,2.119,0,0,1,3.52615,2.35077l-10.39358,15.59036a2.11978,2.11978,0,0,1-1.70427.94293Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <rect
      x="168.91529"
      y="53.41449"
      width="55.94642"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <rect
      x="140.50202"
      y="77.34146"
      width="112.77296"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <rect
      x="140.50202"
      y="101.26843"
      width="112.77296"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <path
      d="M460.72206,567.57545a9.572,9.572,0,1,1,9.572-9.572A9.572,9.572,0,0,1,460.72206,567.57545Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#e6e6e6"
    />
    <path
      d="M464.06037,560.00643l-2.003-2.003,2.003-2.003a.94423.94423,0,0,0-1.33534-1.33535l-2.003,2.003-2.003-2.003a.94422.94422,0,0,0-1.33533,1.33533l2.003,2.003-2.003,2.003a.94423.94423,0,1,0,1.33534,1.33535l2.003-2.003,2.003,2.003a.94422.94422,0,1,0,1.33533-1.33533Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <circle cx="138.17456" cy="224.29618" r="19.04875" fill="#e6e6e6" />
    <path
      d="M410.51908,445.40424a2.11829,2.11829,0,0,1-1.69495-.84774l-5.19679-6.92916a2.11886,2.11886,0,1,1,3.39026-2.54253l3.39991,4.53288,8.73225-13.0982a2.119,2.119,0,1,1,3.52615,2.35077l-10.39358,15.59037a2.11981,2.11981,0,0,1-1.70427.94292Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <rect
      x="110.62184"
      y="258.66194"
      width="55.94642"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <rect
      x="82.20857"
      y="282.5889"
      width="112.77296"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <rect
      x="82.20857"
      y="306.51587"
      width="112.77296"
      height="11.96348"
      rx="5.06951"
      fill="#e6e6e6"
    />
    <path
      d="M658.73263,526.73553a13.21806,13.21806,0,1,1,13.21806-13.21806A13.21807,13.21807,0,0,1,658.73263,526.73553Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#e6e6e6"
    />
    <path
      d="M663.34251,516.28344l-2.76592-2.76592,2.766-2.76595a1.3039,1.3039,0,0,0-1.844-1.844l-2.766,2.766-2.766-2.766a1.30388,1.30388,0,0,0-1.844,1.844l2.76595,2.76595-2.76595,2.766a1.3039,1.3039,0,0,0,1.844,1.844l2.76595-2.76595,2.76592,2.76592a1.30388,1.30388,0,0,0,1.844-1.844Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <circle cx="326.19937" cy="133.14441" r="26.3045" fill={color} />
    <path
      d="M597.55406,358.02875a2.92518,2.92518,0,0,1-2.34058-1.17064l-7.17627-9.56852a2.92595,2.92595,0,1,1,4.68163-3.511l4.695,6.25947,12.0584-18.08737a2.92607,2.92607,0,1,1,4.86927,3.24619l-14.35254,21.52881a2.92726,2.92726,0,0,1-2.35343,1.30209C597.60835,358.02828,597.5812,358.02875,597.55406,358.02875Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#fff"
    />
    <rect
      x="288.15169"
      y="180.60024"
      width="77.25667"
      height="16.52043"
      rx="5.06951"
      fill="#3f3d56"
    />
    <rect
      x="248.91568"
      y="213.64109"
      width="155.7287"
      height="16.52043"
      rx="5.06951"
      fill="#3f3d56"
    />
    <rect
      x="248.91568"
      y="246.68195"
      width="155.7287"
      height="16.52043"
      rx="5.06951"
      fill="#3f3d56"
    />
    <polygon
      points="450.676 463.637 437.92 463.636 431.851 414.433 450.679 414.434 450.676 463.637"
      fill="#ffb6b6"
    />
    <path
      d="M725.27906,670.66458l-13.62-5.54-.39-.16-7.52,5.7a16.01266,16.01266,0,0,0-16.01,16.01v.52h41.13v-16.53Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#2f2e41"
    />
    <polygon
      points="558.496 452.952 546.314 456.737 525.916 411.551 543.896 405.965 558.496 452.952"
      fill="#ffb6b6"
    />
    <path
      d="M831.87728,660.26845l-14.6505-1.24835-.41994-.03708-5.48956,7.67493A16.01269,16.01269,0,0,0,800.77987,686.698l.15431.49654,39.277-12.20625L835.30557,659.203Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#2f2e41"
    />
    <path
      d="M782.39948,422.78008l5.18807,14.75553v7.97628l-.723,8.77129,8.0301,29.25347,6.29922,64.50845,31.70078,95.49155-24.30708,7L746.52778,494.513,726.92613,652.3261l-22.22866-.593s-3.80284-9.19644-2.80284-14.19644,1,1,1-5-4.3351-3.3937-1.16755-8.19685,2.77323-9.00788,2.97039-11.90551-1.80284-119.89764-1.80284-123.89764-.69291-3.12391-.34645-7.062,4.74142-2.31915,3.54394-9.1286-1.59181-5.6191-1.39465-10.71427,2.95307-34.97005,2.95307-34.97005Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#2f2e41"
    />
    <path
      d="M699.20556,427.58018l44.56215-42.97482,12.35119-34.972-18.21837-7.922c-9.35775,8.964-19.47307,36.88414-19.47307,36.88414l-30.66508,39.013c-.239.08184-.47728.16874-.71265.27268a8.77545,8.77545,0,1,0,12.15583,9.699Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#ffb8b8"
    />
    <path
      d="M732.77418,345.61609l23.10872,7.33623.08711-.0145c8.601-1.44076,15.73808-15.48842,20.21041-27.01921a16.09129,16.09129,0,0,0-8.193-20.38362h0a16.12676,16.12676,0,0,0-16.89018,2.131l-11.86648,9.6963Z"
      transform="translate(-274.94317 -211.19407)"
      fill={color}
    />
    <path
      d="M786.32548,441.73565l.135-.17939c7.21-9.56235-2.6512-33.24234-4.142-36.67665l6.70395-1.98581-1.1685-12.76444-.56282-5.69175,5.21244-4.95.01755-.12634,3.58474-25.511,4.69041-16.88452a37.32239,37.32239,0,0,0-9.65629-36.43113l-9.58845-9.51991L770.064,273.03391l-19.26055-.49726-6.92606,12.30362a28.08226,28.08226,0,0,0-22.46071,28.10125l.65206,32.7546-6.911,40.58295-.19388,7.37174-6.78319,7.158,1.25407,7.2366-5.074,2.02112-2.48292,10.28305c-.94826,1.18639-7.20952,9.10084-7.26648,11.30721-.00855.33125.22768.65774.74323,1.02651,4.05284,2.89967,26.70525,9.1828,36.90026,5.40544,10.85957-4.02024,53.4206,3.53019,53.84939,3.607Z"
      transform="translate(-274.94317 -211.19407)"
      fill={color}
    />
    <path
      d="M739.11062,337.90144l-9.83856-19.61512c-14.28065.97549-42.62387,17.48727-42.62387,17.48727l-53.62638,11.36993a9.68825,9.68825,0,1,0,2.64134,16.55568l68.216-4.88972Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#ffb8b8"
    />
    <path
      d="M735.14245,341.12219l25.695-19.09515,4.52613-16.31071a17.81557,17.81557,0,0,0-5.35827-18.02553h0a17.77346,17.77346,0,0,0-24.25389.78253c-9.66945,9.65146-20.698,23.11613-18.329,32.45288l.024.09464Z"
      transform="translate(-274.94317 -211.19407)"
      fill={color}
    />
    <circle cx="486.12469" cy="34.03549" r="22.84874" fill="#ffb8b8" />
    <path
      d="M776.10436,269.12528c-3.82175-1.79511-8.28547-.70422-12.50455-.53819s-9.331-1.4245-10.22623-5.55085c-.66191-3.05105,1.32763-6.39514.074-9.25439-1.36715-3.11808-5.47069-3.56184-8.76148-4.43489a20.197,20.197,0,0,1-14.58438-18.90622c-.22863,1.70365,2.68647,2.26321,3.644.83567s.298-3.4034-.75775-4.75988-2.48076-2.43979-3.27694-3.9632-.64134-3.78587.91341-4.519a12.69623,12.69623,0,0,0,8.0415,6.61488c-.70719-2.1482.29272-4.56332,1.90215-6.15223a18.41178,18.41178,0,0,1,5.81093-3.422c5.26689-2.28313,11.4166-4.55509,16.517-1.92089a9.87675,9.87675,0,0,1,5.10582,6.91138c9.43478.79777,19.12284,11.01793,18.58581,20.47114-.57813,10.17663-1,11-9.4716,28.125"
      transform="translate(-274.94317 -211.19407)"
      fill="#2f2e41"
    />
    <path
      d="M890.563,687.78085H857.37645l-.14258-.25879c-.42432-.76953-.834-1.585-1.2168-2.42285-3.41845-7.31836-4.86328-15.68848-6.13818-23.07325l-.96-5.5664a3.43688,3.43688,0,0,1,5.41016-3.36231q7.56517,5.50488,15.13623,10.999c1.91113,1.39062,4.09375,3,6.18408,4.73925.20166-.97949.4126-1.96191.62353-2.93066a3.43916,3.43916,0,0,1,6.28077-1.08594l3.88281,6.23828c2.832,4.55567,5.33154,9.04493,4.82226,13.88672a.756.756,0,0,1-.01318.17578,10.94679,10.94679,0,0,1-.56348,2.33106Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#f0f0f0"
    />
    <path
      d="M923.87237,688.49862l-315.3575.30731a1.19069,1.19069,0,0,1,0-2.38135l315.3575-.30731a1.19069,1.19069,0,0,1,0,2.38135Z"
      transform="translate(-274.94317 -211.19407)"
      fill="#cacaca"
    />
  </svg>
);

export default FinishIntegration;
