import { Outlet } from 'react-router-dom';

import { Footer, Header, Nav } from 'components/organisms';
import { useAuth } from 'contexts/AuthContext';
import { useRealState } from 'contexts/RealStateContext';
import { MasterWrap } from './styles';

const Master = () => {
    const { isSigned } = useAuth();

    const {
        pessoas,
        logoHeader,
        logoFooter,
        possuiImoveisAluguel,
        possuiImoveisVenda,
        getLinkWithSlug,
    } = useRealState();

    return (
        <MasterWrap className="master-template">
            <Header
                isSigned={isSigned}
                name={pessoas?.nome}
                logo={logoHeader?.url}
                instagram={pessoas?.instagram}
                facebook={pessoas?.facebook}
                whatsapp={pessoas?.whatsapp}
                youtube={pessoas?.youtube}
                getLinkWithSlug={getLinkWithSlug}
            />

            <Nav
                possuiImoveisAluguel={possuiImoveisAluguel}
                possuiImoveisVenda={possuiImoveisVenda}
                getLinkWithSlug={getLinkWithSlug}
            />

            <Outlet />

            <Footer
                name={pessoas?.nome}
                email={pessoas?.email}
                logo={logoFooter?.url}
                instagram={pessoas?.instagram}
                facebook={pessoas?.facebook}
                whatsapp={pessoas?.whatsapp}
                phone={pessoas?.telefone}
                youtube={pessoas?.youtube}
                publicPlace={pessoas?.logradouro}
                complement={pessoas?.complemento}
                number={pessoas?.numero}
                neighborhood={pessoas?.bairros?.nome}
                city={pessoas?.bairros?.cidade?.nome}
                state={pessoas?.bairros?.cidade?.uf}
                haveRentProperties={possuiImoveisAluguel}
                haveSellProperties={possuiImoveisVenda}
                getLinkWithSlug={getLinkWithSlug}
            />
        </MasterWrap>
    );
};

export default Master;
