import styled, { css } from 'styled-components'

interface LineWrapInterface {
  type: 'primary' | 'secondary'
}

const colorsTypes = {
  primary: css`
    background-color: ${props => props.theme.colors.borderQuaterback};
  `,
  secondary: css`
    background-color: ${props => props.theme.colors.borderSecondary};
  `
}

export const LineWrap = styled.hr<LineWrapInterface>`
  height: 0.062rem;
  width: 100%;
  border: 0 solid transparent;

  ${props => colorsTypes[props.type]};
`
