
import { ButtonLineWrap } from './styles';

import type { ButtonLineInterface } from './interface';

const ButtonLine = ({
    color = 'primary',
    children,
    href,
    onClick = () => null,
    style,
    className = '',
}: ButtonLineInterface) => {
    if (!href) {
        return (
            <ButtonLineWrap
                color={color}
                className={`button-line-atom ${className}`}
            >
                <button onClick={() => onClick()} type="button" style={style}>
                    {children}
                </button>
            </ButtonLineWrap>
        );
    }

    return (
        <ButtonLineWrap color={color} className={`button-line-atom ${className}`}
        >
            <a href={href} style={style} target="_blank" rel="noreferrer">
                {children}
            </a>
        </ButtonLineWrap>
    );
};

export default ButtonLine;
