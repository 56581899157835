import { StepCircleItem } from 'components/atoms'

import { StepCircleWrap } from './styles'

import type { StepCircleInterface } from './interface'

const StepCircle = ({
  options = [],
  valueActive = '',
  valueChecked = [],
  typeOnClick = 'isChecked',
  onChangeActive = () => null,
  onChangeChecked = () => null
}: StepCircleInterface) => {

  const onHandleChangeChecked = (newValue: string) => {
    if (valueChecked.includes(newValue)) {
      onChangeChecked(valueChecked.filter(item => item !== newValue))
    } else {
      onChangeChecked([...valueChecked, newValue])
    }
  }


  return (
    <StepCircleWrap className="step-circle-molecule">
      {options.map(({ label, subLabel, value }) => (
        <StepCircleItem
          key={value}
          label={label}
          subLabel={subLabel}
          isChecked={valueChecked?.includes(value)}
          isActive={valueActive?.includes(value)}
          onChecked={() => onHandleChangeChecked(value)}
          onActive={() => onChangeActive(value)}
          typeOnClick={typeOnClick}
        />
      ))}
    </StepCircleWrap>
  )
}

export default StepCircle
