import styled from 'styled-components';

export const InvoiceDetailsWrap = styled.div`
    margin: 1rem;
    padding: 1rem;
    border: 0.063rem solid ${(props) => props.theme.colors.borderPrimary};

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        img {
            height: 3rem;
        }

        .title {
            display: block;
            font-size: 2rem;
        }

        .values {
            display: block;
            font-size: 0.9rem;
        }
    }

    .description {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        margin-bottom: 1rem;

        > div:last-child {
            text-align: right;
        }

        .title {
            display: block;
            font-size: 1.15rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        .values {
            display: block;
            font-size: 0.9rem;
        }
    }

    table {
        width: 100%;

        .value {
            text-align: right;
            min-width: 10rem;
        }

        thead {
            tr {
                th {
                    text-align: left;
                    font-size: 1.25rem;
                    font-weight: 600;

                    padding: 0.5rem;
                }
            }
        }

        tbody {
            tr {
                &:nth-child(2n - 1) {
                    background-color: ${(props) =>
                        props.theme.colors.borderSecondary};
                }

                td {
                    font-size: 1.15rem;
                    padding: 0.5rem;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }
`;
