import { ModalFooterWrap } from './styles';

import type { ModalFooterInterface } from './interface';

const ModalFooter = ({ children, style }: ModalFooterInterface) => (
    <ModalFooterWrap className="modal-footer-atom" style={style}>
        {children}
    </ModalFooterWrap>
);

export default ModalFooter;
