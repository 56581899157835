import React from 'react';

import { OptionInputWrap } from './styles';

import type { OptionInputLocalInterface } from './interface';

const OptionInput = ({
    label,
    value,
    active,
    model = 'primary',
    onHandleActive = () => null,
}: OptionInputLocalInterface) => (
    <OptionInputWrap active={active} model={model}>
        <button
            title={label}
            onClick={() => onHandleActive(value)}
            className="number"
            type="button"
        >
            {label}
        </button>
    </OptionInputWrap>
);

export default OptionInput;
