import styled from 'styled-components';

interface CheckBoxWrapInterface {
    isActive: boolean;
}

export const CheckBoxWrap = styled.button<CheckBoxWrapInterface>`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .box {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 1.375rem;
        width: 1.375rem;

        border: 0.125rem solid ${(props) => props.theme.colors.borderNonary};
        background-color: ${(props) => props.theme.colors.senary};

        overflow: hidden;
        color: ${(props) => props.theme.colors.senary};
        font-size: 0.75rem;

        &.active {
            background-color: ${(props) => props.theme.colors.secondaryBold};
            border-color: ${(props) => props.theme.colors.secondaryBold};
        }
    }

    span {
        font-size: 0.938rem;
        font-weight: 300;
        color: ${(props) => props.theme.colors.quaternary};

        display: block;
        margin-left: 0.5rem;
    }
`;
