import styled, { css } from 'styled-components';

interface TextFormWrapInterface {
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    model: 'primary';
}

const models = {
    primary: css`
        border-color: ${(props) => props.theme.colors.borderQuaternary};
        background-color: ${(props) => props.theme.colors.senary};
    `,
};

export const TextFormWrap = styled.div<TextFormWrapInterface>`
    display: flex;
    align-items: center;
    padding: 1.25rem 1.88rem;
    height: 5.625rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 120%;
    color: ${(props) => props.theme.colors.textSecondary};

    strong {
        font-weight: 900;
    }

    border-left: ${(props) =>
        props.borderLeft ? '0.062rem solid transparent' : 0};
    border-right: ${(props) =>
        props.borderRight ? '0.062rem solid transparent' : 0};
    border-bottom: ${(props) =>
        props.borderBottom ? '0.062rem solid transparent' : 0};
    border-top: ${(props) =>
        props.borderTop ? '0.062rem solid transparent' : 0};

    ${(props) => models[props.model]};
`;
