import styled from 'styled-components'

export const VisitScheduleModalWrap = styled.div`
  .wrap-content {
    padding: 1.438rem 2.49rem;

    .button-select-date {
      margin-bottom: 1.438rem;
    }

    .line-atom {
      background-color: ${props => props.theme.colors.borderPrimary};
      height: 0.125rem;
      margin: 1.875rem 0;
    }

    .button-select-hour {
      margin-bottom: 1.438rem;
    }

    .button-scheduled {
      margin-top: 1.438rem;
      button {
        width: 100%;
      }
    }
  }
`
