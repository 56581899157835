import { convertFloatToBR } from 'utils'

import { HelperMoneyWrap } from './styles'

import type { HelperMoneyInterface } from './interface'

const HelperMoney = ({
  centered = false,
  style,
  value
}: HelperMoneyInterface) => (
  <HelperMoneyWrap
    className={value ? 'helper-money-atom active' : 'helper-money-atom'}
    centered={centered}
    style={style}
  >
    R$ {convertFloatToBR(value)}
  </HelperMoneyWrap>
)

export default HelperMoney
