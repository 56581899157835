import { generateURL } from './prefix';
import api from '../api';

import type { IAValueDetail } from '@types';

export const getRealStatePropertyValueDetailIndex = async (): Promise<
    IAValueDetail[]
> => {
    const url = generateURL(`/detalhes_de_valores`);

    const { data } = await api.get(url);

    return data;
};
