import { InputCapsuleOption, OptionInput } from 'components/atoms';

import { InputRadioWrap } from './styles';

import type { InputRadioInterface } from './interface';

const InputRadio = ({
    id,
    label,
    required,
    name,
    value,
    onChange = () => 'null',
    options = [],
    model = 'primary',
    row = false,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
}: InputRadioInterface) => (
    <InputRadioWrap className="input-radio-molecule">
        <input value={value} name={name} type="hidden" />
        <InputCapsuleOption
            htmlFor={id}
            label={label}
            required={required}
            model={model}
            row={row}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            {options.map((option) => (
                <OptionInput
                    key={option.value}
                    {...option}
                    active={value === option.value}
                    onHandleActive={onChange}
                    model={model}
                />
            ))}
        </InputCapsuleOption>
    </InputRadioWrap>
);

export default InputRadio;
