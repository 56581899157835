export const QUERIES_ADMIN = {
    REAL_STATE: {
        base: 'administrator-real-state',
    },
    USER_TAG: {
        base: 'administrator-user-tag',
    },
    PLAN: {
        base: 'administrator-plan',
    },
    WEB_HOOK: {
        base: 'administrator-webhook'
    },
    METHODS_PAYMENTS: {
        base: 'administrator-methods-payments',
    },
    PRICES_INDEXS: {
        base: 'administrator-prices-indexs',
    },
    PROPERTIES_ATTRIBUTES: {
        base: 'administrator-properties-attributes',
    },
    PROPERTIES_TYPES: {
        base: 'administrator-properties-types',
    },
    SIGNATURES: {
        base: 'administrator-signatures',
    },
    VALUE_DETAIL: {
        base: 'administrator-value-detail',
    }
};

export const QUERIES_REAL_STATE = {
    OWNER: {
        base: 'real-state-owner-base',
        BANK_ACCOUNT: {
            base: `real-state-owner-bank-account-base`,
        },
        FILES: {
            base: 'real-state-owner-files-list',
        },
    },
    INVOICE: {
        base: 'real-state-invoice-base',
        generate: 'real-state-invoice-generate',
        list: 'real-state-invoice-list',
    },
    INVOICE_SETTLEMENT: {
        generate: 'real-state-invoice-settlement-generate',
        list: 'real-state-invoice-settlement-list',
        base: 'real-state-invoice-settlement-base',
    },
    USER_TAG: {
        list: 'real-state-user-tag-list',
    },
    PROPERTY: {
        base: 'real-state-base-property',
        show: 'real-state-property-show',
        list: 'real-state-property-list',
        remove: 'real-state-property-remove',
        consult: 'real-state-property-consult',
    },
    PROPERTY_ATTRIBUTE: {
        base: 'real-state-property-attribute',
    },
    PROPERTY_VALUE_DETAIL: {
        base: 'real-state-property-value-detail',
    },
    PROPERTY_EXPENSE: {
        list: 'real-state-property-expense-list',
        remove: 'real-state-property-expense-remove',
        show: 'real-state-property-expense-show',
    },
    PROPERTY_TYPE: {
        base: 'real-state-property-type',
    },
    RENT_CONTRACT: {
        changeStatus: 'real-state-rent-contract-change-status',
        list: 'real-state-rent-contract-list',
        show: 'real-state-rent-contract-show',
        remove: 'real-state-rent-contract-remove',
        update: 'real-state-rent-contract-update',
    },
    RENT_CONTRACT_VALUE: {
        list: 'real-state-rent-contract-value-list',
        show: 'real-state-rent-contract-value-show',
        create: 'real-state-rent-contract-value-create',
        remove: 'real-state-rent-contract-value-remove',
        update: 'real-state-rent-contract-value-update',
        updateMassive: 'real-state-rent-contract-value-update-massive',
    },
    RENT_CONTRACT_FILE: {
        list: 'real-state-rent-contract-file-list',
    },
    BANNER: {
        base: 'real-state-banner-base',
    },
    CONTACT: {
        base: 'real-state-contact-base',
    },
    INSURANCE_COMPANY: {
        base: 'real-state-insurance-company-base',
    },
    SETTING: {
        base: 'real-state-setting-base',
    },

    REAL_STATE: {
        INTEGRATION_REGISTER: {
            base: 'real-state-integration-register-base',
        },
        BANK_ACCOUNT: {
            base: 'real-state-bank-account-base',
        },
        FILE: {
            base: 'real-state-file-base',
        }
    },
    FINANCIAL: {
        base: 'real-state-financial-base',
    },
};

export const QUERIES_PUBLIC = {
    PAYMENT_METHOD: {
        list: 'public-payment-method',
    },
};

export const QUERIES_EXTERNAL = {
    ADDRESS: {
        consult: 'external-address',
    },
};

export const QUERIES_MISCELLANEOUS = {
    ADDRESS: {
        query: 'miscellaneous-address',
    },
};


export const QUERIES_CUSTOMER = {
    RENT_CONTRACT: {
        base: 'customer-rent-contract-base',
    },
    RENT_CONTRACT_VISIT: {
        base: 'customer-rent-contract-visit-base',
    },
};


export const QUERIES_OWNER = {
    INVOICE_SETTLEMENT: {
        base: 'owner-invoice-settlement-base',
    },
    PROPERTY_INVITE: {
        base: 'owner-property-invite-base',
    },
    REAL_STATE_INVITE: {
        base: 'owner-real-state-invite-base',
    },
};

export const QUERIES_AUTH = {
    PASSWORD_RECOVERY: {
        base: 'auth-password-recovery',
    },
    RECOVERY_CODE: {
        base: 'auth-recovery-code',
    },
    SIGN_IN: {
        base: 'auth-sign-in',
    },
    SIGN_OUT: {
        base: 'auth-sign-out',
    },
    SIGN_UP: {
        base: 'auth-sign-up',
    }
};
