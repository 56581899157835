import { useState} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate , useParams } from 'react-router-dom';

import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import {  OptionBlock, } from 'components/molecules';
import {
    getRealStateOwnerShow,
    createRealStateOwner,
} from 'services';

import { convertDateENToBR } from 'utils'

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { OwnerFormWrap } from './styles';

import { simplifiedPersonDataSchema } from '../validations';

import { SimplifiedPersonData } from '../components'

import Layout from '../../Layout';

import type {
    IAPerson,
} from '@types';


const OwnerSimplifiedForm = () => {
    const titleMessages = 'Formulário de Proprietário';

    const params = useParams();

    const { getLinkWithSlug } = useRealState();

    const navigate = useNavigate();

    const [step, setStep] = useState('GENERAL_INFO');

    const [personData, setPersonData] = useState<Partial<IAPerson>>();

    const {
        isLoading: isLoadingRealStateDetails,
        isFetching: isFetchingRealStateDetails,
    } = useQuery([QUERIES_REAL_STATE.OWNER.base, params?.id], () =>
        getRealStateOwnerShow(String(params?.id)),
        {
            refetchOnMount: true,
            onSuccess: (res) => {

                const personPayload = res;

                if (personPayload?.nascimento) {
                    personPayload.nascimento = convertDateENToBR(personPayload?.nascimento);
                }

                setPersonData(personPayload);
            },
            enabled: Boolean(params?.id),
        },
     
    );

    const { mutate: onMutateCreateRealStateOwner, isLoading: isLoadingCreateRealStateOwner } = useMutation(
        (payload: Partial<IAPerson>) => createRealStateOwner(payload),
        {
            mutationKey: [QUERIES_REAL_STATE.OWNER.base, 'create', personData?.documento],
            onSuccess: (res) => {
                onSuccessMessage(
                    titleMessages,
                    res.message
                );


                navigate(
                    getLinkWithSlug(`${ROUTES_REAL_STATE.OWNER.FORM.fullPath}/${res?.data?.usuariosAcessos?.id}`)
                );
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const handleSubmitToNext = async () => {
  
            const { success } = await onValidationSchema(simplifiedPersonDataSchema, personData);

            if (success && personData) {
                onMutateCreateRealStateOwner(personData);
            }
     
    }


    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Proprietários',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.OWNER.fullPath),
                },
                {
                    label: 'Formulário do Proprietário',
                    href: getLinkWithSlug(`${ROUTES_REAL_STATE.OWNER.FORM.fullPath}/${params?.id}`),
                },
            ]}
            title="Formulário do Proprietário"
            buttonTitle={<Button color="octernary" onClick={() => handleSubmitToNext()} loading={isLoadingCreateRealStateOwner}>Salvar</Button>}
        >
            <OwnerFormWrap className="real-state-panel-owner-register">
                <Shadow>
                    <Loading
                        active={
                            isLoadingRealStateDetails ||
                            isFetchingRealStateDetails
                        }
                    />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Cadastro Simplificado - Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <SimplifiedPersonData values={personData} onChangeValues={setPersonData}  />
                </Shadow>
            </OwnerFormWrap>
        </Layout>
    );
};

export default OwnerSimplifiedForm;
