import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_ADMIN,
    ROUTES_ACCOUNT,
    ROUTES_ADMIN,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow, Grid } from 'components/atoms';
import { OptionBlock, InputText, InputToggle, Select } from 'components/molecules';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';

import {
    createAdministratorPropertiesAttributes,
    getAdministratorPropertiesAttributesShow,
    updateAdministratorPropertiesAttributes,
} from 'services';
import { getIconsTypesAttributesData, getValuesTypesAttributesData } from 'utils';

import { PropertiesAttributesFormWrap } from './styles';
import Layout from '../../Layout';

import type { IAPropertyAttribute } from '@types';

const PropertiesAttributesForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [descricao, setDescricao] = useState('');
    const [icone, setIcone] = useState('');
    const [tipo, setTipo] = useState('');
    const [destaque, setDestaque] = useState(false);
    const [obrigatorio, setObrigatorio] = useState(false);

    const titleMessages = 'Atributo do Imóvel!';

    const { data: dataDetail, isLoading: isLoadingGet } = useQuery(
        [QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base, id],
        () => getAdministratorPropertiesAttributesShow(String(id)),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (dataDetail) {
            setDescricao(dataDetail?.descricao);
            setIcone(dataDetail?.icone);
            setTipo(dataDetail?.tipo);
            setDestaque(dataDetail?.destaque);
            setObrigatorio(dataDetail?.obrigatorio);
        }
    }, [dataDetail]);

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAPropertyAttribute>) => createAdministratorPropertiesAttributes(payload),
        {
            mutationKey: [QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base);

                navigate(getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.fullPath));
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAPropertyAttribute>) => updateAdministratorPropertiesAttributes(String(id), payload),
        {
            mutationKey: [QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base);
                queryClient.refetchQueries([QUERIES_ADMIN.PROPERTIES_ATTRIBUTES.base, id]);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onSubmit = async () => {
        const data = {
            descricao,
            icone,
            tipo,
            destaque,
            obrigatorio,
        };

        const shapeSchema = {
            descricao: Yup.string().nullable().required('A descrição é obrigatória.')
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (!id) {
                mutateCreate(data);
            } else {
                mutateUpdate(data);
            }
        }
    };

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Atributos dos Imóveis',
                    href: getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.fullPath),
                },
                {
                    label: 'Formulário Atributos dos Imóveis',
                    href: getLinkWithSlug(ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.FORM.fullPath),
                },
            ]}
            title="Formulário Atributos dos Imóveis"
            buttonTitle={
                <Button color="denary" onClick={() => onSubmit()} loading={isLoadingGet || isLoadingUpdate || isLoadingCreate}>
                    <AiFillSave />
                    Salvar
                </Button>
            }
        >
            <PropertiesAttributesFormWrap className="administrator-panel-properties-attributes-form">
                <Shadow>
                    <Loading active={isLoadingGet || isLoadingUpdate || isLoadingCreate} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    <InputText
                        model="sinary"
                        id="descricao"
                        required
                        label="Descricao"
                        name="descricao"
                        placeholder="Informe a descrição"
                        value={descricao}
                        onChange={setDescricao}
                    />

                    <Select
                        model="sinary"
                        id="icone"
                        required
                        label="Icone"
                        name="icone"
                        placeholder="Informe o ícone"
                        value={icone}
                        onChange={setIcone}
                        options={getIconsTypesAttributesData()}
                    />

                    <Select
                        model="sinary"
                        id="tipo"
                        required
                        label="Tipo"
                        name="tipo"
                        placeholder="Informe o tipo"
                        value={tipo}
                        onChange={setTipo}
                        options={getValuesTypesAttributesData()}
                    />

                    <Grid numberColumns={2}>
                        <InputToggle
                            model="sinary"
                            id="destaque"
                            required
                            label="Destaque"
                            name="destaque"
                            value={destaque}
                            onChange={setDestaque}
                            labelTrue="Sim"
                            labelFalse="Não"
                        />

                        <InputToggle
                            model="sinary"
                            id="obrigatorio"
                            required
                            label="Obrigatorio"
                            name="obrigatorio"
                            value={obrigatorio}
                            onChange={setObrigatorio}
                            labelTrue="Sim"
                            labelFalse="Não"
                        />
                    </Grid>
                </Shadow>
            </PropertiesAttributesFormWrap>
        </Layout>
    );
};

export default PropertiesAttributesForm;
