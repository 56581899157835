import { useEffect, useState } from 'react'
import { LeafletMouseEvent } from 'leaflet';

import { Grid, Map, MarkerMap } from 'components/atoms';
import { InputNumber } from 'components/molecules'

import type { SettingMapInterface } from './interface'

const PropertyMaps = ({ onChange, value }: SettingMapInterface) => {
  const [latitude, setLatitude] = useState(Number(value?.latitude || 0));
  const [longitude, setLongitude] = useState(Number(value?.longitude || 0));

  const onBlur = () => {
    onChange({ latitude: String(latitude), longitude: String(longitude) })
  }

  return <>
    <Grid numberColumns={2}>
      <InputNumber
        required
        label="Latitude"
        name="latitude"
        placeholder="Informe a latitude"
        value={latitude}
        onChange={setLatitude}
        model="sinary"
        precision={7}
        onBlur={onBlur}
      />

      <InputNumber
        required
        label="Longitude"
        name="longitude"
        placeholder="Informe a longitude"
        value={longitude}
        onChange={setLongitude}
        model="sinary"
        precision={7}
        onBlur={onBlur}

      />
    </Grid>

    <Map
      style={{ height: '25rem' }}
      center={[
        latitude !== 0 ? latitude : -27.1129685,
        longitude !== 0 ? longitude : -52.6041486
      ]}
      mapConsumer={map => {
        map.on('click', (e: LeafletMouseEvent) => {
          const { lat, lng } = e.latlng
          setLatitude(lat)
          setLongitude(lng)

          onChange({ latitude: String(lat), longitude: String(lng) })

        })

        useEffect(() => {
          if (
            typeof latitude === 'number' &&
            typeof longitude === 'number' &&
            latitude !== 0 &&
            longitude !== 0
          ) {
            map.flyTo([latitude, longitude], 15)
          }
        }, [latitude, longitude])
        return null
      }}
    >
      {typeof latitude === 'number' && typeof longitude === 'number' && (
        <MarkerMap interactive={false} position={[latitude, longitude]} />
      )}
    </Map>
  </>
}

export default PropertyMaps;