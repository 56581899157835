import styled from 'styled-components'

export const InputImageWrap = styled.label`
  position: relative;
  width: 10rem;
  height: 10rem;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  padding: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.375rem;
  border: 0.188rem solid ${props => props.theme.colors.senary};
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/images/empty_property.png');
  background-origin: content-box;
  background-color: ${props => props.theme.colors.backgroundImage};

  label,
  button {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;

    background-color: ${props => props.theme.colors.secondary};
    box-shadow: 0px 0.583rem 1rem 0px rgba(24, 28, 50, 0.25);

    svg {
      font-size: 1rem;
      color: ${props => props.theme.colors.tertiaryLight};
    }
  }

  label {
    top: -0.625rem;
    right: -0.625rem;
  }

  button {
    bottom: -0.313rem;
    right: -0.625rem;
    background-color: ${props => props.theme.colors.danger};

    svg {
      color: ${props => props.theme.colors.quinary};
    }
  }

  input {
    display: none;
  }

  span.description {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.secondary};
    opacity: 0.75;
    display: none;
    padding: 1.3rem;
    font-size: 1.15rem;
    font-weight: 500;
    color: ${props => props.theme.colors.textPrimary};

    align-items: center;
    justify-content: center;
  }

  &:hover span.description {
    display: flex;
  }

  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span.label {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: ${props => props.theme.colors.textPrimary};
    text-align: left;
    position: absolute;
    left: 0;
    right: 0;
    top: -1.5rem;
  }
  span.suggestion {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 120%;
    color: ${props => props.theme.colors.textPrimary};
    text-align: left;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2.5rem;
  }
`
