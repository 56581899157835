import { Helmet } from 'react-helmet';

import { OwnerPropertyInviteList } from 'components/templates';

const OwnerPropertyInviteListPage = () => (
    <>
        <Helmet>
            <title>Solicitações dos Imóveis - Painel do Proprietário - Imobiler</title>
        </Helmet>

        <OwnerPropertyInviteList />
    </>
);

export default OwnerPropertyInviteListPage;
