import { MdClose } from 'react-icons/md';

import { ModalHeaderWrap } from './styles';

import type { HeaderModalInterface } from './interface';

const HeaderModal = ({ onCloseModal, title }: HeaderModalInterface) => (
    <ModalHeaderWrap className="modal-header-atom">
        <div>
            <h3>{title}</h3>
        </div>

        <button onClick={onCloseModal} type="button">
            <MdClose />
        </button>
    </ModalHeaderWrap>
);

export default HeaderModal;
