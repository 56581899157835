import { generateURL } from './prefix';
import api from '../api';

import type { IAPropertyAttribute } from '@types';

export const getAdministratorPropertiesAttributesIndex = async () => {
    const url = generateURL(`/imoveis/atributos`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorPropertiesAttributesShow = async (id: number | string) => {
    const url = generateURL(`/imoveis/atributos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorPropertiesAttributes = async (
    payload: Partial<IAPropertyAttribute>
) => {
    const url = generateURL(`/imoveis/atributos`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorPropertiesAttributes = async (
    id: number | string,
    payload: Partial<IAPropertyAttribute>
) => {
    const url = generateURL(`/imoveis/atributos/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorPropertiesAttributes = async (id: number | string) => {
    const url = generateURL(`/imoveis/atributos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
