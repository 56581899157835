import { Helmet } from 'react-helmet';

import { RealStateOwnerNewForm } from 'components/templates';

const RealStateOwnerNewFormPage = () => (
    <>
        <Helmet>
            <title>Proprietários - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateOwnerNewForm />
    </>
);

export default RealStateOwnerNewFormPage;
