import { MapConsumer, MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import type { MapInterface } from './interface';

const MapComp = ({
    children,
    mapConsumer = () => null,
    ...rest
}: MapInterface) => (
    <MapContainer
        style={{ width: '100%', height: '100%' }}
        zoom={15}
        scrollWheelZoom={false}
        className="map-atom"
        {...rest}
    >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapConsumer>{mapConsumer}</MapConsumer>
        {children}
    </MapContainer>
);

export default MapComp;
