import { BreadcrumbWrap } from './styles';

import type { BreadcrumbInterface } from './interface';

const Breadcrumb = ({ label, href, first }: BreadcrumbInterface) => (
    <BreadcrumbWrap to={href} className={first ? 'first' : undefined}>
        {label}
    </BreadcrumbWrap>
);

export default Breadcrumb;
