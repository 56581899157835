/* eslint-disable */
import { utcToZonedTime } from 'date-fns-tz';
import { format, formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
/* eslint-enable */

export const convertDateENToBR = (value?: string | Date): string => {
    if (!value || value === undefined) {
        return '';
    }

    const timezone = 'America/Sao_Paulo';

    if (value instanceof Date) {
        const zonedDate = utcToZonedTime(value, timezone);
        return format(zonedDate, 'dd/MM/y', { locale: ptBR });
    }

    const parsedDate = parseISO(value);
    const zonedDate = utcToZonedTime(parsedDate, timezone);
    return format(zonedDate, 'dd/MM/y', { locale: ptBR });
};

export const distanceToNow = (value: string): string => {
    if (!value) {
        return 'Data inválida';
    }

    const parsedDate = parseISO(value);
    return formatDistance(parsedDate, new Date(), {
        addSuffix: true,
        locale: ptBR,
    });
};
