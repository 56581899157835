import { generateURL } from './prefix';
import api from '../api';

export const createMiscellaneousAddress = async (payload: any) => {
    const url = generateURL(`/enderecos`);

    const { data } = await api.post(url, payload);

    return data;
};
