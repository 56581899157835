import { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Loading } from 'components/atoms'
import { SelectCircle } from 'components/molecules'
import { rentContractEnums } from 'enums'
import { convertFloatToBR } from 'utils'

import { patchCustomerRentContract } from 'services'
import { QUERIES_CUSTOMER } from '@constants'

import { onErrorMessage, onSuccessMessage } from 'helpers'
import * as S from './styles'

import { EnsureInterface } from './interface'

const Ensure = ({ id, imoveisPersonalizacoes, garantia }: EnsureInterface) => {
  const titleMessages = "Contrato de Aluguel";
  const queryClient = useQueryClient();

  const [ensure, setEnsure] = useState(garantia)

  function getAmountValue() {
    const amount = imoveisPersonalizacoes?.reduce(
      (amountReduce, imovelPersonalizacao) => amountReduce + imovelPersonalizacao.ligaImoveisPersonalizacoes.valorAluguel,
      0
    )

    return convertFloatToBR(amount)
  }

  const { isLoading: isLoadingUpdate, mutate: onMutateUpdate } =
    useMutation(() => patchCustomerRentContract(Number(id), { garantia: ensure }), {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(id)],
      onSuccess: ({ message }) => {
        onSuccessMessage(titleMessages, message);

        queryClient.invalidateQueries([QUERIES_CUSTOMER.RENT_CONTRACT.base, String(id)]);
      },
      onError: (err) => {
        onErrorMessage(titleMessages, err);
      },
    });


  useEffect(() => {
    if (ensure !== garantia) {
      onMutateUpdate()
    }
  }, [ensure])

  return (
    <S.EnsureWrap>
      <Loading active={isLoadingUpdate} />
      <div className="description">
        <span className="text">Selecione uma das opções abaixo:</span>

        <span className="value">
          Valor do aluguel + encargos:{' '}
          <strong className="number">R$ {getAmountValue()}</strong>
        </span>
      </div>

      <SelectCircle
        options={rentContractEnums.ensure}
        value={ensure}
        onChange={setEnsure}
      />
    </S.EnsureWrap>
  )
}

export default Ensure
