import { useEffect, useRef } from 'react';

import { checkEmpty } from 'utils';
import { InputCodeWrap } from './styles';

import type { InputCodeInterface } from './interface';



const InputCode = ({
    id,
    name,
    value,
    onChange = () => null,
    ...rest
}: InputCodeInterface) => {
 
    const inputOneRef = useRef<HTMLInputElement>(null);
    const inputTwoRef = useRef<HTMLInputElement>(null);
    const inputThreeRef = useRef<HTMLInputElement>(null); 
    const inputFourRef = useRef<HTMLInputElement>(null);
    const inputFiveRef = useRef<HTMLInputElement>(null);

    const handleChange = (keyIndex: number, paramValue: string) => { 
        if (value?.[keyIndex]){
          const copyValue = value.split(''); // converte em array
          copyValue[keyIndex] = paramValue;
          onChange(copyValue.join('')); // converte de volta para string
      
        } else {
          onChange(`${value}${paramValue}`);
        }
      }
      

    const getValue = (keyIndex: number) => value?.[keyIndex] || ""
    

    useEffect(() => {

        const arrayRefs = [inputOneRef, inputTwoRef, inputThreeRef, inputFourRef, inputFiveRef];
        arrayRefs?.[value?.length]?.current?.focus();
    }, [value])

    return (
        <InputCodeWrap isEmpty={checkEmpty(value)} className="input-text-molecule">

            <input
                ref={inputOneRef}
                id={id}
                name={name}
                value={getValue(0)}
                onChange={(event) => handleChange(0, event.target.value)}
                {...rest}
            />

            <input
                ref={inputTwoRef}
                id={id}
                name={name}
                value={getValue(1)}
                onChange={(event) => handleChange(1, event.target.value)}
                {...rest}
            />

            <input
                ref={inputThreeRef}
                id={id}
                name={name}
                value={getValue(2)}
                onChange={(event) => handleChange(2, event.target.value)}
                {...rest}
            />

            <input
                ref={inputFourRef}
                id={id}
                name={name}
                value={getValue(3)}
                onChange={(event) => handleChange(3, event.target.value)}
                {...rest}
            />

            <input
                ref={inputFiveRef}
                id={id}
                name={name}
                value={getValue(4)}
                onChange={(event) => handleChange(4, event.target.value)}
                {...rest}
            />

        </InputCodeWrap>
    )
}
        

export default InputCode;
