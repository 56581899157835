const ACCOUNT_BASE = `/account/`;
const ACCOUNT_SELECTED = `selected`;

export const ROUTES_ACCOUNT = {
    path: ACCOUNT_BASE,
    fullPath: ACCOUNT_BASE,
    SELECTED: {
        path: ACCOUNT_SELECTED,
        fullPath: `${ACCOUNT_BASE}${ACCOUNT_SELECTED}`,
    },
};
