const theme = {
    colors: {
        backgroundImage: '#4e525c',
        background: '#F8FAFC',
        backgroundPrimary: '#EAF2F8',
        backgroundSuccess: 'rgba(167, 230, 110, .1)',
        backgroundDanger: 'rgba(255, 77, 77, .3)',
        primary: '#0E6EBE',
        primaryLight: '#2ba2e3',
        secondary: '#A7E66E',
        secondaryBold: '#71C822',
        secondaryExtraBold: '#8ACA50',
        tertiaryExtraLight: '#3F4652',
        tertiary: '#404753',
        tertiaryLight: '#515A68',
        tertiaryBold: '#66717F',
        quaternary: '#24282F',
        quaternaryBold: '#374057',
        quinary: '#F3F3F5',
        senaryLight: '#F9F9FA',
        senary: '#FFFFFF',
        septernary: '#CACFD5',
        octernary: '#4CBF68',

        danger: '#FF4D4D',
        check: '#44C541',

        textPrimary: '#2C2C34',
        textSecondary: '#374057',
        textTertiary: '#687181',
        textQuaternary: '#747E8F',
        textQuinary: '#0E6EBE',
        textSenary: '#FFFFFF',
        textSepternary: '#F8F8FC',
        textOcternary: '#7E92C0',
        textEneaternary: '#A5A9B8',
        textMoney: '#4CBF67',
        textSuccess: '#4CBF67',

        facebook: '#59619F',
        twitter: '#03A9F4',
        youtube: '#c4302b',
        instagram: '#FF0B53',
        whatsapp: '#44C541',
        email: '#6E747D',
        star: '#FFBF00',
        toggle: '#8ACA50',
        copy: '#6E747D',

        borderPrimary: 'rgba(224, 226, 229, 0.4)',
        borderSecondary: 'rgba(55, 66, 87, 0.2)',
        borderTertiary: 'rgba(255, 255, 255, 0.4)',
        borderQuaternary: '#DFDFD0',
        borderQuaterback: '#3E3E48',
        borderSenary: '#B3B5BA',
        borderSepternary: '#E7EDF3',
        borderOcternary: '#BEDDFC',
        borderNonary: '#35373E',
        borderSuccess: '#4CBF67',

        tableStriped: 'rgba(113, 122, 150, 0.16)',
        loading: 'rgba(0, 0, 0, .1) ',

        arrow: 'rgba(40,42,54, .6)',
        arrowHover: 'rgba(40,42,54, .8)',
    },
};

export default theme;
