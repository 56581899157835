import { Helmet } from 'react-helmet';

import { AuthSignUp } from 'components/templates';

const SignUpPage = () => (
    <>
        <Helmet>
            <title>Cadastro - Imobiler</title>
        </Helmet>

        <AuthSignUp />
    </>
);

export default SignUpPage;
