import { useRealState } from 'contexts/RealStateContext';

import { ROUTES_ADMIN } from '@constants';

import { Container, TitlePage, Row } from 'components/atoms';
import { SidebarList } from 'components/molecules';
import { HeaderPages } from 'components/organisms';

import { AdministratorLayoutWrap } from './styles';

import type { AdministratorLayoutInterface } from './interface';

const AdministratorLayout = ({
    titlePage,
    breadcrumbs,
    title,
    children,
    buttonTitle,
}: AdministratorLayoutInterface) => {
    const { getLinkWithSlug } = useRealState();

    return (
        <AdministratorLayoutWrap className="administrator-layout-template">
            <HeaderPages label={titlePage} breadcrumbs={breadcrumbs} />

            <Container>
                <Row>
                    <SidebarList
                        data={[
                            {
                                label: 'Minha Conta',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.ACCOUNT.fullPath
                                ),
                            },

                            {
                                label: 'Planos',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.PLAN.fullPath
                                ),
                            },

                            {
                                label: 'Atributos dos Imóveis',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.PROPERTIES_ATTRIBUTES.fullPath
                                 ),
                            },

                            {
                                label: 'Tipo dos Imóveis',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.PROPERTIES_TYPES.fullPath
                                ),
                            },

                            {
                                label: 'Detalhes dos Valores',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.VALUE_DETAIL.fullPath
                                ),
                            },

                            {
                                label: 'Preços de Índices',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.PRICES_INDEXS.fullPath
                                ),
                            },

                            {
                                label: 'Métodos de Pagamentos',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.METHODS_PAYMENTS.fullPath
                                ),
                            },

                            {
                                label: 'Tags da Pessoa',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.USER_TAG.fullPath
                                ),
                            },

                            {
                                label: 'Imobiliárias',
                                route: getLinkWithSlug(
                                    ROUTES_ADMIN.REAL_STATE.fullPath
                                ),
                            },
                        ]}
                    />

                    <div className="administrator-layout-template-content">
                        <TitlePage button={buttonTitle}>{title}</TitlePage>
                        {children}
                        <div className="administrator-layout-template-footer">
                            {buttonTitle}
                        </div>
                    </div>
                </Row>
            </Container>
        </AdministratorLayoutWrap>
    );
};

export default AdministratorLayout;
