import styled from 'styled-components'

export const NewRentWrap = styled.div`
  display: block;
  width: 100%;
  overflow: auto;

  .row-title {
    margin-bottom: 1.875rem;
    justify-content: space-between;
    align-items: center;
  }

  .option-block-molecule {
    li button {
      padding: 0 1.5rem;
    }
  }

  .property-horizontal-molecule {
    margin-top: 1.875rem;
  }

  .shadow-atom {
    margin-top: 4.5rem;
  }

  .buttons-scheduled {
    padding: 2.063rem;
  }

  .button-submit {
    margin-top: 1.25rem;
  }

  .list-scheculed {
    padding: 1.25rem;
  }
`
