import { useAutoAnimate } from '@formkit/auto-animate/react';

import { useCallback, useMemo } from 'react';
import {
    AiFillCaretDown,
    AiFillCaretLeft,
    AiFillCaretUp,
    AiFillCaretRight,
} from 'react-icons/ai';

import { Skeleton } from 'components/atoms';
import { MySearch } from 'components/molecules';

import { TableWrap } from './styles';

import type { TableFetchInterface } from './interface';

const TableFetch = ({
    columns,
    data = [],
    isLoading,
    noMargins = false,
    noShadow = false,
    filter,
    onChangeFilter,
    totalItems = 0
}: TableFetchInterface) => {
    const [yourRef] = useAutoAnimate<HTMLTableSectionElement>();

    const onHandleFilter = (key: string, value: string) => {
        onChangeFilter({ ...filter, [key]: value });
    }

    const handleSortObject = (paramKey: string) => {
        if (paramKey === filter.order) {
            if (filter.sort === 'DESC') {
                onHandleFilter('sort', 'ASC');
            } else {
                onHandleFilter('sort', 'DESC');
            }
        } else {
            onHandleFilter('order', paramKey);
        }
    };

    const verifyOrderActive = useCallback(
        (column: string, sort) =>
            column === filter.order && filter.sort === sort
                ? 'active'
                : '',
        [filter]
    );

    const dataWithLoading = useMemo(() => {
        if (isLoading) {
            return [
                { label: '1' },
                { label: '2' },
                { label: '3' },
                { label: '4' },
                { label: '5' },
                { label: '6' },
                { label: '7' },
                { label: '8' },
                { label: '9' },
                { label: '10' },
            ];
        }

        if (!Array.isArray(data)) {
            return [];
        }

        return data;
    }, [data, isLoading]);

    return (
        <TableWrap noShadow={noShadow} noMargins={noMargins}>
            <MySearch onSearch={(newValue) => onHandleFilter('search', newValue)} />

            <table>
                <thead>
                    <tr>
                        {columns?.map((column) => (
                            <th
                                key={column.text}
                                style={column?.headerStyle}
                                onClick={() =>
                                    column.sort &&
                                    handleSortObject(column.dataField)
                                }
                                className={column.sort ? 'sort' : ''}
                            >
                                <div className="content-header">
                                    {column.formatterText ? column.formatterText : column.text}

                                    {column.sort && (
                                        <div className="carets">
                                            <AiFillCaretUp
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'ASC'
                                                )}
                                            />

                                            <AiFillCaretDown
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'DESC'
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody ref={yourRef}>
                    {dataWithLoading.map((row, indexRow) => {
                        const keyRow = `index_row_${indexRow}`;

                        return (
                            <tr key={keyRow}>
                                {columns.map((column, indexColumn) => {
                                    const keyColumn = `index_column_${indexColumn}`;

                                    if (isLoading) {
                                        return (
                                            <td
                                                key={keyColumn}
                                                style={column?.style}
                                            >
                                                <Skeleton height="1.3rem" />
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            key={keyColumn}
                                            style={column?.style}
                                        >
                                            {column.formatter
                                                ? column.formatter(
                                                    row[column.dataField],
                                                    indexRow,
                                                    row
                                                )
                                                : row[column.dataField]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="footer">
                <div className="box-left">
                    <ul className="pagination">
                        {filter.page > 1 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        onHandleFilter('page',
                                            (+filter.page - 1).toString()
                                        )
                                    }
                                >
                                    <AiFillCaretLeft />
                                </button>
                            </li>
                        )}

                        {+filter.page > 1 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        onHandleFilter('page',
                                            (+filter.page - 1).toString()
                                        )
                                    }
                                >
                                    {+filter.page - 1}
                                </button>
                            </li>
                        )}

                        <li className="page-item active">
                            <button type="button" onClick={() => null}>
                                {filter.page}
                            </button>
                        </li>

                        {filter.page * filter.limit < totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        onHandleFilter('page',
                                            (+filter.page + 1).toString()
                                        )
                                    }
                                >
                                    {+filter.page + 1}
                                </button>
                            </li>
                        )}

                        {(+filter.page + 1) * filter.limit < totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        onHandleFilter('page',
                                            (+filter.page + 2).toString()
                                        )
                                    }
                                >
                                    {+filter.page + 2}
                                </button>
                            </li>
                        )}

                        {filter.page * filter.limit < totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        onHandleFilter('page',
                                            (+filter.page + 1).toString()
                                        )
                                    }
                                >
                                    <AiFillCaretRight />
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="box-right">
                    <span className="description">
                        Mostrando {(+filter.page - 1) * +filter.limit + 1} até{' '}
                        {+filter.page * +filter.limit} de{' '}
                        {totalItems} registros.
                    </span>

                    <select
                        value={+filter.limit}
                        onChange={(event) =>
                            onHandleFilter('limit', event.target.value)
                        }
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                    </select>
                </div>
            </div>

        </TableWrap>
    );
};

export default TableFetch;
