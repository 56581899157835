import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillSave } from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import {
    InputText,
    OptionBlock,
} from 'components/molecules';


import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    createRealStateInsuranceCompany,
    getRealStateInsuranceCompanyShow,
    updateRealStateInsuranceCompany,
} from 'services';

import { InsuranceCompanyFormWrap } from './styles';
import Layout from '../../Layout';

import type { IARealStateInsuranceCompany } from '@types';

const InsuranceCompanyForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');

    const [nome, setNome] = useState('')
    const [numero, setNumero] = useState('')

    const title = 'Seguradoras';

    const { isLoading: isLoadingGet, isFetching: isFetchingGet } = useQuery(
        [QUERIES_REAL_STATE.INSURANCE_COMPANY.base, id],
        () => getRealStateInsuranceCompanyShow(String(id)),
        {
            refetchOnMount: true,
            enabled: !!id,
            onSuccess: (data) => {
                setNome(data?.nome ? data?.nome : '')
                setNumero(data?.numero ? data?.numero : '')
            }
        }
    );
    const {
        mutate: mutateCreate,
        isLoading: isLoadingCreate,
    } = useMutation(
        (payload: Omit<IARealStateInsuranceCompany, "id">) => createRealStateInsuranceCompany(payload),
        {
            mutationKey: QUERIES_REAL_STATE.INSURANCE_COMPANY.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.INSURANCE_COMPANY.base
                );

                navigate(
                    getLinkWithSlug(ROUTES_REAL_STATE.INSURANCE_COMPANY.fullPath)
                );
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const {
        mutate: mutateUpdate,
        isLoading: isLoadingUpdate,
    } = useMutation(
        (payload: Omit<IARealStateInsuranceCompany, "id">) => updateRealStateInsuranceCompany(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.INSURANCE_COMPANY.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.INSURANCE_COMPANY.base
                );
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async () => {

        const dataSend = {
            nome,
            numero
        }

        const shapeSchema = {
            nome: Yup.string().nullable().required('O nome é obrigatório.'),
            numero: Yup.string().nullable().required('O número é obrigatório.')
        }

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };


    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.INSURANCE_COMPANY.fullPath
                    ),
                },
                {
                    label: `Formulário ${title}`,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.INSURANCE_COMPANY.FORM.fullPath
                    ),
                },
            ]}
            title={`Formulário ${title}`}
            buttonTitle={
                    <Button
                        color="denary"
                        onClick={() => onSubmit()}
                        loading={
                            isLoadingGet || isLoadingCreate || isLoadingUpdate
                        }
                    >
                        <AiFillSave />
                        Salvar
                    </Button>
            }
        >
            <InsuranceCompanyFormWrap className="real-state-panel-insurance-company-form">
                <Shadow>
                    <Loading active={isLoadingGet || isFetchingGet} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Informações Gerais',
                                value: 'GENERAL_INFO',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />


                    <InputText
                        required
                        label="Nome"
                        name="nome"
                        placeholder="Informe o nome"
                        value={nome}
                        onChange={setNome}
                        model="sinary"
                    />

                    <InputText
                        required
                        label="Número"
                        name="numero"
                        placeholder="Informe o número"
                        value={numero}
                        onChange={setNumero}
                        model="sinary"

                    />

                </Shadow>
            </InsuranceCompanyFormWrap>
        </Layout>
    );
};

export default InsuranceCompanyForm;
