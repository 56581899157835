import { useMutation, useQueryClient } from 'react-query';

import { useTheme } from 'styled-components';
import { QUERIES_REAL_STATE } from '@constants';

import { Button, Grid } from 'components/atoms';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getRealStateIntegrationRegisterInit } from 'services';

import FinishIntegration from 'assets/svgs/FinishIntegration'

import { FinishWrap } from './styles';

import type { FinishInterface } from './interface'


const Finish = ({ integracoesPagamentosVendedoresIds }: FinishInterface) => {
    const titleMessage = 'Finalização da Integração!';

    const theme = useTheme();

    const queryClient = useQueryClient();

    const { mutate: onSubmit, isLoading } = useMutation(
        () => getRealStateIntegrationRegisterInit(),
        {
            mutationKey: [QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base],
            onSuccess: (res) => {
                queryClient.refetchQueries([QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base]);

                onSuccessMessage(
                    titleMessage,
                    res.message
                );
            },
            onError: (err) => {
                onErrorMessage(titleMessage, err);
            },
        }
    );


    return (
        <FinishWrap>

            <Grid gridTemplateColumns='1fr 1fr' gridGap='6rem'>

                <div className='column-text'>
                    <p>
                        Parabéns por chegar ao fim do processo de integração! Antes de prosseguir, revise cuidadosamente as informações que você forneceu, pois elas serão enviadas para o Imobiler Bank e é mais difícil retornar para fazer alterações após a confirmação.
                    </p>

                    <p>
                        Estamos aqui para ajudá-lo em qualquer dúvida ou necessidade. Obrigado por escolher nossa empresa e aproveite nossos serviços!
                    </p>


                    <Button
                        color={integracoesPagamentosVendedoresIds ? "septernary" : "octernary"}
                        style={{ margin: '1rem auto 0 auto', width: '15rem' }}
                        onClick={() => onSubmit()}
                        loading={isLoading}
                    >
                        {integracoesPagamentosVendedoresIds ? "Atualizar Integração" : "Concluir Integração"}
                    </Button>

                </div>

                <FinishIntegration color={theme.colors.secondary} width={400} height={293} />

            </Grid>

        </FinishWrap>
    );
};

export default Finish;
