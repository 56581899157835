import styled from 'styled-components';

interface OptionBlockWrapInterface {
    transparent: boolean;
}

export const OptionBlockWrap = styled.ul<OptionBlockWrapInterface>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    border-bottom: 1px solid ${(props) => props.theme.colors.borderSepternary};
    background-color: ${(props) =>
        props.transparent ? 'transparent' : props.theme.colors.senary};

    list-style: none;
    padding: 0;

    @media (max-width: 992px) {
        display: block;
    }
`;
