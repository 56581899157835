import { Helmet } from 'react-helmet';

import { AccountAccessSelect } from 'components/templates';

const AccountAccessSelectPage = () => (
    <>
        <Helmet>
            <title>Seleção de Conta - Imobiler</title>
        </Helmet>

        <AccountAccessSelect />
    </>
);

export default AccountAccessSelectPage;
