import { generateURL } from './prefix';
import api from '../api';

import type { IAPaymentMethod } from '@types';

export const getAdministratorMethodsPaymentsIndex = async () => {
    const url = generateURL(`/formas_de_pagamentos`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorMethodsPaymentsShow = async (id: number | string) => {
    const url = generateURL(`/formas_de_pagamentos/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorMethodsPayments = async (
    payload: Partial<IAPaymentMethod>
) => {
    const url = generateURL(`/formas_de_pagamentos`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorMethodsPayments = async (
    id: number | string,
    payload: Partial<IAPaymentMethod>
) => {
    const url = generateURL(`/formas_de_pagamentos/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorMethodsPayments = async (id: number | string) => {
    const url = generateURL(`/formas_de_pagamentos/${id}`);


    const { data } = await api.delete(url);

    return data;
};
