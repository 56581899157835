import { generateURL } from './prefix';
import api from '../api';

import type { IAProperty, IAPropertyCustom } from '@types';

interface IAPropertyShow extends IAProperty {
    editavel: boolean;
    latitudeLongitude: string;
}

export const getRealStatePropertyIndex = async (filter: Record<string, string | number>): Promise<{
    count: number;
    data:IAPropertyCustom[]
}> => {
    let url = generateURL(`/imoveis?pagina=${filter.pagina}`);

    Object.keys(filter).forEach(key => {
      if (!!filter?.[key] && key !== 'pagina') {
        url += `&${key}=${filter[key]}`;
      }
    });


    const { data } = await api.get(url);

    return data;
};

export const getRealStatePropertyShow = async (
    id: number | string
): Promise<IAPropertyShow> => {
    const url = generateURL(`/imoveis/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createRealStateProperty = async (payload: Partial<IAProperty>) => {
    const url = generateURL(`/imoveis`);

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateProperty = async (
    id: number | string,
    payload: Partial<IAProperty>
): Promise<IAProperty> => {
    const url = generateURL(`/imoveis/${id}`);

    const { data } = await api.put(url, payload);

    return data;
};

export const createRealStateNewProperty = async (
    payload: Partial<IAProperty>
) => {
    const url = generateURL(`/imoveis/novo`);

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateNewProperty = async (
    id: number | string,
    payload: Partial<IAProperty>
): Promise<IAProperty> => {
    const url = generateURL(`/imoveis/novo/${id}`);

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateProperty = async (id: number) => {
    const url = generateURL(`/imoveis/${id}`);

    const { data } = await api.delete(url);

    return data;
};

export const consultRealStatePropertyByOwnersIds = async (payload: {
    proprietariosIds: string[];
}) => {
    const url = generateURL(`/imoveis/consultar`);

    const { data } = await api.post(url, payload);

    return data;
};

export const inviteRealStateProperty = async (propertyId: number) => {
    const url = generateURL(`/imoveis/convidar/${propertyId}`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateNewProperty = async (propertyId: number) => {
    const url = generateURL(`/imoveis/novo/${propertyId}`);

    const { data } = await api.get(url);

    return data;
};
