import React from 'react';

import { OptionBlockItem } from 'components/atoms';

import { OptionBlockWrap } from './styles';

import type { OptionBlockInterface } from './interface';

const OptionBlock = ({
    data,
    active,
    onHandleActive = () => null,
    transparent = false,
    style,
}: OptionBlockInterface) => (
    <OptionBlockWrap
        style={style}
        transparent={transparent}
        className="option-block-molecule"
    >
        {data !== null &&
            data.map((item) => (
                <OptionBlockItem
                    key={item.value}
                    {...item}
                    active={active === item.value}
                    onHandleActive={(param) => onHandleActive(param)}
                    transparent={transparent}
                />
            ))}
    </OptionBlockWrap>
);

export default OptionBlock;
