import { ICSelectOption } from '@types';

const data = [
  { label: 'Valor Booleano', value: 'BOOLEANO' },
  { label: 'Valor Numérico', value: 'NUMERICO' },
  { label: 'Valor Descritivo', value: 'DESCRITIVO' },
]

export function getValuesTypesAttributesData(): ICSelectOption[] {
  return data
}

export function getValuesTypesAttributesItem(param?: string): ICSelectOption {
  const filtered = data.find(item => item.value === param)

  return !filtered ? data[0] : filtered
}
