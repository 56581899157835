import { FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import * as Yup from 'yup';

import { QUERIES_AUTH, ROUTES_AUTH } from '@constants';

import { useAuth } from 'contexts/AuthContext';

import { ButtonForm, Container, H1, Shadow, TextForm } from 'components/atoms';

import { InputText } from 'components/molecules';
import { onErrorMessage, onValidationSchema } from 'helpers';
import { createAuthPasswordRecovery } from 'services/auth';

import { PasswordRecoveryWrap } from './styles';
import Layout from '../Layout';

const PasswordRecovery = () => {
    const queryClient = useQueryClient();

    const { onHandleNavigateWithNext} = useAuth();

    const [email, setEmail] = useState('');

    const title = 'Recuperar senha';

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: { email: string}) => createAuthPasswordRecovery(payload),
        {
            mutationKey: QUERIES_AUTH.PASSWORD_RECOVERY.base,
            onSuccess: () => {

                queryClient.refetchQueries(QUERIES_AUTH.PASSWORD_RECOVERY.base);

                onHandleNavigateWithNext(`${ROUTES_AUTH.RECOVERY_CODE.fullPath}/${email}`);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const onHandleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const dataSend = {
            email,
        };

        const shapeSchema = {
            email: Yup.string().nullable().required('O e-mail é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            mutateCreate(dataSend);
        }
    };

    return (
        <Layout>
            <PasswordRecoveryWrap className="password-recovery-template">
                <Container>
                    <H1>
                        <strong>Recuperação de Senha</strong>
                    </H1>
                    <form onSubmit={onHandleSubmit}>
                        <main>
                            <Shadow>
                                <TextForm model="primary" borderTop>
                                    Para redefinir sua senha, por favor, informe
                                    seu e-mail no campo abaixo. Essa informação
                                    será usada para verificar sua identidade e
                                    enviar um código de recuperação de senha
                                    para o contato fornecido.
                                </TextForm>

                                <InputText
                                    required
                                    label="E-mail"
                                    id="email"
                                    name="email"
                                    placeholder="example@gmail.com"
                                    value={email}
                                    onChange={setEmail}
                                    borderTop
                                />
                            </Shadow>

                            <ButtonForm
                                id="on-sign"
                                color="tertiary"
                                type="submit"
                                loading={isLoadingCreate}
                            >
                                Enviar
                            </ButtonForm>
                        </main>
                    </form>
                </Container>
            </PasswordRecoveryWrap>
        </Layout>
    );
};

export default PasswordRecovery;
