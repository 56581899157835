import { Marker } from 'react-leaflet';
import L from 'leaflet';

import type { MarkerProps } from 'react-leaflet';

const happyMapIcon = L.icon({
    iconUrl: '/images/marker.svg',
    iconSize: [45, 45],
});

const MarkerMap = (props: MarkerProps) => (
    <Marker {...props} icon={happyMapIcon} />
);

export default MarkerMap;
