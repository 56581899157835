import styled, { css } from 'styled-components';

interface ButtonLineWrapInterface {
    color:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quaternary'
        | 'quinary'
        | 'senary'
        | 'septernary'
        | 'octernary'
        | 'nonary'
        | 'denary'
        | 'onedenary'
        | 'danger';
}

const buttons = {
    primary: css`
        color: ${(props) => props.theme.colors.textSecondary};
    `,
    secondary: css`
        color: ${(props) => props.theme.colors.textSecondary};
    `,
    tertiary: css`
        color: ${(props) => props.theme.colors.textSenary};
    `,

    quaternary: css`
        color: ${(props) => props.theme.colors.textQuinary};
    `,

    quinary: css`
        color: ${(props) => props.theme.colors.textSenary};
    `,

    senary: css`
        color: ${(props) => props.theme.colors.textSenary};
    `,

    septernary: css`
        color: ${(props) => props.theme.colors.secondary};
    `,

    octernary: css`
        color: ${(props) => props.theme.colors.tertiary};
    `,

    nonary: css`
        color: ${(props) => props.theme.colors.primaryLight};
    `,
    denary: css`
        color: ${(props) => props.theme.colors.textSenary};
    `,

    onedenary: css`
        color: ${(props) => props.theme.colors.textSenary};
    `,

    danger: css`
        color: ${(props) => props.theme.colors.danger};
    `,
};

export const ButtonLineWrap = styled.div<ButtonLineWrapInterface>`
    button,
    a {
        font-weight: 700;
        text-decoration: none;

        cursor: pointer;

        ${(props) => buttons[props.color]};
    }
`;
