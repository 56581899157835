import styled, { css } from 'styled-components';

interface OptionInputWrapInterface {
    active: boolean;
    model: 'primary' | 'secondary';
}

const models = {
    primary: {
        active: css<OptionInputWrapInterface>`
            background-color: ${(props) => props.theme.colors.senary};
            color: ${(props) => props.theme.colors.textQuinary};
            border: 0.062rem solid ${(props) => props.theme.colors.primary};
        `,
        inactive: css<OptionInputWrapInterface>`
            background-color: ${(props) => props.theme.colors.senary};
            color: ${(props) => props.theme.colors.textSecondary};
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderSepternary};

            &:hover {
                color: ${(props) => props.theme.colors.textSecondary};
            }
        `,
    },
    secondary: {
        active: css<OptionInputWrapInterface>`
            padding: 0 2.35rem;
            background-color: ${(props) => props.theme.colors.tertiary};
            color: ${(props) => props.theme.colors.secondary};
            border: 0.062rem solid ${(props) => props.theme.colors.tertiary};
        `,
        inactive: css<OptionInputWrapInterface>`
            padding: 0 2.35rem;
            background-color: ${(props) => props.theme.colors.senary};
            color: ${(props) => props.theme.colors.textSecondary};
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderSepternary};

            &:hover {
                color: ${(props) => props.theme.colors.textSecondary};
            }
        `,
    },
};

export const OptionInputWrap = styled.li<OptionInputWrapInterface>`
    button {
        display: inline-block;
        height: 3.125rem;
        line-height: 3.125rem;
        padding: 0 1rem;
        border-radius: 1.765rem;

        font-size: 0.875rem;
        font-weight: 900;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;

        ${(props) => models[props.model][props.active ? 'active' : 'inactive']};
    }

    @media (max-width: 992px) {
        button {
            display: block;
            width: 100%;
        }
    }
`;
