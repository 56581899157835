import { Helmet } from 'react-helmet';

import { OwnerInvoiceSettlementList } from 'components/templates';

const OwnerInvoiceSettlementListPage = () => (
    <>
        <Helmet>
            <title>Acertos - Painel do Proprietário - Imobiler</title>
        </Helmet>

        <OwnerInvoiceSettlementList />
    </>
);

export default OwnerInvoiceSettlementListPage;
