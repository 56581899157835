import { generateURL } from './prefix';
import api from '../api';

import type { IAPropertyHasRealStateInvite } from '@types';

export const getOwnerPropertyHasRealStateInviteIndex = async (): Promise<
    IAPropertyHasRealStateInvite[]
> => {
    const url = generateURL(`/imoveis/convites`);

    const { data } = await api.get(url);

    return data;
};

export const acceptedOwnerRealStatePropertyInvite = async (inviteId: number) => {
    const url = generateURL(`/imoveis/convites/${inviteId}/aceitar`);

    const { data } = await api.get(url);

    return data;
};

export const removeOwnerRealStatePropertyInvite = async (inviteId: number) => {
    const url = generateURL(`/imoveis/convites/${inviteId}`);

    const { data } = await api.delete(url);

    return data;
};


