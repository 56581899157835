/* eslint-disable jsx-a11y/control-has-associated-label */
import { ToggleWrap } from './styles';

import type { ToggleInterface } from './interface';

const Toggle = ({
    id,
    labelTrue,
    labelFalse,
    value,
    onChange,
    name,
}: ToggleInterface) => (
    <ToggleWrap className="toggle-atom">
        <input name={name} type="hidden" aria-label="hidden" id={id || name} />
        <button
            type="button"
            className={value ? 'active' : ''}
            onClick={() => onChange(!value)}
        />
        <span>{value ? labelTrue : labelFalse}</span>
    </ToggleWrap>
);

export default Toggle;
