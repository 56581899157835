import { useState, ReactNode, useMemo } from 'react'
import { AiOutlineArrowRight, AiOutlineCheck } from 'react-icons/ai'
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PropertyHorizontal, OptionBlock } from 'components/molecules'
import { Button, ButtonForm, H2, Line, Row, Shadow } from 'components/atoms'


import { QUERIES_CUSTOMER, ROUTES_ACCOUNT, ROUTES_CUSTOMER } from '@constants'

import { useRealState } from 'contexts/RealStateContext'
import {
  changeStatusCustomerRentContract,
  getNewCustomerRentContract,
  getCustomerRentContractOne,
  patchPersonDataCustomerRentContract
} from 'services'

import { convertDateENToBR, sweetAlert } from 'utils'

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { Contract, CreditAnalysis, Ensure, PersonData, ScheduledVisit } from './components'

import { NewRentWrap } from './styles'
import { personDataSchema } from './validations';
import Layout from '../Layout'

import type { IAPerson, IARentContract } from '@types'

const CustomerRentContract = () => {
  const title = "Contrato de Locação";

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const { id, slug } = useParams();

  const [currentStep, setCurrentStep] = useState('SCHEDULED');
  const [personData, setPersonData] = useState<Partial<IAPerson>>();


  const { getLinkWithSlug } = useRealState()

  const { data: dataById } = useQuery(
    [QUERIES_CUSTOMER.RENT_CONTRACT.base, id],
    () => getCustomerRentContractOne(Number(id)),
    {
      refetchOnMount: true,
      enabled: !!id,
      onSuccess: (response) => {

        const personPayload = response?.pessoas?.find(pessoa => pessoa.principal)?.ligaPessoas

        if (personPayload?.nascimento) {
          personPayload.nascimento = convertDateENToBR(personPayload?.nascimento);
      }

        setPersonData(personPayload)
      }
    }
  );

  const { data: dataBySlug } = useQuery(
    [QUERIES_CUSTOMER.RENT_CONTRACT.base, slug],
    () => getNewCustomerRentContract(String(slug)),
    {
      refetchOnMount: true,
      enabled: !!slug,
      onSuccess: (data) => {
        if (location.pathname === getLinkWithSlug(`${ROUTES_CUSTOMER.RENT_CONTRACT.NEW.fullPath}/${slug}`)) {
          navigate(getLinkWithSlug(`${ROUTES_CUSTOMER.RENT_CONTRACT.fullPath}/${data.id}`))
        }
      }
    }
  );

  async function nextStep() {
    const steps: Record<string, string> = {
      SCHEDULED: 'PERSON_DATA',
      PERSON_DATA: 'CREDIT_ANALYSIS',
      CREDIT_ANALYSIS: 'ENSURE',
      ENSURE: 'CONTRACT'
    }

    if (currentStep === 'CONTRACT') {
      navigate(getLinkWithSlug(ROUTES_CUSTOMER.ACCOUNT.fullPath))
    } else {
      setCurrentStep(steps[currentStep])
    }
  }


  const { mutate: mutateUpdatePersonData, isLoading: isLoadingUpdatePersonData } = useMutation(
    (payload: Partial<IAPerson>) => patchPersonDataCustomerRentContract(Number(id), payload),
    {
        mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT.base, id],
        onSuccess: () => {
            onSuccessMessage(
                'Dados Pessoais',
                'Sucesso ao salvar as informações!'
            );

            queryClient.invalidateQueries([QUERIES_CUSTOMER.RENT_CONTRACT.base, id]);
        
            nextStep();
          },
        onError: (err) => onErrorMessage('Dados Pessoais', err),
    }
);


  const data = useMemo(() => dataById || dataBySlug, [dataById, dataBySlug])

  function getIsDisabledNextStep(): boolean {
    const steps: Record<string, Record<IARentContract['etapa'], boolean>> = {
      SCHEDULED: {
        INICIADO: true,
        AGENDAR_VISITA: true,
        DADOS_PESSOAIS: false,
        ANALISE_CREDITO: false,
        GARANTIAS: false,
        ASSINATURA: false
      },
      PERSON_DATA: {
        INICIADO: true,
        AGENDAR_VISITA: true,
        DADOS_PESSOAIS: false,
        ANALISE_CREDITO: false,
        GARANTIAS: false,
        ASSINATURA: false
      },
      CREDIT_ANALYSIS: {
        INICIADO: true,
        AGENDAR_VISITA: true,
        DADOS_PESSOAIS: true,
        ANALISE_CREDITO: true,
        GARANTIAS: false,
        ASSINATURA: false
      },
      ENSURE: {
        INICIADO: true,
        AGENDAR_VISITA: true,
        DADOS_PESSOAIS: true,
        ANALISE_CREDITO: true,
        GARANTIAS: true,
        ASSINATURA: false
      },
      CONTRACT: {
        INICIADO: true,
        AGENDAR_VISITA: true,
        DADOS_PESSOAIS: true,
        ANALISE_CREDITO: true,
        GARANTIAS: true,
        ASSINATURA: false
      }
    }

    return steps[currentStep][data?.etapa || 'INICIADO']
  }

  const onValidatePersonData = async () => {
    const payload = personData;

    if (payload?.nascimento) {
        payload.nascimento = payload.nascimento.split('/').reverse().join('-');
    }

    const { success } = await onValidationSchema(personDataSchema, payload);

    if (success && personData) {
      mutateUpdatePersonData(personData);
    }
  }

  
  const onHandleSubmit = () => {
    if(currentStep === 'PERSON_DATA') {
      return onValidatePersonData()
    }

    return nextStep();
  }

  function getButtonSubmitText() {
    const texts: Record<string, { label: string, icon: ReactNode }> = {
      SCHEDULED: {
        label: 'IR PARA DADOS PESSOAIS',
        icon: <AiOutlineArrowRight />
      },
      PERSON_DATA: {
        label: 'IR PARA A ANÁLISE DE CRÉDITO',
        icon: <AiOutlineArrowRight />
      },
      CREDIT_ANALYSIS: {
        label: 'IR PARA GARANTIAS',
        icon: <AiOutlineArrowRight />
      },
      ENSURE: {
        label: 'IR PARA CONTRATO',
        icon: <AiOutlineArrowRight />
      },
      CONTRACT: {
        label: 'CONCLUIR',
        icon: <AiOutlineCheck />
      }
    }

    return texts[currentStep]
  }

  const {
    mutate: mutateCancelRegister,
    isLoading: isLoadingCancelRegister
  } = useMutation(
    () => changeStatusCustomerRentContract(Number(id), { status: 'CANCELADO' }),
    {
      mutationKey: [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(id)],
      onSuccess: () => {
        onSuccessMessage(title, 'Sucesso ao cancelar o processo de aluguel!');

        queryClient.refetchQueries(
          [QUERIES_CUSTOMER.RENT_CONTRACT.base, String(id)]
        );

        queryClient.refetchQueries(
          QUERIES_CUSTOMER.RENT_CONTRACT.base
        );

        navigate(getLinkWithSlug(ROUTES_CUSTOMER.ACCOUNT.fullPath));
      },
      onError: (err) => {
        onErrorMessage(title, err);
      },
    }
  );

  function cancelRegisterRequest() {
    sweetAlert
      .fire({
        title: 'Você tem certeza?',
        text: 'Se você confirmar este contrato será cancelado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2ba2e3',
        cancelButtonColor: '#FF4D4D',
        confirmButtonText: 'Sim, remover registro!',
        cancelButtonText: 'Não, cliquei errado!'
      })
      .then(result => {
        if (result.isConfirmed) {
          mutateCancelRegister()
        }
      })
  }

  const tabsComponent: Record<string, ReactNode> = {
    SCHEDULED: (
      <ScheduledVisit
        idContract={data?.id}
        visitas={data?.visitas}
      />
    ),
    PERSON_DATA: <PersonData onChangeValues={setPersonData} values={personData} />,
    CREDIT_ANALYSIS: <CreditAnalysis arquivos={data?.arquivos || []} id={data?.id} />,
    ENSURE: <Ensure id={data?.id} garantia={data?.garantia || ''} imoveisPersonalizacoes={data?.imoveisPersonalizacoes || []} />,
    CONTRACT: <Contract whatsapp={data?.imobiliarias?.pessoas?.whatsapp} />
  }

  return (
    <Layout
      titlePage="Painel do Cliente"
      breadcrumbs={[
        {
          label: 'Home',
          href: ROUTES_ACCOUNT.SELECTED.fullPath,
        },
        {
          label: 'Painel do Cliente',
          href: ROUTES_CUSTOMER.ACCOUNT.fullPath,
        },
        {
          label: title,
          href: `${ROUTES_CUSTOMER.RENT_CONTRACT.fullPath}/${id || slug} `,
        },
      ]}

    >
      <NewRentWrap className="new-rent-template">
        <Row className="row-title">
          <H2 className="number">
            <strong>Imóvel selecionado</strong> para o processo de aluguel
          </H2>

          <Button
            color="danger"
            size="mini"
            onClick={() => cancelRegisterRequest()}
            loading={isLoadingCancelRegister}
          >
            Cancelar Processo
          </Button>
        </Row>

        <Line type="secondary" />

        {data?.imoveisPersonalizacoes?.map(
          ({ ligaImoveisPersonalizacoes }) => (
            <PropertyHorizontal
              key={ligaImoveisPersonalizacoes.id}
              addressCity={ligaImoveisPersonalizacoes.imoveis.bairros?.cidade?.nome}
              addressNeightborhood={ligaImoveisPersonalizacoes.imoveis.bairros?.nome}
              addressState={ligaImoveisPersonalizacoes.imoveis.bairros?.cidade?.uf}
              attributes={ligaImoveisPersonalizacoes.imoveis.atributos.filter(item => item?.ligaAtributos?.destaque)
                .slice(0, 3).map(atributo => ({
                  label: atributo?.ligaAtributos?.descricao,
                  value: atributo?.valor,
                  type: atributo?.ligaAtributos?.icone || 'DEFAULT'
                }))}
              typeValueProperty="VALOR ALUGUEL"
              valueDetails={ligaImoveisPersonalizacoes.imoveis.detalhesDeValores?.map(detalheValores => ({
                label: detalheValores?.ligaDetalhes?.nome || '',
                value: detalheValores?.valor || '',
              }))}
              valueProperty={ligaImoveisPersonalizacoes.imoveis.valorAluguel}
              imageCode={ligaImoveisPersonalizacoes.id}
              imageDescription={ligaImoveisPersonalizacoes.imoveis.tipos.descricao}
              imageUrl={ligaImoveisPersonalizacoes?.imoveis?.imagens?.find(item => item?.destaque)?.url || ligaImoveisPersonalizacoes?.imoveis?.imagens?.[0]?.url}
            />
          )
        )}

        <Shadow>
          <OptionBlock
            active={currentStep}
            data={[
              {
                label: 'Agendar Uma Visita',
                value: 'SCHEDULED',
                optional: true
              },
              {
                label: 'Dados Pessoais',
                value: 'PERSON_DATA'
              },
              {
                label: 'Análise de Crédito',
                value: 'CREDIT_ANALYSIS'
              },
              {
                label: 'Garantias',
                value: 'ENSURE'
              },
              {
                label: 'Contrato',
                value: 'CONTRACT'
              }
            ]}
          />

          {tabsComponent[currentStep]}
        </Shadow>

        <ButtonForm
          loading={isLoadingUpdatePersonData}
          onClick={() => onHandleSubmit()}
          className="button-submit"
          disabled={getIsDisabledNextStep()}
          icon={getButtonSubmitText().icon}
        >
          {getButtonSubmitText().label}
        </ButtonForm>
      </NewRentWrap>
    </Layout>
  )
}

export default CustomerRentContract
