import type { IUOptions } from '@types'

interface EnsureOptionsInterface extends IUOptions {
  subLabel?: string
}

class RentContractEnums {
  public status = [
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Finalizado', value: 'FINALIZADO' },
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Despejo', value: 'DESPEJO' },
    { label: 'Cancelado', value: 'CANCELADO' }
  ]

  public ensure = [
    {
      label: 'Fiador',
      value: 'FIADOR'
    },
    {
      label: 'Depósito Caução',
      value: 'CAUCAO'
    },
    {
      label: 'Seguro Digital Online',
      value: 'SEGURO_DIGITAL'
    },
    {
      label: 'Seguro Fiança',
      value: 'SEGURO_FIANCA'
    },
    {
      label: 'Título de Capitalização',
      value: 'TITULO_CAPITALIZACAO'
    }
  ]

  public terminationType = [
    { label: 'Sem isenção', value: 'SEM_ISENCAO' },
    { label: 'Isento', value: 'ISENTO' },
    { label: 'Meses de Isenção', value: 'MESES_ISENCAO' }
  ]

  public step = [
    { label: 'Iniciado', value: 'INICIADO' },
    { label: 'Agendar Visita', value: 'AGENDAR_VISITA' },
    { label: 'Análise de Crédito', value: 'ANALISE_CREDITO' },
    { label: 'Garantias', value: 'GARANTIAS' },
    { label: 'Assinatura', value: 'ASSINATURA' },
    { label: 'Finalizado', value: 'FINALIZADO' },
  ]

  public incomeTax = [
    { label: 'Não retém', value: 'NAO_RETEM' },
    {
      label: 'Retém, mas não controlado pelo sistema',
      value: 'RETEM_NAO_CONTROLADO_SISTEMA'
    },
    { label: 'Retém, imobiliária recolhe', value: 'RETEM_IMOBILIARIA_RECOLHE' },
    { label: 'Retém, inquilino recolhe', value: 'RETEM_INQUILINO_RECOLHE' }
  ]

  public rehearse = [
    { label: 'Split', value: 'SPLIT' },
    { label: 'Manual', value: 'MANUAL' }
  ]

  public typeTaxAdministration = [
    { label: 'Aluguel', value: 'ALUGUEL' },
    { label: 'Aluguel e Demais Serviços', value: 'ALUGUEL_E_DEMAIS_SERVICOS' }
  ]

  public getStatus(param?: string): IUOptions {
    const filtered = this.status.find(item => item.value === param)

    return !filtered ? this.status[0] : filtered
  }

  public getEnsure(param?: string): EnsureOptionsInterface {
    const filtered = this.ensure.find(item => item.value === param)

    return !filtered ? this.ensure[0] : filtered
  }

  public getTerminationType(param?: string): IUOptions {
    const filtered = this.terminationType.find(item => item.value === param)

    return !filtered ? this.terminationType[0] : filtered
  }

  public getStep(param?: string): IUOptions {
    const filtered = this.step.find(item => item.value === param)

    return !filtered ? this.step[0] : filtered
  }

  public getIncomeTax(param?: string): IUOptions {
    const filtered = this.incomeTax.find(item => item.value === param)

    return !filtered ? this.incomeTax[0] : filtered
  }

  public getRehearseItem(param?: string): IUOptions {
    const filtered = this.rehearse.find(item => item.value === param)

    return !filtered ? this.rehearse[0] : filtered
  }

  public getTypeTaxAdministrationItem(param?: string): IUOptions {
    const filtered = this.typeTaxAdministration.find(
      item => item.value === param
    )

    return !filtered ? this.typeTaxAdministration[0] : filtered
  }
}

export default new RentContractEnums()
