import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useMemo, useState } from 'react';
import { addMonths, format } from 'date-fns';
import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { Table } from 'components/organisms';
import { Button, HelperOptions, Text } from 'components/atoms';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    getRealStateRentContractIndex,
    removeRealStateRentContract,
    updateRealStateRentContractValueMassive,
} from 'services';

import { sweetAlert } from 'utils';

import { InputNumber, Modal, Select } from 'components/molecules';
import { RentContractListWrap } from './styles';
import Layout from '../../Layout';
import type { RentContractMutationInterface } from './interface';
import type { IARentContract } from '@types';

const RentContract = () => {
    const navigate = useNavigate();
    const titleMessages = 'Contratos de Aluguel';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [isOpenModalUpdateValues, setIsOpenModalUpdateValues] =
        useState(false);


    const [percentReadjustment, setPercentReadjustment] = useState(0);
    const [period, setPeriod] = useState('');

    function onHandleNavigate(param: string) {
        navigate(param);
    }

    const onOpenModalUpdateValues = () => {
        setIsOpenModalUpdateValues(true);
    };

    const onCloseModalUpdateValues = () => {
        setIsOpenModalUpdateValues(false);
    };

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.RENT_CONTRACT.list,
        () => getRealStateRentContractIndex(),
        { refetchOnMount: true }
    );

    const { mutate: removeRegister }: RentContractMutationInterface =
        useMutation(({ id }) => removeRealStateRentContract(Number(id)), {
            mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT.remove,
            onSuccess: (response) => {
                onSuccessMessage(titleMessages, response?.data?.message || "Registro removido com sucesso!");

                queryClient.invalidateQueries(
                    QUERIES_REAL_STATE.RENT_CONTRACT.list
                );
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        });

    function editRegister(id: string | number) {
        onHandleNavigate(
            getLinkWithSlug(
                `${ROUTES_REAL_STATE.RENT_CONTRACT.FORM.fullPath}/${id}`
            )
        );
    }

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    const { isLoading: isLoadingReadjust, mutate: readjustRentContract } =
        useMutation(
            () =>
                updateRealStateRentContractValueMassive(
                    period,
                    percentReadjustment
                ),
            {
                mutationKey:
                    QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.updateMassive,
                onSuccess: ({ message }) => {
                    onSuccessMessage('Ajustes dos Contratos', message);

                    onCloseModalUpdateValues();

                    queryClient.refetchQueries(
                        QUERIES_REAL_STATE.RENT_CONTRACT_VALUE.list
                    );
                },
                onError: (err) => {
                    onErrorMessage('Ajustes dos Contratos', err);
                },
            }
        );

    const optionsPeriod = useMemo(() => {
        const currentDate = new Date();
        const nextMonth = addMonths(currentDate, 1);


        return [
            {
                label: format(currentDate, 'MM/yyyy'),
                value: format(currentDate, 'yyyy-MM'),
            },

            {
                label: format(nextMonth, 'MM/yyyy'),
                value: format(nextMonth, 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 2), 'MM/yyyy'),
                value: format(addMonths(currentDate, 2), 'yyyy-MM'),
            },
        ];
    }, []);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Contratos de Aluguel',
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.RENT_CONTRACT.fullPath
                    ),
                },
            ]}
            title="Contratos de Aluguel"
            buttonTitle={
                <Button
                    style={{ margin: '0 1rem 0 0' }}
                    onClick={onOpenModalUpdateValues}
                    color="septernary"
                >
                    Reajustar
                </Button>
            }
        >
            <RentContractListWrap className="rent-contracts-template">
                <Table
                    isLoading={isLoadingList}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'Cód.',
                            sort: true,
                            headerStyle: { width: '50px' },
                        },
                        {
                            dataField: 'pessoas',
                            text: 'Clientes',
                            sort: true,
                            headerStyle: { width: '100%' },
                            formatter: (fieldValue) =>
                                fieldValue
                                    ?.reduce(
                                        (
                                            accumulator: string,
                                            fieldItem: IARentContract
                                        ) =>
                                            `${accumulator}${fieldItem?.ligaPessoas?.nome}, `,
                                        ''
                                    )
                                    .slice(0, -2),
                        },
                        {
                            dataField: 'imoveisPersonalizacoes',
                            text: 'Cod. Imóveis',
                            sort: true,
                            headerStyle: { width: '100%' },
                            formatter: (fieldValue) =>
                                fieldValue
                                    ?.reduce(
                                        (accumulator: any, fieldItem: any) =>
                                            `${accumulator}${fieldItem?.imoveisPersonalizacoesIds}, `,
                                        ''
                                    )
                                    .slice(0, -2),
                        },
                        {
                            dataField: 'status',
                            text: 'Status',
                            sort: true,
                            headerStyle: { width: '100%' },
                        },
                        {
                            dataField: 'etapa',
                            text: 'Etapa',
                            sort: true,
                            headerStyle: { width: '100%' },
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    editFunction: () =>
                                        editRegister(fieldValue),
                                    removeFunction: () =>
                                        removeRegisterRequest(fieldValue),
                                }),
                        },
                    ]}
                    data={dataList}
                />
            </RentContractListWrap>


            <Modal
                open={isOpenModalUpdateValues}
                onCloseModal={() => onCloseModalUpdateValues()}
                title="Reajustar contratos"
                footer={
                    <Button
                        color="octernary"
                        loading={isLoadingReadjust}
                        onClick={readjustRentContract}
                    >
                        Reajustar
                    </Button>
                }
            >
                <Text style={{ padding: '1.875rem' }}>
                    O reajuste acontecerá em todos os contratos que completaram
                    um ano sem reajuste no mês atual.
                </Text>
                <Select
                    required
                    label="Competência do reajuste"
                    placeholder="selecione uma competência"
                    value={period}
                    onChange={setPeriod}
                    name="competencia"
                    model="sinary"
                    options={optionsPeriod}
                />

                <InputNumber
                    required
                    label="Porcentagem de reajuste"
                    value={percentReadjustment}
                    onChange={setPercentReadjustment}
                    id="porcentagem-reajuste"
                    name="porcentagem-reajuste"
                    placeholder="Informe a porcentagem de reajuste"
                    model="sinary"
                    postfix="%"
                    precision={2}
                />
            </Modal>

        </Layout>
    );
};

export default RentContract;
