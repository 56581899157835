/* eslint-disable react/no-unstable-nested-components, @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import { FiEye } from 'react-icons/fi';
import { RiBarcodeBoxLine } from 'react-icons/ri';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { invoiceEnums } from 'enums';

import {
    Button,
    HelperDate,
    HelperMoney,
    HelperOptions,
    Row,
    Text,
} from 'components/atoms';
import { Modal, Select, InputToggle } from 'components/molecules';
import { InvoiceDetails, Table } from 'components/organisms';
import { onErrorMessage, onSuccessMessage } from 'helpers';

import {
    getRealStateInvoiceGenerate,
    getRealStateInvoiceIndex,
    getRealStateRentContractIndex,
    getRealStateInvoicePayMannually,
    getRealStateInvoiceCancel
} from 'services';

import { InvoiceListWrap } from './styles';
import Layout from '../../Layout';
import type { ButtonInterface } from 'components/atoms/Button/interface';

import type { IAInvoice } from '@types';

const PropertyInvoice = () => {
    const titleMessages = 'Faturas!';
    const { getLinkWithSlug } = useRealState();

    const [isOpenModalShowInvoices, setIsOpenModalShowInvoices] =
        useState(false);
    const [itemModalShowInvoices, setItemModalShowInvoices] =
        useState<IAInvoice>();

    const [isOpenModalGenerateInvoices, setIsOpenModalGenerateInvoices] =
        useState(false);

    const [period, setPeriod] = useState('');

    const [isSingleGenerate, setIsSingleGenerate] = useState(false)
    const [rentContractIds, setRentContractIds] = useState("")


    const { data: dataRentContract } = useQuery(
        QUERIES_REAL_STATE.RENT_CONTRACT.list,
        () => getRealStateRentContractIndex(),
        { refetchOnMount: true }
    );

    const formattedDataRentContract = useMemo(() => dataRentContract?.map(item => ({ label: `${item.id} - ${item?.pessoas?.find((pessoa) => pessoa.principal)?.ligaPessoas?.nome}`, value: item.id })), [dataRentContract]);

    const onOpenModalShowInvoices = (item: IAInvoice) => {
        setIsOpenModalShowInvoices(true);
        setItemModalShowInvoices(item);
    };

    const onCloseModalShowInvoices = () => {
        setIsOpenModalShowInvoices(false);
        setItemModalShowInvoices(undefined);
    };

    const onOpenModalGenerateInvoices = () => {
        setIsOpenModalGenerateInvoices(true);
    };

    const onCloseModalGenerateInvoices = () => {
        setIsOpenModalGenerateInvoices(false);
    };

    const optionsPeriod = useMemo(() => {
        const currentDate = new Date();
        const nextMonth = addMonths(currentDate, 1);
        const lastMonth = subMonths(currentDate, 1);

        return [
            {
                label: format(lastMonth, 'MM/yyyy'),
                value: format(lastMonth, 'yyyy-MM'),
            },
            {
                label: format(currentDate, 'MM/yyyy'),
                value: format(currentDate, 'yyyy-MM'),
            },

            {
                label: format(nextMonth, 'MM/yyyy'),
                value: format(nextMonth, 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 2), 'MM/yyyy'),
                value: format(addMonths(currentDate, 2), 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 3), 'MM/yyyy'),
                value: format(addMonths(currentDate, 3), 'yyyy-MM'),
            },
            {
                label: format(addMonths(currentDate, 4), 'MM/yyyy'),
                value: format(addMonths(currentDate, 4), 'yyyy-MM'),
            },
        ];
    }, []);

    const queryClient = useQueryClient();

    const { data, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.INVOICE.list,
        () => getRealStateInvoiceIndex(),
    );

    const { isLoading: isLoadingGenerate, mutate: generateInvoices } =
        useMutation(() => getRealStateInvoiceGenerate(period, isSingleGenerate ? rentContractIds : undefined), {
            mutationKey: QUERIES_REAL_STATE.INVOICE.generate,
            onSuccess: ({ message }) => {
                onSuccessMessage(titleMessages, message);
                onCloseModalGenerateInvoices();

                queryClient.invalidateQueries(QUERIES_REAL_STATE.INVOICE.list);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });



    const { isLoading: isLoadingPayManually, mutate: payMannually } =
        useMutation(() => getRealStateInvoicePayMannually(`${itemModalShowInvoices?.id}`), {
            mutationKey: QUERIES_REAL_STATE.INVOICE.base,
            onSuccess: ({ message }) => {
                onSuccessMessage(titleMessages, message);
                onCloseModalShowInvoices();

                queryClient.invalidateQueries(QUERIES_REAL_STATE.INVOICE.list);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

        const { isLoading: isLoadingCancelInvoice, mutate: cancelInvoice } =
        useMutation(() => getRealStateInvoiceCancel(`${itemModalShowInvoices?.id}`), {
            mutationKey: QUERIES_REAL_STATE.INVOICE.base,
            onSuccess: ({ message }) => {
                onSuccessMessage(titleMessages, message);
                onCloseModalShowInvoices();

                queryClient.invalidateQueries(QUERIES_REAL_STATE.INVOICE.list);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

        const statusComponent = useCallback((fieldValue) => {
            const statusItem = invoiceEnums.getStatus(fieldValue);
            const buttonColors: Record<string, ButtonInterface['color']> = {
                PENDENTE: 'septernary',
                CANCELADA: 'danger',
                VENCIDA: 'octernary',
                PAGA: 'octernary',
            };
    
            return (
                <Button size="mini" color={buttonColors[fieldValue]}>
                    {statusItem.label}
                </Button>
            );
        }, []);

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Faturas',
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.INVOICE_PROPERTY.fullPath
                    ),
                },
            ]}
            title="Faturas"
            buttonTitle={
                <Row>
                    <Button
                        onClick={onOpenModalGenerateInvoices}
                        color="octernary"
                        loading={isLoadingGenerate}
                    >
                        Gerar Faturas
                    </Button>
                </Row>
            }
        >
            <InvoiceListWrap className="invoices-template">
                <Table
                    isLoading={isLoadingList || isLoadingGenerate}
                    columns={[
                        {
                            dataField: 'id',
                            text: 'ID',
                            sort: true,
                            headerStyle: { width: '10%' },
                        },
                        {
                            dataField: 'devedores',
                            text: 'Devedor',
                            sort: true,
                            formatter: (fieldValue) => fieldValue?.nome,
                            headerStyle: { width: '35%' },
                        },

                        {
                            dataField: 'dataVencimento',
                            text: 'Vencimento',
                            headerStyle: { width: '15%' },
                            formatter: (fieldValue) => (
                                <HelperDate value={fieldValue} />
                            ),
                        },

                        {
                            dataField: 'competencia',
                            text: 'Competência',
                            sort: true,
                            headerStyle: { width: '20%' },
                            formatter: (fieldValue) =>
                                fieldValue?.split('-').reverse().join('/'),
                        },

                        {
                            dataField: 'valor',
                            text: 'Valor',
                            formatter: (fieldValue) => (
                                <HelperMoney value={fieldValue} />
                            ),
                            headerStyle: { width: '20%' },
                        },

                        {
                            dataField: 'status',
                            text: 'Status',
                            sort: true,
                            headerStyle: { width: '14.5%' },
                            formatter: (fieldValue) =>
                                statusComponent(fieldValue),
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue, index, row) => (
                                <HelperOptions
                                    editFunction={() =>
                                        onOpenModalShowInvoices(row)
                                    }
                                    editIcon={<FiEye />}
                                    remove={false}
                                />
                            ),
                        },
                    ]}
                    data={data}
                />
            </InvoiceListWrap>

            <Modal
                open={isOpenModalShowInvoices}
                onCloseModal={() => onCloseModalShowInvoices()}
                title="Fatura"
                size="lg"
                footer={
                    <Row>
                       {["PENDENTE", "VENCIDA"].includes(`${itemModalShowInvoices?.status}`) &&
                       (
                                <Button
                                    color="danger"
                                    onClick={() => cancelInvoice()}
                                    loading={isLoadingCancelInvoice}
                                >
                                    Cancelar Fatura
                                </Button>
                            )}

                        {["PENDENTE", "VENCIDA"].includes(`${itemModalShowInvoices?.status}`) &&
                        itemModalShowInvoices?.contratosAlugueis?.repasseTipo === 'MANUAL' &&
                            (
                                <Button
                                    color="septernary"
                                    onClick={() => payMannually()}
                                    loading={isLoadingPayManually}
                                >
                                    Marcar Pago
                                </Button>
                            )}
                        {["PENDENTE"].includes(`${itemModalShowInvoices?.status}`) && 
                        itemModalShowInvoices?.urlBoleto && (
                            <Button
                                color="octernary"
                                onClick={() =>
                                    window.open(itemModalShowInvoices?.urlBoleto)
                                }
                            >
                                <RiBarcodeBoxLine />
                                Boleto
                            </Button>
                        )}
                    </Row>
                }
            >
                <InvoiceDetails data={itemModalShowInvoices} />
            </Modal>

            <Modal
                open={isOpenModalGenerateInvoices}
                onCloseModal={() => onCloseModalGenerateInvoices()}
                title="Gerar Faturamento"
                footer={
                    <Button color="octernary" onClick={generateInvoices} loading={isLoadingGenerate}>
                        Gerar
                    </Button>
                }
            >
                <Text style={{ padding: '1.875rem' }}>
                    É necessário selecionar uma competência para gerar o
                    faturamento.
                </Text>

                <Select
                    required
                    label="Competência do faturamento"
                    placeholder="selecione uma competência"
                    value={period}
                    onChange={setPeriod}
                    name="competencia"
                    model="sinary"
                    options={optionsPeriod}
                />

                <InputToggle
                    labelTrue='Gerar faturas unitárias'
                    labelFalse='Gerar todas as faturas da competência'
                    value={isSingleGenerate}
                    id='single-generate'
                    name='single-generate'
                    onChange={setIsSingleGenerate}
                />

                {isSingleGenerate && (
                    <Select
                        required
                        label="Contratos"
                        placeholder="selecione um contrato"
                        value={rentContractIds}
                        onChange={setRentContractIds}
                        name="competencia"
                        model="sinary"
                        options={formattedDataRentContract}
                    />
                )}
            </Modal>

        </Layout>
    );
};

export default PropertyInvoice;
