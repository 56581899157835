import styled from 'styled-components'

export const HelperOptionsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    cursor: pointer;
    height: 1.875rem;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;

    transition: fill 0.3s ease;
    color: ${props => props.theme.colors.tertiary};

    &:focus {
      outline: 0;
    }

    svg {
      display: inline-block;
      font-size: 1.2rem;
      color: ${props => props.theme.colors.tertiary};
    }

    &.danger {
      color: ${props => props.theme.colors.danger};
      svg {
        color: ${props => props.theme.colors.danger};
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
`
