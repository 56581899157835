import { GrUpdate } from 'react-icons/gr';
import { Table } from 'components/organisms';

import { HelperBoolean, HelperOptions } from 'components/atoms';
import { PeopleRentContractWrap } from './styles';

import type {
    PeopleRentContractInterface,
} from './interface';

const PeopleRentContract = ({ data, onUpdateIntegration }: PeopleRentContractInterface) =>

(
    <PeopleRentContractWrap className="people-rent-contract-panel-template">
        <Table
            columns={[
                {
                    dataField: 'id',
                    text: 'Cód.',
                    headerStyle: { width: '5% ' },
                },

                {
                    dataField: 'ligaPessoas',
                    text: 'Nome',
                    headerStyle: { width: '85% ' },
                    formatter: (value) => value?.nome
                },

                {
                    dataField: 'principal',
                    text: 'Principal',
                    headerStyle: { width: '10%' },
                    formatter: (fieldValue) => (
                        <HelperBoolean value={fieldValue} />
                    ),
                },


                {
                    dataField: 'ligaPessoas',
                    text: 'Opções',
                    formatter: (fieldValue) =>
                        HelperOptions({
                            editIcon: <GrUpdate />,
                            editFunction: () => onUpdateIntegration(fieldValue.id),
                            remove: false
                        })
                }

            ]}
            data={data}
            isLoading={false}
        />

    </PeopleRentContractWrap>
)
    ;

export default PeopleRentContract;
