import { Helmet } from 'react-helmet';

import { AdministratorPersonTagForm } from 'components/templates';

const AdministratorPersonTagFormPage = () => (
    <>
        <Helmet>
            <title>Tags da Pessoa - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPersonTagForm />
    </>
);

export default AdministratorPersonTagFormPage;
