import { Helmet } from 'react-helmet';

import { RealStateInsuranceCompanyList } from 'components/templates';

const RealStateInsuranceCompanyListPage = () => (
    <>
        <Helmet>
            <title>Seguradoras - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateInsuranceCompanyList />
    </>
);

export default RealStateInsuranceCompanyListPage;
