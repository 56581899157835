import styled from 'styled-components';

export const InputCalendarWrap = styled.div`

  position: relative;

  .calendar-molecule {
    position: absolute;
    top: 100%;
    left: 49%;
    transform: translateX(-50%);
    z-index: 9999;
    background-color: #fff;
  }
`;
