import {
    ModalOffset,
    ModalHeader,
    ModalContent,
    ModalFooter,
} from 'components/atoms';

import type { ModalInterface } from './interface';

const Modal = ({
    open: openOutside = false,
    onCloseModal: onCloseModalOutside = () => null,
    size = 'md',
    children,
    title,
    footer,
}: ModalInterface) => (
    <ModalOffset
        open={openOutside}
        onCloseModal={onCloseModalOutside}
        size={size}
    >
        <>
            {title !== undefined && (
                <ModalHeader title={title} onCloseModal={onCloseModalOutside} />
            )}
            <ModalContent>{children}</ModalContent>

            {footer !== undefined && <ModalFooter>{footer}</ModalFooter>}
        </>
    </ModalOffset>
);

export default Modal;
