import styled, { css } from 'styled-components'

interface RowWrapInterface {
  haveColumn: boolean
}

const haveColumnStyles = css`
  margin-left: -0.93rem !important;
  margin-right: -0.93rem !important;
`

export const RowWrap = styled.div<RowWrapInterface>`
  display: flex;
  flex-direction: row;

  ${props => props.haveColumn && haveColumnStyles}
`
