/* eslint-disable  no-lonely-if */
import { useState, useEffect } from 'react';

import { checkEmpty, convertFloatToBR } from 'utils';

import { InputCapsule } from 'components/atoms';

import { InputNumberWrap } from './styles';

import type { InputNumberInterface } from './interface';

const InputNumber = ({
    id,
    label,
    required,
    model,
    name,
    placeholder,
    value: valueReceived,
    readOnly,
    onChange = () => null,
    onBlur: onBlurOutside = () => null,

    precision = 2,
    max = 99999999999999,
    min = -99999999999999,
    prefix = '',
    postfix = '',
}: InputNumberInterface) => {
    const [valueFormated, setValueFormated] = useState<string>('');
    const [value, setValue] = useState<number | null>(null);
    const [edit, setEdit] = useState<boolean>(false);

    function changeValueFormated(param: number | null) {
        if (param !== null) {
            const valFormated =
                prefix + convertFloatToBR(param, precision) + postfix;

            setValueFormated(valFormated);
        } else {
            if (value !== null) {
                const valFormated =
                    prefix + convertFloatToBR(value, precision) + postfix;

                setValueFormated(valFormated);
            }
        }
    }

    function onChangeValue(val: string) {
        setValueFormated(val);

        let numberVal = Number(val);

        if (String(val).indexOf(',') > -1) {
            numberVal = parseFloat(
                String(val).replace('.', '').replace(',', '.')
            );
        }

        if (numberVal < min) {
            numberVal = min;
        }

        if (numberVal > max) {
            numberVal = max;
        }

        setValue(numberVal);
        onChange(numberVal);
    }

    function clearNumber() {
        if (!checkEmpty(value)) {
            const val = String(value).replace('^[d,.?!]+$', '');

            setValue(Number(val));
            setValueFormated(String(val).replace('.', ','));
        } else {
            setValue(null);
            setValueFormated('');
        }
    }

    function onFocus() {
        clearNumber();
        setEdit(true);
    }

    function onBlur() {
        changeValueFormated(value);
        setEdit(false);


        onBlurOutside();
    }

    useEffect(() => {
        if (!edit) {
            changeValueFormated(Number(valueReceived));
            setValue(Number(valueReceived));
        }
    }, [valueReceived]);

    useEffect(() => {
        if (!edit && value !== null) {
            onChange(value);
        }
    }, [edit]);

    return (
        <InputNumberWrap className="input-number-molecule">
            <InputCapsule
                htmlFor={id}
                label={label}
                required={required}
                empty={checkEmpty(value)}
                model={model}
            >
                <input
                    id={id || name}
                    placeholder={placeholder}
                    name={name}
                    value={valueFormated}
                    onChange={(event) => onChangeValue(event.target.value)}
                    readOnly={readOnly}
                    onFocus={() => onFocus()}
                    onBlur={() => onBlur()}
                />
            </InputCapsule>
        </InputNumberWrap>
    );
};

export default InputNumber;
