import { useEffect } from 'react'

import { checkEmpty } from 'utils'
import { ColumnWrap } from './styles'

import type { ColumnInterface } from './interface'

const Column = ({
  children,
  xl,
  lg,
  md,
  sm,
  basis = false,
  style
}: ColumnInterface) => {

  let xlNew = xl;
  let lgNew = lg;
  let mdNew = md;
  let smNew = sm;

  useEffect(() => {
    if (checkEmpty(sm)) {
      smNew = 1
    }

    if (checkEmpty(md)) {
      mdNew = smNew
    }

    if (checkEmpty(lg)) {
      lgNew = mdNew
    }

    if (checkEmpty(xl)) {
      xlNew = lgNew
    }
  }, [xl, lg, md, sm, basis])

  return (
    <ColumnWrap
      xl={xlNew}
      lg={lgNew}
      md={mdNew}
      sm={smNew}
      basis={basis}
      style={style}
    >
      {children}
    </ColumnWrap>
  )
}

export default Column
