import { convertDateENToBR, distanceToNow } from 'utils';

import { HelperDateWrap } from './styles';

import type { HelperDateInterface } from './interface';

const HelperDate = ({ value, distance = false }: HelperDateInterface) => (
    <HelperDateWrap
        className={value ? 'helper-date-atom active' : 'helper-date-atom'}
    >
        {distance ? distanceToNow(value) : convertDateENToBR(value)}
    </HelperDateWrap>
);

export default HelperDate;
