import styled from 'styled-components';

export const ModalFooterWrap = styled.div`
    width: 100%;
    min-height: 4.063rem;

    display: flex;
    padding: 0.945rem 1.875rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${(props) => props.theme.colors.background};
    border-top: 0.063rem solid ${(props) => props.theme.colors.borderSepternary};

    button {
        margin-left: 0.938rem;
    }
`;
