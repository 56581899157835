import { Helmet } from 'react-helmet';

import { RealStateBannerList } from 'components/templates';

const RealStateBannerListPage = () => (
    <>
        <Helmet>
            <title>Banner - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateBannerList />
    </>
);

export default RealStateBannerListPage;
