import styled from 'styled-components';

export const PropertyInviteListWrap = styled.div`
    display: block;
    width: 100%;
    overflow: auto;
`;

export const ModalContent = styled.div`
    padding: 2rem;

    p {
        margin-bottom: 1rem;
    }
`;