import { ModalContentWrap } from './styles';

import type { ModalContentInterface } from './interface';

const ModalContent = ({ children }: ModalContentInterface) => (
    <ModalContentWrap className="modal-content-atom">
        {children}
    </ModalContentWrap>
);

export default ModalContent;
