import { Helmet } from 'react-helmet';

import { AdministratorPropertiesTypesForm } from 'components/templates';

const AdministratorPropertiesTypesFormPage = () => (
    <>
        <Helmet>
            <title>Tipos dos Imóveis - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPropertiesTypesForm />
    </>
);

export default AdministratorPropertiesTypesFormPage;
