import { Helmet } from 'react-helmet';

import { RealStateAccount } from 'components/templates';

const RealStateAccountPage = () => (
    <>
        <Helmet>
            <title>Minha Conta - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateAccount />
    </>
);

export default RealStateAccountPage;
