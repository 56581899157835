import { generateURL } from './prefix';
import api from '../api';

export const getPublicRealStateImobilerDetail = async () => {
    const url = generateURL('/imobiler');

    const { data } = await api.get(url);

    return data;
};

export const getPublicRealStateDetail = async (realStateSlug: string) => {
    const url = generateURL(`/imobiliarias/${realStateSlug}`);

    const { data } = await api.get(url);

    return data;
};
