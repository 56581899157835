/* eslint-disable  @typescript-eslint/no-unused-vars, react/no-unstable-nested-components */
import { ReactNode, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import {QUERIES_ADMIN,  ROUTES_ACCOUNT, ROUTES_ADMIN } from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import {
    Button,
    Loading,
    Shadow,
} from 'components/atoms';
import {  OptionBlock } from 'components/molecules';

import {
    getAdministratorRealStateShow
} from 'services';

import { RealStateFormWrap } from './styles';
import { InvoiceProperty, InvoiceSettlement } from './components'
import Layout from '../../Layout';


const RealStateForm = () => {
    const { id } = useParams();

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('INVOICE_PROPERTY');


    const refreshData = () => {
        queryClient.refetchQueries([QUERIES_ADMIN.REAL_STATE.base, String(id)]);
    }

    const { data, isLoading, isFetching } = useQuery(
        [QUERIES_ADMIN.REAL_STATE.base, id],
        () => getAdministratorRealStateShow(String(id)),
        {
            enabled: !!id,
        }
    );

    const tabsComponent: Record<string, ReactNode> = {
        INVOICE_PROPERTY: <InvoiceProperty id={id} data={data?.faturas} isLoading={isLoading} />,
        INVOICE_SETTLEMENT: <InvoiceSettlement id={id} data={data?.faturasAcertos} isLoading={isLoading}/>,
    }

    return (
        <Layout
            titlePage="Painel Administrativo"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Imobiliárias',
                    href: getLinkWithSlug(ROUTES_ADMIN.REAL_STATE.fullPath),
                },
                {
                    label: 'Visão da Imobiliária',
                    href: getLinkWithSlug(
                        ROUTES_ADMIN.REAL_STATE.FORM.fullPath
                    ),
                },
            ]}
            title="Visão da Imobiliária"
            buttonTitle={
                <Button loading={isLoading || isFetching} color='septernary' onClick={refreshData}>
                    <BiRefresh />
                    Atualizar 
                </Button>
            }
        >
            <RealStateFormWrap className="administrator-panel-real-state-form">
                <Shadow>
                    <Loading active={false} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={[
                            {
                                label: 'Faturas Imóveis',
                                value: 'INVOICE_PROPERTY',
                            },
                            {
                                label: 'Faturas Acertos',
                                value: 'INVOICE_SETTLEMENT',
                            },
                        ]}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    {tabsComponent[step]}
                   
                </Shadow>

               
            </RealStateFormWrap>
        </Layout>
    );
};

export default RealStateForm;
