import { generateURL } from './prefix';
import api from '../api';

export const getAdministratorInvoicePay = async (type: "FATURA_IMOVEL" | "FATURA_ACERTO", id: number | string) => {
    const url = generateURL(`/faturas/${type}@${id}/pagar`);

    const { data } = await api.get(url);

    return data;
};
