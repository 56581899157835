import { Helmet } from 'react-helmet';

import { RealStateInvoiceSettlementList } from 'components/templates';

const RealStateInvoiceSettlementListPage = () => (
    <>
        <Helmet>
            <title>Acertos - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateInvoiceSettlementList />
    </>
);

export default RealStateInvoiceSettlementListPage;
