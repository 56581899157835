const OWNER_BASE = `/owner/`;
const OWNER_ACCOUNT = `account`;

const OWNER_PROPERTY_INVITE = `property-invite`;

const OWNER_INVOICE_SETTLEMENT = `invoice-settlement`;
const OWNER_INVOICE_SETTLEMENT_PRINT = `print`;

const OWNER_REAL_STATE_INVITE = `real-state-invite`;


export const ROUTES_OWNER = {
    path: OWNER_BASE,
    fullPath: OWNER_BASE,
    ACCOUNT: {
        path: OWNER_ACCOUNT,
        fullPath: `${OWNER_BASE}${OWNER_ACCOUNT}`,
    },
    INVOICE_SETTLEMENT: {
        path: OWNER_INVOICE_SETTLEMENT,
        fullPath: `${OWNER_BASE}${OWNER_INVOICE_SETTLEMENT}`,
        PRINT: {
            path: `${OWNER_INVOICE_SETTLEMENT}/${OWNER_INVOICE_SETTLEMENT_PRINT}`,
            fullPath: `${OWNER_BASE}${OWNER_INVOICE_SETTLEMENT}/${OWNER_INVOICE_SETTLEMENT_PRINT}`,
        }
    },
    PROPERTY_INVITE: {
        path: OWNER_PROPERTY_INVITE,
        fullPath: `${OWNER_BASE}${OWNER_PROPERTY_INVITE}`,
    },
    REAL_STATE_INVITE: {
        path: OWNER_REAL_STATE_INVITE,
        fullPath: `${OWNER_BASE}${OWNER_REAL_STATE_INVITE}`,
    }
};
