import { ReactNode, useState, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';

import { useRealState } from 'contexts/RealStateContext';

import { BoxInfo, Button, Loading, Shadow } from 'components/atoms';
import { StepCircle } from 'components/molecules';
import {
    getRealStateBankAccountIndex,
    getRealStateIntegrationRegister,
    updateRealStateIntegrationRegisterBusinessData,
    updateRealStateIntegrationRegisterOwnerData,
    createRealStateBankAccountIndex,
    updateRealStateBankAccountIndex,
} from 'services';

import { convertDateENToBR } from 'utils'

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { IntegrationRegisterWrap } from './styles';

import { bankAccountDataSchema, businessDataSchema, ownerDataSchema } from './validations'

import BusinessData from './BusinessData';
import OwnerData from './OwnerData';
import BankAccountData from './BankAccountData';
import Finish from './Finish'

import Layout from '../Layout';

import type {
    IAPerson, IAPersonBankAccount,
} from '@types';


const IntegrationRegister = () => {
    const titleMessages = 'Registro de Integração'

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('BUSINESS_DATA');

    const [bankAccountData, setBankAccountData] = useState<Partial<IAPersonBankAccount>>();
    const [personData, setPersonData] = useState<Partial<IAPerson>>();
    const [ownerPersonData, setOwnerPersonData] = useState<Partial<IAPerson>>();

    const {
        isLoading: isLoadingBankAccountList,
        data: bankAccountDataApi
    } = useQuery([QUERIES_REAL_STATE.REAL_STATE.BANK_ACCOUNT.base], () =>
        getRealStateBankAccountIndex(),
        {
            refetchOnMount: true,
            onSuccess: (res) => {
                const findedBankAccount = res?.find((bankAccount) => bankAccount?.ativa) || res?.[0] || undefined;

                if (findedBankAccount) {
                    setBankAccountData(findedBankAccount)
                }
            }
        }
    );

    const {
        isLoading: isLoadingRealStateDetails,
        isFetching: isFetchingRealStateDetails,
        data: realStateDataApi,
    } = useQuery([QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base], () =>
        getRealStateIntegrationRegister(),
        {
            refetchOnMount: true,
            onSuccess: (res) => {
                setPersonData(res?.pessoas);

                const ownerPayload = res?.pessoas?.responsaveis;

                if (ownerPayload?.nascimento) {
                    ownerPayload.nascimento = convertDateENToBR(ownerPayload?.nascimento);
                }
                setOwnerPersonData(ownerPayload);

                if (bankAccountData === undefined) {
                    setBankAccountData({
                        nomeTitular: res?.pessoas?.nome,
                        tipoConta: '',
                        banco: '',
                        agencia: '',
                        conta: '',
                        numeroDocumento: res?.pessoas?.documento,
                        ativa: true,
                    })
                } else {
                    setBankAccountData({
                        ...bankAccountData,
                        nomeTitular: res?.pessoas?.nome,
                        numeroDocumento: res?.pessoas?.documento,
                    })
                }
            }
        }
    );

    const { mutate: mutateUpdateBusinessData, isLoading: isLoadingUpdateBusinessData } = useMutation(
        (payload: Partial<IAPerson>) => updateRealStateIntegrationRegisterBusinessData(payload),
        {
            mutationKey: [QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdateOwnerData, isLoading: isLoadingUpdateOwnerData } = useMutation(
        (payload: Partial<IAPerson>) => updateRealStateIntegrationRegisterOwnerData(payload),
        {
            mutationKey: [QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateCreateBankAccountData, isLoading: isLoadingCreateBankAccountData } = useMutation(
        (payload: Partial<IAPersonBankAccount>) => createRealStateBankAccountIndex(payload),
        {
            mutationKey: [QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.REAL_STATE.BANK_ACCOUNT.base]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { mutate: mutateUpdateBankAccountData, isLoading: isLoadingUpdateBankAccountData } = useMutation(
        (payload: Partial<IAPersonBankAccount>) => updateRealStateBankAccountIndex(String(payload?.id), payload),
        {
            mutationKey: [QUERIES_REAL_STATE.REAL_STATE.INTEGRATION_REGISTER.base, step],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao salvar as informações!'
                );

                queryClient.invalidateQueries([QUERIES_REAL_STATE.REAL_STATE.BANK_ACCOUNT.base]);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const handleSubmitToNext = async () => {
        if (step === 'BUSINESS_DATA') {
            const { success } = await onValidationSchema(businessDataSchema, personData);

            if (success && personData) {
                mutateUpdateBusinessData(personData);
            }
        } else if (step === 'OWNER_DATA') {
            const { success } = await onValidationSchema(ownerDataSchema, ownerPersonData);

            if (success && ownerPersonData) {
                const payload = ownerPersonData;

                if (payload?.nascimento) {
                    payload.nascimento = payload.nascimento.split('/').reverse().join('-');
                }

                mutateUpdateOwnerData(payload);
            }

        } else if (step === 'BANK_ACCOUNT_DATA') {

            const { success } = await onValidationSchema(bankAccountDataSchema, bankAccountData);

            if (success && bankAccountData) {
                if (bankAccountData?.id) {
                    mutateUpdateBankAccountData(bankAccountData);
                } else {
                    mutateCreateBankAccountData(bankAccountData);
                }

            }

        }
    }


    const valueChecked = useMemo(() => {
        const checked: string[] = [];

        const personDataLocal = realStateDataApi?.pessoas;
        const ownerPersonDataLocal = realStateDataApi?.pessoas?.responsaveis;
        const bankAccountDataLocal = bankAccountDataApi?.find((bankAccount) => bankAccount?.ativa) || bankAccountDataApi?.[0] || undefined;

        let currentStep = 'BUSINESS_DATA';

        if (
            !!personDataLocal?.nome &&
            !!personDataLocal?.telefone &&
            !!personDataLocal?.email &&
            !!personDataLocal?.documento &&
            !!personDataLocal?.cep &&
            !!personDataLocal?.logradouro &&
            !!personDataLocal?.bairrosIds &&
            !!personDataLocal?.numero
        ) {
            checked.push('BUSINESS_DATA')

            currentStep = 'OWNER_DATA'
        }

        if (
            !!ownerPersonDataLocal?.nome &&
            !!ownerPersonDataLocal?.telefone &&
            !!ownerPersonDataLocal?.email &&
            !!ownerPersonDataLocal?.documento &&
            !!ownerPersonDataLocal?.nascimento &&
            !!ownerPersonDataLocal?.cep &&
            !!ownerPersonDataLocal?.logradouro &&
            !!ownerPersonDataLocal?.bairrosIds &&
            !!ownerPersonDataLocal?.numero
        ) {
            checked.push('OWNER_DATA')

            currentStep = 'BANK_ACCOUNT_DATA'
        }

        if (!!bankAccountDataLocal?.nomeTitular &&
            !!bankAccountDataLocal?.tipoConta &&
            !!bankAccountDataLocal?.banco &&
            !!bankAccountDataLocal?.agencia &&
            !!bankAccountDataLocal?.conta &&
            !!bankAccountDataLocal?.numeroDocumento) {

            checked.push('BANK_ACCOUNT_DATA')

            currentStep = 'FINISH'
        }

        if (personDataLocal?.integracoesPagamentosVendedoresIds) {
            checked.push('FINISH')
        }

        setStep(currentStep)

        return checked;

    }, [realStateDataApi, bankAccountDataApi])


    const tabsComponent: Record<string, ReactNode> = {
        BUSINESS_DATA: <BusinessData values={personData} onChangeValues={setPersonData} />,
        OWNER_DATA: <OwnerData values={ownerPersonData} onChangeValues={setOwnerPersonData} />,
        BANK_ACCOUNT_DATA: <BankAccountData
            values={bankAccountData}
            onChangeValues={setBankAccountData}
        />,
        FINISH: <Finish integracoesPagamentosVendedoresIds={personData?.integracoesPagamentosVendedoresIds} />,
    };

    const buttonsActions: Record<string, ReactNode> = {
        BUSINESS_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingUpdateBusinessData}>Avançar</Button>),
        OWNER_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingUpdateOwnerData}>Avançar</Button>),
        BANK_ACCOUNT_DATA: (<Button color="octernary" onClick={handleSubmitToNext} loading={isLoadingCreateBankAccountData || isLoadingUpdateBankAccountData}>Avançar</Button>),
        FINISH: null,
    };

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Registro de Integração',
                    href: getLinkWithSlug(ROUTES_REAL_STATE.INTEGRATION_REGISTER.fullPath),
                },
            ]}
            title="Formulário Integração de Registro"
            buttonTitle={buttonsActions[step]}
        >
            <IntegrationRegisterWrap className="real-state-panel-integration-register">
                <BoxInfo style={{ marginBottom: '2rem' }}>Com o objetivo de garantir uma transação segura e eficiente junto ao Imobiler, solicitamos gentilmente que nos forneça os seguintes dados referentes à sua empresa. Essas informações nos permitirão oferecer um atendimento personalizado e ajustado às suas necessidades, garantindo que todo o processo ocorra sem quaisquer problemas. </BoxInfo>
                <Shadow>
                    <Loading
                        active={
                            isLoadingBankAccountList ||
                            isLoadingRealStateDetails ||
                            isFetchingRealStateDetails
                        }
                    />

                    <StepCircle
                        options={[
                            { label: 'Etapa 1', subLabel: "Dados da Empresa", value: 'BUSINESS_DATA' },
                            { label: 'Etapa 2', subLabel: "Dados do Responsável", value: 'OWNER_DATA' },
                            { label: 'Etapa 3', subLabel: 'Dados Bancários', value: 'BANK_ACCOUNT_DATA' },
                            { label: 'Etapa 4', subLabel: 'Concluir Integração', value: 'FINISH' }
                        ]}
                        valueActive={step}
                        onChangeActive={setStep}
                        typeOnClick="isActive"
                        valueChecked={valueChecked}
                    />

                    {tabsComponent[step]}
                </Shadow>
            </IntegrationRegisterWrap>
        </Layout>
    );
};

export default IntegrationRegister;
