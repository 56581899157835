import styled from 'styled-components'

export const PropertyHorizontalWrap = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.senary};

  .box-content {
    flex: 1;
    grid-gap: 0;

    .column-left {
      padding: 2.25rem;
      border-right: 0.125rem solid ${props => props.theme.colors.borderPrimary};

      .button-action {
        margin-left: 3rem;
        margin-top: 1.2rem;
        display: inline-block;
      }
    }

    .column-right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 2.25rem;
    }
  }

  .grid-atom {
    margin: 0 !important;
  }

  .card-image-atom {
    height: 13.5rem;
    width: 17rem;
  }
`
