import styled from 'styled-components';

export const InputCodeWrap = styled.div<{ isEmpty: boolean }>`
     font-family: 'Work Sans', sans-serif !important;
   position: relative;

         input {
           height: 3.75rem;
           width: 3.75rem;
           padding: 1.2rem;
           border: 0.068rem solid
           ${(props) => props.theme.colors.borderSenary};
           outline: 0;
           opacity: ${(props) => (props.isEmpty ? 0.6 : 1)};
           margin-right: 1.5rem;

           &:last-child {
            margin-right: 0;
           }
           
           
           font-family: 'Work Sans', sans-serif !important;
             font-size: 1.13rem;
             font-weight: ${(props) => (props.isEmpty ? 300 : 700)};
                line-height: 3.75rem;
                color: ${(props) => props.theme.colors.textSecondary};
                text-align: center;

                &:focus,
                &.is-valid,
                &.is-invalid {
                  outline: 0;
                    border: 0.068rem solid
                        ${(props) => props.theme.colors.borderSenary};
                }

         }
     


`
