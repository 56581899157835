import { Helmet } from 'react-helmet';

import { OwnerRealStateInviteList } from 'components/templates';

const OwnerRealStateInviteListPage = () => (
    <>
        <Helmet>
            <title>Convites das Imobiliárias - Painel do Proprietário - Imobiler</title>
        </Helmet>

        <OwnerRealStateInviteList />
    </>
);

export default OwnerRealStateInviteListPage;
