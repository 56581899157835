import { Helmet } from 'react-helmet';

import { AdministratorPropertiesAttributesList } from 'components/templates';

const AdministratorPropertiesAttributesListPage = () => (
    <>
        <Helmet>
            <title>Atributos dos Imóveis - Painel Administrador - Imobiler</title>
        </Helmet>

        <AdministratorPropertiesAttributesList />
    </>
);

export default AdministratorPropertiesAttributesListPage;
