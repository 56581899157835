import { BadgeWrap } from './styles'

import type { BadgeInterface } from './interface'

const Badge = ({ color = 'primary', children, className, style }: BadgeInterface) => (
  <BadgeWrap style={style} color={color} className={className ? `${className} badge-atom number` : 'badge-atom number'}>
    {children}
  </BadgeWrap>
)

export default Badge
