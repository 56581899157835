import { useQuery } from 'react-query';

import { QUERIES_REAL_STATE } from '@constants';
import { Loading } from 'components/atoms';
import { InputNumber } from 'components/molecules';
import { getRealStatePropertyValueDetailIndex } from 'services';

import type { PropertyNewFormValueDetailsInterface } from './interface';

const PropertyNewFormValueDetails = ({
    value: valueOutside = [],
    onChange,
}: PropertyNewFormValueDetailsInterface) => {
    const { data, isLoading } = useQuery(
        QUERIES_REAL_STATE.PROPERTY_VALUE_DETAIL.base,
        () => getRealStatePropertyValueDetailIndex()
    );

    const onGetValueDetailsAndValue = (id: number) => {
        const detalheDoValor = valueOutside?.find(
            (detValor) => Number(detValor.detalhesDeValoresIds) === Number(id)
        );

        return Number(detalheDoValor?.valor) || undefined;
    };

    const onSetValueDetailsAndValue = (id: number, value: any) => {
        let edit = false;

        const newDetalhesDeValores = valueOutside?.map((detValor: any) => {
            if (Number(detValor.detalhesDeValoresIds) !== Number(id)) {
                return detValor;
            }

            edit = true;

            return {
                ...detValor,
                valor: value,
            };
        });

        if (edit) {
            onChange(newDetalhesDeValores);
        } else {
            onChange([
                ...valueOutside,
                {
                    detalhesDeValoresIds: id,
                    valor: value,
                },
            ]);
        }
    };

    return (
        <div>
            <Loading active={isLoading} />

            {data?.map((detalheDoValor) => (
                <InputNumber
                    model="sinary"
                    key={detalheDoValor.id}
                    label={detalheDoValor.nome}
                    name={detalheDoValor.nome}
                    placeholder={`Informe ${detalheDoValor.nome}`}
                    value={onGetValueDetailsAndValue(detalheDoValor.id)}
                    onChange={(value) =>
                        onSetValueDetailsAndValue(detalheDoValor.id, value)
                    }
                />
            ))}
        </div>
    );
};

export default PropertyNewFormValueDetails;
