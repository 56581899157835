const ADMINISTRATOR_BASE = `/administrator/`;
const ADMINISTRATOR_ACCOUNT = `account`;
const ADMINISTRATOR_USER_TAG = `user-tag`;
const ADMINISTRATOR_USER_TAG_FORM = `form`;
const ADMINISTRATOR_REAL_STATE = 'real-state';
const ADMINISTRATOR_REAL_STATE_FORM = 'form';
const ADMINISTRATOR_PLAN = 'plan';
const ADMINISTRATOR_PLAN_FORM = 'form';
const ADMINISTRATOR_METHODS_PAYMENTS = 'methods-payments';
const ADMINISTRATOR_METHODS_PAYMENTS_FORM = 'form';
const ADMINISTRATOR_PRICES_INDEXS = 'prices-indexs';
const ADMINISTRATOR_PRICES_INDEXS_FORM = 'form';
const ADMINISTRATOR_PROPERTIES_ATTRIBUTES = 'properties-attributes';
const ADMINISTRATOR_PROPERTIES_ATTRIBUTES_FORM = 'form';
const ADMINISTRATOR_PROPERTIES_TYPES = 'properties-types';
const ADMINISTRATOR_PROPERTIES_TYPES_FORM = 'form';
const ADMINISTRATOR_SIGNATURES = 'signatures';
const ADMINISTRATOR_SIGNATURES_FORM = 'form';
const ADMINISTRATOR_VALUE_DETAIL = 'value-detail';
const ADMINISTRATOR_VALUE_DETAIL_FORM = 'form';

export const ROUTES_ADMIN = {
    path: ADMINISTRATOR_BASE,
    fullPath: ADMINISTRATOR_BASE,
    ACCOUNT: {
        path: ADMINISTRATOR_ACCOUNT,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_ACCOUNT}`,
    },
    REAL_STATE: {
        path: ADMINISTRATOR_REAL_STATE,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_REAL_STATE}`,
        FORM: {
            path: `${ADMINISTRATOR_REAL_STATE}/${ADMINISTRATOR_REAL_STATE_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_REAL_STATE}/${ADMINISTRATOR_REAL_STATE_FORM}`,
        },
    },
    USER_TAG: {
        path: ADMINISTRATOR_USER_TAG,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_USER_TAG}`,
        FORM: {
            path: `${ADMINISTRATOR_USER_TAG}/${ADMINISTRATOR_USER_TAG_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_USER_TAG}/${ADMINISTRATOR_USER_TAG_FORM}`,
        },
    },
    PLAN: {
        path: ADMINISTRATOR_PLAN,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PLAN}`,
        FORM: {
            path: `${ADMINISTRATOR_PLAN}/${ADMINISTRATOR_PLAN_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PLAN}/${ADMINISTRATOR_PLAN_FORM}`,
        },
    },
    METHODS_PAYMENTS: {
        path: ADMINISTRATOR_METHODS_PAYMENTS,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_METHODS_PAYMENTS}`,
        FORM: {
            path: `${ADMINISTRATOR_METHODS_PAYMENTS}/${ADMINISTRATOR_METHODS_PAYMENTS_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_METHODS_PAYMENTS}/${ADMINISTRATOR_METHODS_PAYMENTS_FORM}`,
        },
    },
    PRICES_INDEXS: {
        path: ADMINISTRATOR_PRICES_INDEXS,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PRICES_INDEXS}`,
        FORM: {
            path: `${ADMINISTRATOR_PRICES_INDEXS}/${ADMINISTRATOR_PRICES_INDEXS_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PRICES_INDEXS}/${ADMINISTRATOR_PRICES_INDEXS_FORM}`,
        },
    },
    PROPERTIES_ATTRIBUTES: {
        path: ADMINISTRATOR_PROPERTIES_ATTRIBUTES,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PROPERTIES_ATTRIBUTES}`,
        FORM: {
            path: `${ADMINISTRATOR_PROPERTIES_ATTRIBUTES}/${ADMINISTRATOR_PROPERTIES_ATTRIBUTES_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PROPERTIES_ATTRIBUTES}/${ADMINISTRATOR_PROPERTIES_ATTRIBUTES_FORM}`,
        },
    },
    PROPERTIES_TYPES: {
        path: ADMINISTRATOR_PROPERTIES_TYPES,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PROPERTIES_TYPES}`,
        FORM: {
            path: `${ADMINISTRATOR_PROPERTIES_TYPES}/${ADMINISTRATOR_PROPERTIES_TYPES_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_PROPERTIES_TYPES}/${ADMINISTRATOR_PROPERTIES_TYPES_FORM}`,
        },
    },
    SIGNATURES: {
        path: ADMINISTRATOR_SIGNATURES,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_SIGNATURES}`,
        FORM: {
            path: `${ADMINISTRATOR_SIGNATURES}/${ADMINISTRATOR_SIGNATURES_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_SIGNATURES}/${ADMINISTRATOR_SIGNATURES_FORM}`,
        },
    },
    VALUE_DETAIL: {
        path: ADMINISTRATOR_VALUE_DETAIL,
        fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_VALUE_DETAIL}`,
        FORM: {
            path: `${ADMINISTRATOR_VALUE_DETAIL}/${ADMINISTRATOR_VALUE_DETAIL_FORM}`,
            fullPath: `${ADMINISTRATOR_BASE}${ADMINISTRATOR_VALUE_DETAIL}/${ADMINISTRATOR_VALUE_DETAIL_FORM}`,
        }
    }
};
