import styled from 'styled-components';

export const BannerFormWrap = styled.div`
    display: block;
    width: 100%;
    overflow: auto;

    .shadow-atom {
        padding-bottom: 0.5rem;
    }
`;

export const AlignImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
`;


