import { generateURL } from './prefix'
import api from '../api';

import type { IAInvoiceSettlement } from '@types';

export const getOwnerInvoiceSettlementIndex = async (): Promise<
    IAInvoiceSettlement[]
> => {
    const url = generateURL(`/acertos`);

    const { data } = await api.get(url);

    return data;
};

export const getOwnerInvoiceSettlementFilter = async (ids: string): Promise<
    IAInvoiceSettlement[]
> => {
    const url = generateURL(`/acertos/${ids}`);

    const { data } = await api.get(url);

    return data;
};
