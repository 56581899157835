interface TypeUser {
  label: string;
  value: string;
}

class UserAccessEnums {
  public typeUserDataSelect = [
    { label: 'Imobiliária', value: 'IMOBILIARIA' },
    { label: 'Cliente', value: 'CLIENTE' }
  ];

  public typeUserData = [
    ...this.typeUserDataSelect,
    { label: 'Proprietário', value: 'PROPRIETARIO' },
    { label: 'Corretor', value: 'CORRETOR' },
    { label: 'Operador', value: 'OPERADOR' },
    { label: 'Prestador', value: 'PRESTADOR_SERVICO' },
    { label: 'Administrador', value: 'ADMINISTRADOR' },
  ];

  public getTypeUserItem(param?: string): TypeUser {
    const filtered = this.typeUserData.filter(item => item.value === param)[0];

    return !filtered ? this.typeUserData[0] : filtered;
  }
}

export default new UserAccessEnums();
