import { Breadcrumb } from 'components/atoms'

import { BreadcrumbsWrap } from './styles'

import type { BreadcrumbsInterface } from './interface'

const Breadcrumbs = ({ data }: BreadcrumbsInterface) => (
  <BreadcrumbsWrap className="breadcrumbs-molecule">
    {Array.isArray(data) &&
      data.map((item, index) => (
        <Breadcrumb {...item} key={item.href} first={index === 0} />
      ))}
  </BreadcrumbsWrap>
)
export default Breadcrumbs
