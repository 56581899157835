import { ROUTES_ACCOUNT, ROUTES_REAL_STATE } from '@constants';

import { AccountWrap } from './styles';
import Layout from '../Layout';

const Account = () => (
    <Layout
        titlePage="Painel Imobiliário"
        breadcrumbs={[
            {
                label: 'Home',
                href: ROUTES_ACCOUNT.SELECTED.fullPath,
            },
            {
                label: 'Painel Imobiliário',
                href: ROUTES_REAL_STATE.ACCOUNT.fullPath,
            },
        ]}
        title="Dashboard"
    >
        <AccountWrap className="real-state-account-template" />
    </Layout>
);

export default Account;
