import styled from 'styled-components';

interface TextIconWrapInterface {
    icon?:
        | 'facebook'
        | 'instagram'
        | 'youtube'
        | 'email'
        | 'whatsapp'
        | 'map'
        | 'phone';
}

export const TextIconWrap = styled.a<TextIconWrapInterface>`
    display: flex;
    padding-left: 1.88rem;
    position: relative;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    span {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.textSenary};
    }

    svg {
        position: absolute;
        left: 0;
        top: 0.062rem;
        color: ${(props) =>
            props.icon === 'whatsapp'
                ? props.theme.colors.whatsapp
                : props.theme.colors.textTertiary};
    }
`;
