import { ReactNode, useState, useEffect } from 'react';
import {
    AiOutlineSave,
    AiOutlineCheck,
    AiOutlinePlusCircle,
} from 'react-icons/ai';
import {
    BiBlock,
    BiRefresh,
} from 'react-icons/bi';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { QUERIES_REAL_STATE, ROUTES_ACCOUNT, ROUTES_REAL_STATE } from '@constants';
import { Button, Loading, Row, Shadow, Text } from 'components/atoms';
import { OptionBlock, PropertyHorizontal, Modal, InputNumber } from 'components/molecules';
import { useRealState } from 'contexts/RealStateContext';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    getRealStateRentContractShow,
    updateRealStateRentContract,
    changeStatusRealStateRentContract,
    updatePersonDataIntegrationRealStateRentContract
} from 'services';

import { PropertyHorizontalWrap, RentContractFormWrap } from './styles';

import {
    RentContractFiles,
    RentContractGeneralInfo,
    RentContractPeople,
    RentContractSummary,
    RentContractValues,
} from '../components';
import Layout from '../../Layout';

import type { IARentContract, IARentContractValue } from '@types';

const RentContractForm = () => {
    const params = useParams();
    const queryClient = useQueryClient();
    const { getLinkWithSlug } = useRealState();

    const titleMessages = 'Contratos Aluguel!';

    const { id } = params;

    const [step, setStep] = useState('SUMMARY');
    const [optionsBlock] = useState([
        { label: 'Resumo', value: 'SUMMARY' },
        { label: 'Inf. Gerais', value: 'GENERAL_INFO' },
        { label: 'Valores', value: 'VALUES' },
        { label: 'Arquivos', value: 'FILES' },
        { label: 'Locatário', value: 'PEOPLE' },
    ]);

    const [generalInfo, setGeneralInfo] = useState<Partial<IARentContract> | undefined>(undefined);
    const [valuesInfo, setValuesInfo] =
        useState<Partial<IARentContractValue>>();

    const [isOpenModalFormValues, setIsOpenModalFormValues] = useState(false);
    const [isOpenModalRevoke, setIsOpenModalRevoke] = useState(false);

    const [contractualPenalty, setContractualPenalty] = useState(0);

    const onHandleOpenRevoke = () => {
        setIsOpenModalRevoke(!isOpenModalRevoke);
    };

    const convertDateToISO = (dateString: string | undefined): string | undefined => {
        if (!dateString || typeof dateString !== 'string') return undefined;

        const [day, month, year] = dateString.split('/');
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return date.toISOString();
    };

    const { data: rentContractData, isLoading, refetch: refetchGet, isFetching: isFetchingGet } = useQuery(
        [QUERIES_REAL_STATE.RENT_CONTRACT.show, id],
        () => getRealStateRentContractShow(String(id)),
        {
            enabled: !!params?.id,
            refetchOnMount: true
        }
    );

    useEffect(() => {
        setGeneralInfo(rentContractData);
    }, [rentContractData]);

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IARentContract>) => updateRealStateRentContract(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT.update,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.RENT_CONTRACT.list
                );
                queryClient.refetchQueries([
                    QUERIES_REAL_STATE.RENT_CONTRACT.show,
                    id,
                ]);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const { mutate: onUpdateIntegrationRegister, isLoading: isLoadingOnUpdateIntegration } = useMutation(
        (personId: string | number) => updatePersonDataIntegrationRealStateRentContract(String(id), personId),
        {
            mutationKey: [QUERIES_REAL_STATE.RENT_CONTRACT.show, id],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(
                    [QUERIES_REAL_STATE.RENT_CONTRACT.show, id]
                );

            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );


    const onSubmit = async () => {
        const data = {
            ...generalInfo,
            dataInicial: convertDateToISO(generalInfo?.dataInicial),
            dataFinal: convertDateToISO(generalInfo?.dataFinal),
            formasPagamentosIds: 1,
        };

        const shapeSchema = {
            impostoDeRenda: Yup.string()
                .nullable()
                .required('A seleção de imposto de renda é obrigatória.'),
            repasseTipo: Yup.string()
                .nullable()
                .required('O tipo de repasse é obrigatório.'),
            garantia: Yup.string()
                .nullable()
                .required('A garantia é obrigatória.'),
            taxaAdministracao: Yup.string()
                .nullable()
                .required('O valor da taxa de administração é obrigatório.'),
            tipoTaxaAdministracao: Yup.string()
                .nullable()
                .required('O tipo de taxa de administração é obrigatório.'),
            tipoMultaRecisoria: Yup.string()
                .nullable()
                .required('O tipo de multa é obrigatório.'),
            multaMesesCarencia: Yup.string()
                .nullable()
                .required('Os meses de carência são obrigatórios.'),
            mesesPrazo: Yup.string()
                .nullable()
                .required('Os meses de prazo são obrigatórios.'),
            diaVencimento: Yup.string()
                .nullable()
                .required('O dia do vencimento é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            mutateUpdate(data);
        }
    };

    const { mutate: mutateChangeStatus, isLoading: isLoadingChangeStatus } = useMutation(
        (status: "ATIVO" | "FINALIZADO") =>
            changeStatusRealStateRentContract(String(id), { status }, { multa: contractualPenalty }),
        {
            mutationKey: QUERIES_REAL_STATE.RENT_CONTRACT.changeStatus,
            onSuccess: (response) => {
                onSuccessMessage(
                    titleMessages,
                    response.message
                );

                queryClient.refetchQueries(
                    QUERIES_REAL_STATE.RENT_CONTRACT.list
                );
                queryClient.refetchQueries([
                    QUERIES_REAL_STATE.RENT_CONTRACT.show,
                    String(id),
                ]);

                setIsOpenModalRevoke(false);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const newRentContractValue = () => {
        setIsOpenModalFormValues(true);
        setValuesInfo({
            id: undefined,
            dataInicial: '',
            dataFinal: '',
            valor: 0,
            porcentagemReajuste: 0,
            contratosAlugueisIds: undefined,
        });
    };

    const tabsComponent: Record<string, ReactNode> = {
        SUMMARY: (<RentContractSummary
            tags={rentContractData?.tags}
        />
        ),
        GENERAL_INFO: (
            <RentContractGeneralInfo
                values={generalInfo}
                onChange={setGeneralInfo}
            />
        ),
        VALUES: (
            <RentContractValues
                rentContractId={id}
                values={valuesInfo}
                onChange={setValuesInfo}
                isOpenModalForm={isOpenModalFormValues}
                onOpenModalForm={setIsOpenModalFormValues}
            />
        ),
        FILES: (
            <RentContractFiles
                data={rentContractData?.arquivos}
                isLoading={isLoading}
            />
        ),
        PEOPLE: (
            <RentContractPeople
                data={rentContractData?.pessoas || []}
                onUpdateIntegration={onUpdateIntegrationRegister}
            />
        )
    };

    const buttonsActions: Record<string, ReactNode> = {
        SUMMARY: (
            <Row>
                {rentContractData?.status === 'ATIVO' && (
                    <Button
                        color="danger"
                        onClick={onHandleOpenRevoke}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <BiBlock />
                        Rescindir
                    </Button>
                )}

                {rentContractData?.status !== 'ATIVO' && (
                    <Button
                        color="septernary"
                        onClick={() => mutateChangeStatus("ATIVO")}
                        loading={isLoadingChangeStatus}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <AiOutlineCheck />
                        Ativar
                    </Button>
                )}

                <Button
                    color="septernary"
                    onClick={() => refetchGet()}
                    loading={isLoading || isFetchingGet}
                    style={{ margin: '0 0 0 1rem' }}
                >
                    <BiRefresh />
                    Atualizar
                </Button>
            </Row>
        ),
        GENERAL_INFO: (
            <Row>
                {rentContractData?.status === 'ATIVO' && (
                    <Button
                        color="danger"
                        onClick={onHandleOpenRevoke}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <BiBlock />
                        Rescindir
                    </Button>
                )}

                {rentContractData?.status !== 'ATIVO' && (
                    <Button
                        color="septernary"
                        onClick={() => mutateChangeStatus("ATIVO")}
                        loading={isLoadingChangeStatus}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <AiOutlineCheck />
                        Ativar
                    </Button>
                )}
                <Button color="nonary" onClick={() => onSubmit()} loading={isLoadingUpdate}>
                    <AiOutlineSave />
                    Salvar
                </Button>
            </Row>
        ),
        VALUES: (
            <Row>
                {rentContractData?.status === 'ATIVO' && (
                    <Button
                        color="danger"
                        onClick={onHandleOpenRevoke}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <BiBlock />
                        Rescindir
                    </Button>
                )}

                {rentContractData?.status !== 'ATIVO' && (
                    <Button
                        color="septernary"
                        onClick={() => mutateChangeStatus("ATIVO")}
                        loading={isLoadingChangeStatus}
                        style={{ margin: '0 0 0 1rem' }}
                    >
                        <AiOutlineCheck />
                        Ativar
                    </Button>
                )}

                <Button color="nonary" onClick={() => newRentContractValue()}>
                    <AiOutlinePlusCircle />
                    Novo
                </Button>
            </Row>
        ),
        FILES: <Row>
            {rentContractData?.status === 'ATIVO' && (
                <Button
                    color="danger"
                    onClick={onHandleOpenRevoke}
                    style={{ margin: '0 0 0 1rem' }}
                >
                    <BiBlock />
                    Rescindir
                </Button>
            )}

            {rentContractData?.status !== 'ATIVO' && (
                <Button
                    color="septernary"
                    onClick={() => mutateChangeStatus("ATIVO")}
                    loading={isLoadingChangeStatus}
                    style={{ margin: '0 0 0 1rem' }}
                >
                    <AiOutlineCheck />
                    Ativar
                </Button>
            )}
        </Row>,
        PEOPLE: <Row>
            {rentContractData?.status === 'ATIVO' && (
                <Button
                    color="danger"
                    onClick={onHandleOpenRevoke}
                    style={{ margin: '0 0 0 1rem' }}
                >
                    <BiBlock />
                    Rescindir
                </Button>
            )}

            {rentContractData?.status !== 'ATIVO' && (
                <Button
                    color="septernary"
                    onClick={() => mutateChangeStatus("ATIVO")}
                    loading={isLoadingChangeStatus}
                    style={{ margin: '0 0 0 1rem' }}
                >
                    <AiOutlineCheck />
                    Ativar
                </Button>
            )}
        </Row>,
    };

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: 'Contratos de Aluguel',
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.RENT_CONTRACT.fullPath
                    ),
                },
                {
                    label: 'Formulário Contratos Aluguel',
                    href: getLinkWithSlug(
                        `${ROUTES_REAL_STATE.RENT_CONTRACT.FORM.fullPath}/${id}`
                    ),
                }
            ]}
            title="Formulário Contratos Aluguel"
            buttonTitle={buttonsActions[step]}
        >
            <RentContractFormWrap className="realstate-panel-rent-contract-form">
                <Shadow>
                    <Loading active={isLoading || isLoadingOnUpdateIntegration} />

                    {
                        rentContractData?.imoveisPersonalizacoes?.map(({ ligaImoveisPersonalizacoes, imoveisPersonalizacoesIds }) => (
                            <PropertyHorizontalWrap
                                key={ligaImoveisPersonalizacoes.id}
                            >
                                <PropertyHorizontal
                                    addressCity={
                                        ligaImoveisPersonalizacoes
                                            .imoveis.bairros?.cidade
                                            ?.nome
                                    }
                                    addressNeightborhood={
                                        ligaImoveisPersonalizacoes
                                            .imoveis.bairros?.nome
                                    }
                                    addressState={
                                        ligaImoveisPersonalizacoes
                                            .imoveis.bairros?.cidade?.uf
                                    }
                                    attributes={ligaImoveisPersonalizacoes.imoveis.atributos
                                        .filter(
                                            (item) =>
                                                item?.ligaAtributos
                                                    ?.destaque
                                        )
                                        .slice(0, 3)
                                        .map((atributo) => ({
                                            label: atributo
                                                ?.ligaAtributos
                                                ?.descricao,
                                            value: atributo?.valor,
                                            type:
                                                atributo?.ligaAtributos
                                                    ?.icone ||
                                                'DEFAULT',
                                        }))}
                                    typeValueProperty="VALOR ALUGUEL"
                                    valueDetails={ligaImoveisPersonalizacoes.imoveis.detalhesDeValores?.map(
                                        (detalheValores) => ({
                                            label:
                                                detalheValores
                                                    ?.ligaDetalhes
                                                    ?.nome || '',
                                            value:
                                                detalheValores?.valor ||
                                                '',
                                        })
                                    )}
                                    valueProperty={
                                        ligaImoveisPersonalizacoes
                                            .imoveis.valorAluguel
                                    }
                                    iconSize="normal"
                                    imageCode={
                                        ligaImoveisPersonalizacoes.id
                                    }
                                    imageDescription={
                                        ligaImoveisPersonalizacoes
                                            .imoveis.tipos.descricao
                                    }
                                    imageUrl={
                                        ligaImoveisPersonalizacoes?.imoveis?.imagens?.find(
                                            (item) => item?.destaque
                                        )?.url ||
                                        ligaImoveisPersonalizacoes
                                            ?.imoveis?.imagens?.[0]?.url
                                    }
                                    buttonLabel="Ver Registro do Imóvel"
                                    buttonLink={`${ROUTES_REAL_STATE.PROPERTY.FORM.fullPath}/${imoveisPersonalizacoesIds}`}
                                />
                            </PropertyHorizontalWrap>

                        ))
                    }



                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={optionsBlock}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    {tabsComponent[step]}
                </Shadow>
            </RentContractFormWrap>


            <Modal
                open={isOpenModalRevoke}
                onCloseModal={onHandleOpenRevoke}
                title="Rescindir Contrato de Aluguel"
                footer={
                    <Button color="danger" onClick={() => mutateChangeStatus("FINALIZADO")} loading={isLoadingChangeStatus}>
                        Rescindir
                    </Button>
                }
            >
                <Text style={{ padding: '1.875rem' }}>
                    Informe abaixo o valor da multa contratual. O sistema irá gerar uma última fatura com os dias utilizados no mês e o valor da multa.
                    <br /> <strong>(Certifique-se de informar a data final do contrato)</strong>
                </Text>

                <InputNumber
                    required
                    label="Valor da Multa"
                    placeholder="Informe o valor da multa"
                    name='contractualPenalty'
                    value={contractualPenalty}
                    onChange={setContractualPenalty}
                    model="sinary"
                    prefix='R$ '
                />


            </Modal>
        </Layout>
    );
};

export default RentContractForm;
