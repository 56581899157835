
import ReactLoading from 'react-loading'

import { ButtonFormWrap } from './styles'

import type { ButtonFormInterface } from './interface'

const ButtonForm = ({
  children,
  type = 'submit',
  icon = null,
  color = 'primary',
  size = 'medium',
  active = false,
  id,
  onClick = () => null,
  disabled = false,
  loading = false,
  className,
}: ButtonFormInterface) => (
  <ButtonFormWrap
    color={color}
    icon={icon !== null}
    id={id && id}
    className={`${active ? 'active' : ''} ${className || ''} button-form-atom`}
    onClick={disabled || loading ? () => null : onClick}
    size={size}
    disabled={disabled}
    type={type}
  >
    {!loading && <span>{children}</span>}

    {loading && (
      <ReactLoading
        type="spinningBubbles"
        color="#FFFFFF"
        height={40}
        width={40}
        className="loader"
      />
    )}
    <div className="icon">{icon}</div>
  </ButtonFormWrap>
)

export default ButtonForm
