import { generateURL } from './prefix';
import api from '../api';

import type { IAPersonTag } from '@types';

export const getAdministratorPersonTagIndex = async () => {
    const url = generateURL(`/pessoas/tags`);

    const { data } = await api.get(url);

    return data;
};

export const getAdministratorPersonTagShow = async (id: number | string) => {
    const url = generateURL(`/pessoas/tags/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const createAdministratorPersonTag = async (
    payload: Partial<IAPersonTag>
) => {
    const url = generateURL(`/pessoas/tags`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateAdministratorPersonTag = async (
    id: number | string,
    payload: Partial<IAPersonTag>
) => {
    const url = generateURL(`/pessoas/tags/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeAdministratorPersonTag = async (id: number | string) => {
    const url = generateURL(`/pessoas/tags/${id}`);


    const { data } = await api.delete(url);

    return data;
};
