import { useEffect, useMemo } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useAuth } from 'contexts/AuthContext';
import { useRealState } from 'contexts/RealStateContext';

import { AccountType, Container, H3, Row } from 'components/atoms';
import { HeaderPages } from 'components/organisms';

import { AccountAccessSelectWrap } from './styles';
import Layout from '../Layout';

const AccountAccessSelect = () => {
    const [yourRef] = useAutoAnimate<HTMLDivElement>();

    const { getCredentials, loading, onNavigateAccountAccess, user } =
        useAuth();
    const { getLinkWithSlug } = useRealState();

    useEffect(() => {
        getCredentials();
    }, []);

    const accountsComponent = useMemo(() => {
        if (loading) {
            return [0, 1, 2].map((item) => <AccountType key={item} loading />);
        }

        return (
            user !== null &&
            user?.usuariosAcessos?.map((usuarioAcesso) => (
                <AccountType
                    key={usuarioAcesso.id}
                    tipo={usuarioAcesso?.tipo || 'CLIENTE'}
                    imobiliaria={usuarioAcesso?.imobiliarias?.nome}
                    onClick={() =>
                        onNavigateAccountAccess(
                            usuarioAcesso?.tipo || 'CLIENTE',
                            usuarioAcesso.id
                        )
                    }
                />
            ))
        );
    }, [user, loading]);

    return (
        <Layout>
            <AccountAccessSelectWrap className="account-access-template">
                <HeaderPages
                    label="Seleção de Conta"
                    breadcrumbs={[
                        {
                            label: 'Home',
                            href: getLinkWithSlug(`/account/selected`),
                        },
                    ]}
                />

                <Container id="account-access-content">
                    <H3>Selecione uma das contas abaixo:</H3>
                    <Row id="row-atom-account-type">
                        <div ref={yourRef}>{accountsComponent}</div>
                    </Row>
                </Container>
            </AccountAccessSelectWrap>
        </Layout>
    );
};

export default AccountAccessSelect;
