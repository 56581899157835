import styled, { css } from 'styled-components';

interface ButtonWrapInterface {
    color:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quaternary'
        | 'quinary'
        | 'senary'
        | 'septernary'
        | 'octernary'
        | 'nonary'
        | 'denary'
        | 'onedenary'
        | 'danger';
    size: 'normal' | 'mini';
}

const buttons = {
    primary: css`
        color: ${(props) => props.theme.colors.textSecondary};
        border: 0.062rem solid ${(props) => props.theme.colors.borderSecondary};
        background-color: ${(props) => props.theme.colors.senary};

        svg {
            fill: ${(props) => props.theme.colors.textSecondary};
        }

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.quinary};
            background-color: ${(props) => props.theme.colors.quinary};

            svg {
                fill: ${(props) => props.theme.colors.textSecondary};
            }
        }
    `,
    secondary: css`
        color: ${(props) => props.theme.colors.textSecondary};
        border: 0.062rem solid ${(props) => props.theme.colors.quinary};
        background-color: ${(props) => props.theme.colors.quinary};

        svg {
            fill: ${(props) => props.theme.colors.textSecondary};
        }

        &:hover {
            border: 0.062rem solid
                ${(props) => props.theme.colors.borderSecondary};
            background-color: ${(props) => props.theme.colors.senary};
        }
    `,
    tertiary: css`
        color: ${(props) => props.theme.colors.textSenary};
        border: 0.062rem solid ${(props) => props.theme.colors.senary};
        background-color: transparent;

        svg {
            fill: ${(props) => props.theme.colors.textSenary};
        }

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.senary};
            background-color: ${(props) => props.theme.colors.senary};
            color: ${(props) => props.theme.colors.primary};

            svg {
                fill: ${(props) => props.theme.colors.primary};
            }
        }
    `,

    quaternary: css`
        color: ${(props) => props.theme.colors.textQuinary};
        border: 0.062rem solid ${(props) => props.theme.colors.primary};
        background-color: transparent;

        svg {
            fill: ${(props) => props.theme.colors.textQuinary};
        }

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.primary};
            background-color: ${(props) => props.theme.colors.primary};
            color: ${(props) => props.theme.colors.textSenary};

            svg {
                fill: ${(props) => props.theme.colors.textSenary};
            }
        }
    `,

    quinary: css`
        color: ${(props) => props.theme.colors.textSenary};
        border: 0.062rem solid ${(props) => props.theme.colors.primary};
        background-color: ${(props) => props.theme.colors.primary};

        svg {
            fill: ${(props) => props.theme.colors.textSenary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.textQuinary};
            border: 0.062rem solid ${(props) => props.theme.colors.primary};
            background-color: transparent;

            svg {
                fill: ${(props) => props.theme.colors.textQuinary};
            }
        }
    `,

    senary: css`
        color: ${(props) => props.theme.colors.textSenary};
        border: 0.062rem solid ${(props) => props.theme.colors.octernary};
        background-color: ${(props) => props.theme.colors.octernary};

        svg {
            fill: ${(props) => props.theme.colors.textSenary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.octernary};
            border: 0.062rem solid ${(props) => props.theme.colors.octernary};
            background-color: transparent;

            svg {
                fill: ${(props) => props.theme.colors.octernary};
            }
        }
    `,

    septernary: css`
        color: ${(props) => props.theme.colors.secondary};
        border: 0.062rem solid ${(props) => props.theme.colors.tertiary};
        background-color: ${(props) => props.theme.colors.tertiary};

        svg {
            fill: ${(props) => props.theme.colors.secondary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.tertiary};
            border: 0.062rem solid ${(props) => props.theme.colors.tertiary};
            background-color: ${(props) => props.theme.colors.secondary};
            border-color: ${(props) => props.theme.colors.secondary};

            svg {
                fill: ${(props) => props.theme.colors.tertiary};
            }
        }
    `,

    octernary: css`
        color: ${(props) => props.theme.colors.tertiary};
        border: 0.062rem solid ${(props) => props.theme.colors.secondary};
        background-color: ${(props) => props.theme.colors.secondary};

        svg {
            fill: ${(props) => props.theme.colors.tertiary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.secondary};
            border: 0.062rem solid ${(props) => props.theme.colors.tertiary};
            background-color: ${(props) => props.theme.colors.tertiary};

            svg {
                fill: ${(props) => props.theme.colors.secondary};
            }
        }
    `,

    nonary: css`
        color: ${(props) => props.theme.colors.primaryLight};
        border: 0.062rem solid ${(props) => props.theme.colors.primaryLight};
        background-color: transparent;

        svg {
            fill: ${(props) => props.theme.colors.primaryLight};
        }

        &:hover {
            background-color: ${(props) => props.theme.colors.primaryLight};
            color: ${(props) => props.theme.colors.textSenary};

            svg {
                fill: ${(props) => props.theme.colors.textSenary};
            }
        }
    `,
    denary: css`
        color: ${(props) => props.theme.colors.textSenary};
        border: 0.062rem solid
            ${(props) => props.theme.colors.secondaryExtraBold};
        background-color: ${(props) => props.theme.colors.secondaryExtraBold};

        svg {
            fill: ${(props) => props.theme.colors.textSenary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.secondaryExtraBold};
            border: 0.062rem solid ${(props) => props.theme.colors.tertiaryBold};
            background-color: ${(props) => props.theme.colors.tertiaryBold};

            svg {
                fill: ${(props) => props.theme.colors.secondaryExtraBold};
            }
        }
    `,

    onedenary: css`
        color: ${(props) => props.theme.colors.textSenary};
        border: 0.062rem solid ${(props) => props.theme.colors.tertiaryBold};
        background-color: ${(props) => props.theme.colors.tertiaryBold};

        svg {
            fill: ${(props) => props.theme.colors.textSenary};
        }

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.primary};
            background-color: ${(props) => props.theme.colors.primary};

            svg {
                fill: ${(props) => props.theme.colors.textSenary};
            }
        }
    `,

    danger: css`
        color: ${(props) => props.theme.colors.danger};
        border: 0.062rem solid ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.senary};

        svg {
            fill: ${(props) => props.theme.colors.danger};
        }

        &:hover {
            border: 0.062rem solid ${(props) => props.theme.colors.danger};
            background-color: ${(props) => props.theme.colors.danger};
            color: ${(props) => props.theme.colors.textSenary};

            svg {
                fill: ${(props) => props.theme.colors.textSenary};
            }
        }
    `,
};

const sizes = {
    normal: css`
        height: 3.13rem;
        width: 10rem;

        font-size: 0.81rem;
    `,
    mini: css`
        height: 1.75rem;
        padding: 0 0.813rem;

        font-size: 0.688rem;
    `,
};

export const ButtonWrap = styled.div<ButtonWrapInterface>`
    button,
    a {
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 900;
        text-transform: uppercase;
        text-decoration: none;

        cursor: pointer;

        svg {
            margin-right: 1rem;
        }

        ${(props) => buttons[props.color]};
        ${(props) => sizes[props.size]};

        .loader {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 0;
            }
        }
    }
`;
