const AUTH_BASE = `/sign/`;
const AUTH_SIGN_IN = `in`;
const AUTH_SIGN_UP = `up`;
const AUTH_SIGN_UP_PLANS = `plans`;
const AUTH_SIGN_OUT = `out`;
const AUTH_PASSWORD_RECOVERY = `password-recovery`;
const AUTH_RECOVERY_CODE = `recovery-code`;

export const ROUTES_AUTH = {
    path: AUTH_BASE,
    fullPath: AUTH_BASE,
    SIGN_IN: {
        path: AUTH_SIGN_IN,
        fullPath: `${AUTH_BASE}${AUTH_SIGN_IN}`,
    },
    SIGN_UP: {
        path: AUTH_SIGN_UP,
        fullPath: `${AUTH_BASE}${AUTH_SIGN_UP}`,
        PLANS: {
            path: `${AUTH_SIGN_UP}/${AUTH_SIGN_UP_PLANS}`,
            fullPath: `${AUTH_BASE}${AUTH_SIGN_UP}/${AUTH_SIGN_UP_PLANS}`,
        },
    },
    SIGN_OUT: {
        path: AUTH_SIGN_OUT,
        fullPath: `${AUTH_BASE}${AUTH_SIGN_OUT}`,
    },
    PASSWORD_RECOVERY: {
        path: AUTH_PASSWORD_RECOVERY,
        fullPath: `${AUTH_BASE}${AUTH_PASSWORD_RECOVERY}`,
    },
    RECOVERY_CODE: {
        path: AUTH_RECOVERY_CODE,
        fullPath: `${AUTH_BASE}${AUTH_RECOVERY_CODE}`,
    },
};
