import { Helmet } from 'react-helmet';

import { AuthSignIn } from 'components/templates';

const SignInPage = () => (
    <>
        <Helmet>
            <title>Autenticar - Imobiler</title>
        </Helmet>

        <AuthSignIn />
    </>
);

export default SignInPage;
