/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns'
import { pt } from 'date-fns/locale'

import { ScheduledItemListInterface } from './interface'

import { ScheduledItemListWrap } from './styles'
import { Button } from '../../atoms'


const ScheduledItemList = ({
  date,
  hour,
  isLoadingCancel,
  isLoadingSchedule,
  onClickScheduled = () => null,
  onClickCancel = () => null
}: ScheduledItemListInterface) => (
  <ScheduledItemListWrap className="scheduled-item-list-molecule">
    <div className="column-left">
      <Button color="octernary" size="mini" onClick={onClickScheduled}>
        DATA
      </Button>
      <span className="date number">
        {format(parseISO(date), 'd, LLLL Y', {
          locale: pt
        })}
      </span>
      <Button color="octernary" size="mini" onClick={onClickScheduled}>
        HORÁRIO
      </Button>
      <span className="hour number">🕒 {hour}</span>
    </div>

    <div className="column-right">
      <Button color="septernary" size="mini" onClick={onClickScheduled} loading={isLoadingSchedule}>
        REAGENDAR
      </Button>

      <Button color="primary" size="mini" onClick={onClickCancel} loading={isLoadingCancel}>
        CANCELAR
      </Button>
    </div>
  </ScheduledItemListWrap>
)

export default ScheduledItemList
