import { AccountWrap } from './styles';
import Layout from '../Layout';

const Account = () => (
    <Layout
        titlePage="Painel Administrativo"
        breadcrumbs={[
            {
                label: 'Home',
                href: '/account/selected',
            },
            {
                label: 'Painel Administrativo',
                href: '/administrator',
            },
        ]}
        title="Dashboard"
    >
        <AccountWrap className="administrator-account-template" />
    </Layout>
);

export default Account;
