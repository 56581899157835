import { Helmet } from 'react-helmet';

import { RealStatePropertyList } from 'components/templates';

const RealStatePropertyListPage = () => (
    <>
        <Helmet>
            <title>Imóveis - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStatePropertyList />
    </>
);

export default RealStatePropertyListPage;
