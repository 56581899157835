import styled from 'styled-components';
import { CircularProgressbar as RCP } from 'react-circular-progressbar';

export const ImageListSquareWrap = styled.section`
    margin-top: 1.25rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 19rem;
    gap: 1.563rem;
    max-width: 100%;
    font-family: 'Work Sans';
    list-style: none;
`;

interface PreviewInterface {
    src?: string;
}

export const File = styled.li<PreviewInterface>`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.senary};
    border: 0.168rem solid ${(props) => props.theme.colors.borderPrimary};
    box-shadow: 0 -0.43rem 125rem 0 rgba(126, 157, 184, 0.1);

    .image {
        width: 100%;
        height: 12.5rem;
        background-image: url(${(props) => props.src});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        .absolute-icons {
            .right,
            .left {
                top: 0.625rem;
                position: absolute;
            }

            .right {
                right: 0.625rem;
            }
            .left {
                left: 0.625rem;
            }
        }

        &:hover a {
            display: flex;
        }

        a {
            display: none;
            height: 100%;
            width: 100%;
            transition: background ease 2s;
            background-color: ${(props) => props.theme.colors.loading};
            align-items: center;
            justify-content: center;

            &:hover {
                display: flex;
            }
        }
    }

    .content {
        border-top: 0.168rem solid
            ${(props) => props.theme.colors.borderPrimary};
        padding: 0.625rem;

        .name {
            display: block;
            width: 100%;
            font-size: 1rem;
            font-weight: 700;
            color: ${(props) => props.theme.colors.textPrimary};
        }

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.5rem;
        }

        .size {
            font-size: 1rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primary};
        }

        button {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 700;
            color: ${(props) => props.theme.colors.secondary};

            &.btn-delete {
                margin-left: auto;
                color: ${(props) => props.theme.colors.danger};
            }
        }
    }
`;

export const CircularProgressbar = styled(RCP).attrs((props) => ({
    styles: {
        root: { width: 150 },
        path: { stroke: props.theme.colors.secondaryExtraBold },
    },
    strokeWidth: 5,
}))``;

export const FileInfo = styled.div`
    display: flex;
    align-items: center;

    div {
        display: flex;
        flex-direction: column;
        span {
            font-size: 0.75rem;
            color: #999;
            margin-top: 0.313rem;
            max-width: 100%;

            button {
                border: 0;
                background: transparent;
                color: #e74c3c;
                margin-left: 0.313rem;
                cursor: pointer;
            }
        }
    }
`;

interface PreviewInterface {
    src?: string;
}

export const Preview = styled.div<PreviewInterface>`
    width: 3.125rem;
    height: 3.125rem;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 0.625rem;
`;