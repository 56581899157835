import { HelperButtonWrap } from './styles';

import type { HelperButtonInterface } from './interface';

const HelperButton = ({
    isLoading,
    text,
    onClick = () => null,
    color = 'active',
}: HelperButtonInterface) => (
    <HelperButtonWrap className="helper-button-atom" color={color}>
        <button type="button" color={color} onClick={() => onClick()}>
            {isLoading ? 'Carregando...' : text}
        </button>
    </HelperButtonWrap>
);
export default HelperButton;
