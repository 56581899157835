import { ROUTES_ACCOUNT, ROUTES_OWNER } from '@constants';

import { AccountWrap } from './styles';
import Layout from '../Layout';

const Account = () => (
    <Layout
        titlePage="Painel do Proprietário"
        breadcrumbs={[
            {
                label: 'Home',
                href: ROUTES_ACCOUNT.SELECTED.fullPath,
            },
            {
                label: 'Painel do Proprietário',
                href: ROUTES_OWNER.ACCOUNT.fullPath,
            },
        ]}
        title="Dashboard"
    >
        <AccountWrap className="owner-account-template" />
    </Layout>
);

export default Account;
