import { generateURL } from './prefix'
import api from '../api';

import type { IARealStateFinance } from '@types';

export interface FilterProps {
    descricao?: string;
    numeroDocumento?: string;
    pessoasIds?: string;
    formaPagamentoIds?: string;
    status?: string;
    tipoDeConta?: string;
    dataVencimento?: string;
    dataPagamento?: string;
    dataDocumento?: string;
}

export const getRealStateFinanceIndex = async (filters: Record<string, string>): Promise<
    IARealStateFinance[]
> => {
    let url = generateURL(`/financas`);

    const filtersFill = Object.keys(filters).filter((key: string) => !!filters?.[key])

    if (filtersFill.length > 0) {
        url += `?${filtersFill.map((key: string) => `${key}=${filters[key]}`).join('&')}`
    }

    const { data } = await api.get(url);

    return data;
};

export const getRealStateFinanceShow = async (
    id: number | string
): Promise<IARealStateFinance> => {
    const url = generateURL(`/financas/${id}`);


    const { data } = await api.get(url);

    return data;
};

export const createRealStateFinance = async (
    payload: Partial<IARealStateFinance>
) => {
    const url = generateURL(`/financas`);


    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateFinance = async (
    id: number | string,
    payload: Partial<IARealStateFinance>
): Promise<IARealStateFinance> => {
    const url = generateURL(`/financas/${id}`);


    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateFinance = async (id: number) => {
    const url = generateURL(`/financas/${id}`);


    const { data } = await api.delete(url);

    return data;
};
