import { Helmet } from 'react-helmet';

import { RealStateOwnerSimplifiedForm } from 'components/templates';

const RealStateOwnerSimplifiedFormPage = () => (
    <>
        <Helmet>
            <title>Proprietários - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateOwnerSimplifiedForm />
    </>
);

export default RealStateOwnerSimplifiedFormPage;
