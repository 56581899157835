import styled from 'styled-components'

export const NavBarWrap = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  list-style: none;
  padding: 0;
  z-index: 100;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`
