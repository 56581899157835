import { Helmet } from 'react-helmet';

import { OwnerAccount } from 'components/templates';

const OwnerAccountPage = () => (
    <>
        <Helmet>
            <title>Minha Conta - Painel Proprietário - Imobiler</title>
        </Helmet>

        <OwnerAccount />
    </>
);

export default OwnerAccountPage;
