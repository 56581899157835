import styled from 'styled-components';

export const LayoutWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .h1-atom {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 1.75rem;
    }

    main {
        width: 77.5rem;
        margin: 0 auto;

        .shadow-atom {
            background-color: ${(props) => props.theme.colors.senary};
        }

        .button-form-atom#on-sign {
            margin-top: 1.25rem;
        }

        .text-atom {
            text-transform: initial;
            margin-top: 2.375rem;
            text-align: center;
        }
    }
`;
