/* eslint-disable  @typescript-eslint/no-unused-vars */
import { Grid } from 'components/atoms';
import { InputNumber, InputText, InputToggle } from 'components/molecules';

import type { IAPropertyAttribute } from '@types';
import type { PropertyNewFormAttributesInterface } from './interface';

const PropertyNewFormAttributes = ({
  attributes,
  value: valueOutside,
  onChange
}: PropertyNewFormAttributesInterface) => {
  const attributesHasNumeric = attributes?.filter(({ tipo }) => tipo === 'NUMERICO');
  const attributesHasBoolean = attributes?.filter(({ tipo }) => tipo === 'BOOLEANO');
  const attributesHasDescritive = attributes?.filter(({ tipo }) => tipo === 'DESCRITIVO');

  function onGetAttributeAndValueString(id?: number) {
    const atributoDoImovel = valueOutside?.find(
      attrIM => Number(attrIM.imoveisAtributosIds) === Number(id)
    );

    return String(atributoDoImovel?.valor || '');
  }

  function onGetAttributeAndValueNumber(id?: number) {
    const atributoDoImovel = valueOutside?.find(
      attrIM => Number(attrIM.imoveisAtributosIds) === Number(id)
    );

    return Number(atributoDoImovel?.valor || 0);
  }


  function onGetAttributeAndValueBoolean(id?: number) {
    const atributoDoImovel = valueOutside?.find(
      attrIM => Number(attrIM.imoveisAtributosIds) === Number(id)
    );

    return Boolean(atributoDoImovel?.valor) || false;
  }

  function onSetAttributeAndValue(id: number, value: string | boolean | number) {
    const atributo = attributes?.find((attr) => attr.id === id);

    let edit = false;

    const newAtributosDoImovel = valueOutside?.map(attrIM => {
      if (Number(attrIM.imoveisAtributosIds) !== Number(id)) {
        return attrIM;
      }

      edit = true;

      return {
        ...attrIM,
        valor: value,
      };
    });

    if (edit) {
      onChange(newAtributosDoImovel || []);
    } else {
      onChange([
        ...valueOutside || [],
        {
          imoveisAtributosIds: id,
          valor: value,
        },
      ]);
    }
  }

  return (
    <>
      <Grid numberColumns={3}>
        {attributesHasDescritive?.map((atributo: IAPropertyAttribute) => (
          <InputText
            id={`property-attributes-${atributo?.id}`}
            key={atributo?.id}
            required={atributo.obrigatorio}
            label={atributo.descricao}
            name={atributo.descricao}
            placeholder={`Informe ${atributo.descricao}`}
            model="sinary"
            value={onGetAttributeAndValueString(atributo?.id)}
            onChange={value => onSetAttributeAndValue(atributo?.id, value)}
          />
        ))}
      </Grid>

      <Grid numberColumns={3}>
        {attributesHasNumeric?.map((atributo: IAPropertyAttribute) => (
          <InputNumber
            id={`property-attributes-${atributo?.id}`}
            key={atributo?.id}
            required={atributo.obrigatorio}
            label={atributo.descricao}
            name={atributo.descricao}
            placeholder={`Informe ${atributo.descricao}`}
            postfix={atributo?.icone === "AREA" ? " m²" : ""}
            precision={atributo?.icone === "AREA" ? 2 : 0}
            model="sinary"
            value={onGetAttributeAndValueNumber(atributo?.id)}
            onChange={value => onSetAttributeAndValue(atributo?.id, value)}
          />
        ))}
      </Grid>

      <Grid numberColumns={4}>
        {attributesHasBoolean?.map((atributo: IAPropertyAttribute) => (
          <InputToggle
            id={`property-attributes-${atributo?.id}`}
            key={atributo?.id}
            labelTrue='Sim'
            labelFalse='Não'
            model="sinary"
            required={atributo.obrigatorio}
            label={atributo.descricao}
            name={atributo.descricao}
            value={onGetAttributeAndValueBoolean(atributo?.id)}
            onChange={value => onSetAttributeAndValue(atributo?.id, value)}
          />
        ))}
      </Grid>
    </>
  );
};

export default PropertyNewFormAttributes;
