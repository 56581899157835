import styled from 'styled-components'

export const H1Wrap = styled.h1`
  font-size: 2.38rem;
  line-height: 120%;
  font-weight: 300;
  color: ${props => props.theme.colors.textSecondary};
  text-transform: uppercase;

  strong {
    font-weight: 900;
  }
`
