import { CheckBox } from 'components/atoms';

import { SummaryRentContractWrap } from './styles'

import type { SummaryRentContractInterface } from './interface'

const SummaryRentContract = ({ tags }: SummaryRentContractInterface) => (<SummaryRentContractWrap>
    <p>
        Para poder gerar faturas deste contrato de aluguel existe alguns requisitos
        que o contrato deve cumprir, abaixo segue uma lista do que é necessário:
    </p>

    {tags?.map(({ isActive, key, label }) => (
        <CheckBox
            label={label}
            key={key}
            isActive={isActive
            }
        />
    ))}



</SummaryRentContractWrap>
)

export default SummaryRentContract;