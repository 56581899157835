import styled, { css } from 'styled-components'

interface StepCircleItemWrapInterface {
  isChecked: boolean;
  isActive: boolean;
}

export const StepCircleItemWrap = styled.button<StepCircleItemWrapInterface>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 7.5rem;
  width: 100%;
  padding: 0 2.5rem;
  border-bottom: 0.063rem solid ${props => props.theme.colors.borderSecondary};

  background-color: ${props => props.isActive ? props.theme.colors.quaternary : 'transparent'};

  .circle {
    position: relative;
    height: 3.125rem;
    min-width: 3.125rem;
    border-radius: 3.125rem;
    border: 0.313rem solid ${props => props.theme.colors.secondary};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    ${props =>
    props.isChecked &&
    css`
        background-color: ${props.theme.colors.secondary};

        svg {
          display: block;
          color: ${props.theme.colors.senary};
          font-size: 1.25rem;
        }

        &:after {
          content: '';
          position: absolute;

          display: block;
          height: 2.375rem;
          width: 2.375rem;
          border-radius: 2.375rem;
          border: 0.063rem solid ${props.theme.colors.senary};
        }
      `}
  }

  .step-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.938rem;
    flex: 1;

    .label {
      display: block;
      font-size: 0.938rem;
      font-weight: 800;
      color: ${props => props.isActive ? props.theme.colors.textSenary : props.theme.colors.textSecondary};
    }

    .sub-label {
      display: block;
      margin-top: 0.313rem;
      font-size: 0.938rem;
      font-weight: 300;
      color: ${props => props.isActive ? props.theme.colors.textSenary : props.theme.colors.textSecondary};

      text-align: left;
    }
  }
`
