import { IAPerson } from '@types';
import { generateURL } from './prefix';

import api from '../api';

export const getRealStateOwnerIndex = async () => {
    const url = generateURL(`/proprietarios`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateOwnerShow = async (id: string | number) => {
    const url = generateURL(`/proprietarios/${id}`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateOwnerCheckUserExist = async (documento: string) => {
    const url = generateURL(`/proprietarios/documento/${documento}/consultar`);

    const { data } = await api.get(url);

    return data;
};

export const getRealStateOwnerInviteUserExist = async (documento: string) => {
    const url = generateURL(`/proprietarios/documento/${documento}/convidar`);

    const { data } = await api.get(url);

    return data;
};

export const createRealStateOwner = async (payload?: Partial<IAPerson>) => {
    const url = generateURL(`/proprietarios`);

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateOwner = async (id: number, payload?: Partial<IAPerson>) => {
    const url = generateURL(`/proprietarios/${id}`);

    const { data } = await api.put(url, payload);

    return data;
};

export const updateRealStateOwnerResponsible = async (id: number, payload?: Partial<IAPerson>) => {
    const url = generateURL(`/proprietarios/${id}/responsaveis`);

    const { data } = await api.put(url, payload);

    return data;
};

export const getRealStateOwnerIntegration = async (
    idOwner: string | number
) => {
    const url = generateURL(`/proprietarios/${idOwner}/integrar/zoop`);

    const { data } = await api.get(url);

    return data;
};
