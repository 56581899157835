import styled from 'styled-components';

interface SkeletonWrapInterface {
    duration: number;
    height: string;
    width: string;
}

export const SkeletonWrap = styled.div<SkeletonWrapInterface>`
    .shimmer-placeholder {
        animation-duration: ${(props) => props.duration}ms;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(
            to right,
            #eeeeee 8%,
            #dddddd 18%,
            #eeeeee 33%
        );
        background-size: 1000px 104px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        height: ${(props) => props.height};
        width: ${(props) => props.width};
    }

    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
`;
