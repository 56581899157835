import { ReactNode } from 'react'

import {
  RiRuler2Fill,
  RiHotelBedFill,
  RiMapPin2Fill,
  RiCarFill,
  RiMapPinTimeFill
} from 'react-icons/ri'
import { MdAttachMoney } from 'react-icons/md'
import { FaBath, FaCouch } from 'react-icons/fa'
import { GoPrimitiveDot } from 'react-icons/go'
import { AttributesInterface } from './interface'

import { AttributesWrap } from './styles'

const Attributes = ({
  label,
  riskLabel = false,
  value,
  type = 'DEFAULT',
  style,
  size = 'normal'
}: AttributesInterface) => {
  const Icons: Record<string, ReactNode> = {
    AREA: <RiRuler2Fill />,
    ROOM: <RiHotelBedFill />,
    ADDRESS: <RiMapPin2Fill />,
    VALUE: <MdAttachMoney />,
    BATH: <FaBath />,
    GARAGE: <RiCarFill />,
    FURNISHED: <FaCouch />,
    AVAILABILITY: <RiMapPinTimeFill />,
    DEFAULT: <GoPrimitiveDot />
  }

  return (
    <AttributesWrap
      style={style}
      type={type}
      riskLabel={riskLabel}
      className="attributes-atom"
      size={size}
      key={value + type}
    >
      <div className="icon">{Icons[type]}</div>

      <div className="content">
        <span className="label">{label}</span>

        {typeof value === 'boolean' || value === 'true' || value === 'false' ? (
          <span className="value number">
            {value ? 'Sim' : 'Não'}
          </span>
        ) : (
          <span className="value number">{value}</span>
        )}

      </div>
    </AttributesWrap>
  )
}

export default Attributes
