/* eslint-disable jsx-a11y/label-has-associated-control, no-lonely-if */
import { ChangeEvent } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';

import { sweetAlert, checkEmpty } from 'utils';

import { InputImageWrap } from './styles';

import type { InputImageInterface } from './interface';

const InputImage = ({
    id = 'avatar',
    label = 'Imagem',
    loading = false,
    value,
    suggestion,
    onChange,
    onDelete,
}: InputImageInterface) => {
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const types = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];

        const fileItem = event?.currentTarget?.files?.[0];

        if (types.indexOf(fileItem?.type || '') === -1 || !fileItem) {
            sweetAlert.fire({
                title: 'Upload',
                text: 'Imagem inválida!',
                icon: 'error',
            });
        } else {
            if ((fileItem?.size || 0) > 1024 * 1024 * 2) {
                sweetAlert.fire({
                    title: 'Upload',
                    text: 'Tamanho excede 2MB',
                    icon: 'error',
                });
            } else {
                onChange(fileItem);
            }
        }
    }

    return (
        <InputImageWrap
            className="input-image-molecule"
            htmlFor={id}
            style={
                checkEmpty(value) ? {} : { backgroundImage: `url(${value})` }
            }
        >
            {loading && <div className="spinner" />}

            <span className="description">
                Formatos aceitos: png, jpg, jpeg.
            </span>

            <input
                type="file"
                id={id}
                accept="image/*"
                onChange={handleChange}
            />

            <label htmlFor={id}>
                <MdEdit />
            </label>

            {onDelete && (
                <button type="button" onClick={() => onDelete()}>
                    <BsTrashFill />
                </button>
            )}

            <span className="label">{label}</span>

            <span className="suggestion number">{suggestion}</span>
        </InputImageWrap>
    );
};

export default InputImage;
