import { ItemList } from 'components/atoms';
import { ListWrap } from './styles';

import type { ListInterface } from './interface';

const List = ({ data, style }: ListInterface) => (
    <ListWrap style={style} className="list-molecule">
        {Array.isArray(data) &&
            data.map((item) => <ItemList key={item.href} {...item} />)}
    </ListWrap>
);

export default List;
