import { Helmet } from 'react-helmet';

import { RealStateContactList } from 'components/templates';

const RealStateContactListPage = () => (
    <>
        <Helmet>
            <title>Contatos - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateContactList />
    </>
);

export default RealStateContactListPage;
