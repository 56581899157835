import React, { useState, useEffect } from 'react';

import logoDefault from 'assets/images/logo_white.png';
import {
    Column,
    Container,
    Line,
    Row,
    SocialIcon,
    TextIcon,
    TitleList,
} from 'components/atoms';
import { List } from 'components/molecules';
import { maskCellphone } from 'utils';
import { FooterWrap } from './styles';

import type { FooterInterface } from './interface';
import type { ICItemList } from '@types';

const Footer = ({
    name,
    email,
    logo,
    instagram,
    facebook,
    whatsapp,
    phone,
    youtube,
    publicPlace,
    number,
    complement,
    neighborhood,
    city,
    state,
    haveRentProperties,
    haveSellProperties,
    getLinkWithSlug = (param) => param,
}: FooterInterface) => {
    const [items, setItems] = useState<ICItemList[]>([]);

    useEffect(() => {
        const newItems = [];

        if (haveRentProperties) {
            newItems.push({
                label: 'aluguel',
                href: getLinkWithSlug('/properties?option=aluguel', true),
            });
        }

        if (haveSellProperties) {
            newItems.push({
                label: 'Comprar',
                href: getLinkWithSlug('/properties?option=venda', true),
            });
        }

        setItems(newItems);
    }, [haveRentProperties, haveSellProperties]);

    return (
        <FooterWrap className="footer-organism">
            <section className="content">
                <Container>
                    <Row
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            margin: '0 0 1.88rem 0',
                        }}
                    >
                        <img
                            src={!logo ? logoDefault : logo}
                            alt={!name ? 'Imobiler' : name}
                            height={45}
                        />

                        <Row>
                            {facebook && (
                                <SocialIcon
                                    icon="facebook"
                                    variation="footer"
                                    href={facebook}
                                />
                            )}

                            {instagram && (
                                <SocialIcon
                                    icon="instagram"
                                    variation="footer"
                                    href={instagram}
                                />
                            )}

                            {youtube && (
                                <SocialIcon
                                    icon="youtube"
                                    variation="footer"
                                    href={youtube}
                                />
                            )}
                        </Row>
                    </Row>

                    <Row haveColumn className="row-list-footer">
                        <Column>
                            <TitleList style={{ margin: '0 0 1.25rem 0' }}>
                                Buscando Imóvel Para
                            </TitleList>

                            <Line
                                style={{ margin: '0 0 1.88rem 0' }}
                                type="primary"
                            />

                            <Row haveColumn>
                                <Column>
                                    <List
                                        data={items}
                                        style={{ width: '100%' }}
                                    />
                                </Column>
                            </Row>
                        </Column>

                        <Column>
                            <TitleList style={{ margin: '0 0 1.25rem 0' }}>
                                Navegue
                            </TitleList>

                            <Line
                                style={{ margin: '0 0 1.88rem 0' }}
                                type="primary"
                            />

                            <Row>
                                <List
                                    data={[
                                        {
                                            label: 'Blog',
                                            href: getLinkWithSlug(
                                                '/blog',
                                                true
                                            ),
                                        },
                                        {
                                            label: 'Corretores',
                                            href: getLinkWithSlug(
                                                '/brokers',
                                                true
                                            ),
                                        },
                                    ]}
                                    style={{ width: '100%' }}
                                />
                            </Row>
                        </Column>

                        <Column>
                            <TitleList style={{ margin: '0 0 1.25rem 0' }}>
                                Entre em Contato
                            </TitleList>

                            <Line
                                style={{ margin: '0 0 1.88rem 0' }}
                                type="primary"
                            />

                            {whatsapp && (
                                <TextIcon
                                    href={`https://api.whatsapp.com/send?phone=55${whatsapp}`}
                                    icon="whatsapp"
                                    style={{ margin: '0 0 1.25rem 0' }}
                                    className="number"
                                >
                                    {maskCellphone(whatsapp)}
                                </TextIcon>
                            )}
                            {phone && (
                                <TextIcon
                                    href={`tel:0${phone}`}
                                    icon="phone"
                                    style={{ margin: '0 0 1.25rem 0' }}
                                    className="number"
                                >
                                    {maskCellphone(phone)}
                                </TextIcon>
                            )}

                            {email && (
                                <TextIcon
                                    href={`mailto:${email}`}
                                    icon="email"
                                    style={{ margin: '0 0 1.25rem 0' }}
                                    className="number"
                                >
                                    {email}
                                </TextIcon>
                            )}

                            {publicPlace && number && neighborhood && (
                                <TextIcon
                                    href="#"
                                    icon="map"
                                    style={{ margin: '0 0 1.25rem 0' }}
                                    className="number"
                                >
                                    {`${publicPlace}, ${number} ${
                                        complement ? `-  ${complement}` : ''
                                    }`}
                                    <br />{' '}
                                    {`${neighborhood} - ${city}/${state} `}
                                </TextIcon>
                            )}
                        </Column>
                    </Row>
                </Container>
            </section>

            <section className="copyright">
                <span>
                    {new Date().getFullYear()} © IMOBILER. TODOS OS DIREITOS
                    RESERVADOS.
                </span>
            </section>
        </FooterWrap>
    );
};

export default Footer;
