import styled from 'styled-components';

export const TitlePageWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding-bottom: 1.563rem;
    margin-bottom: 1.563rem;
    border-bottom: 0.063rem solid
        ${(props) => props.theme.colors.borderSepternary};

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0.188rem;

            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1rem;

            margin-bottom: 1rem;
            color: ${(props) => props.theme.colors.tertiaryBold};
            text-transform: uppercase;

            svg {
                font-size: 1rem;
                color: ${(props) => props.theme.colors.quaternary};
            }
        }
    }

    .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-left: 0.938rem;
        }
    }

    h2 {
        font-size: 1.563rem;
        line-height: 120%;
        font-weight: 700;
        color: ${(props) => props.theme.colors.textPrimary};
    }
`;
