import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { InvoiceSettlementDetails } from 'components/organisms';
import { getOwnerInvoiceSettlementFilter } from 'services';
import { QUERIES_OWNER } from '@constants';

import { InvoiceSettlementPrintWrap } from './styles'

const InvoiceSettlementPrint = () => {
    const { ids } = useParams();

    const { data } = useQuery(
        [QUERIES_OWNER.INVOICE_SETTLEMENT.base, ids],
        () => getOwnerInvoiceSettlementFilter(String(ids))
    );

    return (
        <InvoiceSettlementPrintWrap>
            {data?.map((item, index) => (
                <div key={item.id}>
                    <div className='settlement-item'>
                        <InvoiceSettlementDetails data={item} />
                    </div>

                    {data?.length === index + 1 ? null : <div className="page-break" />}
                </div>
            ))}
        </InvoiceSettlementPrintWrap>
    );
};

export default InvoiceSettlementPrint;
