import styled from 'styled-components';

interface BackgroundProps {
    open: boolean;
}

export const Background = styled.div<BackgroundProps>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    display: ${(props) => (props.open ? 'flex' : 'none')};
    transition: display 1s;
    background-color: rgba(0, 0, 0, 0.5);

    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const Offset = styled.button`
    flex: 1;
    width: 100%;
    min-height: 2.5vh;
    height: 100%;
`;

const sizes: Record<string, string> = {
    mc: '18.76rem',
    sm: '25rem',
    md: '31.25rem',
    lg: '50rem',
    xl: '71.25rem',
};

interface ContentInterface {
    size: string;
}

export const Content = styled.section<ContentInterface>`
    display: block;
    width: 100%;
    max-width: ${(props) => sizes[props.size]};
    background-color: #fff;

    max-height: calc(95vh);

    @media (max-width: 768px) {
        margin: 0 auto;
        width: 90%;
    }

    @media (max-width: 350px) {
        margin: 0 auto;
        width: 97.5%;
    }
`;
