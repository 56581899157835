import api from '../api';

import type { IAPropertyExpense } from '@types';

export const getRealStatePropertyExpenseIndex = async (): Promise<
    IAPropertyExpense[]
> => {
    const url = `/imobiliarias/lancamentos`;

    const { data } = await api.get(url);

    return data;
};

export const getRealStatePropertyExpenseShow = async (
    id: number | string
): Promise<IAPropertyExpense> => {
    const url = `/imobiliarias/lancamentos/${id}`;

    const { data } = await api.get(url);

    return data;
};

export const createRealStatePropertyExpense = async (
    payload: Partial<IAPropertyExpense>
) => {
    const url = `/imobiliarias/lancamentos`;

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStatePropertyExpense = async (
    id: number | string,
    payload: Partial<IAPropertyExpense>
): Promise<IAPropertyExpense> => {
    const url = `/imobiliarias/lancamentos/${id}`;

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStatePropertyExpense = async (id: number) => {
    const url = `/imobiliarias/lancamentos/${id}`;

    const { data } = await api.delete(url);

    return data;
};
