import { Helmet } from 'react-helmet';

import { RealStateOwnerForm } from 'components/templates';

const RealStateOwnerFormPage = () => (
    <>
        <Helmet>
            <title>Proprietários - Painel Imobiliário - Imobiler</title>
        </Helmet>

        <RealStateOwnerForm />
    </>
);

export default RealStateOwnerFormPage;
