import { ICSelectOption } from '@types';

const data = [
  { label: 'Áreas', value: 'AREA' },
  { label: 'Quartos', value: 'ROOM' },
  { label: 'Endereço', value: 'ADDRESS' },
  { label: 'Valor', value: 'VALUE' },
  { label: 'Banheiro', value: 'BATH' },
  { label: 'Garagem', value: 'GARAGE' },
  { label: 'Mobiliada', value: 'FURNISHED' },
  { label: 'Disponibilidade', value: 'AVAILABILITY' }
]

export function getIconsTypesAttributesData(): ICSelectOption[] {
  return data
}

export function getIconsTypesAttributesItem(param?: string): ICSelectOption {
  const filtered = data.find(item => item.value === param)

  return !filtered ? data[0] : filtered
}
