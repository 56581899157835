/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { AiOutlineSave, AiFillCheckCircle } from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';

import { Button, Loading, Shadow } from 'components/atoms';
import { OptionBlock } from 'components/molecules';
import { UploadFiles } from 'components/organisms';

import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    getRealStateNewProperty,
    getPublicPropertyTypeIndex,
    createRealStateNewProperty,
    updateRealStateNewProperty,
} from 'services';

import theme from 'styles/theme';

import { sweetAlert } from 'utils';

import { Attributes, GeneralInfo, Maps, ValueDetails } from './components';
import { PropertyNewFormWrap } from './styles';
import Layout from '../../Layout';

import type { IAProperty } from '@types';

const RealStateNewForm = () => {
    const navigate = useNavigate();
    const { id: idParam } = useParams();

    const queryClient = useQueryClient();
    const { getLinkWithSlug } = useRealState();

    const title = 'Imóveis';

    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isConsultedAddress, setIsConsultedAddress] = useState(false);
    const [isManualNeighborhood, setIsManualNeighborhood] = useState(false);

    const [step, setStep] = useState('GENERAL_INFO');

    const [isReady, setIsReady] = useState(true);

    const [values, setValues] = useState<Partial<IAProperty>>();

    const [publicado, setPublicado] = useState([]);

    const { isLoading: isLoadingGet, refetch } = useQuery(
        [QUERIES_REAL_STATE.PROPERTY.base, idParam],
        () => getRealStateNewProperty(Number(idParam)),
        {
            enabled: !!idParam,
            onSuccess: (res) => {
                setValues({
                    ...res,
                    imoveisTiposIds: res?.tipos?.id,
                    editavel: res?.personalizacoes?.[0]?.editavel || false,
                });

                setIsOpenForm(true);
                setIsConsultedAddress(true);
                setIsReady(true);
            },
            refetchOnMount: true,
        }
    );

    const { data: dataPropertyTypes, isLoading: isLoadingTypes } = useQuery(
        QUERIES_REAL_STATE.PROPERTY_TYPE.base,
        () => getPublicPropertyTypeIndex()
    );

    const propertyAttributes = useMemo(() => {
        if (!values?.imoveisTiposIds || !Array.isArray(dataPropertyTypes)) {
            return [];
        }

        const typeSelected = dataPropertyTypes.find(
            (type) => Number(type.id) === Number(values?.imoveisTiposIds)
        );

        return typeSelected?.atributos || [];
    }, [dataPropertyTypes, values]);


    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAProperty>) => createRealStateNewProperty(payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(QUERIES_REAL_STATE.PROPERTY.list);

                navigate(getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.fullPath));
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAProperty>) =>
            updateRealStateNewProperty(String(idParam), payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.base,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(QUERIES_REAL_STATE.PROPERTY.list);

                queryClient.refetchQueries([
                    QUERIES_REAL_STATE.PROPERTY.base,
                    idParam,
                ]);
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async (publish?: boolean) => {

        const dataSend = {
            ...values,
            publicado: Boolean(publicado || publish),
        };

        const shapeSchema = {
            titulo: Yup.string().required('Título é obrigatório'),
            descricao: Yup.string().required('Descrição é obrigatória'),
            bairrosIds: Yup.string().required(
                'Campos de endereço são obrigatórios'
            ),
        };

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!values?.id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };

    function onPublish() {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Ao publicar o imóvel, a não ser que o esteja salvando como editável, apenas o proprietário poderá editar as informações base do imóvel, restando a imobiliária apenas as informações personalizadas!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.primary,
                cancelButtonColor: theme.colors.danger,
                confirmButtonText: 'Sim, publicar!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    onSubmit(true);
                }
            });
    }

    const isLoading = useMemo(
        () => isLoadingGet || isLoadingTypes,
        [isLoadingGet, isLoadingTypes]
    );

    const optionsBlock = useMemo(() => {
        if (idParam) {
            return [
                { label: 'Inf. Gerais', value: 'GENERAL_INFO' },
                { label: 'Valores', value: 'VALUE_DETAILS' },
                { label: 'Características', value: 'ATTRIBUTES' },
                { label: 'Imagens', value: 'IMAGES' },
                { label: 'Mapa', value: 'MAPS' },
            ];
        }

        return [{ label: 'Inf. Gerais', value: 'GENERAL_INFO' }];
    }, [idParam]);

    const tabsComponent: Record<string, ReactNode> = {
        GENERAL_INFO: (
            <GeneralInfo
                values={values}
                onChange={setValues}
                isOpenForm={isOpenForm}
                onOpenForm={setIsOpenForm}
            />
        ),
        VALUE_DETAILS: (
            <ValueDetails
                onChange={(newValue) =>
                    setValues({ ...values, detalhesDeValores: newValue })
                }
                value={values?.detalhesDeValores}
            />
        ),
        ATTRIBUTES: (
            <Attributes
                onChange={(newValue) =>
                    setValues({ ...values, atributos: newValue })
                }
                value={values?.atributos}
                attributes={propertyAttributes || []}
            />
        ),
        IMAGES: (
            <UploadFiles
                data={values?.imagens || []}
                urlAdd={`/imobiliarias/imoveis/${values?.id}/imagem`}
                urlRemove={`/imobiliarias/imoveis/${values?.id}/imagem`}
                urlFeature={`/imobiliarias/imoveis/${values?.id}/imagem/destaque`}
                successedUpload={() => refetch()}
            >
                Clique ou arraste arquivos aqui, tamanho sugerido: 1525{" × "}670.
            </UploadFiles>
        ),
        MAPS: (
            <Maps
                onChange={(newValue) =>
                    setValues({ ...values, ...newValue })
                }
                value={{ latitude: values?.latitude, longitude: values?.longitude }}
            />
        )
    };

    return (
        <PropertyNewFormWrap>
            <Layout
                titlePage="Painel Imobiliário"
                breadcrumbs={[
                    {
                        label: 'Home',
                        href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                    },
                    {
                        label: title,
                        href: getLinkWithSlug(
                            ROUTES_REAL_STATE.PROPERTY.fullPath
                        ),
                    },
                    {
                        label: `Formulário ${title}`,
                        href: getLinkWithSlug(
                            ROUTES_REAL_STATE.PROPERTY.FORM.fullPath
                        ),
                    },
                ]}
                title={`Formulário ${title}`}
                buttonTitle={
                    isOpenForm && (
                        <>
                            <Button
                                color="nonary"
                                onClick={() => window.open('https://buscacepinter.correios.com.br/app/endereco/index.php')}
                            >
                                Consultar CEP Correios
                            </Button>
                            <Button
                                color="septernary"
                                onClick={() => onSubmit(false)}
                                loading={isLoadingCreate || isLoadingUpdate}
                            >
                                <AiOutlineSave />
                                Salvar
                            </Button>
                            {idParam !== null && !publicado && (
                                <Button
                                    color="octernary"
                                    onClick={() => onPublish()}
                                >
                                    <AiFillCheckCircle />
                                    Concluir
                                </Button>
                            )}
                        </>
                    )
                }
            >
                <PropertyNewFormWrap className="realstate-panel-property-new-form">
                    <Shadow>
                        <Loading active={isLoading} />

                        <OptionBlock
                            active={step}
                            onHandleActive={(param) => setStep(param)}
                            data={optionsBlock}
                            style={{ margin: '0 auto 0 0' }}
                        />

                        {tabsComponent[step]}
                    </Shadow>
                </PropertyNewFormWrap>
            </Layout>
        </PropertyNewFormWrap>
    );
};

export default RealStateNewForm;
