import { useState, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import {
    AiOutlineSave,
    AiOutlineDatabase,
    AiFillCheckCircle,
    AiFillCloseCircle,
} from 'react-icons/ai';

import {
    QUERIES_REAL_STATE,
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
} from '@constants';

import { Row, Button, Shadow, Loading } from 'components/atoms';
import { useRealState } from 'contexts/RealStateContext';

import {
    OptionBlock,
    InputText,
    InputNumber,
    InputToggle,
    TextHtmlArea,
} from 'components/molecules';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    createRealStateProperty,
    getRealStatePropertyShow,
    updateRealStateProperty,
} from 'services';

import { PropertyFormWrap } from './styles';

import Layout from '../../Layout';

import type { IAProperty } from '@types';

const PropertyForm = () => {
    const navigate = useNavigate();
    const { id: idParam } = useParams();

    const queryClient = useQueryClient();

    const title = 'Imóveis';

    const { getLinkWithSlug } = useRealState();

    const [step, setStep] = useState('GENERAL_INFO');
    const [optionsBlock] = useState([
        { label: 'Inf. Gerais', value: 'GENERAL_INFO' },
    ]);

    const [openForm, setOpenForm] = useState(false);

    const [id, setId] = useState<number | null>(null);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [valorAluguel, setValorAluguel] = useState(0);
    const [valorVenda, setValorVenda] = useState(0);
    const [destaque, setDestaque] = useState(false);
    const [publicado, setPublicado] = useState(false);
    const [editavel, setEditavel] = useState(false);
    const [latitudeLongitude, setLatitudeLongitude] = useState('');

    const { isLoading: isLoadingGet } = useQuery(
        [QUERIES_REAL_STATE.PROPERTY.show, id],
        () => getRealStatePropertyShow(String(idParam)),
        {
            enabled: !!idParam,
            onError: (err) => {
                onErrorMessage(title, err);

                navigate(getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.fullPath));
            },
            onSuccess: (res) => {
                setOpenForm(true);

                setId(res.id ? res.id : null);
                setTitulo(res.titulo ? res.titulo : '');
                setDescricao(res.descricao ? res.descricao : '');
                setValorAluguel(res.valorAluguel ? res.valorAluguel : 0);
                setValorVenda(res.valorVenda ? res.valorVenda : 0);
                setDestaque(res.destaque ? res.destaque : false);
                setLatitudeLongitude(
                    res.latitudeLongitude ? res.latitudeLongitude : ''
                );

                setPublicado(res?.publicado ? res.publicado : false);
                setEditavel(res?.editavel ? res.editavel : false);
            },
            refetchOnMount: true,
        }
    );

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
        (payload: Partial<IAProperty>) => createRealStateProperty(payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.show,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao criar novo registro!');

                queryClient.refetchQueries(QUERIES_REAL_STATE.PROPERTY.list);

                navigate(getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.fullPath));
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        (payload: Partial<IAProperty>) => updateRealStateProperty(String(id), payload),
        {
            mutationKey: QUERIES_REAL_STATE.PROPERTY.show,
            onSuccess: () => {
                onSuccessMessage(title, 'Sucesso ao atualizar o registro!');

                queryClient.refetchQueries(QUERIES_REAL_STATE.PROPERTY.list);

                queryClient.refetchQueries([
                    QUERIES_REAL_STATE.PROPERTY.show,
                    id,
                ]);
            },
            onError: (err) => {
                onErrorMessage(title, err);
            },
        }
    );

    const onSubmit = async (publish?: boolean) => {
        const dataSend = {
            titulo,
            descricao,
            valorAluguel,
            valorVenda,
            destaque,
            publicado: publish ?? publicado,
            latitudeLongitude,
        };

        const shapeSchema = {
            titulo: Yup.string().required('Título é obrigatório'),
            descricao: Yup.string().required('Descrição é obrigatória'),
        };

        const { success } = await onValidationSchema(shapeSchema, dataSend);

        if (success) {
            if (!id) {
                mutateCreate(dataSend);
            } else {
                mutateUpdate(dataSend);
            }
        }
    };

    function handlePublish() {
        onSubmit(!publicado);
    }

    function onNavigateBaseRegister() {
        navigate(
            getLinkWithSlug(
                `${ROUTES_REAL_STATE.PROPERTY.NEW_FORM.fullPath}/${id}`
            )
        );
    }


    const tabsComponent: Record<string, ReactNode> = {
        GENERAL_INFO: (
            <>
                <InputText
                    required
                    model="sinary"
                    label="Título"
                    name="titulo"
                    placeholder="Informe o titulo"
                    value={titulo}
                    onChange={setTitulo}
                />

                <TextHtmlArea
                    required
                    model="sinary"
                    label="Descrição"
                    name="descricao"
                    placeholder="Informe a descrição"
                    value={descricao}
                    onChange={setDescricao}
                />

                <Row>
                    <InputNumber
                        required
                        model="sinary"
                        label="Valor Aluguel"
                        value={valorAluguel}
                        onChange={setValorAluguel}
                        prefix="R$ "
                        name="valor"
                        placeholder="Informe o valor do aluguel"
                    />

                    <InputNumber
                        required
                        model="sinary"
                        label="Valor Venda"
                        value={valorVenda}
                        onChange={setValorVenda}
                        prefix="R$ "
                        name="valor"
                        placeholder="Informe o valor da venda"
                    />

                    <InputToggle
                        model="sinary"
                        id="destaque"
                        label="Destaque"
                        labelTrue="Sim"
                        labelFalse="Não"
                        name="destaque"
                        value={destaque}
                        onChange={(param) => setDestaque(param)}
                    />
                </Row>
            </>
        ),
    };

    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_REAL_STATE.PROPERTY.fullPath),
                },
                {
                    label: `Formulário ${title}`,
                    href: getLinkWithSlug(
                        ROUTES_REAL_STATE.PROPERTY.FORM.fullPath
                    ),
                },
            ]}
            title="Formulário Imóveis"
            buttonTitle={
                openForm && (
                    <Row>
                        {editavel && (
                            <Button
                                color="nonary"
                                onClick={() => onNavigateBaseRegister()}
                                loading={isLoadingGet}
                            >
                                <AiOutlineDatabase />
                                Registro Base
                            </Button>
                        )}

                        <Button color="nonary" onClick={() => onSubmit()} loading={isLoadingUpdate || isLoadingCreate}>
                            <AiOutlineSave />
                            Salvar
                        </Button>

                        <Button
                            color={publicado ? 'onedenary' : 'denary'}
                            onClick={() => handlePublish()}
                            loading={isLoadingUpdate || isLoadingCreate}
                        >
                            {publicado ? (
                                <AiFillCloseCircle />
                            ) : (
                                <AiFillCheckCircle />
                            )}
                            {publicado ? 'Despublicar' : 'Publicar'}
                        </Button>
                    </Row>
                )
            }
        >
            <PropertyFormWrap className="realstate-panel-property-form">
                <Shadow>
                    <Loading active={isLoadingGet} />

                    <OptionBlock
                        active={step}
                        onHandleActive={(param) => setStep(param)}
                        data={optionsBlock}
                        style={{ margin: '0 auto 0 0' }}
                    />

                    {tabsComponent[step]}
                </Shadow>
            </PropertyFormWrap>
        </Layout>
    );
};

export default PropertyForm;
