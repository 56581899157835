import { InputCapsuleWrap } from './styles';

import type { ICInputCapsule } from '@types';

const InputCapsule = ({
    htmlFor,
    label,
    required,
    children,
    borderLeft = false,
    borderRight = false,
    borderTop = false,
    borderBottom = false,
    empty,
    model = 'primary',
}: ICInputCapsule) => {
    function getLabel() {
        if (model === 'tertiary' && typeof label === 'string') {
            const labels = label.split(' ');

            if (labels.length > 1) {
                const firtWord = labels[0];
                delete labels[0];
                return (
                    <>
                        {firtWord}
                        <strong>{labels.join(' ')}</strong>
                    </>
                );
            }

            return label;
        }

        return label;
    }

    return (
        <InputCapsuleWrap
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderTop={borderTop}
            borderBottom={borderBottom}
            empty={empty || false}
            model={model}
            className="input-capsule-atom"
        >
            {label && (
                <label htmlFor={htmlFor}>
                    {getLabel()} {required && <span>*</span>}
                </label>
            )}
            <div className="input">{children}</div>
        </InputCapsuleWrap>
    );
};

export default InputCapsule;
