/* eslint-disable react/no-unstable-nested-components */
import { FiEye } from 'react-icons/fi';

import { HelperDate, HelperOptions } from 'components/atoms';
import { Table } from 'components/organisms';

import { RentContractFileWrap } from './styles';

import type { RentContractFileInterface } from './interface';

const RentContractFiles = ({
    data = [],
    isLoading,
}: RentContractFileInterface) => {
    const openFile = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <RentContractFileWrap>
            <Table
                columns={[
                    {
                        dataField: 'id',
                        text: 'Cód.',
                        headerStyle: { width: '5%' },
                    },
                    {
                        dataField: 'tipo',
                        text: 'Tipo',
                        headerStyle: { width: '45%' },
                        formatter: (fieldValue) =>
                            fieldValue === 'comprovantes'
                                ? 'Comprovantes'
                                : 'Documento',
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        headerStyle: { width: '45%' },
                        formatter: (fieldValue) => (
                            <HelperDate value={fieldValue} />
                        ),
                    },
                    {
                        dataField: 'url',
                        text: 'Opções',
                        headerStyle: { width: '5%' },
                        formatter: (fieldValue) =>
                            HelperOptions({
                                editIcon: <FiEye />,
                                editFunction: () => openFile(fieldValue),
                                remove: false,
                            }),
                    },
                ]}
                data={data}
                isLoading={isLoading}
            />
        </RentContractFileWrap>
    );
};

export default RentContractFiles;
