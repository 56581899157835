import { RowWrap } from './styles';

import type { RowInterface } from './interface';

const Row = ({
    children,
    haveColumn = false,
    style,
    className,
    id,
}: RowInterface) => (
    <RowWrap
        id={id}
        className={className ? `${className} row-atom` : 'row-atom'}
        haveColumn={haveColumn}
        style={style}
    >
        {children}
    </RowWrap>
);

export default Row;
