export const useField = (
    values: any,
    onChange: (param: Record<string, any>) => void,
    index: string
) => {
    let value = null;

    if (values !== undefined) {
        value = values?.[index];
    }

    return {
        onChange: (newValue: string | boolean | number) =>
            onChange({ ...values, [index]: newValue }),
        value,
        id: index,
        name: index,
    };
};
