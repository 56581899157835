import styled from 'styled-components'

export const ContainerWrap = styled.div`
  display: block;
  max-width: calc(100% - 3.75rem);
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;

  position: relative;

  @media (max-width: 992px) {
    max-width: 100%;
  }
`
