import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { QUERIES_EXTERNAL, QUERIES_MISCELLANEOUS } from '@constants';

import { Grid, Loading } from 'components/atoms';
import { onErrorMessage } from 'helpers';
import { createMiscellaneousAddress, getAddressExternalApi } from 'services';

import { InputMask, InputText } from 'components/molecules';
import { BusinessDataWrap } from './styles';
import type { IAPerson } from '@types';

import type { BusinessDataInterface } from './interface';

const BusinessData = ({ onChangeValues, values }: BusinessDataInterface) => {
    const titleMessagesAddress = 'Endereço';

    const useField = useCallback(
        (key: keyof IAPerson) => {
            let value = null;

            if (values !== undefined) {
                value = values?.[key];
            }

            return {
                onChange: (newValue: string) =>
                    onChangeValues({ ...values, [String(key)]: newValue }),
                value: value ? String(value) : '',
                id: String(key),
                name: String(key),
            };
        },
        [values]
    );

    const [isValidatedAddress, setIsValidatedAddress] = useState(true);
    const [isManualNeighborhood, setIsManualNeighborhood] = useState(false);

    const { mutate: onCreateAddress, isLoading: isLoadingCreateAddress } = useMutation(
        (payload: {
            bairro: string;
            cidade: string;
            uf: string;
            logradouro: string;
            complemento: string;
        }) => createMiscellaneousAddress(payload),
        {
            mutationKey: QUERIES_MISCELLANEOUS.ADDRESS.query,
            onSuccess: (res) => {
                onChangeValues({ ...values, bairrosIds: res?.data?.bairrosIds });
            },
            onError: (err) => {
                onErrorMessage(titleMessagesAddress, err);
            },
        }
    );

    const { mutate: onGetAddress, isLoading: isLoadingGetAddress } = useMutation(
        () => getAddressExternalApi(values?.cep || ''),
        {
            mutationKey: QUERIES_EXTERNAL.ADDRESS.consult,
            onSuccess: (res) => {

                setIsValidatedAddress(true);

                const newAddress = {
                    bairros: {
                        nome: res.bairro,
                        cidade: {
                            nome: res.localidade,
                            uf: res.uf,
                        },
                    },
                    logradouro: res.logradouro,
                    complemento: res.complemento,
                };

                let requestData = res;

                if (isManualNeighborhood) {
                    requestData = {
                        ...requestData,
                        bairro: values?.bairro,
                    };
                }

                setIsManualNeighborhood(res?.bairro === '');

                onChangeValues({ ...values, ...newAddress });
                onCreateAddress(requestData);
            },
            onError: (err) => {
                onErrorMessage(titleMessagesAddress, err);
            },
        }
    );


    const onChangedCep = (newValue: string) => {
        if (isValidatedAddress) {
            setIsValidatedAddress(false);
        }

        onChangeValues({ ...values, cep: newValue });
    }



    const onBlurCep = () => {
        if (values?.cep?.length === 8 && !isValidatedAddress) {
            onGetAddress();
        }
    }

    const onBlurNeighborhood = () => {
        if (values?.bairro !== '') {
            onGetAddress();
        }
    }


    let localizationValue = `${values?.bairros?.nome || ''} - ${values?.bairros?.cidade?.nome || ''
        } - ${values?.bairros?.cidade?.uf || ''}`

    if (isManualNeighborhood) {
        localizationValue = `${values?.bairros?.cidade?.nome || ''
            } - ${values?.bairros?.cidade?.uf || ''}`
    }


    return (
        <BusinessDataWrap>

            <Loading active={isLoadingCreateAddress || isLoadingGetAddress} />


            <InputText
                model="sinary"
                required
                label="Nome"
                placeholder="Informe o nome"
                {...useField('nome')}
            />

            <Grid numberColumns={2}>
                <InputMask
                    model="sinary"
                    required
                    mask={
                        (values?.telefone?.length || 9) > 10
                            ? '(99) 9 9999-9999'
                            : '(99) 9999-9999 9'
                    }
                    label="Telefone"
                    placeholder="Informe o telefone"
                    {...useField('telefone')}
                />

                <InputText
                    model="sinary"
                    required
                    label="E-mail"
                    placeholder="Informe o email"
                    {...useField('email')}
                />
            </Grid>




            <Grid numberColumns={isManualNeighborhood ? 3 : 2}>
                <InputMask
                    model="sinary"
                    required
                    mask="99999-999"
                    label="Cep"
                    placeholder="Informe o cep"
                    {...useField('cep')}
                    onChange={onChangedCep}
                    onBlur={onBlurCep}
                />

                {isManualNeighborhood && (
                    <InputText
                        model="sinary"
                        required
                        label="Bairro"
                        placeholder="Informe o bairro"
                        {...useField('bairro')}
                        onBlur={onBlurNeighborhood}
                    />
                )}

                <InputText
                    model="sinary"
                    required
                    label={
                        isManualNeighborhood ? 'Cidade / UF' : 'Bairro / Cidade / UF'
                    }
                    name="localizacao"
                    placeholder="Preencha o cep primeiro"
                    value={localizationValue}
                    onChange={() => null}
                />
            </Grid>

            <Grid numberColumns={2}>
                <InputText
                    model="sinary"
                    required
                    label="Logradouro"
                    placeholder="Informe o logradouro"
                    {...useField('logradouro')}
                />

                <InputText
                    model="sinary"
                    required
                    label="Número"
                    placeholder="Informe o número"
                    {...useField('numero')}
                />
            </Grid>

            <Grid numberColumns={2}>
                <InputText
                    model="sinary"
                    label="Complemento"
                    placeholder="Informe o complemento"
                    {...useField('complemento')}
                />

                <InputText
                    model="sinary"
                    label="Referência"
                    {...useField('referencia')}
                    placeholder="Informe a referência"
                />
            </Grid>

        </BusinessDataWrap>
    );
};

export default BusinessData;
