import { generateURL } from './prefix';
import api from '../api';

// import type { IAOwnerHasRealStateInvite } from '@types';

export const getOwnerOwnerHasRealStateInviteIndex = async (): Promise<
    any[]
> => {
    const url = generateURL(`/imobiliarias/convites`);

    const { data } = await api.get(url);

    return data;
};


export const acceptedOwnerRealStateOwnerInvite = async (inviteId: number) => {
    const url = generateURL(`/imobiliarias/convites/${inviteId}/aceitar`);

    const { data } = await api.get(url);

    return data;
};


export const removeOwnerRealStateOwnerInvite = async (inviteId: number) => {
    const url = generateURL(`/imobiliarias/convites/${inviteId}`);

    const { data } = await api.delete(url);

    return data;
};
