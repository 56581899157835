

import { CheckBoxWrap } from './styles';

import type { CheckBoxInterface } from './interface';

const CheckBox = ({ isActive = false, label, onClick }: CheckBoxInterface) => (
    <CheckBoxWrap className="check-box-atom" isActive={isActive} type="button" onClick={onClick}>
        <div className={`box ${isActive ? 'active' : ''}`}>
            ✓
        </div>

        {label && <span>{label}</span>}
    </CheckBoxWrap>
);

export default CheckBox;
