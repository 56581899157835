import { generateURL } from './prefix';
import api from '../api';
import type { IAPersonBankAccount } from '@types';

export const getRealStateBankAccountIndex = async (
): Promise<IAPersonBankAccount[]> => {
    const url = generateURL(`/imobiliaria/contas_bancarias`);

    const { data } = await api.get(url);

    return data;
};

export const createRealStateBankAccountIndex = async (
    payload: Partial<IAPersonBankAccount>
) => {
    const url = generateURL(`/imobiliaria/contas_bancarias`);

    const { data } = await api.post(url, payload);

    return data;
};

export const updateRealStateBankAccountIndex = async (
    idRealStateBankAccount: number | string,
    payload: Partial<IAPersonBankAccount>
) => {
    const url = generateURL(`/imobiliaria/contas_bancarias/${idRealStateBankAccount}`);

    const { data } = await api.put(url, payload);

    return data;
};

export const removeRealStateBankAccountIndex = async (
    idRealStateBankAccount: number | string
) => {
    const url = generateURL(`/imobiliaria/contas_bancarias/${idRealStateBankAccount}`);

    const { data } = await api.delete(url);

    return data;
};
