import { Helmet } from 'react-helmet';

import { AuthRecoveryCode} from 'components/templates';

const RecoveryCodePage = () => (
    <>
        <Helmet>
            <title>Autenticar - Imobiler</title>
        </Helmet>

        <AuthRecoveryCode />
    </>
);

export default RecoveryCodePage;
