import styled from 'styled-components';

export const AuthLayoutWrap = styled.div`
    display: block;
    width: 100%;
    overflow: auto;
    margin: 6rem 0 9.375rem 0;

    .h1-atom {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 1.75rem;
    }

    main {
        width: 77.5rem;
        margin: 0 auto;

        .shadow-atom {
            background-color: ${(props) => props.theme.colors.senary};
        }

        .button-form-atom#on-sign {
            margin-top: 1.25rem;
        }

        .text-atom {
            text-transform: initial;
            margin-top: 2.375rem;
            text-align: center;
        }
    }
`;
