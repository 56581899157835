import styled from 'styled-components'

export const SelectModalPersonWrap = styled.div`
  .title {
    font-size: 1.25rem;
    color: ${props => props.theme.colors.textPrimary};
    font-weight: 700;

    margin: 1.88rem 1.88rem 0.625rem 1.88rem;
    display: block;
    text-transform: uppercase;
  }

  .line-atom {
    margin-bottom: 1.5rem;
  }

  .button-person {
    display: inline-block;
    margin: 0.625rem 1.88rem 2rem 1.88rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    background-color: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.secondary};
    span {
      color: ${props => props.theme.colors.secondary};
    }

    div.icon {
      color: ${props => props.theme.colors.secondary};
    }
    &:hover,
    &.active {
      background-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.textSecondary};
      span {
        color: ${props => props.theme.colors.textSecondary};
      }

      div.icon {
        color: ${props => props.theme.colors.textSecondary};
        border-left: 1px solid ${props => props.theme.colors.tertiary};
      }
    }
  }
`
