import { useCallback } from 'react';

import { Grid } from 'components/atoms';
import { InputMask, InputText, Select } from 'components/molecules';
import { bankAccountEnums } from 'enums';

import { BankAccountDataWrap } from './styles';

import type { IAPersonBankAccount } from '@types';
import type { BankAccountDataInterface } from './interface';

const BankAccountData = ({
    onChangeValues,
    values,
}: BankAccountDataInterface) => {

    const useField = useCallback(
        (key: keyof IAPersonBankAccount) => {
            let value = null;

            if (values !== undefined) {
                value = values?.[key];
            }

            return {
                onChange: (newValue: string) =>
                    onChangeValues({ ...values, [String(key)]: newValue }),
                value: value ? String(value) : '',
                id: String(key),
                name: String(key),
                key
            };
        },
        [values]
    );
    return (
        <BankAccountDataWrap className="real-state-bank-account-panel-template">
            <InputText
                model="sinary"
                label="Nome do titular"
                placeholder="Informe o nome do títular"
                required
                readOnly
                {...useField('nomeTitular')}
            />

            <Select
                model="sinary"
                label="Tipo da Conta"
                placeholder="Informe o tipo da conta"
                required
                options={bankAccountEnums.backAccountType}
                {...useField('tipoConta')}
            />

            <Select
                model="sinary"
                label="Banco"
                placeholder="Informe o banco"
                required
                options={bankAccountEnums.bankAccountCodeData}
                {...useField('banco')}
            />

            <Grid numberColumns={2}>
                <InputText
                    model="sinary"
                    label="Agência da Conta"
                    placeholder="Informe o número da agência"
                    required
                    {...useField('agencia')}
                />

                <InputText
                    model="sinary"
                    label="Número da Conta"
                    placeholder="Informe o número da conta"
                    required
                    {...useField('conta')}
                />
            </Grid>

            <InputMask
                model="sinary"
                label="Documento do Responsável"
                mask={
                    useField('numeroDocumento').value.length > 11
                        ? '99.999.999/9999-99'
                        : '999.999.999-99'
                }
                placeholder="Informe o documento do responsável"
                readOnly
                required
                {...useField('numeroDocumento')}
            />
        </BankAccountDataWrap>
    );
};

export default BankAccountData;
