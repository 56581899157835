import { Helmet } from 'react-helmet';

import CustomerAccount from 'components/templates/Customer/Account';

const CustomerAccountPage = () => (
    <>
        <Helmet>
            <title>Minha Conta - Painel Cliente - Imobiler</title>
        </Helmet>

        <CustomerAccount />
    </>
);

export default CustomerAccountPage;
