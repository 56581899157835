import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { ImWhatsapp } from 'react-icons/im';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    ROUTES_ACCOUNT,
    ROUTES_REAL_STATE,
    QUERIES_REAL_STATE,
} from '@constants';
import { useRealState } from 'contexts/RealStateContext';
import { InputText, Modal } from 'components/molecules';
import { Table } from 'components/organisms';
import { Button, HelperDate, HelperOptions, Row } from 'components/atoms';

import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getRealStateContactIndex, removeRealStateContact } from 'services';
import { maskCellphone, sweetAlert } from 'utils';

import { ContactListWrap } from './styles';
import Layout from '../../Layout';

import type { IARealStateContact } from '@types';

const Contact = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [item, setItem] = useState<any>(null);

    const title = 'Contatos';

    const queryClient = useQueryClient();

    const { getLinkWithSlug } = useRealState();

    const { data: dataList, isLoading: isLoadingList } = useQuery(
        QUERIES_REAL_STATE.CONTACT.base,
        () => getRealStateContactIndex(),
        { refetchOnMount: true }
    );

    const { mutate: removeRegister, isLoading: isLoadingRemove } = useMutation(
        ({ id }: { id: number }) => removeRealStateContact(Number(id)),
        {
            mutationKey: QUERIES_REAL_STATE.CONTACT.base,
            onSuccess: (response) => {
                onSuccessMessage(
                    title,
                    response?.data?.message || 'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(QUERIES_REAL_STATE.CONTACT.base);
            },
            onError: (err) => onErrorMessage(title, err),
        }
    );

    function removeRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ba2e3',
                cancelButtonColor: '#FF4D4D',
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister({ id });
                }
            });
    }

    function onViewRegister(idParam: number) {

        setItem(dataList?.find((dataItem: IARealStateContact) => dataItem.id === idParam));
        setIsOpenModal(true);
    }


    return (
        <Layout
            titlePage="Painel Imobiliário"
            breadcrumbs={[
                {
                    label: 'Home',
                    href: getLinkWithSlug(ROUTES_ACCOUNT.SELECTED.fullPath),
                },
                {
                    label: title,
                    href: getLinkWithSlug(ROUTES_REAL_STATE.CONTACT.fullPath),
                },
            ]}
            title={title}
        >
            <ContactListWrap className="contact-template">
                <Table
                    isLoading={isLoadingList || isLoadingRemove}
                    columns={[
                        {
                            dataField: 'nome',
                            text: 'Nome',
                            sort: true,
                            headerStyle: { width: '100%' }
                        },
                        {
                            dataField: 'telefone',
                            text: 'Telefone',
                            sort: true,
                            formatter: fieldValue => maskCellphone(fieldValue),
                            headerStyle: { minWidth: '15rem' }
                        },

                        {
                            dataField: 'createdAt',
                            text: 'Data',
                            sort: true,
                            formatter: fieldValue => <HelperDate value={fieldValue} distance />,
                            headerStyle: { minWidth: '15rem' }
                        },

                        {
                            dataField: 'id',
                            text: 'Opções',
                            formatter: (fieldValue) =>
                                HelperOptions({
                                    editIcon: <FiEye />,
                                    editFunction: () => onViewRegister(fieldValue),
                                    removeFunction: () => removeRegisterRequest(fieldValue)
                                })
                        }
                    ]}
                    data={dataList}
                />
            </ContactListWrap>

            <Modal
                size="lg"
                open={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                title={`Contato de ${item?.nome}`}
                footer={
                    <>
                        <Button
                            color="octernary"
                            href={`https://api.whatsapp.com/send?phone=55${item?.telefone}`}
                        >
                            <ImWhatsapp size="1.5rem" />
                            Contatar
                        </Button>
                        <Button color="septernary" onClick={() => setIsOpenModal(false)}>
                            Fechar
                        </Button>
                    </>
                }
            >
                <InputText
                    label="Nome"
                    placeholder="Nome não informado"
                    name="name"
                    value={item?.nome}
                    onChange={() => null}
                    model="sinary"
                />

                <Row>
                    <InputText
                        label="E-mail"
                        placeholder="E-mail não informado"
                        name="email"
                        value={item?.email}
                        onChange={() => null}
                        model="sinary"

                    />

                    <InputText
                        label="Telefone"
                        placeholder="Telefone não informado"
                        name="phone"
                        value={item?.telefone ? maskCellphone(item?.telefone) : ''}
                        onChange={() => null}
                        model="sinary"

                    />
                </Row>

                <InputText
                    label="Assunto"
                    placeholder="Assunto não informado"
                    name="about"
                    value={item?.assunto}
                    onChange={() => null}
                    model="sinary"

                />

                <InputText
                    label="Mensagem"
                    placeholder="Mensagem não informada"
                    name="message"
                    value={item?.mensagem}
                    onChange={() => null}
                    model="sinary"
                />
            </Modal>

        </Layout>
    );
};

export default Contact;
