import type { IUOptions } from '@types'

class FinanceEnums {

  public financeTypes = [
    {
      label: 'À Pagar',
      value: 'A_PAGAR'
    },
    {
      label: 'À Receber',
      value: 'A_RECEBER'
    }
  ]

  public financeStatus = [
    {
      label: 'Pago',
      value: 'PAGO'
    },
    {
      label: 'Pendente',
      value: 'PENDENTE'
    }
  ]


  public getFinanceType(param?: string): IUOptions {
    const filtered = this.financeTypes.find(item => item.value === param)

    return !filtered ? { label: 'N/I', value: null } : filtered
  }

  public getFinanceStatus(param?: string): IUOptions {
    const filtered = this.financeStatus.find(item => item.value === param)

    return !filtered ? { label: 'N/I', value: null } : filtered
  }
}

export default new FinanceEnums()
